import {getItem} from './LocalStorage';
import {DEFAULT_IMAGE, ENCRYPTION_KEY, selected_lang} from './env';
import moment from 'moment-timezone';
import {errorMessageGuest} from './ToastMessages';
import {labels} from '../Constants/Translations';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import {NULL} from 'sass';
// import { getActiveCompanyUserMenus } from '../Store/Actions/MenuAction';
// import { menuActiveList } from '../Store/Reducers/MenusReducer';
// import { useSelector } from 'react-redux';
// import commonApi from '../Services/CommonService';
// import { errorMessage } from './ToastMessages';

export async function checkMenu(link, response) {
  let proceed = false;

  if (link === 'recruitment') {
    proceed = checkMenuNameExists('Recruitment', response);
  } else if (link === 'virtual') {
    proceed = checkMenuNameExists('Meetups', response);
  } else if (link === 'in-person') {
    proceed = checkMenuNameExists('Meetups', response);
  } else if (link === 'schedule') {
    proceed = checkMenuNameExists('Scheduler', response);
  } else if (link === 'group-chat') {
    proceed = checkMenuNameExists('Messages', response);
  } else if (link === 'snapshot') {
    proceed = checkMenuNameExists('Meetups', response);
  } else if (link === 'profile') {
    proceed = checkMenuNameExists('Meetups', response);
  }
  return proceed;
}

export async function checkMenuNameExists(name, response) {
  const exists = response.menus.menu_details.some(
    item => item.menu_name === name,
  );
  return exists;
}
export async function checkMenuExists(link, response) {
  const exists = response.menus.menu_details.some(
    item => item.link === '/' + link,
  );
  return exists;
}

export async function checkRouteExists(link, response) {
  const accessibleUrls = staticUrls
    .filter(urlObj => !menuRoutes.includes(urlObj.url))
    .map(route => route.url);
  // console.log(accessibleUrls,'===========>accessibleRoutesaccessibleRoutes')
  if (accessibleUrls.includes(link)) {
    return true;
  }
  const exists = response.menus.menu_details.some(
    item => item.link === '/' + link,
  );
  return exists;
}

export function extractStartDatesAsDate(data) {
  const dates = data.map(item => item.start_date.split(' ')[0]);

  // Create a Set to remove duplicates based on dates
  const uniqueDatesSet = new Set(dates);

  // Convert Set back to an array and convert each date string to a Date object
  const uniqueDatesArray = Array.from(uniqueDatesSet).map(
    dateString => new Date(dateString),
  );

  return uniqueDatesArray;
}

export function filterAppointmentDataByDate(data, startDate) {
  return data.filter(item => {
    const itemStartDate = new Date(item.start);
    const itemStartDateFormatted = new Date(
      itemStartDate.getFullYear(),
      itemStartDate.getMonth(),
      itemStartDate.getDate(),
    );
    const givenStartDate = new Date(startDate);
    const givenStartDateFormatted = new Date(
      givenStartDate.getFullYear(),
      givenStartDate.getMonth(),
      givenStartDate.getDate(),
    );
    return (
      itemStartDateFormatted.getTime() === givenStartDateFormatted.getTime()
    );
  });
}

export const blockInvalidChar = e =>
  ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();

export function validateStandard(arr) {
  // Count the occurrences of "OR" in the array
  const orCount = arr.reduce((count, item) => {
    if (item.add_on === 'OR' || item.add_on === 'or') {
      count++;
    }
    return count;
  }, 0);

  // Check if the count is at least 1
  return orCount >= 1;
}

export function formatNumberWithCommas(number) {
  const parts = number.toString().split('.');

  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return parts.join('.');
}

export const GoogleAble = [
  {
    name: 'Growth Consulting',
    subList: [
      '1-on-1 Consulting (30 minutes)',
      'Monthly Vitals Report',
      '1 Automation',
    ],
  },

  {
    name: 'Marketing',
    subList: [
      '1 Page Tailored Website',
      'On-Page SEO',
      '1 SEO Optimized Articles',
      'Google BP Management',
      'ROI Profile',
      'Social Media Setup',
      'Social Media Posting',
      'Basic Email Marketing',
      'Business Directory Management',
    ],
  },

  {
    name: 'Virtual Assistance',
    subList: [
      '2 Hours of Assistance',
      'Email Follow-ups',
      'Reputation Management',
    ],
  },
  {
    name: 'Sales',
    subList: [' Online Conversion Report'],
  },
  {
    name: 'Growth Tools',
    subList: [
      'LevelUp',
      'Live Convert',
      'Block Scheduler Pro',
      'Vitals',
      'Property Sync',
      'Capture',
      'Contactless',
    ],
  },

  {
    name: 'Website Maintenance',

    subList: [
      'Plug-in Updates',
      'Malware Protection',
      'Backup Management',
      'Domain Uptime Management',
      'Restoration Coverage',
    ],
  },
  {
    name: 'Competitor Monitoring',
    subList: [],
  },

  {
    name: 'Integration',

    subList: [],
  },
];
export const GoogleAble2 = [
  {
    name: 'Growth Consulting',
    subList: [
      '1-on-1 Consulting (1 hour)',
      'Monthly Vitals Report',
      'AI Business Prompting',
      'AI Model Training',
      '2 Automations',
    ],
  },

  {
    name: 'Marketing',
    subList: [
      '5-7 Page Tailored Website',
      'On Page SEO',
      '2 SEO Optimized Articles',
      'Google BP Management',
      'ROI Profile',
      'Social Media Setup',
      'Social Media Management',
      'Topical Authority Strategy',
      'Email Marketing (2 Sequences)',
      'Ads Management*',
      'Business Directory Management',
    ],
  },

  {
    name: 'Virtual Assistance',
    subList: [
      '5 Hours of Assistance',
      'Company Trained Bot',
      'Email Follow-ups',
      'Reputation Management',
      'SMS Messaging',
    ],
  },
  {
    name: 'Sales',
    subList: [
      '1 Phone Number',
      'Call Recording',
      'AI Call Takeaways',
      'Sales Analytics',
      'AI Sales Trend Analysis',
    ],
  },
  {
    name: 'Growth Tools',
    subList: [
      'CRM',
      'Live Convert',
      'Block Scheduler Pro',
      'Vitals',
      'Property Sync',
      'Chrome',
      'Contactless',
    ],
  },

  {
    name: 'Website Maintenance',

    subList: [
      'Plug-in Updates',
      'Malware Protection',
      'Backup Management',
      'Domain Uptime Reporting',
      'Restoration Coverage',
      'Content Edits',
    ],
  },
  {
    name: 'Competitor Monitoring',
    subList: ['Keywords', 'Social Media', 'Ads', 'Blog Articles'],
  },

  {
    name: 'Integration',

    subList: ['1 Custom Integration*'],
  },
];
export const GoogleAble3 = [
  {
    name: 'Growth Consulting',
    subList: [
      '1-on-1 Consulting (1 hour)',
      'Monthly Vitals Report',
      'AI Business Prompting',
      'AI Model Training',
      'AI Personality Training',
      '4 Automations',
    ],
  },

  {
    name: 'Marketing',
    subList: [
      '7-10 Page Tailored Website',
      'On Page SEO',
      '3 SEO Optimized Articles',
      'Google BP Management',
      'ROI Profile',
      'Social Media Setup',
      'Social Media Management',
      'Topical Authority Strategy',
      'Email Marketing (3 Sequences)',
      'Email Marketing A/B Testing',
      'Social Post Engagement',
      'Ads Management*',
      'Business Directory Management',
      'Direct Mail Management*',
    ],
  },

  {
    name: 'Virtual Assistance',
    subList: [
      '1 Phone Number',
      'Human Representative',
      'Incoming Appointments',
      'Follow-ups',
      'Customer Review Outreach',
      'SMS Communication',
      'Live Convert Engagement',
      'In-App CRM Messaging',
    ],
  },
  {
    name: 'Sales',
    subList: [
      '2 Phone Number',
      'Call Recording',
      'AI Call Takeaways',
      'Sales Analytics',
      'AI Sales Trend Analysis',
    ],
  },
  {
    name: 'Growth Tools',
    subList: [
      'CRM',
      'Live Convert',
      'Block Scheduler Pro',
      'Vitals',
      'Property Sync',
      'Chrome',
      'Contactless',
    ],
  },

  {
    name: 'Website Maintenance',

    subList: [
      'Plug-in Updates',
      'Malware Protection',
      'Backup Management',
      'Domain Uptime Management',
      'Restoration Coverage',
      'Content Edits',
    ],
  },
  {
    name: 'Competitor Monitoring',
    subList: [
      'Public Offerings',
      'Keywords',
      'Social Media',
      'Ads',
      'Blog Articles',
      '1 Custom Scrape',
    ],
  },

  {
    name: 'Integration',

    subList: ['2 Custom Integration*'],
  },
];

export function checkCatalogIds(arr) {
  for (const item of arr) {
    if (
      !item.hasOwnProperty('catalog_ids') ||
      item.catalog_details.length === 0
    ) {
      return false; // Return false if catalog_ids does not exist or is empty
    }
  }

  return true; // Return true if all items have non-empty catalog_ids
}

export function formatNumberWithTwoDecimals(number) {
  const roundedNumber = Number(number).toFixed(2);

  return roundedNumber.toString();
}

export function formatValueWithTwoDecimals(number) {
  const roundedNumber = Number(number).toFixed(2);

  return roundedNumber;
}

export function formatDiscountWithTwoDecimals(item) {

  let number  = item.discount
  const roundedNumber = Number(number).toFixed(2);

  return roundedNumber;
}


export function checkSubscriptionType(items) {
  const subscriptionTypes = items.map(item => item.subscription);
  const uniqueSubscriptionTypes = [...new Set(subscriptionTypes)];

  if (uniqueSubscriptionTypes.length === 1 && uniqueSubscriptionTypes[0]) {
    return uniqueSubscriptionTypes[0];
  } else {
    return 'mixed';
  }
}

export function formatMinutesTime(minutes) {
  if (minutes < 60) {
    return minutes + ' mins';
  } else {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    if (remainingMinutes === 0) {
      return hours + ' hours';
    } else {
      return hours + ' hours  ' + remainingMinutes + ' mins';
    }
  }
}
export function formatHoursMinutesTime(minutes) {
  if (minutes < 60) {
    return minutes + ' minutes';
  } else {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    if (remainingMinutes === 0) {
      return hours + ' hours';
    } else {
      return hours + ' hours  ' + remainingMinutes + ' minutes';
    }
  }
}

export const menuRoutes = [
  'meetups',
  'recruitment',
  'messages',
  'business_profile',
  'shareables',
  'circle',
  'schedule_activities',
  'offerings',
  'task',
  'analytics',
  'customer',
  'fleet',
  'payment',
  'check_in',
  'pipeline',
  'referrals',
  'customer_services',
  'dispatch_screen',
  'reward',
  'road_map',
  'timesheet',
  'reputation',
  'agreements',
  'team',
  'okrs',
  'form',
  'leaderboard',
  'files',
  'play_center',
  'call_center',
  'ride',
  'donate',
  'pick&drop',
  'projects',
  'chat_gpt',
  'ratings',
  'purchase_orders',
  'property',
  'announcements',
  'monitor',
];

export const staticUrls = [
  // {name: 'Register Company', url: 'registercompany'},
  {name: 'Landing Page', url: 'landing-page'},
  {name: 'Landing Page', url: 'updateProfile'},
  {name: 'Session Event', url: ''},
  {name: 'Recruitment Details', url: 'recruitment/:type/:id'},
  {name: 'Home Map', url: 'screens'},
  {name: 'Recruitment Invite', url: '/:companyName/recruitment/invite'},
  {name: 'Profile Invite', url: ':companyName/profile'},
  {name: 'Recruitment Invite', url: ':companyName/recruitment/:type/:id'},
  {name: 'Question Invite', url: ':username/question/invite'},
  {name: 'Event Invite', url: ':username/event/invite'},
  {name: 'Meetup Invite', url: ':companyName/:userId/meetup/invite'},
  {name: 'Login', url: 'login'},
  {name: 'Register', url: 'register'},
  {name: 'Onboarding', url: 'onboarding'},
  {name: 'Appointment Call', url: 'appointment-call'},
  {name: 'Business Profile', url: 'business_profile'},
  {name: 'Profile', url: 'profile/:any'},
  {name: 'Profile with Type', url: 'profile/:type/:any'},
  {name: 'Profile with ID', url: 'profile/:id'},
  {name: 'Messages', url: 'messages'},
  {name: 'Messages with ID', url: 'messages/:id'},
  {name: 'Payment', url: 'payment'},
  {name: 'Offerings', url: 'offerings'},
  {name: 'Technician Payment', url: 'payment'},
  {name: 'Technician Tasks', url: 'tasks'},
  {name: 'Dummy Messages', url: 'messages-dummy'},
  {name: 'Sessions and Events', url: 'meetups'},
  {name: 'Session Events with ID', url: 'meetups/:id'},
  {name: 'Ride Share Home', url: 'ride'},
  {name: 'Pick & Drop', url: 'pick&drop'},
  {name: 'Schedule Appointment', url: 'schedule-appointment'},
  {name: 'Retail Home', url: 'cart'},
  {name: 'Non-Profit', url: 'donate'},
  {name: 'Home Template', url: 'settings'},
  {name: 'My Notes', url: 'my_notes'},
  {name: 'Showcase', url: 'showcase'},
  {name: 'Scan QR', url: 'scanqr'},
  {name: 'Company QR', url: 'companyqr'},
  {name: 'Funnel', url: 'pipeline'},
  {name: 'Reward', url: 'reward'},
  {name: 'User Profile', url: 'user_profile'},
  {name: 'Check In', url: 'check_in'},
  {name: 'Announcements', url: 'announcements'},
  {name: 'Customer Services', url: 'customer_services'},
  {name: 'Home Market', url: 'home_market'},
  {name: 'Profile Page', url: 'profile'},
  {name: 'Consulting', url: 'consulting'},
  {name: 'OKRs', url: 'okrs'},
  {name: 'Monitor', url: 'monitor'},
  {name: 'Orders', url: 'orders'},
  {name: 'Events with Type and ID', url: 'events/:type/:id'},
  {name: 'Session Details', url: 'session/:sessionId'},
  {name: 'Session Details Dummy', url: 'session-details-dummy'},
  {name: 'Play Center', url: 'play_center'},
  {name: 'My Mall', url: 'my_mall'},
  {name: 'Customer Mall', url: 'customer-mall'},
  {name: 'PaymentsR', url: 'payment'},
  {name: 'Form Page', url: 'form'},
  {name: 'Agreement Proposal', url: 'agreements'},
  {name: 'Template', url: 'template'},
  {name: 'Call Center', url: 'call_center'},
  {name: 'Team', url: 'team'},
  {name: 'Fleet', url: 'fleet'},
  {name: 'Session Add', url: 'session-add'},
  {name: 'Recruitment', url: 'recruitment'},
  {name: 'Shareables', url: 'shareables'},
  {name: 'Schedule', url: 'schedule'},
  {name: 'Recruitment with ID', url: 'recruitment/:id'},
  {name: 'On-boarding Step 1', url: 'on-boarding'},
  {name: 'Widgets', url: 'widgets'},
  {name: 'Spotify Page', url: 'spotify'},
  {name: 'Spotify Authenticate', url: 'spotify/authenticate'},
  {name: 'Invite Page', url: 'invite-page'},
  {name: 'Referral Page', url: 'referrals'},
  {name: 'Referral Page with ID', url: 'referrals/:id'},
  {name: 'Builders Dummy Page', url: 'circle'},
  {name: 'Events Dummy', url: 'events/:num'},
  {name: 'Opportunity Details', url: 'opportunity/:num'},
  {name: 'Time Sheet', url: 'timeSheet'},
  {name: 'Time Sheet', url: 'timeSheet'},
  {name: 'Template Page', url: 'template-page'},
  {name: 'Preferences', url: 'preferences'},
  {name: 'Chat GPT', url: 'chat_gpt'},
  {name: 'Reputation', url: 'reputation'},
  {name: 'Ratings', url: 'ratings'},
  {name: 'Session Subpages', url: 'session-subpages'},
  {name: 'Appointments', url: 'appointments'},
  {name: 'Feedback', url: 'feedback'},
  {name: 'Snapshot Users List', url: 'snapshot'},
  {name: 'SnapshotUsersList', url: 'notification'},
  {name: 'Task', url: 'task'},
  {name: 'Road Map', url: 'road_map'},
  {name: 'Catalog', url: 'catalog'},
  {name: 'Customer', url: 'customer'},
  {name: 'Customer QR', url: 'customer-qr'},
  {name: 'Dispatch Screen', url: 'dispatch_screen'},
  {name: 'Project Management', url: 'project_management'},
  {name: 'Schedule Activities', url: 'schedule_activities'},
  {name: 'Leaderboard', url: 'leaderboard'},
  {name: 'Customers List', url: 'customers'},
  {name: 'Property', url: 'property'},
  {name: 'Projects', url: 'projects'},
  {name: 'History', url: 'history'},
  {name: 'Gifts Page', url: 'gifts'},
  {name: 'Qrs', url: 'qrs'},
  {name: 'Upload File', url: 'upload_File'},
  {name: 'Files', url: 'files'},
  {name: 'No Record Found', url: 'NoRecordFound'},
  {name: 'Widgets', url: 'widget/:user/:widget/'},
  {name: 'Widgets with Options', url: 'widget/:user/:widget/:options'},
  {name: 'Analytics', url: 'analytics'},
  {name: 'Notify Profile', url: 'notify_profile'},
  {name: 'Home', url: ':username/:pageType'},
  {name: 'Home with ID', url: ':id'},
  {name: 'Profile Temp Page', url: 'profile-temp-page'},
  {name: 'Work', url: 'work'},
];

export const staticUrlsLogout = [
  // {name: 'Register Company', url: 'registercompany'},
  // { name: 'Onboarding 2', url: 'onboarding2' },
  {name: 'Landing Page', url: ''},
  {name: 'Login', url: 'login'},
  {name: 'Invite', url: ':companyName/:userId/:type/invite'},
  {name: 'Recruitment Invite', url: '/:companyName/recruitment/invite'},
  // { name: 'Invite', url: ':username/:type/invite' },
  // { name: 'Login Type ID', url: 'login/:type/:id' },
  // { name: 'Register', url: 'register' },
  {name: 'Getting Started', url: 'started'},
  {name: 'Widgets', url: 'widget/:user/:widget/'},
  // { name: 'QR Links Home', url: 'qrlinks' },
  // { name: 'QR Links Type', url: 'qrlinks/:type' },
  // { name: 'QR Links Type ID', url: 'qrlinks/:type/:id' },
  {name: 'Widgets with Options', url: 'widget/:user/:widget/:options'},
  {name: 'Notify Profile', url: 'notify_profile'},
  {name: 'Home Username PageType', url: ':username/:pageType'},
  {name: 'Home with ID', url: ':id'},
  {name: 'Onboarding', url: 'on-boarding'},
  {name: 'Home Username PageType', url: ':username/:pageType'},

  // Add more routes as needed
  // { name: 'Additional Route', url: 'additional-route' },
];

export const checkURLAndLogin = (urls, currentURL, userLoggedIn) => {
  for (const urlObj of urls) {
    if (currentURL === urlObj.url && userLoggedIn) {
      return true;
    }
  }
  return false;
};

export function getDateWithTimezone(date) {
  return moment.tz(date, 'America/New_York').toISOString();
}

export function checkTimeDurationWithDate(startTime, endTime) {
  const date1 = new Date(startTime);
  const date2 = new Date(endTime);
  const diffTime = date2.getTime() - date1.getTime();
  // const diffDays = Math.ceil(diffTime / (1000  60  60 * 24));
  return diffTime ? diffTime : 0;
}

export function parseUrl(url) {
  const urlParts = url.split('/');
  const queryString = urlParts[urlParts.length - 1].split('?')[1];
  const queryParams = new URLSearchParams(queryString);
  const lastPart = urlParts[urlParts.length - 1];

  let companyName = '';
  let userId = '';
  let type = '';
  let typeId = '';

  if (urlParts.length === 3 && !isNaN(lastPart)) {
    userId = lastPart;
  } else if (urlParts.length >= 2) {
    const userIdParts = urlParts.pop().split('?')[0]; // Remove query parameters from userId
    userId = userIdParts; // Update userId
    companyName = urlParts.pop();

    if (queryParams && queryParams.has('meetup')) {
      type = 'meetup';
      typeId = queryParams.get('meetup');
    }
  }

  return {
    companyName,
    userId,
    type,
    typeId,
  };
}

export function checkUrlForDeepLink() {
  const {pathname} = window.location;
  const urlParts = pathname.split('/').filter(Boolean);

  const url = window.location.href.split('/').filter(Boolean);

  const lastSegment = url[url.length - 1];

  console.log('lastSegment:', lastSegment);

  const queryString = lastSegment.split('?')[1];
  console.log(queryString, 'queryParamsqueryParams');

  const queryParams = new URLSearchParams(queryString);
  return (
    queryParams &&
    queryParams.has('id') &&
    (urlParts.includes('invite') || urlParts.includes('profile')) &&
    (urlParts.includes('recruitment') ||
      urlParts.includes('virtual') ||
      urlParts.includes('in-person') ||
      urlParts.includes('schedule') ||
      urlParts.includes('group-chat') ||
      urlParts.includes('snapshot') ||
      urlParts.includes('profile'))
  );
}

// export function parseUrl(url) {
//   const urlParts = url.split('/');
//   const queryString = urlParts[urlParts.length - 1].split('?')[1];
//   const queryParams = new URLSearchParams(queryString);

//   let companyName = '';
//   let userId = '';
//   let type = '';
//   let typeId = '';

//   if(/^\d+$/.test(urlParts[urlParts.length - 1])){

// if(/^\d+$/.test(urlParts[3])){
//   userId = urlParts[urlParts.length - 1]

// }
// else{
//   companyName = urlParts[urlParts.length - 2].replace(/-/g, ' ')
//   userId = urlParts[urlParts.length - 1]
// }

//   }else{
//     if (urlParts.length >= 2) {
//       const userIdParts = urlParts.pop().split('?')[0]; // Remove query parameters from userId
//       userId = userIdParts; // Update userId
//       companyName = urlParts.pop().replace(/-/g, ' ');

//       if (queryParams && queryParams.has('meetup')) {
//           type = 'meetup';
//           typeId = queryParams.get('meetup');
//       }else if(queryParams && queryParams.has('recruitment')) {
//         type = 'recruitment';
//         typeId = queryParams.get('recruitment');
//     }
//     else if(queryParams && queryParams.has('consult')) {
//       type = 'consult';
//       typeId = queryParams.get('consult');
//   }
//   }
//   }

//   return {
//       companyName,
//       userId,
//       type,
//       typeId
//   };
// }

export const collectingUsers = item => {
  const creator = item.hasOwnProperty('creator_details')
    ? 'creator_details'
    : 'creator_detail';
  const moderator =
    item.chat_type === 'opportunity'
      ? 'team_member_details'
      : 'moderator_detail';
  const audience =
    item.chat_type === 'opportunity'
      ? 'other_persons_opportunity_chat_room'
      : 'audience_detail';

  const chatUsers = [
    {
      id: item[creator]['id'],
      first_name: item[creator]['first_name'],
      last_name: item[creator]['last_name'],
      profile_img: item[creator]['profile_img'],
      type: 'creator',
    },
  ];

  if (item[audience] && item[audience].length > 0) {
    item[audience].forEach(member => {
      member.type = 'audience';
      chatUsers.push(member);
    });
  }

  if (item[moderator] && item[moderator].length > 0) {
    item[moderator].forEach(member => {
      member.type = 'moderator';
      chatUsers.push(member);
    });
  }

  return chatUsers;
};

export const collectingOppoUsers = item => {
  const creator = item.hasOwnProperty('creator_details')
    ? 'creator_details'
    : 'creator_detail';
  // const moderator =
  //   item.chat_type === 'opportunity'
  //     ? 'team_member_details'
  //     : 'moderator_detail';
  // const audience =
  //   item.chat_type === 'opportunity'
  //     ? 'other_persons_opportunity_chat_room'
  //     : 'audience_detail';
  const moderator = 'team_member_details';
  const audience = 'other_persons_opportunity_chat_room';

  const chatUsers = [
    {
      id: item[creator]['id'],
      first_name: item[creator]['first_name'],
      last_name: item[creator]['last_name'],
      profile_img: item[creator]['profile_img'],
      type: 'creator',
    },
  ];
  if (item[audience] && item[audience].length > 0) {
    item[audience].forEach(item => {
      item.type = 'audience';
      chatUsers.push(item);
    });
  }
  if (item[moderator] && item[moderator].length > 0) {
    item[moderator].forEach(item => {
      item.type = 'moderator';
      chatUsers.push(item);
    });
  }
  return chatUsers;
};

export const filteringUsers = (users, ids) => {
  return users.filter(item => ids.includes(item.id));
};

// export const arrayColumn = (array, column) => {
//   return array.forEach(item => item[column]);
// };

export const arrayColumn = (array, column) => {
  let rtnArr = [];
  for (let x in array) {
    if (array[x].hasOwnProperty(column)) {
      rtnArr.push(array[x][column]);
    }
  }
  return rtnArr;
};

export const arrayColumnMerge = (array, column) => {
  let rtnArr = [];
  for (let x in array) {
    if (array[x].hasOwnProperty(column)) {
      rtnArr = rtnArr.concat(array[x][column]);
    }
  }
  return rtnArr;
};

// To show proper image and name of new one to one chat
export const newSingleChatroom = user => {
  let rtnObj = {
    creator: true,
    moderator: false,
    name: fixName(user.first_name, user.last_name),
    image: user.profile_img ? user.profile_img : DEFAULT_IMAGE,
    count: 0,
    chat_room_type: 'single',
    type: 'personal',
    type_id: null,
    chat_id: '',
    chat_name: '',
    like: null,
    private_group: 'no',
    profile_id: user.id,
  };

  return rtnObj;
};

// To show proper image and name of a chat
export const chatroomInfo = item => {
  const loginUser = getItem('user');
  let rtnObj = {
    creator: false,
    moderator: false,
    name: '',
    image: '',
    count: 0,
    chat_room_type: item.chat_room_type,
    type: item.chat_type ? item.chat_type : item.type,
    type_id: null,
    chat_id: item.chat_id ? item.chat_id : item.id,
    chat_name: item.chat_name ? item.chat_name : item.chat_room_name,
    like: item.like,
    private_group: 'no',
    profile_id: item.creator_id,
  };
  if (loginUser === item.creator_id) {
    rtnObj.creator = true;
    if (item.group_image) {
      rtnObj.image = item.group_image;
      rtnObj.name = item.group_name;
      rtnObj.count = item.count;
    } else {
      if (item.hasOwnProperty('user_detail')) {
        rtnObj.image = item.user_detail.profile_img
          ? item.user_detail.profile_img
          : DEFAULT_IMAGE;
        rtnObj.name = fixName(
          item.user_detail.first_name,
          item.user_detail.last_name,
        );
        rtnObj.profile_id = item.user_detail.id;
      } else if (item.audience_detail && item.audience_detail.length > 0) {
        // faltu kaam
        rtnObj.image = item.audience_detail[0].profile_img
          ? item.audience_detail[0].profile_img
          : DEFAULT_IMAGE;
        rtnObj.name = fixName(
          item.audience_detail[0].first_name,
          item.audience_detail[0].last_name,
        );
        rtnObj.profile_id = item.audience_detail[0].id;
      } else if (
        item.other_persons_opportunity_chat_room &&
        item.other_persons_opportunity_chat_room.length > 0
      ) {
        // faltu kaam 3
        rtnObj.image = item.other_persons_opportunity_chat_room[0].profile_img
          ? item.other_persons_opportunity_chat_room[0].profile_img
          : DEFAULT_IMAGE;
        rtnObj.name = fixName(
          item.other_persons_opportunity_chat_room[0].first_name,
          item.other_persons_opportunity_chat_room[0].last_name,
        );
        rtnObj.profile_id = item.other_persons_opportunity_chat_room[0].id;
      } else if (item.leave_user_detail && item.leave_user_detail.length > 0) {
        // faltu kaam 2
        rtnObj.image = item.leave_user_detail[0].profile_img
          ? item.leave_user_detail[0].profile_img
          : DEFAULT_IMAGE;
        rtnObj.name = fixName(
          item.leave_user_detail[0].first_name,
          item.leave_user_detail[0].last_name,
        );
        rtnObj.profile_id = item.leave_user_detail[0].id;
      }
    }
  } else {
    if (item.group_image) {
      rtnObj.image = item.group_image;
      rtnObj.name = item.group_name;
      rtnObj.count = item.count;
    } else {
      if (item.hasOwnProperty('creator_detail')) {
        rtnObj.image = item.creator_detail.profile_img
          ? item.creator_detail.profile_img
          : DEFAULT_IMAGE;
        rtnObj.name = fixName(
          item.creator_detail.first_name,
          item.creator_detail.last_name,
        );
      } else {
        rtnObj.image = item.creator_details.profile_img
          ? item.creator_details.profile_img
          : DEFAULT_IMAGE;
        rtnObj.name = fixName(
          item.creator_details.first_name,
          item.creator_details.last_name,
        );
      }
    }
  }
  if (item.chat_type === 'personal') {
  } else if (item.chat_type === 'opportunity') {
    rtnObj.type_id = item.opportunity_id;
    if (item.team_member_details && item.team_member_details.length > 0) {
      item.team_member_details.forEach(obj => {
        if (obj.id === getItem('user')) {
          rtnObj.moderator = true;
        }
      });
    }
  } else {
    rtnObj.type_id = item.type_id;
    if (item.moderator_detail.length > 0) {
      item.moderator_detail.forEach(obj => {
        if (obj.id === getItem('user')) {
          rtnObj.moderator = true;
        }
      });
    }
  }
  if (item.private_group) {
    rtnObj.private_group = item.private_group;
  }

  return rtnObj;
};

// To show proper image and name of a pre-chat
export const chatroomInfoItem = (item, type = '') => {
  let rtnObj = {
    creator: false,
    moderator: false,
    name: '',
    image: '',
    count: 0,
    chat_room_type: 'single',
    type: type,
    type_id: null,
    // chat_id: item.chat_id,
    chat_id: '',
    chat_name: '',
    like: item.like,
    private_group: 'no',
    profile_id: '',
  };

  if (item.chat_detail && item.chat_detail.length > 0) {
    const chatInfo = chatroomInfo(item.chat_detail);
    return chatInfo;
  } else {
    if (item.hasOwnProperty('creator_detail')) {
      rtnObj.image = item.creator_detail.profile_img
        ? item.creator_detail.profile_img
        : DEFAULT_IMAGE;
      rtnObj.name = fixName(
        item.creator_detail.first_name,
        item.creator_detail.last_name,
      );
      rtnObj.profile_id = item.creator_detail.id;
    } else {
      rtnObj.image = item.creator_details.profile_img
        ? item.creator_details.profile_img
        : DEFAULT_IMAGE;
      rtnObj.name = fixName(
        item.creator_details.first_name,
        item.creator_details.last_name,
      );
      rtnObj.profile_id = item.creator_details.id;
    }

    rtnObj.type_id = item.id;
    if (item.team_member_details.length > 0) {
      item.team_member_details.forEach(obj => {
        if (obj.id === getItem('user')) {
          rtnObj.moderator = true;
        }
      });
    }

    return rtnObj;
  }
};
// To show proper image and name of a pre-oppo-chat
export const chatroomInfoOppo = (item, type = '') => {
  const loginUser = getItem('user');
  let rtnObj = {
    creator: false,
    moderator: false,
    name: '',
    image: '',
    count: 0,
    chat_room_type: item.chat_detail.chat_room_type,
    type: type,
    type_id: null,
    chat_id: item.chat_detail.id,
    chat_name: item.chat_detail.chat_room_name,
    like: item.like,
    private_group: 'no',
    profile_id: item.chat_detail.creator_id,
  };

  if (loginUser === item.chat_detail.creator_id) {
    rtnObj.creator = true;
    if (item.chat_detail.group_image) {
      rtnObj.image = item.chat_detail.group_image;
      rtnObj.name = item.chat_detail.group_name;
      // rtnObj.count = item.count;
    } else if (
      item.other_persons_opportunity_chat_room &&
      item.other_persons_opportunity_chat_room.length > 0
    ) {
      rtnObj.image = item.other_persons_opportunity_chat_room[0].profile_img
        ? item.other_persons_opportunity_chat_room[0].profile_img
        : DEFAULT_IMAGE;
      rtnObj.name = fixName(
        item.other_persons_opportunity_chat_room[0].first_name,
        item.other_persons_opportunity_chat_room[0].last_name,
      );
    } else {
      // rtnObj.image = item.user_detail.profile_img
      //   ? item.user_detail.profile_img
      //   : DEFAULT_IMAGE;
      // rtnObj.name =
      //   item.user_detail.first_name + ' ' + item.user_detail.last_name;
    }
  } else if (loginUser === item.created_by && type === 'opportunity') {
    rtnObj.creator = true;
    rtnObj.type_id = item.opportunity_id;
    rtnObj.profile_id = item.user_id;
    rtnObj.image = item.profile_img ? item.profile_img : DEFAULT_IMAGE;
    rtnObj.name = fixName(item.first_name, item.last_name);
  } else {
    if (item.chat_detail.group_image) {
      rtnObj.image = item.chat_detail.group_image;
      rtnObj.name = item.chat_detail.group_name;
      // rtnObj.count = item.count;
    } else {
      if (item.hasOwnProperty('creator_detail')) {
        rtnObj.image = item.creator_detail.profile_img
          ? item.creator_detail.profile_img
          : DEFAULT_IMAGE;
        rtnObj.name = fixName(
          item.creator_detail.first_name,
          item.creator_detail.last_name,
        );
      } else {
        rtnObj.image = item.creator_details.profile_img
          ? item.creator_details.profile_img
          : DEFAULT_IMAGE;
        rtnObj.name = fixName(
          item.creator_details.first_name,
          item.creator_details.last_name,
        );
      }
    }
  }
  if (type === 'personal') {
  } else if (type === 'opportunity') {
    rtnObj.type_id = item.opportunity_id;
    if (item.team_member_details && item.team_member_details.length > 0) {
      item.team_member_details.forEach(obj => {
        if (obj.id === getItem('user')) {
          rtnObj.moderator = true;
          rtnObj.count += 1; // temp sol
        }
      });
    }
  } else {
    // rtnObj.type_id = item.type_id;
    // if (item.moderator_detail.length > 0) {
    //   item.moderator_detail.forEach(obj => {
    //     if (obj.id === getItem('user')) {
    //       rtnObj.moderator = true;
    //     }
    //   });
    // }
  }
  // if (item.private_group) {
  //   rtnObj.private_group = item.private_group;
  // }

  return rtnObj;
};
// To show proper image and name of refer oppo chat
export const chatroomInfoOppoRefer = (item, type = '') => {
  const loginUser = getItem('user');
  let rtnObj = {
    creator: false,
    moderator: false,
    name: '',
    image: '',
    count: 0,
    chat_room_type: item.chat_detail.chat_room_type,
    type: type,
    type_id: null,
    chat_id: item.chat_detail.id,
    chat_name: item.chat_detail.chat_room_name,
    like: item.like,
    private_group: 'no',
    profile_id: item.chat_detail.creator_id,
    status: item.chat_detail.status,
  };

  if (loginUser === item.chat_detail.creator_id) {
    rtnObj.creator = true;
    if (item.chat_detail.group_image) {
      rtnObj.image = item.chat_detail.group_image;
      rtnObj.name = item.chat_detail.group_name;
      // rtnObj.count = item.count;
    } else if (
      item.other_persons_opportunity_chat_room &&
      item.other_persons_opportunity_chat_room.length > 0
    ) {
      rtnObj.image = item.other_persons_opportunity_chat_room[0].profile_img
        ? item.other_persons_opportunity_chat_room[0].profile_img
        : DEFAULT_IMAGE;
      rtnObj.name = fixName(
        item.other_persons_opportunity_chat_room[0].first_name,
        item.other_persons_opportunity_chat_room[0].last_name,
      );
    } else {
      // rtnObj.image = item.user_detail.profile_img
      //   ? item.user_detail.profile_img
      //   : DEFAULT_IMAGE;
      // rtnObj.name =
      //   item.user_detail.first_name + ' ' + item.user_detail.last_name;
    }
  } else {
    if (item.chat_detail.group_image) {
      rtnObj.image = item.chat_detail.group_image;
      rtnObj.name = item.chat_detail.group_name;
      // rtnObj.count = item.count;
    } else {
      if (item.hasOwnProperty('creator_detail')) {
        rtnObj.image = item.creator_detail.profile_img
          ? item.creator_detail.profile_img
          : DEFAULT_IMAGE;
        rtnObj.name = fixName(
          item.creator_detail.first_name,
          item.creator_detail.last_name,
        );
      } else {
        rtnObj.image = item.creator_details.profile_img
          ? item.creator_details.profile_img
          : DEFAULT_IMAGE;
        rtnObj.name = fixName(
          item.creator_details.first_name,
          item.creator_details.last_name,
        );
      }
    }
  }
  if (type === 'personal') {
  } else if (type === 'opportunity') {
    rtnObj.type_id = item.opportunity_id;
    if (item.team_member_details.length > 0) {
      item.team_member_details.forEach(obj => {
        if (obj.id === getItem('user')) {
          rtnObj.moderator = true;
          rtnObj.count += 1; // temp sol
        }
      });
    }
  } else {
    // rtnObj.type_id = item.type_id;
    // if (item.moderator_detail.length > 0) {
    //   item.moderator_detail.forEach(obj => {
    //     if (obj.id === getItem('user')) {
    //       rtnObj.moderator = true;
    //     }
    //   });
    // }
  }
  // if (item.private_group) {
  //   rtnObj.private_group = item.private_group;
  // }

  return rtnObj;
};
//
export const chatroomInfoOppoMsgList = (item, type = '') => {
  const loginUser = getItem('user');
  let rtnObj = {
    creator: false,
    moderator: false,
    name: fixName(item.first_name, item.last_name),
    // name: fixName(item.first_name, item.last_name),
    image: item.profile_img,
    count: 0,
    chat_room_type: 'single',
    type: type,
    type_id: item.opportunity_id,
    chat_id: item.chat_room_id,
    chat_name: '',
    like: item.like,
    private_group: 'no',
    profile_id: item.user_id,
    status: '',
  };

  if (loginUser === item.created_by) {
    rtnObj.creator = true;
  } else if (item.team_member_details.length > 0) {
    const found = item.team_member_details.find(objs => objs.id === loginUser);
    if (typeof found === 'object') {
      rtnObj.moderator = true;
    }
  }

  if (
    item.hasOwnProperty('chat_detail') &&
    Object.entries(item.chat_detail).length > 0
  ) {
    rtnObj.chat_name = item.chat_detail.chat_room_name;
    if (item.chat_detail.group_image) {
      rtnObj.chat_room_type = 'group';
      rtnObj.count =
        item.team_member_details.length +
        item.other_persons_opportunity_chat_room.length;
      rtnObj.group_image = item.chat_detail.group_image;
      rtnObj.group_name = item.chat_detail.group_name;
      rtnObj.image = item.chat_detail.group_image;
      rtnObj.name = item.chat_detail.group_name;
      rtnObj.status = item.chat_detail.status;
    }
    return rtnObj;
  } else {
    return rtnObj;
  }
};

export const capitalizeFirst = str => {
  if (str && str.length > 0) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return str;
};

export const fixName = (fname, lname) => {
  if (fname && lname) {
    let name = `${capitalizeFirst(fname)} ${lname.charAt(0).toUpperCase()}.`;
    if (name.length > 12) {
      return `${name.slice(0, 10)}...`;
    } else {
      return name;
    }
  } else {
    if (fname && fname.length > 12) {
      return `${fname.slice(0, 10)}...`;
    } else {
      if (fname) {
        let newName = `${capitalizeFirst(fname)}`;

        return newName;
      }
      return 'no Name';
    }
  }
  // return `${capitalizeFirst(fname)} ${lname.charAt(0).toUpperCase()}.`;
};

export const capitalizeWords = name => {
  return name
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};
export const removeLeftUsers = user => {
  let groupUsers = [];
  if (user.length > 0) {
    for (let index = 0; index < user.length; index++) {
      const element = user[index];
      if (
        (element.status !== 'left' && element.status !== 'pending') ||
        element.type === 'creator' ||
        element.type === 'moderator'
      ) {
        groupUsers.push(element);
      }
    }
    return groupUsers;
  }
};

export const convertTime12To24 = time => {
  let hours = Number(time.match(/^(\d+)/)[1]);
  let minutes = Number(time.match(/:(\d+)/)[1]);
  let AMPM = time.match(/\s(.*)$/)[1];
  if (AMPM === 'PM' && hours < 12) hours = hours + 12;
  if (AMPM === 'AM' && hours === 12) hours = hours - 12;
  let sHours = hours.toString();
  let sMinutes = minutes.toString();
  if (hours < 10) sHours = '0' + sHours;
  if (minutes < 10) sMinutes = '0' + sMinutes;
  return sHours + ':' + sMinutes;
};

export const formatAmount = amount => {
  const isDecimal = amount % 1 !== 0; // Check if amount has decimal places

  amount = Math.round(amount * 100) / 100; // Round to two decimal places

  if (isDecimal && amount < 1000 && amount > 1) {
    // Handle decimal values
    return `$${amount.toFixed(2)}`;
  } else if (amount < 1000 && amount > 1) {
    // Handle non-decimal values less than 1000
    return '$' + amount.toString();
  } else if (amount < 1) {
    // Handle non-decimal values less than 1
    return '';
  } else {
    // Handle values greater than or equal to 1000
    const thousands = Math.floor(amount / 1000);
    const remainder = amount % 1000;
    const formattedRemainder =
      remainder >= 100 ? `.${remainder.toString().slice(0, 1)}` : '';
    return `$${thousands}${formattedRemainder}k`;
  }
};

export const appendZerosToUserId = userId => {
  // If the user ID is less than 8 characters, add "00" before the ID to fill 8 digits
  if (!userId) {
  } else {
    if (userId.length < 8 && userId.length > 0) {
      const zerosToAdd = 8 - userId.length;
      const paddedUserId = '0'.repeat(zerosToAdd) + userId;
      return paddedUserId;
    } else if (userId.length === 8) {
      return userId;
    }
  }
};

export const ticketPrice = (total, amount, remianingTickets) => {
  let rtnArr = [{number: 0, price: 0}];
  if (amount > 0) {
    for (let i = 1; i <= remianingTickets; i++) {
      rtnArr.push({number: i, price: amount * i});
    }
  } else {
    for (let i = 1; i <= remianingTickets; i++) {
      rtnArr.push({number: i, price: 'Free'});
    }
  }

  return rtnArr;
};
export const createFollowUpMessage = user => {
  if (user.first_name && user.last_name) {
    // Assuming user.first_name and user.last_name are provided in the user object
    const firstName = user.first_name;
    const lastName = user.last_name;

    // You can customize the follow-up message format as per your requirements
    const followUpMessage = `Follow up with @ ${firstName} ${lastName}`;

    return followUpMessage;
  } else {
    return "User's first name and last name are not provided.";
  }
};

// To show proper count in chat
export const countUsers = (arr, left) => {
  const validStatus = ['match', 'decline', 'left'];
  let count = 1;

  if (arr && arr.length > 0) {
    for (let index = 0; index < arr.length; index++) {
      const element = arr[index];
      if (element.status === 'left' || element.type === 'creator') {
      } else if (
        !element.status ||
        (element.status && element.status === 'accepted')
      ) {
        count = count + 1;
      } else if (left && element.status === 'left') {
        count = count + 1;
      } else if (element.user_type && validStatus.includes(element.status)) {
        count = count + 1;
      }
    }
  }

  return count;
};
// time manage function for events tickets
export const getIntervalEND = (saleEndDateString, serverTime) => {
  const saleEndDate = new Date(saleEndDateString);
  const now = new Date(serverTime);
  const diffInSeconds = (saleEndDate.getTime() - now.getTime()) / 1000; // difference in seconds

  if (diffInSeconds < 0) {
    const diffInMinutes = Math.floor(-diffInSeconds / 60);
    if (diffInMinutes < 60) {
      return `Sales ended ${diffInMinutes} minute${
        diffInMinutes > 1 ? 's' : ''
      } ago`;
    } else {
      const diffInHours = Math.floor(diffInMinutes / 60);
      if (diffInHours < 24) {
        return `Sales ended ${diffInHours} hour${
          diffInHours > 1 ? 's' : ''
        } ago`;
      } else {
        const diffInDays = Math.floor(diffInHours / 24);
        return `Sales ended ${diffInDays} day${diffInDays > 1 ? 's' : ''} ago`;
      }
    }
  } else {
    if (diffInSeconds < 60) {
      return `Sales ends in ${Math.floor(diffInSeconds)} second${
        diffInSeconds > 1 ? 's' : ''
      }`;
    } else if (diffInSeconds < 3600) {
      return `Sales ends in ${Math.floor(diffInSeconds / 60)} minute${
        Math.floor(diffInSeconds / 60) > 1 ? 's' : ''
      }`;
    } else {
      const diffInHours = Math.floor(diffInSeconds / 3600);
      if (diffInHours < 24) {
        return `Sales ends in ${diffInHours} hour${diffInHours > 1 ? 's' : ''}`;
      } else {
        const diffInDays = Math.floor(diffInHours / 24);
        return `Sales ends in ${diffInDays} day${diffInDays > 1 ? 's' : ''}`;
      }
    }
  }
};

export const CountSessionHistoryUsers = arr => {
  let countMod = 0;

  if (arr && arr.length > 0) {
    for (let index = 0; index < arr.length; index++) {
      const element = arr[index];
      if (element.user_type === 'moderator') {
        countMod = countMod + 1;
      }
    }
  }

  return arr.length + '/' + countMod;
};

export const distanceInMiles = (lat1, lon1, lat2, lon2) => {
  const R = 3958.8; // Radius of the earth in miles
  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c; // Distance in miles
  return distance.toFixed(2);
};

export const deg2rad = deg => {
  return deg * (Math.PI / 180);
};

export const getDomainFromUrl = url => {
  const parsedUrl = new URL(url);
  if (parsedUrl.hostname.startsWith('www.')) {
    return parsedUrl.hostname.replace(/^www\./, '');
  }
  return parsedUrl.hostname;
};

export const subtractTime = (
  time = '2001-01-01 01:00:00',
  quantity = 1,
  unit = 'minute',
  format = 'YYYY-MM-DD HH:mm:ss',
) => {
  const originalTime = moment(time);
  const newTime = originalTime.subtract(quantity, unit);
  const formattedTime = newTime.format(format);
  return formattedTime;
};

export const loginUserRole = e => {
  if (getItem('userRole') === 'guest') {
    if (e) {
      e.preventDefault();
    }
    errorMessageGuest(labels.errorMessage_for_uninvite_User[selected_lang]);
  }
};

export function generateName(length) {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export const getChatCount = (chatData, currentUser) => {
  // Filter private and non-private chats
  const privateChats = chatData.filter(
    chat =>
      chat.isPrivate &&
      (chat.privateChatData.sent_to === currentUser ||
        chat.sent_by === currentUser),
  );

  const nonPrivateChats = chatData.filter(chat => chat.isPrivate === false);
  const nonPrivateChatsCount =
    nonPrivateChats && nonPrivateChats.length ? nonPrivateChats.length : 0;
  const privateChatsCount =
    privateChats && privateChats.length ? privateChats.length : 0;

  // Count of non-private chats involving the current user
  const chatCount = nonPrivateChatsCount + privateChatsCount;

  return chatCount;
};

export const list = [
  {title: 'Personal'},
  {title: 'Workspace 1'},
  {title: 'Workspace 2'},
];

export const getCityStateFromAddress = fetchDetails => {
  const {lat: latitude, lng: longitude} = fetchDetails.geometry.location;
  let city = '';
  let state = '';
  let country = '';
  let address = '';

  fetchDetails.address_components.forEach(addressComponent => {
    if (addressComponent.types.includes('locality')) {
      city = addressComponent.long_name;
    } else if (addressComponent.types.includes('administrative_area_level_2')) {
      state = addressComponent.short_name;
    } else if (addressComponent.types.includes('administrative_area_level_1')) {
      state = addressComponent.short_name;
    } else if (addressComponent.types.includes('country')) {
      country = addressComponent.long_name;
    }
  });

  if (city || state) {
    address = [city, state].filter(Boolean).join(', ');
  } else if (country) {
    address = country;
  } else {
    address = 'Unknown Location';
  }

  return {latitude, longitude, address};
};
export const formatString = input => {
  // Regular expression pattern to match a basic URL
  const urlPattern = /^(http|https):\/\/[^\s/$.?#].[^\s]*$/;

  if (urlPattern.test(input)) {
    return (
      <img
        style={{maxWidth: '20px', marginBottom: '8px'}}
        src={input}
        alt="url"
      />
    );
  } else {
    return <p className="single-line-text">{input} </p>;
  }
};

function removeDuplicatesFromArray(arr, key) {
  const uniqueObjects = [];
  const seenKeys = {};

  for (const obj of arr) {
    if (!seenKeys[obj[key]]) {
      seenKeys[obj[key]] = true;
      uniqueObjects.push(obj);
    }
  }

  return uniqueObjects;
}

export function getEmojisAndSnapshots(chats, userId) {
  let emojisSentByUser = [];
  let snapshotsSentByUser = [];

  for (const date in chats) {
    const chatThreads = chats[date];

    for (const chatId in chatThreads) {
      const chat = chatThreads[chatId];

      if (chat.emojis && chat.emojis.some(emoji => emoji.sent_by === userId)) {
        emojisSentByUser.push(...chat.emojis);
      }

      if (chat.type === 'snapshot' && chat.sent_by === userId) {
        for (let picture of chat.picture_url) {
          snapshotsSentByUser.push(picture);
        }
        // snapshotsSentByUser.push(
        //   ...chat.picture_url.forEach(picture => picture),
        // );
      }
    }
  }

  if (emojisSentByUser.length < 4 || snapshotsSentByUser.length < 4) {
    const secondLastChatThread = chats[chats.length - 2];
    if (secondLastChatThread && secondLastChatThread.data) {
      const secondLastChatData = secondLastChatThread.data;

      for (const chat of secondLastChatData) {
        if (
          emojisSentByUser.length < 4 &&
          chat.emojis &&
          chat.emojis.some(emoji => emoji.sent_by === userId)
        ) {
          emojisSentByUser.push(...chat.emojis);
        }

        if (
          snapshotsSentByUser.length < 4 &&
          chat.type === 'snapshot' &&
          chat.sent_by === userId
        ) {
          snapshotsSentByUser.push(...chat.picture_url);
        }

        if (emojisSentByUser.length >= 4 && snapshotsSentByUser.length >= 4) {
          break;
        }
      }
    }
  }

  const uniqueEmojis = removeDuplicatesFromArray(emojisSentByUser, 'emojiName');
  const uniqueSnapshots = removeDuplicatesFromArray(snapshotsSentByUser, 'id');

  const emojis = uniqueEmojis.slice(0, 4);
  const snapshots = uniqueSnapshots.slice(0, 3);

  // return emojis
  return {emojis: emojis, snapshots: snapshots};
}

export function getRepliesEmojisAndSnapshots(chatData, userId) {
  let emojisSentByUser = [];
  let snapshotsSentByUser = [];

  // for (const chat of chatData) {
  //   if (chat.emojis && chat.emojis.some((emoji) => emoji.sent_by == userId)) {
  //     emojisSentByUser.push(...chat.emojis);
  //   }

  //   if (chat.type == 'snapshot' && chat.sent_by == userId) {
  //     snapshotsSentByUser.push(...chat.picture_url);
  //   }
  // }
  for (const date in chatData) {
    const chatThreads = chatData[date];

    for (const chatId in chatThreads) {
      const chat = chatThreads[chatId];

      if (chat.emojis && chat.emojis.some(emoji => emoji.sent_by === userId)) {
        emojisSentByUser.push(...chat.emojis);
      }

      if (chat.type === 'snapshot' && chat.sent_by === userId) {
        snapshotsSentByUser.push(
          ...chat.picture_url.forEach(picture => picture),
        );
      }
    }
  }

  const uniqueEmojis = removeDuplicatesFromArray(emojisSentByUser, 'emojiName');
  const uniqueSnapshots = removeDuplicatesFromArray(snapshotsSentByUser, 'id');

  const emojis = uniqueEmojis.slice(0, 4);
  const snapshots = uniqueSnapshots.slice(0, 3);

  return {emojis, snapshots};
}

export const convertTimeIntoDateTime = (selectedDate, timeString) => {
  const [time, period] = timeString.split(' ');

  let [hours, minutes] = time.split(':');
  hours = parseInt(hours);
  minutes = parseInt(minutes);

  if (period === 'PM' && hours !== 12) {
    hours += 12;
  } else if (period === 'AM' && hours === 12) {
    hours = 0;
  }

  const dateTime = new Date(
    selectedDate.getFullYear(),
    selectedDate.getMonth(),
    selectedDate.getDate(),
    hours,
    minutes,
  );

  return dateTime;
};

export const addEventToCalendar = async (token, calendarId, event) => {
  const headers = {
    Authorization: `Bearer ${token}`,
    authorization: `Bearer ${token}`,
  };

  try {
    const config = {
      headers,
      data: event,
      method: 'POST',
      url: `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events`,
    };
    const response = await axios(config);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getAccessTokenFromRefreshToken = async refreshToken => {
  const decrypted = CryptoJS.AES.decrypt(refreshToken, ENCRYPTION_KEY).toString(
    CryptoJS.enc.Utf8,
  );

  const requestBody = {
    grant_type: 'refresh_token',
    client_id:
      '556170493308-2o3iobkftj6d5ppk7uh1vtc8c3fn84g4.apps.googleusercontent.com',
    client_secret: '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',
    refresh_token: decrypted,
  };

  try {
    const response = await axios.post(
      'https://oauth2.googleapis.com/token',
      requestBody,
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
export const listForDropDown = [
  // {title: 'All', id: '1'},
  // {title: 'Workspace', id: '1'},
  {title: 'Sessions', id: '1'},
  {title: 'Events', id: '4'},
  {title: 'Recruitments', id: '5'},
  {title: 'Appointments', id: '6'},
  {title: 'Group Chats', id: '7'},
  {title: 'Network', id: '8'},
  {title: 'Credentials', id: '9'},
  {title: 'Links', id: '10'},
  {title: 'Forms', id: '11'},
  {title: 'Offerings', id: '12'},
  {title: 'Polls', id: '13'},
  {title: 'Payments', id: '14'},
  {title: 'Gifts', id: '15'},

  // 'Recent',
];

export const getRelativeDate = inputDate => {
  // Create a Date object for the input date
  const inputDateObject = new Date(inputDate);

  // Get the current date in local time
  const currentDateObject = new Date();

  // Calculate the difference in days between the input date and the current date
  const timeDifference = inputDateObject - currentDateObject;
  const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

  if (daysDifference === 0) {
    return ' today';
  } else if (daysDifference === 1) {
    return ' tomorrow';
  } else {
    // Format the input date as 'YYYY-MM-DD'
    const year = inputDateObject.getFullYear();
    const month = String(inputDateObject.getMonth() + 1).padStart(2, '0');
    const day = String(inputDateObject.getDate()).padStart(2, '0');
    return `${day}-${month}-${year}`;
  }
};

export const convertPermissionsToArrayOfObjects = array => {
  return array.forEach(obj => {
    return {
      ...obj,
      permissions: obj.permissions.forEach(permission => {
        const permissionsObject = JSON.parse(permission.permissions);
        return permissionsObject;
      }),
    };
  });
};
export const updateStateWithModifiedPermissions = arrayOfObjects => {
  // Create a copy of the state object
  return arrayOfObjects.forEach(obj => {
    return {
      id: obj.id,
      role_type_id: obj.role_type_id,
      role_alias: obj.role_alias,
      menu_ids: obj.menu_ids,
      role_name: obj.role_name,
      permissions: obj.permissions.forEach(permission => ({
        id: permission.menu_id,
        menu_name: permission.menu_name,
        link: permission.link,
        created_at: permission.created_at,
        updated_at: permission.updated_at,
        permission: JSON.parse(permission.permissions),
        role_type_id: permission.role_type_id,
      })),
    };
  });
};

export const checkPermissions = (obj, menuName) => {
  if (obj.creator || obj.market || obj.ride_share || obj.roi) {
    return 'allow';
  } else {
    const foundMenu = obj?.menus_permission?.find(
      menu => menu.menu_name === menuName,
    );
    return foundMenu ? foundMenu.menu_permissions : 'Menu not found';
  }
};

export const replaceSpaceWithDot = str => {
  // Check if the string contains spaces
  if (str.includes(' ')) {
    // If spaces exist, replace them with dots and return the modified string
    return str.replace(/\s+/g, '-');
  } else {
    // If no spaces exist, return the original string
    return str;
  }
};

export const duplicateAndChangeType = objects => {

  const inPersonObjects = objects.filter(obj => obj.type === 'in_person');


  console.log(inPersonObjects,'------>inPersonObjects')
  
  const duplicatedObjects = inPersonObjects.map(obj => {
    const duplicate = { ...obj };
    duplicate.type = 'virtual';
    return duplicate;
  });
  console.log(duplicatedObjects,'------>duplicatedObjects')
  
  return [...objects, ...duplicatedObjects];
};



export const undoDuplicateByTicketId = (objects) => {
  
  const inPersonTicketIds = new Set(
    objects
      .filter(obj => obj.type === 'in_person')
      .map(obj => obj.ticket_id)
  );

 
  return objects.filter(obj => { 
    if (obj.type === 'in_person') return true;
    if (obj.type === 'virtual' && inPersonTicketIds.has(obj.ticket_id)) return false;
    
    return true;
  });
};

export const removeTicketId = (objects) => {
  
  if (!Array.isArray(objects) || objects.length === 0) {
    return [];
  }
  return objects.map(({ ticket_id, ...rest }) => rest);
};


export const addUnderscores2 = name => {
  if (name.includes(' ')) {
    return name.replace(/ /g, '_');
  }
  return name;
};

export const allowedFileTypes = [
  {id: 'doc', name: 'doc'},
  {id: 'docx', name: 'docx'},
  {id: 'xls', name: 'xls'},
  {id: 'xlsx', name: 'xlsx'},
  {id: 'pdf', name: 'pdf'},
  {id: 'ppt', name: 'ppt'},
  {id: 'pptx', name: 'pptx'},
];

export const ValidatePipelinePermissions = permissions => {
  for (const item of permissions) {
    if (
      !(item.associate_pipeline || item.show_pipeline || item.create_pipeline)
    ) {
      return false; // If none of the properties are true, return false immediately
    }
  }
  return true; // If all items have at least one property true, return true
};

export const ValidateTagsPermissions = permissions => {
  for (const item of permissions) {
    if (!(item.create_tag || item.show_tag || item.associate_tag)) {
      return false; // If none of the properties are true, return false immediately
    }
  }
  return true; // If all items have at least one property true, return true
};

export const DealsNoRecordFound = {
  img: null,
  title: null,
  description: 'No Deals found.',
  button: 'Create Deals',
};
export const FieldsNoRecordFound = {
  img: null,
  title: null,
  description: 'No fields found.',
  button: 'Create Fields',
};
export const NoCustomerFound = {
  img: null,
  title: null,
  description: 'No contacts found.',
  button: 'null',
};
export const NoTeamFound = {
  img: null,
  title: null,
  description: 'No team found.',
  button: 'null',
};

export function catalogsTotalCost(items) {
  let totalCost = 0;
  items.forEach(item => {
    totalCost += parseFloat(item.cost ? item.cost : 0);
  });
  return totalCost;
}

export function catalogsTotalCostForAllType(items, dealsList) {
  let totalCost = 0;

  items.forEach(item => {
    if (item.catalog_type === 'membership') {
      // Find the matching membership level and frequency
      dealsList.forEach(level => {
        if (level.id === item.id) {
          totalCost += parseFloat(level.cost ? level.cost : 0);
        }
      });
    } else {
      totalCost += parseFloat(item.cost ? item.cost : 0);
    }
  });
  return totalCost === 0 ? 'Free' : '$' + totalCost;
}

export function checkPhases(phases) {
  if (!phases) return false;
  const countWithPipelineId = phases.filter(
    phase => phase.pipeline_id !== undefined && phase.pipeline_id !== null,
  ).length;
  return countWithPipelineId !== 1;
}

export const formatDateTimeTodayPre = date => {
  return moment(date).isSame(moment(), 'day')
    ? moment(date).format('HH:mm A')
    : moment(date).format('YYYY-MM-DD HH:mm A');
};

export function checkPipelinesDeals(array) {
  // Iterate through each object in the array
  for (let i = 0; i < array.length; i++) {
    // Check if the 'deals' array is not empty
    if (array[i].deals.length > 0) {
      return false; // If any 'deals' array is not empty, return false
    }
  }
  return true; // If all 'deals' arrays are empty, return true
}

export const allowedImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
export const allowedVideoTypes = ['video/mp4', 'video/webm', 'video/ogg'];

export const generatymentFrequency = (selectedDuration, durationArray) => {
  if (!selectedDuration) return [];

  // Find the selected duration object
  const durationObject = durationArray.find(
    item => item.id === selectedDuration,
  );
  if (!durationObject) return [];

  const frequencyArray = [];

  switch (durationObject.value) {
    case '1 month':
      frequencyArray.push(
        {id: '1', value: '1 week'},
        {id: '2', value: '2 weeks'},
        {id: '3', value: '3 weeks'},
        {id: '4', value: '4 weeks'},
      );
      break;
    case '2 months':
      frequencyArray.push(
        {id: '1', value: '1 month'},
        {id: '2', value: '2 months'},
      );
      break;
    case '3 months':
      frequencyArray.push(
        {id: '1', value: '1 month'},
        {id: '2', value: '2 months'},
        {id: '3', value: '3 months'},
      );
      break;
    case '4 months':
      frequencyArray.push(
        {id: '1', value: '1 month'},
        {id: '2', value: '2 months'},
        {id: '3', value: '3 months'},
        {id: '4', value: '4 months'},
      );
      break;
    case '6 months':
      frequencyArray.push(
        {id: '1', value: '1 month'},
        {id: '2', value: '2 months'},
        {id: '3', value: '3 months'},
        {id: '4', value: '4 months'},
        {id: '5', value: '6 months'},
      );
      break;
    case '2 years':
      frequencyArray.push(
        {id: '1', value: '1 month'},
        {id: '2', value: '3 months'},
        {id: '3', value: '6 months'},
        {id: '4', value: '1 year'},
        {id: '5', value: '2 years'},
      );
      break;
    case '3 years':
      frequencyArray.push(
        {id: '1', value: '1 month'},
        {id: '2', value: '4 months'},
        {id: '3', value: '8 months'},
        {id: '4', value: '1 year'},
        {id: '5', value: '2 years'},
        {id: '6', value: '3 years'},
      );
      break;
    case '4 years':
      frequencyArray.push(
        {id: '1', value: '1 month'},
        {id: '2', value: '5 months'},
        {id: '3', value: '10 months'},
        {id: '4', value: '1 year'},
        {id: '5', value: '2 years'},
        {id: '6', value: '3 years'},
        {id: '7', value: '4 years'},
      );
      break;
    default:
      break;
  }

  return frequencyArray;
};

export const generatePaymentFrequency = (selectedDuration, durationArray) => {
  if (!selectedDuration) return [];

  const durationObject = durationArray.find(
    item => item.name === selectedDuration,
  );
  if (!durationObject) return [];

  const frequencyArray = [];

  switch (durationObject.name) {
    case '1 month':
      frequencyArray.push({id: '1', name: '1 month', value: '1'});
      break;
    case '2 months':
      frequencyArray.push({id: '1', name: '1 month', value: '1'});
      break;
    case '3 months':
      frequencyArray.push({id: '1', name: '1 month', value: '1 '});
      break;
    case '4 months':
      frequencyArray.push({id: '1', name: '1 month', value: '1 '});
      break;
    case '5 months':
      frequencyArray.push({id: '1', name: '1 month', value: '1 '});
      break;
    case '6 months':
      frequencyArray.push(
        {id: '1', name: '1 month', value: '1 '},
        {id: '2', name: '3 months', value: '3'},
      );
      break;
    case '7 months':
      frequencyArray.push(
        {id: '1', name: '1 month', value: '1 '},
        {id: '2', name: '3 months', value: '3'},
      );
      break;
    case '8 months':
      frequencyArray.push(
        {id: '1', name: '1 month', value: '1 '},
        {id: '2', name: '3 months', value: '3'},
      );
      break;
    case '9 months':
      frequencyArray.push(
        {id: '1', name: '1 month', value: '1 '},
        {id: '2', name: '3 months', value: '3'},
      );
      break;
    case '10 months':
      frequencyArray.push(
        {id: '1', name: '1 month', value: '1 '},
        {id: '2', name: '3 months', value: '3'},
      );
      break;
    case '11 months':
      frequencyArray.push(
        {id: '1', name: '1 month', value: '1 '},
        {id: '2', name: '3 months', value: '3'},
      );
      break;
    case '12 months':
      frequencyArray.push(
        {id: '1', name: '1 month', value: '1 '},
        {id: '2', name: '3 months', value: '3'},
      );
      break;
    case '2 years':
      frequencyArray.push(
        {id: '1', name: '1 month', value: '1 '},
        {id: '2', name: '3 months', value: '3'},
        {id: '3', name: '1 year', value: '12'},
      );
      break;
    case '3 years':
      frequencyArray.push(
        {id: '1', name: '1 month', value: '1 '},
        {id: '2', name: '3 months', value: '3'},
        {id: '3', name: '1 year', value: '12'},
      );
      break;
    case '4 years':
      frequencyArray.push(
        {id: '1', name: '1 month', value: '1 '},
        {id: '2', name: '3 months', value: '3'},
        {id: '3', name: '1 year', value: '12'},
      );
      break;
    case '5 years':
      frequencyArray.push(
        {id: '1', name: '1 month', value: '1 '},
        {id: '2', name: '3 months', value: '3'},
        {id: '3', name: '1 year', value: '12'},
      );
      break;
    default:
      break;
  }

  return frequencyArray;
};

// export  const durationArray = [
//   {id: '1', name: '1 month',value: '1' },
//   {id: '2', name: '2 month'},
//   {id: '3', name: '3 month'},
//   {id: '4', name: '4 month'},
//   {id: '5', name: '5 month'},
//   {id: '6', name: '6 month'},
//   {id: '7', name: '7 month'},
//   {id: '8', name: '8 month'},
//   {id: '9', name: '9 month'},
//   {id: '10', name: '10 month'},
//   {id: '11', name: '11 month'},
//   {id: '12', name: '12 month'},
//   {id: '13', name: '2 years'},
//   {id: '14', name: '3 years'},
//   {id: '15', name: '4 years'},
//   {id: '16', name: '5 years'},
// ];

export const durationArray = [
  {id: '1', name: '1 month', value: '1'},
  {id: '2', name: '2 months', value: '2'},
  {id: '3', name: '3 months', value: '3'},
  {id: '4', name: '4 months', value: '4'},
  {id: '5', name: '5 months', value: '5'},
  {id: '6', name: '6 months', value: '6'},
  {id: '7', name: '7 months', value: '7'},
  {id: '8', name: '8 months', value: '8'},
  {id: '9', name: '9 months', value: '9'},
  {id: '10', name: '10 months', value: '10'},
  {id: '11', name: '11 months', value: '11'},
  {id: '12', name: '12 months', value: '12'},
  {id: '13', name: '2 years', value: '24'},
  {id: '14', name: '3 years', value: '36'},
  {id: '15', name: '4 years', value: '15'},
  {id: '16', name: '5 years', value: '60'},
];

export const monthDays = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
  '30',
];

export function getNameOfDuration(value) {
  const entry = durationArray.find(item => item.value === value);
  return entry ? entry.name : '';
}

export function capitalizeFirstLetter(string) {
  return string[0].toUpperCase() + string.slice(1);
}

export const calculateTotal = items => {
  let total = 0;

  items.forEach(item => {
    total += parseFloat(item.total ? item.total : 0);
  });

  return total.toFixed(2);
};

export const getNameFromDurationValue = value => {
  const durationObject = durationArray.find(item => item.value === value);
  return durationObject ? durationObject.name : '';
};

export const formatPaymentDescription = membership => {
  const {duration, payment_amount} = membership;
  const durationName = getNameFromDurationValue(duration);

  if (payment_amount === '') {
    return `${durationName} - Free`;
  } else {
    return `${durationName} - $${payment_amount}`;
  }
};

export const formatSelectedDuration = membership => {
  const {duration, payment_amount} = membership;
  const durationName = getNameFromDurationValue(duration);

  if (payment_amount === '') {
    return `${durationName}`;
  } else {
    return `${durationName}`;
  }
};

export function calculateSubtotal(array) {


  if (!Array.isArray(array)) {
    return [];
  }

  return array.map(item => {
    const shippingHandling = parseFloat(item.shipping_handling) || 0;
    const taxesFee = parseFloat(item.taxes_fee) || 0;
    const lateFee = parseFloat(item.late_fee) || 0;
    const cost = parseFloat(item.cost) || 0;
    const discount = parseFloat(item.discount) || 0;

    const total = shippingHandling + taxesFee + lateFee + cost - discount;

    if (item.catalog_type === 'membership') {
      const levelItem = item?.membership_levels.find(level => level.selected);
      const updatedLevel = {
        ...levelItem,
        frequency:
          levelItem?.frequencies.length > 0
            ? levelItem.frequencies.find(freq => freq.selected)
            : {},
      };

      const {frequencies, ...levelWithoutFrequencies} = updatedLevel;
      const {id, catalog_membership_id, frequency} = levelWithoutFrequencies;
      return {
        ...item,
        level: {
          id,
          catalog_membership_id,
          frequency: {
            id: frequency.id,
            duration: frequency.duration,
            payment_frequency: frequency.payment_frequency,
            payment_amount: frequency.payment_amount,
            renewal: frequency.renewal,
            payment_milestones: frequency.payment_milestones,
            subscription: frequency.subscription,
            payment_type: frequency.payment_type,
          },
        },

        ...(item.hasOwnProperty('catalog_id') ? {} : { catalog_id: item.id }),
        payment_milestones: frequency?.payment_milestones,
        // subscription_month: frequency.subscription,
        total: total.toFixed(2),
      };
    } else {
      return {
        ...item,
        ...(item.hasOwnProperty('catalog_id') ? {} : { catalog_id: item.id }),
        total: total.toFixed(2),
      };
    }
  });
}

export function extractFirstLevelAndFrequency(item) {
  if (!item || typeof item !== 'object' || item.catalog_type !== 'membership') {
    return null;
  }

  const firstLevel =
    item.membership_levels && item.membership_levels.length > 0
      ? item.membership_levels[0]
      : null;

  if (!firstLevel) {
    return null;
  }

  const firstFrequency =
    firstLevel.frequencies && firstLevel.frequencies.length > 0
      ? firstLevel.frequencies[0]
      : null;

  if (!firstFrequency) {
    return null;
  }

  return {
    id: firstLevel.id,
    catalog_membership_id: firstLevel.catalog_membership_id, // Assuming this is available at the item level
    frequency: {
      id: firstFrequency.id,
      duration: firstFrequency.duration,
      payment_frequency: firstFrequency.payment_frequency,
      payment_amount: firstFrequency.payment_amount,
      renewal: firstFrequency.renewal,
      payment_milestones: firstFrequency.payment_milestones,
      subscription: firstFrequency.subscription,
      payment_type: firstFrequency.payment_type,
    },
  };
}

export function calculateItemSubtotal(item) {
  if (typeof item !== 'object' || item === null) {
    return null; // or handle the invalid input accordingly
  }

  const shippingHandling = parseFloat(item.shipping_handling) || 0;
  const taxesFee = parseFloat(item.taxes_fee) || 0;
  const lateFee = parseFloat(item.late_fee) || 0;
  const cost = parseFloat(item.cost) || 0;
  const discount = parseFloat(item.discount) || 0;

  const total = shippingHandling + taxesFee + lateFee + cost - discount;

  return total.toFixed(2);
  // return {
  //   ...item,
  //   total: total.toFixed(2)
  // };
}

// export const transformDealArray = (inputArray) => {

//   let transformedArray = [];

//   inputArray.forEach(item => {

//     if (item.catalog_type === 'membership') {

//       const membershipItem = {
//         type: 'membership',
//         id: item.id,
//         level: {
//           level_id: item.membership_levels.id,
//           frequency_id: item.membership_levels.frequencies.id
//         }
//       };

//       transformedArray.push(membershipItem);
//     } else {

//       transformedArray.push({
//         type: item.catalog_type,
//         id: item.id
//       });
//     }
//   });

//   return transformedArray;
// };

export const transformDealArray = inputArray => {
  let transformedArray = [];

  inputArray.forEach(item => {
    if (item.catalog_type === 'membership') {
      // Find the selected membership level
      const selectedLevel = item.membership_levels.find(
        level => level.selected,
      );

      if (selectedLevel) {
        const membershipItem = {
          type: 'membership',
          id: item.id,
          level: {
            level_id: selectedLevel.id,
            frequency_id: selectedLevel.frequencies.find(freq => freq.selected)
              ?.id,
          },
        };

        transformedArray.push(membershipItem);
      }
    } else {
      transformedArray.push({
        type: item.catalog_type,
        id: item.id,
      });
    }
  });

  return transformedArray;
};

// export const filterOutLevels = (arrayList) => {
//   return arrayList.map(invoice => {

//     const catalog_details = invoice.catalog_details.map(detail => {
//       const { membership_levels, ...rest } = detail;
//       return rest;
//     });

//     return {
//       ...invoice,
//       catalog_details: catalog_details
//     };
//   });
// };

export const filterOutLevels = catalogDetails => {
  return catalogDetails.map(detail => {
    const newDetail = {...detail};

    delete newDetail.membership_levels;
    // delete newDetail.payment_milestones;

    return newDetail;
  });
};

export function addProductQuantity(productId, array) {
  const index = array.findIndex(item => item.product_id === productId);

  if (index !== -1) {
    array[index].count++;
  } else {
    array.push({product_id: productId, count: 1});
  }

  return [...array];
}

export function checkProductQuantity(array, item) {
  if (item.catalog_type === 'product') {
    if (Number(item.left_item) === 0) {
      return false;
    }

    const product = array.find(product => product.product_id === item.id);

    if ((product && product.count < Number(item.left_item)) || !product) {
      return true;
    }

    return false;
  } else {
    return true;
  }
}

// export function checkProductQuantityInStandard(productId, standardList) {

//   standardList.forEach(standard => {
//  const newArayy =  standard.catalog_details.map(item => {

//   if(item.id ===productId && standard.add_on ==='OR' )

//       return { product_id: productId, count: 1 }
//     });
//   });

// }

export function checkProductQuantityInStandard(
  estimateComparisons,
  item,
  data,
) {
  let canProcced = true;
  if (item.add_on == '' && data.catalog_type == 'product') {
    const selectedCatalog = item.catalog_details;
    const selected = selectedCatalog
      ? selectedCatalog.filter(catalog => catalog.id == data.id)
      : [];
    canProcced = selected.length < parseInt(data.left_item);
  } else if (item.add_on == 'OR' && data.catalog_type == 'product') {
    let selectedItems = [];
    const selectedCatalog = item.catalog_details;
    const selected = selectedCatalog
      ? selectedCatalog.filter(catalog => catalog.catalog_id == data.id)
      : [];
    const filteredAND = estimateComparisons.filter(
      data => data.add_on == 'AND',
    );

    filteredAND.forEach(catalog => {
      const filterd = catalog.catalog_details.filter(
        cat => cat.catalog_id == data.id,
      );
      selectedItems.push(...filterd);
    });

    const totalCount = selectedItems.length + selected.length;
    canProcced = totalCount < parseInt(data.left_item);
  } else if (item.add_on == 'AND' && data.catalog_type == 'product') {
    let maxCount = 0;
    let AndCount = 0;
    const selectedCatalog = item.catalog_details;

    const selected = selectedCatalog
      ? selectedCatalog.filter(catalog => catalog.catalog_id == data.id)
      : [];

    const filteredAnd = estimateComparisons.filter(
      data => data.add_on == 'AND',
    );
    //  filteredAnd.forEach(catalog => {
    //    const filterd = catalog.catalog_details.filter(
    //      cat => cat.catalog_id == data.id,
    //    );

    //    console.log(filterd,'Andfilterdfilterdfilterd')

    //    if (AndCount < filterd.length) {
    //      AndCount = filterd.length;
    //    }
    //  });

    filteredAnd.forEach(catalog => {
      catalog.catalog_details.forEach(cat => {
        if (cat.catalog_id === data.id) {
          AndCount += 1; // Increment count for each occurrence
        }
      });
    });

    const filteredOR = estimateComparisons.filter(data => data.add_on == 'OR');
    console.log(filteredOR, 'filteredOR');
    filteredOR.forEach(catalog => {
      const filterd = catalog.catalog_details.filter(
        cat => cat.catalog_id == data.id,
      );

      console.log(filterd, 'orfilterdfilterdfilterd');

      if (maxCount < filterd.length) {
        maxCount = filterd.length;
      }
    });

    // let totalCount;
    // if(selectedCatalog.add_on ==='AND'){
    //    totalCount = maxCount  + AndCount + selected.length;
    // }
    // else{
    const totalCount = maxCount + AndCount;
    // }
    console.log(totalCount, 'totalCounttotalCount');
    canProcced = totalCount < parseInt(data.left_item);
  }

  return canProcced;

  // if (!canProcced) {
  //   showErrorToast(I18n.t('OUT_OF_STOCK'), 2000);
  // }
}

export function updateOutOfStockStatus(selectedProducts, availableQuantities) {
  const availableQuantitiesMap = new Map();
  availableQuantities.forEach(availability => {
    availableQuantitiesMap.set(availability.id, {
      itemLeft: parseInt(availability.item_left, 10),
    });
  });

  const productOccurrences = new Map();
  selectedProducts.forEach(product => {
    if (product.catalog_id) {
      const count = productOccurrences.get(product.catalog_id) || 0;
      productOccurrences.set(product.catalog_id, count + 1);
    }
  });

  const updatedProducts = selectedProducts.map((product, index) => {
    if (product.catalog_id) {
      const available = availableQuantitiesMap.get(product.catalog_id);
      if (available) {
        const totalCount = productOccurrences.get(product.catalog_id);
        const itemLeft = available.itemLeft;

        const excessCount = Math.max(0, totalCount - itemLeft);

        let countSoFar = 0;
        for (let i = 0; i <= index; i++) {
          if (selectedProducts[i].catalog_id === product.catalog_id) {
            countSoFar += 1;
          }
        }

        const isOutOfStock = countSoFar > itemLeft;

        return {
          ...product,
          out_of_stock: isOutOfStock,
        };
      }
    }

    return {
      ...product,
      out_of_stock: false,
    };
  });

  return updatedProducts;
}

export function checkProductStock(selectedProducts, productStocks, userCount) {
  // Iterate over each selected product
  for (let productStock of productStocks) {
    // Find the corresponding product in the productStock array

    let stockProduct = selectedProducts.find(
      product => product.catalog_id === productStock.product_id,
    );

    // Calculate the total required quantity for the current product
    let totalRequired = parseInt(productStock.count) * userCount;

    // Check if the corresponding product is available and calculate the remaining stock
    if (
      !stockProduct ||
      (stockProduct.catalog_type === 'product' &&
        totalRequired > parseInt(stockProduct.left_item))
    ) {
      // Return an error alert with the product title if not enough stock
      return {
        errormsg: `Required quantity of products is not available for all users`,
        error: true,
      };
    }
  }

  // If all products have enough stock, return a success alert
  return 'Success: All products have enough stock';
}

// export const checkAvailablitiy = (type,selectedCatalog,selectedUsersIds,estimateComparisons) => {
//   let ids = [];
//   let notAvailable = [];

//   if (type == 'invoice') {
//     selectedCatalog.forEach(cat => {
//       if (!ids.includes(cat.catalog_id) && cat.catalog_type == 'product') {
//         ids.push(cat.catalog_id);
//       }
//     });

//     ids.forEach(id => {
//       const filtered = selectedCatalog.filter(cat => cat.catalog_id == id);

//       const totalRequired = filtered.length * selectedUsersIds.length;
//       const available = Number(filtered[0].left_item);

//       if (available < totalRequired) {
//         const item = { id: filtered[0].catalog_id, item_left: filtered[0].left_item, quantity_available: filtered[0].quantity, total_used: filtered[0].total_used };
//         notAvailable.push(item);
//       }
//     });
//   } else if (type == 'estimate') {
//     estimateComparisons.forEach((item, index) => {
//       const selectedCatalog = item.catalog_details;

//       selectedCatalog.forEach(data => {
//         const selected = selectedCatalog ? selectedCatalog.filter(catalog => catalog.catalog_id == data.catalog_id) : [];
//         const quantity = selected.length + selectedUsersIds.length;
//         const canProcced = !notAvailable.some(obj => obj.id == data.catalog_id && obj.itemIndex == index);

//         if (item.add_on == '' && data.catalog_type == 'product' && canProcced) {
//           if (quantity > parseInt(data.left_item)) {
//             const newData = { itemIndex: index, id: data.catalog_id, item_left: data.left_item, quantity_available: data.quantity, total_used: data.total_used };
//             notAvailable.push(newData);
//           }
//         } else if (item.add_on == 'OR' && data.catalog_type == 'product' && canProcced) {
//           let selectedItems = [];
//           const filteredAND = estimateComparisons.filter(ele => ele.add_on == 'AND');

//           filteredAND.forEach(catalog => {
//             const filterd = catalog.catalog_details.filter(cat => cat.catalog_id == data.catalog_id);
//             selectedItems.push(...filterd);
//           });

//           const totalCount = selectedItems.length + quantity;

//           if (totalCount > parseInt(data.left_item)) {
//             const newData = { itemIndex: index, id: data.catalog_id, item_left: data.left_item, quantity_available: data.quantity, total_used: data.total_used };
//             notAvailable.push(newData);
//           }
//         } else if (item.add_on == 'AND' && data.catalog_type == 'product' && canProcced) {
//           let maxCount = 0;
//           const filteredANDOR = estimateComparisons.filter(ele => ele.add_on == 'OR' || ele.add_on == 'AND');

//           filteredANDOR.forEach(catalog => {
//             const filterd = catalog.catalog_details.filter(cat => cat.catalog_id == data.catalog_id);

//             if (maxCount < filterd.length) {
//               maxCount = filterd.length;
//             }
//           });

//           const totalCount = maxCount + quantity;

//           if (totalCount > parseInt(data.left_item)) {
//             const newData = { itemIndex: index, id: data.catalog_id, item_left: data.left_item, quantity_available: data.quantity, total_used: data.total_used };
//             notAvailable.push(newData);
//           }
//         }
//       });
//     });
//   }

//   return notAvailable;
// }

export const checkAvailablitiy = (
  type,
  selectedCatalog,
  selectedUsersIds,
  estimateComparisons,
) => {
  let ids = [];
  let notAvailable = [];

  if (type == 'invoice') {
    selectedCatalog.forEach(cat => {
      if (!ids.includes(cat.catalog_id) && cat.catalog_type == 'product') {
        ids.push(cat.catalog_id);
      }
    });

    ids.forEach(id => {
      let totalRequired = 0;
      const filtered = selectedCatalog.filter(cat => cat.catalog_id == id);

      if (selectedUsersIds.length > 0) {
        totalRequired = filtered.length * selectedUsersIds.length;
      } else {
        totalRequired = filtered.length;
      }

      if (totalRequired > parseInt(filtered[0].left_item)) {
        const item = {
          id: filtered[0].catalog_id,
          item_left: filtered[0].left_item,
          quantity_available: filtered[0].quantity,
          total_used: filtered[0].total_used,
        };
        notAvailable.push(item);
      }
    });
  } else if (type == 'estimate') {
    estimateComparisons.forEach((item, index) => {
      const selectedCatalog = item.catalog_details;

      selectedCatalog.forEach(data => {
        const canProcced = !notAvailable.some(
          obj => obj.id == data.catalog_id && obj.itemIndex == index,
        );

        if (item.add_on == '' && data.catalog_type == 'product' && canProcced) {
          let totalRequired = 0;
          const selected = selectedCatalog.filter(
            catalog => catalog.catalog_id == data.catalog_id,
          );

          if (selectedUsersIds.length > 0) {
            totalRequired = selected.length * selectedUsersIds.length;
          } else {
            totalRequired = selected.length;
          }

          if (totalRequired > parseInt(data.left_item)) {
            const newData = {
              itemIndex: index,
              id: data.catalog_id,
              item_left: data.left_item,
              quantity_available: data.quantity,
              total_used: data.total_used,
            };
            notAvailable.push(newData);
          }
        } else if (
          item.add_on == 'OR' &&
          data.catalog_type == 'product' &&
          canProcced
        ) {
          let itemsCount = 0;
          let totalRequired = 0;

          // const filteredAND = estimateComparisons.filter(ele => ele.add_on == 'and');
          // filteredAND.forEach(catalog => {
          //   const filterd = catalog.catalog_details.filter(cat => cat.id == data.id);
          //   itemsCount = itemsCount + filterd.length;
          // });

          const selected = selectedCatalog.filter(
            catalog => catalog.catalog_id == data.catalog_id,
          );
          const selectedTotal = selected.length + itemsCount;

          if (selectedUsersIds.length > 0) {
            totalRequired = selectedTotal * selectedUsersIds.length;
          } else {
            totalRequired = selectedTotal;
          }

          if (totalRequired > parseInt(data.left_item)) {
            const newData = {
              itemIndex: index,
              id: data.catalog_id,
              item_left: data.left_item,
              quantity_available: data.quantity,
              total_used: data.total_used,
            };
            notAvailable.push(newData);
          }
        } else if (
          item.add_on == 'AND' &&
          data.catalog_type == 'product' &&
          canProcced
        ) {
          let maxCount = 0;
          let totalRequired = 0;

          const filteredOR = estimateComparisons.filter(
            ele => ele.add_on == 'OR',
          );

          filteredOR.forEach(catalog => {
            const filterd = catalog.catalog_details.filter(
              cat => cat.catalog_id == data.catalog_id,
            );

            if (maxCount < filterd.length) {
              maxCount = filterd.length;
            }
          });

          const selected = selectedCatalog.filter(
            catalog => catalog.catalog_id == data.catalog_id,
          );
          const selectedTotal = selected.length + maxCount;

          if (selectedUsersIds.length > 0) {
            totalRequired = selectedTotal * selectedUsersIds.length;
          } else {
            totalRequired = selectedTotal;
          }

          if (totalRequired > parseInt(data.left_item)) {
            const leftCount = Number(data.left_item) - maxCount;
            const newData = {
              itemIndex: index,
              id: data.catalog_id,
              item_left: leftCount >= 0 ? leftCount : 0,
              quantity_available: data.quantity,
              total_used: data.total_used,
            };
            notAvailable.push(newData);
          }
        }
      });
    });
  }

  return notAvailable;
};

export const ActionCategories = [
  {title: 'Custom'},
  {title: 'Tomorrow'},
  {title: '7 days'},
  {title: '15 days'},
  {title: '21 days'},
  {title: '3 month'},
  // {title: 'Circle 4'},
];
export const follow_up_List = [
  {id: 'Tomorrow', title: 'Tomorrow'},
  {id: '7 days', title: '7 days'},
  {id: '15 days', title: '15 days'},
  {id: '21 days', title: '21 days'},
  {id: '3 month', title: '3 months'},
  // {title: 'Circle 4'},
];

export const getFutureDateByCategory = categoryTitle => {
  if (categoryTitle === 'Custom') {
    return null; // Handle 'Custom' category or other cases
  }

  let unit, amount;

  if (categoryTitle === 'Tomorrow') {
    unit = 'day';
    amount = 1;
  } else if (categoryTitle === '7 days') {
    unit = 'day';
    amount = 7;
  } else if (categoryTitle === '15 days') {
    unit = 'day';
    amount = 15;
  } else if (categoryTitle === '21 days') {
    unit = 'day';
    amount = 21;
  } else if (categoryTitle === '3 month') {
    unit = 'month';
    amount = 3;
  }

  if (unit && amount) {
    return calculateFutureDate(unit, amount);
  }

  return null; // Handle invalid category titles
};
export const calculateFutureDate = (unit, amount) => {
  const currentDate = new Date();

  if (unit === 'day') {
    currentDate.setDate(currentDate.getDate() + amount);
  } else if (unit === 'month') {
    currentDate.setMonth(currentDate.getMonth() + amount);
  }

  return currentDate;
};

export const getTomorrowDate = () => {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  return tomorrow.toISOString().split('T')[0]; // Format to YYYY-MM-DD
};

export const checkPermissionsTags = (rolesArray, id, type, mainPermesion) => {
  // If the ID is "creator", return true
  if (id === 'creator') {
    return true;
  }

  // Find the role that matches the given ID
  const role = rolesArray.find(role => role.id === id);

  // If the role is found, check the permissions
  if (role) {
    if (role.update && type === 'update' && mainPermesion === true) {
      return true;
    }
    if (role.read && type === 'read' && mainPermesion === true) {
      return true;
    }

    return false;
  }
};

export const checkUserPermissionsAdd = (selectedAllAlias, userPermissions) => {
  // Check if the alias is "creator"
  if (userPermissions.alias === 'creator') {
    return true;
  }

  // Check if alias matches any id in selectedAllAlias
  const matchFound = selectedAllAlias.some(
    alias => alias.id === userPermissions.alias,
  );

  if (matchFound) {
    return true;
  }
  return false;
};

export const goToZoom = session_id => {
  const zoomUrl = session_id;
  window.open(zoomUrl);
};

const linkMap = {
  virtual: '/meetups',
  'in-person': '/meetups',
  recruitment: '/recruitment',
  'group-chat': '/messages',
  snapshot: '/meetups',
  profile: '/meetups',
  schedule: '/schedule_activities',
};

export function isLinkActive(urlParts, link) {
  return urlParts.length > 1 && urlParts.some(part => linkMap[part] === link);
}

export function truncateTitle(title = '', wordLimit = 4, charLimit = 4) {
  // Split the title into words
  const words = title.split(' ');

  // Check if the word limit is exceeded
  if (words.length > wordLimit) {
    return words.slice(0, wordLimit).join(' ') + '...';
  }

  // Check if the character limit is exceeded for a single long word or short sentence
  if (title.length > charLimit) {
    return title.slice(0, charLimit) + '...';
  }

  return title;
}

export const AddHttps = input => {
  if (!input.startsWith('http://') && !input.startsWith('https://')) {
    input = `https://${input}`;
  }

  if (!input.includes('.com')) {
    input = `${input}.com`;
  }

  const comIndex = input.indexOf('.com');

  if (comIndex !== -1 && comIndex + 4 < input.length) {
    input = input.slice(0, comIndex + 4);
  }

  return input;
};

export function getTimeDifference(date) {
  let inputDate = new Date(date);

  if (isNaN(inputDate)) {
    return 'Select Date';
  }

  // Add one day to the input date
  inputDate.setDate(inputDate.getDate() + 1);

  const currentDate = new Date();
  const diffInMilliseconds = inputDate - currentDate;

  if (diffInMilliseconds < 0) {
    return 'The date has already passed';
  }

  const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
  const diffInMinutes = Math.floor(diffInSeconds / 60);
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);
  const diffInWeeks = Math.floor(diffInDays / 7);
  const diffInMonths = Math.floor(diffInDays / 30.436875); // Average days in a month
  const diffInYears = Math.floor(diffInDays / 365.25); // Considering leap years

  if (diffInYears > 0) {
    return `${diffInYears} year${diffInYears > 1 ? 's' : ''}`;
  } else if (diffInMonths > 0) {
    return `${diffInMonths} month${diffInMonths > 1 ? 's' : ''}`;
  } else if (diffInWeeks > 0 || diffInDays === 7) {
    return `${diffInWeeks > 0 ? diffInWeeks : 1} week${
      diffInWeeks > 1 || diffInDays === 7 ? 's' : ''
    }`;
  } else if (diffInDays > 0) {
    return `${diffInDays} day${diffInDays > 1 ? 's' : ''}`;
  } else if (diffInHours > 0) {
    return `${diffInHours} hour${diffInHours > 1 ? 's' : ''}`;
  } else if (diffInMinutes > 0) {
    return `${diffInMinutes} minute${diffInMinutes > 1 ? 's' : ''}`;
  } else {
    return `${diffInSeconds} second${diffInSeconds > 1 ? 's' : ''}`;
  }
}

export const updatedCompanyData = (companies, companyItem) => {
  const updatedCompanies = companies.map(company =>
    company.id === companyItem.id
      ? {...company, company_name: companyItem.company_name}
      : company,
  );

  const updatedCompany = updatedCompanies.find(
    company => company.id === companyItem.id,
  );

  return {updatedCompanies, updatedCompany};
};

export const getFutureDate = (monthsToAdd, currentDate) => {
  const dateStr =
    currentDate instanceof Date
      ? moment(currentDate).format('YYYY-MM-DD')
      : currentDate;

  const dateMoment = moment(dateStr, 'YYYY-MM-DD');
  const futureDate = dateMoment.add(monthsToAdd, 'months');

  return futureDate.format('YYYY-MM-DD');
};

// export const getFutureDate = (monthsToAdd, currentDate) => {
//   const daysPerMonth = 30.436875;

//   const daysToAdd = monthsToAdd * daysPerMonth;

//   const dateStr =
//     currentDate instanceof Date
//       ? moment(currentDate).format('YYYY-MM-DD')
//       : currentDate;

//   const dateMoment = moment(dateStr, 'YYYY-MM-DD');

//   const futureDate = dateMoment.add(daysToAdd, 'days');

//   return futureDate.format('YYYY-MM-DD');
// };

export const updateDurations = (items, todayDateStr) => {
  return items.map(item => {
    if (item.level && item.level.duration) {
      item.duration = getFutureDate(
        parseInt(item.level.duration, 10),
        todayDateStr,
      );
    }

    return item;
  });
};

export const generateMembershipItem = miniItem => {
  return {
    ...miniItem,

    level: {
      level_id:
        miniItem.membership_levels && miniItem.membership_levels.length > 0
          ? miniItem.membership_levels[0].id
          : '',
      frequency_id:
        miniItem.membership_levels &&
        miniItem.membership_levels.length > 0 &&
        miniItem.membership_levels[0].frequencies.length > 0
          ? miniItem?.membership_levels[0]?.frequencies[0]?.id
          : '',
      duration:
        miniItem.membership_levels &&
        miniItem.membership_levels.length > 0 &&
        miniItem.membership_levels[0].frequencies.length > 0
          ? miniItem?.membership_levels[0]?.frequencies[0]?.duration
          : '',
    },
    cost:
      miniItem.membership_levels &&
      miniItem.membership_levels.length > 0 &&
      miniItem.membership_levels[0].frequencies.length > 0
        ? miniItem?.membership_levels[0]?.frequencies[0]?.payment_amount
        : '',
  };
};

export const WithBottomClassPaymentBtn =
  'filter-btn-wrapper pt-1 pb-2 create-session-btn  fix-width-forms mx-0 bg_transparent bottom-0 payment_card_btn';

export const WithBottomClass =
  'filter-btn-wrapper pt-1 pb-1 create-session-btn fixed-width-inputs  fix-width-forms mx-0 bg_transparent bottom-0';
export const WithOutBottomClass =
  'filter-btn-wrapper pt-1 pb-1 create-session-btn  fix-width-forms mx-0 bg_transparent';

export const SeprateAllArrays = Array => {
  const data = Array.milestones;
  let MileStones = [];
  let deliverableTitles = [];
  let tasks = [];
  let offerings = [];

  // Function to extract values
  data &&
    data.length > 0 &&
    data.forEach(milestone => {
      // Push milestone title
      MileStones.push(milestone.title || '');

      // Extract deliverable titles, tasks, and offerings
      milestone.deliverables.forEach(deliverable => {
        // Push deliverable title
        deliverableTitles.push(deliverable.title || '');

        deliverable.offering_ids.forEach(offering => {
          offerings.push(offering || '');
        });

        deliverable.task_ids.forEach(task => {
          tasks.push(task || '');
        });
      });
    });

  console.log(MileStones, 'ddddddddddddddddddddddddddddddd');

  // Return all arrays as an object
  return {
    MileStones,
    deliverableTitles,
    tasks,
    offerings,
    Overview: {
      customer_details: Array.customer_details,
      poc_details: Array.poc_details,
      team_alias_detail: Array.team_alias_detail,
    },
  };
};

export const RadioButtonName = [
  {id: '1', label: 'Monthly'},
  {id: '3', label: 'Quarterly'},
  {id: '12', label: 'Annually'},
];

export const getLabelFromSubscriptionMonth = subscriptionMonth => {
  const found = RadioButtonName.find(item => item.id === subscriptionMonth);
  return found ? found.label : 'Monthly';
};

export function transformDataForDate(data) {
  return data.map(item => {
    return {
      ...item,
      deliverables: item.deliverables.map(deliverable => {
        return {
          ...deliverable,
          task_ids: deliverable.task_ids.map(task => {
            const {date, ...rest} = task;
            return {
              ...rest,
              due_date: date,
            };
          }),
          offering_ids: deliverable.offering_ids.map(offering => {
            const {date, ...rest} = offering;
            return {
              ...rest,
              duration: date,
            };
          }),
        };
      }),
    };
  });
}

// export const isPaymentMilestoneDuplicate = (array, newObj) => {

//   const newObjStr = JSON.stringify(newObj);
//   const isDuplicate = array.some(item => JSON.stringify(item) === newObjStr);
//   return !isDuplicate;
// };

export const isPaymentMilestoneDuplicate = (array, newObj) => {
  const newObjDate = newObj.date;

  // Check if there's already an item with the same date
  const isDuplicate = array.some(
    item => moment(item.date).format('YYYY-MM-DD') === newObjDate,
  );

  return !isDuplicate;
};

export const formatPaymentMilestone = item => {
  console.log(item, '------->item');
  const {date, amount} = item;

  if (amount === '') {
    return `${moment(date).format('YYYY-MM-DD')} - Free`;
  } else {
    return `${moment(date).format('YYYY-MM-DD')} - ($${amount})`;
  }
};

export function isWithinBudget(
  paymentMilestoneAmount,
  paymentMilestones,
  cost,
) {
  let totalMilestones = 0;

  if (paymentMilestones && paymentMilestones.length > 0) {
    totalMilestones = paymentMilestones.reduce(
      (sum, milestone) => sum + parseInt(milestone.amount),
      0,
    );
  }
  // console.log( parseInt(paymentMilestoneAmount) + parseInt(totalMilestones) <= parseInt(cost))
  // console.log( paymentMilestoneAmount, totalMilestones,cost)

  return (
    parseInt(paymentMilestoneAmount) + parseInt(totalMilestones) <=
    parseInt(cost)
  );
}

export function compareDates(permanentPeriod, userSelectedDate) {
  const userDate = new Date(userSelectedDate);

  // Extract the number and unit from the permanent period
  const [quantity, unit] = permanentPeriod.split(' ');

  // Calculate the permanent date based on the unit (month/year)
  let permanentDate = new Date();

  switch (unit.toLowerCase()) {
    case 'month':
    case 'months':
      permanentDate.setMonth(permanentDate.getMonth() + parseInt(quantity));
      break;
    case 'year':
    case 'years':
      permanentDate.setFullYear(
        permanentDate.getFullYear() + parseInt(quantity),
      );
      break;
    default:
      throw new Error('Invalid period unit. Use "month(s)" or "year(s)".');
  }

  // Compare the user-selected date with the permanent date
  return userDate <= permanentDate;
}

export function isCostEqualToSum(milestones, cost) {

  const totalCost = parseFloat(cost);


  if (isNaN(totalCost)) {
    throw new TypeError('Cost should be a valid number.');
  }


  const totalSum = milestones.reduce((sum, milestone) => {
    const amount = parseFloat(milestone.amount);
    return sum + (isNaN(amount) ? 0 : amount);
  }, 0);


  return totalSum === totalCost;
}

export function validateCatalogCost(milestones, cost,discount) {

  const totalCost = parseFloat(cost);
  const discountValue = parseFloat(discount) || 0; 

  if (isNaN(totalCost)) {
    throw new TypeError('Cost should be a valid number.');
  }

  const adjustedCost = totalCost - discountValue;

  const totalSum = milestones.reduce((sum, milestone) => {
    const amount = parseFloat(milestone.amount);
    return sum + (isNaN(amount) ? 0 : amount);
  }, 0);

  return totalSum === adjustedCost;
}

export function isDateMatched(dateArray, dateToCheck) {
  // Check if the array has any data
  if (!Array.isArray(dateArray) || dateArray.length === 0) {
    return false;
  }

  // Try to parse the dateToCheck
  const parsedDateToCheck = Date.parse(dateToCheck);
  if (isNaN(parsedDateToCheck)) {
    console.error(`Invalid date: ${dateToCheck}`);
    return false;
  }
  const normalizedDateToCheck = new Date(parsedDateToCheck)
    .toISOString()
    .split('T')[0];

  return dateArray.some(item => {
    // Try to parse the item's date
    const parsedItemDate = Date.parse(item.date);
    if (isNaN(parsedItemDate)) {
      console.error(`Invalid date in array: ${item.date}`);
      return false;
    }
    const normalizedItemDate = new Date(parsedItemDate)
      .toISOString()
      .split('T')[0];
    return normalizedItemDate === normalizedDateToCheck;
  });
}

// export const validatePaymentMilestone = catalogs => {
//   return !catalogs.some(catalog => {
//     const totalAmount = catalog.payment_milestones.reduce((sum, milestone) => {
//       return sum + parseFloat(milestone.amount);
//     }, 0);
//     return totalAmount < parseFloat(catalog.cost);
//   });
// };

// export const processCatalogs = (catalogs) => {
//   return catalogs.map(catalog => {

//     const newCatalog = { ...catalog };
//     delete newCatalog.membership_levels;
//     const hasSubscription = newCatalog?.payment_type.includes('subscription');
//     const hasMilestone = newCatalog?.payment_type.includes('milestone');

//     newCatalog.subscription_month = hasSubscription ? newCatalog?.subscription_month : '';
//     newCatalog.payment_milestones = hasMilestone ? newCatalog?.payment_milestones : [];

//     return newCatalog;
//   });
// };

export const processCatalogs = catalogs => {
  return catalogs.map(catalog => {
    const newCatalog = {...catalog};
    delete newCatalog.membership_levels;
    delete newCatalog?.payment_type;
    delete newCatalog?.expense_deatils;
    const {selected_payment_type,expense_exist} = newCatalog;
    if (newCatalog.catalog_type === 'membership') {
      // newCatalog.payment_milestones = [];
      newCatalog.subscription_month = '';
      if (selected_payment_type === 'milestone') {
        delete newCatalog?.level?.frequency?.subscription;
        delete newCatalog?.level?.frequency?.payment_type;
      }
    }

    if(newCatalog.hasOwnProperty('expense_exist') && !expense_exist){
      newCatalog.expense_ids =''
    }else{
      newCatalog.expense_ids = newCatalog.expense_ids.join(',')
    }

    if (selected_payment_type === 'one_time') {
      newCatalog.payment_milestones = [];
      newCatalog.subscription_month = '';
    } else if (selected_payment_type === 'subscription') {
      newCatalog.payment_milestones = [];
    } else if (selected_payment_type === 'milestone') {
      newCatalog.subscription_month = '';
    }

    if(newCatalog.hasOwnProperty('expense_exist')){
      delete newCatalog.expense_exist;
    }
    

    return newCatalog;
  });
};
export function generatePaymentType(inputArray) {
  console.log(inputArray, '----------->inputArray generatePaymentType');
  return inputArray.map(item => {
    return {
      title: item.charAt(0).toUpperCase() + item.slice(1).replace(/_/g, ' '),
      id: item,
    };
  });
}

export const validateCatalogItem = item => {
  if (item.catalog_type === 'membership') {
    if (Array.isArray(item.levels)) {
      item.levels = item.levels.map(level => {
        return {
          ...level,
          frequencies: level.frequencies.map(frequency => {
            if (!frequency.payment_type.includes('subscription')) {
              frequency.subscription = '';
            }

            if (!frequency.payment_type.includes('milestone')) {
              frequency.payment_milestones = [];
            }

            if (frequency.payment_type.includes('all')) {
              frequency.payment_type = frequency.payment_type.filter(type => type !== 'all');
            }

            return frequency;
          }),
        };
      });
    }

    item.payment_type = [];
    item.payment_milestones = [];
    item.subscription = '';
  }

  if (item.catalog_type !== 'membership') {
    if (!item.payment_type.includes('subscription')) {
      item.subscription = '';
    }

    if (!item.payment_type.includes('milestone')) {
      item.payment_milestones = [];
    }
    if (item.payment_type.includes('all')) {
      item.payment_type = item.payment_type.filter(type => type !== 'all');
    }
  }

  return item;
};

// export function validatePaymentMilestone(catalogItems) {
//   if (!Array.isArray(catalogItems)) {
//     throw new TypeError('Catalog items should be an array.');
//   }

//   const validateItem = item => {
//     if (!item || typeof item !== 'object') return false;

//     const cost = parseFloat(item.cost);
//     if (isNaN(cost)) {
//       throw new TypeError('Cost should be a valid number.');
//     }

//     const includesMilestone =
//       item.payment_type &&
//       Array.isArray(item.payment_type) &&
//       item.payment_type.includes('milestone');

//     if (includesMilestone) {
//       const totalSum = item.payment_milestones.reduce((sum, milestone) => {
//         const amount = parseFloat(milestone.amount);
//         return sum + (isNaN(amount) ? 0 : amount);
//       }, 0);

//       if (totalSum !== cost) {
//         return false;
//       }
//     } else {
//       return true;
//     }

//     return true;
//   };

//   for (const item of catalogItems) {
//     if (!validateItem(item)) {
//       return false;
//     }
//   }

//   return true;
// }\

export function validatePaymentMilestone(catalogItems) {
  if (!Array.isArray(catalogItems)) {
    throw new TypeError('Catalog items should be an array.');
  }

  const validateItem = item => {
    if (!item || typeof item !== 'object') {
      throw new TypeError('Each catalog item should be an object.');
    }

    // Parse cost as a float
    const cost = parseFloat(item.cost);
    if (isNaN(cost)) {
      throw new TypeError('Cost should be a valid number.');
    }

    // Check if payment_type includes 'milestone'
    const includesMilestone =
      item.selected_payment_type && item.selected_payment_type === 'milestone';

    if (includesMilestone) {
      // Ensure payment_milestones is an array
      if (!Array.isArray(item.payment_milestones)) {
        throw new TypeError('Payment milestones should be an array.');
      }

      // Calculate total sum of payment milestones
      const totalSum = item.payment_milestones.reduce((sum, milestone) => {
        const amount = parseFloat(milestone.amount);
        return sum + (isNaN(amount) ? 0 : amount);
      }, 0);

      // Validate total sum against cost
      if (totalSum !== cost) {
        console.error(
          `Total sum of payment milestones (${totalSum}) does not match cost (${cost}).`,
        );
        return false;
      }
    }

    return true;
  };

  for (const item of catalogItems) {
    try {
      if (!validateItem(item)) {
        return false;
      }
    } catch (error) {
      console.error(
        `Validation failed for item: ${JSON.stringify(item)}. Error: ${
          error.message
        }`,
      );
      return false;
    }
  }

  return true;
}


export function validateCatalogsDiscount(catalogs) {
  for (const item of catalogs) {
      const discount = parseFloat(item.discount) || 0;
      const cost = parseFloat(item.cost) || 0;

      
      if (discount >= cost) {
          return true;
      }
  }

  return false;
}



export function validateEstimatesCatalogsDiscount(invoices) {
  for (const invoice of invoices) {
      const { catalog_details } = invoice;

      for (const catalog of catalog_details) {
          const discount = parseFloat(catalog.discount) || 0;
          const cost = parseFloat(catalog.cost) || 0;

          
          if (discount >= cost) {
              return true;
          }
      }
  }
 
  return false;
}


export function validatePaymentEstimates(invoices) {
  if (!Array.isArray(invoices)) {
    throw new TypeError('Invoices should be an array.');
  }

  const validateCatalogDetails = details => {
    if (!Array.isArray(details)) {
      throw new TypeError('Catalog details should be an array.');
    }

    return details.every(item => {
      if (!item || typeof item !== 'object') {
        throw new TypeError('Each catalog item should be an object.');
      }

      // Parse cost as a float
      const cost = parseFloat(item.cost);
      if (isNaN(cost)) {
        throw new TypeError('Cost should be a valid number.');
      }

      // Check if selected_payment_type is 'milestone'
      const isMilestone = item.selected_payment_type === 'milestone';

      if (isMilestone) {
        // Ensure payment_milestones is an array
        if (!Array.isArray(item.payment_milestones)) {
          throw new TypeError('Payment milestones should be an array.');
        }

        // Calculate total sum of payment milestones
        const totalSum = item.payment_milestones.reduce((sum, milestone) => {
          const amount = parseFloat(milestone.amount);
          return sum + (isNaN(amount) ? 0 : amount);
        }, 0);

        // Validate total sum against cost
        if (totalSum !== cost) {
          console.error(
            `Total sum of payment milestones (${totalSum}) does not match cost (${cost}) for catalog ID ${item.catalog_id}.`,
          );
          return false;
        }
      }

      return true;
    });
  };

  return invoices.every(invoice => {
    if (!invoice || typeof invoice !== 'object') {
      throw new TypeError('Each invoice should be an object.');
    }

    // Validate catalog_details
    return validateCatalogDetails(invoice.catalog_details);
  });
}


export function generateOutlookData(calendars, outlookClenderData, email) {
  
  const filterData = calendars.filter(item =>
      item.canEdit && !outlookClenderData.some(dataItem => dataItem.google_calendar_id === item.id)
  );


  if (filterData.length > 0) {
      const updatedData = filterData.map(item => ({
          ...item,
          email: email
      }));

      return updatedData;
  }


  return [];
}







export function checkPayableAmount(catalogArray) {
  // Check if catalogArray is valid and is an array
  if (!Array.isArray(catalogArray)) {
    console.error('Invalid input: catalogArray should be an array.');
    return false; // Or handle as appropriate
  }

  // Iterate through each catalog item in the array
  for (const catalog of catalogArray) {
    // Check if the selected_payment_type is 'milestone'
    if (catalog.selected_payment_type === 'milestone') {
      return true; // Found a milestone payment type, return true
    }
  }
  
  return false; // No milestone payment type found, return false
}


export const transformOutlookEventData = (events,calendarId, userId, companyId,email) => {
  return events.map(event => ({
    creator_id: userId,
    company_ids: companyId,
    calender_id: calendarId,
    event_id: event.id || "",
    start: event.start?.dateTime || "",
    end: event.end?.dateTime || "",
    status: 'confirmed',
    event_type: 'default',
    summary: event.bodyPreview || "",
    self: true,
    email: email,
    type: 'outlook'
  }));
};

export const isTokenAndEmailUnique = (dataArray, email, refreshToken) => {
  const ciphertext = CryptoJS.AES.encrypt(
    refreshToken,
    ENCRYPTION_KEY,
  ).toString();

  const isUnique = !dataArray.some(item =>
    item.refresh_token === ciphertext && item.email === email
  );

  return isUnique;
};


 export const getLatestTokenForEmail = (tokens, email) => {
  console.log(tokens,'tokenarray')
  // Filter tokens by the provided email
  const filteredTokens = tokens.filter(token => token.email === email);
  
  // If no tokens match the email, return null
  if (filteredTokens.length === 0) {
    console.log('No tokens found for email:', email);
    return null;
  }

  // Log filtered tokens for debugging
  console.log('Filtered tokens:', filteredTokens);

  // Sort the filtered tokens by 'created_at' in descending order (latest first)
  const sortedTokens = filteredTokens.sort((a, b) => {
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);
    console.log('Comparing dates:', dateA, dateB);
    return dateB - dateA; // Latest date first
  });

  // Log sorted tokens for debugging
  console.log('Sorted tokens:', sortedTokens);

  // Get the most recent token
  const latestToken = sortedTokens[0];
  console.log('Latest token:', latestToken);

  return latestToken;
 }


 export const getGoogleLatestToken = (tokens) => {

  if (tokens.length === 0) {
    return null; 
  }

  
  tokens.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));


  return tokens[0];
};

 export const getEventsByCalendarType = (events) => {
  
  if (!Array.isArray(events)) {
    throw new TypeError('Events should be an array.');
  }
  
 
  const outlookEvents = events.filter(event => event.calendar_type === 'outlook');
  const googleEvents = events.filter(event => event.calendar_type === 'google');

  return {
    outlookEvents,
    googleEvents,
  };
};

export const getCalendarsByType = (events) => {
  
  if (!Array.isArray(events)) {
    throw new TypeError('Events should be an array.');
  }
  
 
  const outlookCalendars = events.filter(event => event.type === 'outlook');
  const googleCalendars = events.filter(event => event.type === 'google');

  return {
    outlookCalendars,
    googleCalendars,
  };
};


export const filterExpenseIds = (arrayToFilter, referenceArray) => {
  
  if (!Array.isArray(arrayToFilter) || arrayToFilter.length === 0) {
    return []; 
  }

  const validIds = new Set(referenceArray.map(item => item.id));

  const filteredArray = arrayToFilter.filter(item => validIds.has(item));

  
  return filteredArray;
};



export const validatePaymentMilestoneDates = (catalog, dueDate) => {
  
  const milestoneDates = catalog
    .filter(item => item.selected_payment_type === 'milestone')
    .flatMap(item => item.payment_milestones.map(milestone => milestone.date));

    // console.log(milestoneDates,'----------.milestoneDates')
 
  if (milestoneDates.length === 0) return true;

  
  const latestDate = new Date(Math.min(...milestoneDates.map(date => new Date(date))));

  
  const formattedLatestDate = latestDate.toLocaleDateString('en-CA'); // YYYY-MM-DD format
  const formattedDueDate = new Date(dueDate).toLocaleDateString('en-CA');

  const hasOtherPaymentType = catalog.some(item => item.selected_payment_type !== 'milestone');

  
  if (hasOtherPaymentType && formattedDueDate < formattedLatestDate) {
    return true;
  }

  return formattedLatestDate === formattedDueDate;
 
 
};
