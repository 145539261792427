import React, {useRef, useState} from 'react';

import {getTimeFormatForChat} from '../../Services/AppServices';
import {DEFAULT_IMAGE, S3_EMOJI_DEV} from '../../Utils/env';
import SmartChatReplyBox from './SmartChatReplyBox';
import SmartChatPoll from './SmartChatPoll';
import moment from 'moment/moment';
import {
  fixName,
  getDateWithTimezone,
  checkTimeDurationWithDate,
  filteringUsers,
  arrayColumn,
  formatString,
} from '../../Utils/commonUtils';
import {getItem} from '../../Utils/LocalStorage';
import OtherUserImages from './OtherUserImages';
import Moment from 'react-moment';
import ShareAableOpportunity from '../ShareAbles/ShareAableOpportunity';
import ShareAblesSessions from '../ShareAbles/ShareAblesSessions';
import ShreAblesEvent from '../ShareAbles/ShreAblesEvent';

const SmartChatBox = ({
  allChat,
  allChatUsers,
  handleChatBoxSignal,
  chatStatus,
  chatModule,
  lastMsgTime,
  chatEnableStatus,
  ShowSelectedComponent,
  handleOpenChatRoom,
}) => {
  // const [creatorId, setCreatorId] = useState(null);
  const [currentComponent, setCurrentComponent] = useState('');

  // const [replies, setReplies] = useState([]);

  const [chatData, setChatData] = useState([]);

  const messagesEndRef = useRef(null);

  const currentUrl = window.location.href;
  const lastSlashIndex = currentUrl.lastIndexOf('/');
  const newSubstring = currentUrl.substring(lastSlashIndex + 1);

  const handleSelectReply = (key, item, cKey, pKey) => {
    if (key === 'update-poll') {
      handleChatBoxSignal('update-poll', item, cKey, pKey);
    } else if (key === 'reply') {
      // delete item.document_url;
      // delete item.key;
      // delete item.parentKey;
      // delete item.reply;

      // handleChatBoxSignal('add-reply', item, cKey, pKey);
      handleChatBoxSignal('add-replies', item, cKey, pKey);
    } else if (key === 'emoji') {
      handleChatBoxSignal('add-emoji', item, cKey, pKey);
    } else if (key === 'best-answer') {
      handleChatBoxSignal('best-answer', item, cKey, pKey);
    } else if (key === 'show-participants') {
      handleChatBoxSignal('show-participants', item, cKey, pKey);
    } else if (key === 'show-reply-users') {
      handleChatBoxSignal('show-reply-users', item, cKey, pKey);
    }
  };

  // const componentDecesion = () => {};
  const getLastNonPrivateObject = arr => {
    const id = localStorage.getItem('id');
    let count = 0;
    let lastNonPrivateObj = null;

    // for (let i = arr.length - 1; i >= 0; i--) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].isPrivate === false) {
        count++;
        lastNonPrivateObj = arr[i];
        // break;
      } else if (
        id &&
        arr[i].isPrivate &&
        (arr[i].sent_by === id || arr[i].privateChatData.sent_to === id)
      ) {
        count++;
        lastNonPrivateObj = arr[i];
        // break;
      }
    }

    return {
      lastNonPrivateObj,
      count,
    };
  };

  const getEmojiObject = arr => {
    return arr
      .reduce((acc, curr) => {
        const found = acc.find(item => item.emojiID === curr.emojiID);
        if (found) {
          found.count++;
        } else {
          acc.push({
            emojiID: curr.emojiID,
            image: S3_EMOJI_DEV + curr.skinTone + '/' + curr.emojiName + '.svg',
            count: 1,
          });
        }
        return acc;
      }, [])
      .sort((a, b) => b.count - a.count)
      .slice(0, 3);
  };

  const getRepliesObject = arr => {
    return arr
      .reduce((acc, curr) => {
        const found = acc.find(item => item.sent_by === curr.sent_by);
        if (found) {
          found.count++;
        } else {
          const found = allChatUsers.find(
            objs =>
              objs.id === curr.sent_by &&
              (!curr.isPrivate ||
                (curr.isPrivate && curr.sent_by === getItem('id'))),
          );
          if (typeof found === 'object') {
            acc.push({
              sent_by: curr.sent_by,
              profile_img: found.profile_img
                ? found.profile_img
                : DEFAULT_IMAGE,
            });
          }
        }
        return acc;
      }, [])
      .slice(-3);
  };

  // useEffect(() => {
  //   const found = allChatUsers.find(objs => objs.type === 'creator');
  //   if (typeof found === 'object') {
  //     if (localStorage.getItem('user') == found.id) {
  //       setCreatorId(found.id);
  //     }
  //   }
  // }, [allChatUsers]);

  return (
    <>
      {/* commented currentComponent state for replies case,(chat disappear case) */}
      {/* {currentComponent === '' && ( */}
      <div>
        <div
          className={
            newSubstring === 'history' ? 'messages history-chat' : 'messages'
          }>
          {allChat.length > 0 &&
            allChat.map((item, index) => (
              <div
                className="messages-list"
                key={`msg-list-${item[0]}-${index}`}>
                <div className="message-day-saparator">
                  {moment(item[0]).calendar({
                    sameDay: '[Today]',
                    nextDay: '[Tomorrow]',
                    nextWeek: 'dddd',
                    lastDay: '[Yesterday]',
                    lastWeek: 'dddd',
                    sameElse: 'DD/MM/YYYY',
                  })}
                </div>
                {Object.entries(item[1]).length > 0 &&
                  Object.entries(item[1]).map((chat, index) => {
                    if (chat[1].isCorrect) {
                      localStorage.setItem(
                        'isCorrectPath',
                        item[0] + '/' + chat[0],
                      );
                      localStorage.setItem(
                        'isCorrectMessage',
                        JSON.stringify(chat[1]),
                      );
                    }

                    const loggedInUserId = localStorage.getItem('id');
                    const typeCondition = chat[1].type;
                    const dateAndTimeCondition = chat[1]?.message?.dateAndTime;
                    const participants = chat[1]?.message?.participants;
                    const participantCondition =
                      participants?.includes(loggedInUserId);
                    const shouldShowPoll =
                      typeCondition !== 'poll' ||
                      (typeCondition === 'poll' && !dateAndTimeCondition) ||
                      (typeCondition === 'poll' &&
                        dateAndTimeCondition &&
                        participantCondition);

                    return (
                      // (!lastMsgTime || lastMsgTime > chat[1].send_date) &&
                      (!lastMsgTime ||
                        checkTimeDurationWithDate(
                          lastMsgTime,
                          getDateWithTimezone(chat[1].send_date),
                        ) < 3000) &&
                      shouldShowPoll &&
                      (chat[1].sent_by === localStorage.getItem('user') ? (
                        <div
                          key={`msg-${chat[1].send_date}-${index}`}
                          className={`message-item new-msg-item reply-message ${
                            chat[1].isPrivate ? 'private-msg' : ''
                          }`}>
                          <div className="msg-container">
                            <div className="msg-content">
                              {/* <div className="msg-content-left">
                                  <div
                                    className="msg-usr-img"
                                    onClick={() => {
                                      handleChatBoxSignal(
                                        'show-profile',
                                        allChatUsers.find(
                                          objs => objs.id == chat[1].sent_by,
                                        ),
                                      );
                                    }}>
                                    <img
                                      src={
                                        allChatUsers.find(
                                          objs => objs.id == chat[1].sent_by,
                                        )?.profile_img
                                          ? allChatUsers.find(
                                              objs =>
                                                objs.id == chat[1].sent_by,
                                            )?.profile_img
                                          : DEFAULT_IMAGE
                                      }
                                      alt=""
                                    />
                                  </div>
                                </div> */}
                              <div className="msg-content-right msg-smartchat-contnt-right">
                                <h2>
                                  {allChatUsers.find(
                                    objs => objs.id === chat[1].sent_by,
                                  )?.first_name &&
                                    fixName(
                                      allChatUsers.find(
                                        objs => objs.id === chat[1].sent_by,
                                      )?.first_name,
                                      allChatUsers.find(
                                        objs => objs.id === chat[1].sent_by,
                                      )?.last_name,
                                    )}{' '}
                                  <span className="msg-date">
                                    {getTimeFormatForChat(chat[1].send_date)}
                                  </span>
                                  {chat[1].isCorrect && (
                                    <span className="icon">
                                      {' '}
                                      <i className="fa-solid fa-circle-check"></i>
                                    </span>
                                  )}
                                </h2>
                                <div className="msg-content-text">
                                  <p>
                                    {chat[1].type !== 'poll' &&
                                      !chat[1].type !== 'emoji' &&
                                      chat[1].message}
                                  </p>
                                  <div className="emoji-icons-wrapper">
                                    <div className="emoji-icons d-flex align-items-center">
                                      {chat[1].type &&
                                        chat[1].type === 'emoji' && (
                                          <div className="emoji-icons-item">
                                            {chat[1].emoji?.skinTone &&
                                              chat[1].emoji?.emojiName && (
                                                <img
                                                  src={
                                                    S3_EMOJI_DEV +
                                                    chat[1].emoji.skinTone +
                                                    '/' +
                                                    chat[1].emoji.emojiName +
                                                    '.svg'
                                                  }
                                                  alt=""
                                                />
                                              )}
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                  {chat[1].type === 'accomplishment' && (
                                    <div
                                      className="container-fromChat-Shareables shareables-snapshots"
                                      onClick={() => {
                                        ShowSelectedComponent(
                                          chat[1].accomplishment_data,
                                          chat[1].type,
                                        );
                                      }}>
                                      <div className="as-link">
                                        <div className=" d-flex align-items-center mb-3">
                                          <div className="snapshotsImage as-link">
                                            <img
                                              src={
                                                chat[1].accomplishment_data &&
                                                chat[1].accomplishment_data
                                                  .images[0]
                                              }
                                              alt=""
                                            />
                                          </div>
                                          <div
                                            className="snapshots-content as-link"
                                            onClick={() => {
                                              // setSnapShotId(item);
                                              // setShowComponent('SnapShotDetail');
                                            }}>
                                            <h2 className="single-line-text">
                                              {chat[1].accomplishment_data &&
                                                chat[1].accomplishment_data
                                                  .title}
                                            </h2>
                                            {chat[1].accomplishment_data
                                              .category_name && (
                                              <p className="p-reg single-line-text category-name">
                                                {chat[1].accomplishment_data &&
                                                  chat[1].accomplishment_data
                                                    .category_name}
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                        {chat[1].accomplishment_data && (
                                          <p className="p-reg truncate_2_lines">
                                            {
                                              chat[1].accomplishment_data
                                                .description
                                            }
                                          </p>
                                        )}
                                      </div>
                                      {/* {ElementDesign('snapShot', item)} */}
                                    </div>
                                  )}
                                  {chat[1].type === 'session' &&
                                    chat[1].session_data && (
                                      <ShareAblesSessions
                                        item={chat[1].session_data}
                                        heart={''}
                                        componentDecesion={
                                          ShowSelectedComponent
                                        }
                                        //  ElementDesign={}
                                        typeMesages={true}
                                      />
                                    )}
                                  {chat[1].type === 'event' &&
                                    chat[1].event_data && (
                                      <ShreAblesEvent
                                        //  key={`event-item-${index}`}
                                        item={chat[1].event_data}
                                        //  index={index}
                                        // eventDetails={eventDetails}
                                        componentDecesion={
                                          ShowSelectedComponent
                                        }
                                        //  ElementDesign={ElementDesign}
                                        typeMesages={true}
                                      />
                                    )}
                                  {chat[1].type === 'opportunity' &&
                                    chat[1].opportunity_data && (
                                      <ShareAableOpportunity
                                        key={`opportunity-item-${index}`}
                                        setShowButton={''}
                                        onShare={() => {
                                          // setProperComponent('refer-opportunity');
                                        }}
                                        handleItem={ShowSelectedComponent}
                                        setChatData={setChatData}
                                        chatData={chatData}
                                        //  handleItem={''}
                                        item={chat[1].opportunity_data}
                                        //  ElementDesign={''}
                                        typeMesages={true}
                                      />
                                    )}
                                  {chat[1].type === 'group_chat' &&
                                    chat[1].group_chat_data && (
                                      <div
                                        className="messages-users-list as-link container-fromChat-Shareables group-chat"
                                        onClick={() => {
                                          handleOpenChatRoom(item);
                                        }}>
                                        <div
                                          className={
                                            'message-user-item no-link m-0 p-0'
                                          }>
                                          <div
                                            className="user-img as-link"
                                            // onClick={() => {
                                            //   amplitudeEvent('OPEN_CHAT_ROOM');
                                            //   handleOpenChatRoom(item);
                                            // }}
                                          >
                                            <img
                                              src={
                                                chat[1].group_chat_data
                                                  .group_image !== ''
                                                  ? chat[1].group_chat_data
                                                      .group_image
                                                  : chat[1].group_chat_data[
                                                      parseInt(
                                                        chat[1].group_chat_data
                                                          .creator_id,
                                                      ) ===
                                                      parseInt(
                                                        localStorage.getItem(
                                                          'user',
                                                        ),
                                                      )
                                                        ? 'user_detail'
                                                        : chat[1].group_chat_data.hasOwnProperty(
                                                            'creator_details',
                                                          )
                                                        ? 'creator_details'
                                                        : 'creator_detail'
                                                    ]['profile_img']
                                                  ? chat[1].group_chat_data[
                                                      parseInt(
                                                        chat[1].group_chat_data
                                                          .creator_id,
                                                      ) ===
                                                      parseInt(
                                                        localStorage.getItem(
                                                          'user',
                                                        ),
                                                      )
                                                        ? 'user_detail'
                                                        : chat[1].group_chat_data.hasOwnProperty(
                                                            'creator_details',
                                                          )
                                                        ? 'creator_details'
                                                        : 'creator_detail'
                                                    ]['profile_img']
                                                  : DEFAULT_IMAGE
                                              }
                                              alt=""
                                            />
                                            {chat[1].group_chat_data
                                              .chat_type === 'question' && (
                                              <span>
                                                <i className="fa-solid fa-q"></i>
                                              </span>
                                            )}
                                            {chat[1].group_chat_data
                                              .chat_type === 'opportunity' && (
                                              <span className="icon icon-posts"></span>
                                            )}
                                            {chat[1].group_chat_data
                                              .chat_type === 'event' && (
                                              <span className="icon-tickets"></span>
                                            )}
                                            {chat[1].group_chat_data
                                              .chat_type === 'session' && (
                                              <span className="icon-video-camera-2"></span>
                                            )}
                                            {/* <span className="fa-solid fa-shop"></span> */}
                                          </div>
                                          <div
                                            className="user-content as-link"
                                            onClick={() => {
                                              // handleOpenChatRoom(item);
                                            }}>
                                            <h2 className="single-line-text">
                                              {/* {tabType === 'subInvites' &&
                                             !item.hasOwnProperty('read') */}
                                              {chat[1].group_chat_data
                                                .group_name !== ''
                                                ? chat[1].group_chat_data
                                                    .group_name
                                                : fixName(
                                                    chat[1].group_chat_data[
                                                      parseInt(
                                                        chat[1].group_chat_data
                                                          .creator_id,
                                                      ) ===
                                                      parseInt(
                                                        localStorage.getItem(
                                                          'user',
                                                        ),
                                                      )
                                                        ? 'user_detail'
                                                        : chat[1].group_chat_data.hasOwnProperty(
                                                            'creator_details',
                                                          )
                                                        ? 'creator_details'
                                                        : 'creator_detail'
                                                    ]['first_name'],
                                                    chat[1].group_chat_data[
                                                      parseInt(
                                                        chat[1].group_chat_data
                                                          .creator_id,
                                                      ) ===
                                                      parseInt(
                                                        localStorage.getItem(
                                                          'user',
                                                        ),
                                                      )
                                                        ? 'user_detail'
                                                        : chat[1].group_chat_data.hasOwnProperty(
                                                            'creator_details',
                                                          )
                                                        ? 'creator_details'
                                                        : 'creator_detail'
                                                    ]['last_name'],
                                                  )}
                                            </h2>

                                            {formatString(
                                              chat[1].group_chat_data
                                                .last_message,
                                            )}

                                            <div className="message-date-info">
                                              <span className="message-date">
                                                <Moment format="MM/DD/YY HH:mm A">
                                                  {chat[1].group_chat_data
                                                    .last_message_time
                                                    ? chat[1].group_chat_data
                                                        .last_message_time
                                                    : chat[1].group_chat_data
                                                        .created_at}
                                                </Moment>
                                              </span>
                                              {/* {renderMessageUsers(item.moreUsers)} */}
                                            </div>
                                          </div>

                                          {/* {!chat[1].group_chat_data.hasOwnProperty('invitation_type') &&
                                             chat[1].group_chat_data.hasOwnProperty('group_detail') && (
                                              //  <OtherUserImages
                                              //    otherUsers={filteringUsers(
                                              //      chat[1].group_chat_data.audience_detail.concat(
                                              //        chat[1].group_chat_data.moderator_detail,
                                              //        chat[1].group_chat_data.creator_detail
                                              //          ? [chat[1].group_chat_data.creator_detail]
                                              //          : [chat[1].group_chat_data.creator_details],
                                              //      ),
                                              //      arrayColumn(
                                              //        chat[1].group_chat_data.group_detail,
                                              //        'id',
                                              //      ),
                                              //    )}
                                              //  />
                                             )} */}
                                          {!chat[1].group_chat_data.hasOwnProperty(
                                            'invitation_type',
                                          ) &&
                                            !chat[1].group_chat_data.hasOwnProperty(
                                              'opportunity_invitaion_status',
                                            ) &&
                                            chat[1].group_chat_data.hasOwnProperty(
                                              'opportunity_id',
                                            ) &&
                                            chat[1].group_chat_data
                                              .chat_type === 'opportunity' && (
                                              <OtherUserImages
                                                otherUsers={filteringUsers(
                                                  chat[1].group_chat_data.other_persons_opportunity_chat_room.concat(
                                                    chat[1].group_chat_data
                                                      .team_member_details,
                                                    chat[1].group_chat_data
                                                      .creator_detail
                                                      ? [
                                                          chat[1]
                                                            .group_chat_data
                                                            .creator_detail,
                                                        ]
                                                      : [
                                                          chat[1]
                                                            .group_chat_data
                                                            .creator_details,
                                                        ],
                                                  ),
                                                  arrayColumn(
                                                    [
                                                      ...chat[1].group_chat_data
                                                        .other_persons_opportunity_chat_room,
                                                      ...chat[1].group_chat_data
                                                        .team_member_details,
                                                      chat[1].group_chat_data
                                                        .creator_details,
                                                    ],
                                                    'id',
                                                  ),
                                                )}
                                              />
                                            )}
                                        </div>
                                        {/* {PageDesign()} */}
                                      </div>
                                    )}
                                  {chat[1].type === 'appointment' &&
                                    chat[1].appointment_data && (
                                      <div
                                        className="refferals-list as-link container-fromChat-Shareables"
                                        onClick={() => {
                                          ShowSelectedComponent(
                                            chat[1].appointment_data,
                                            chat[1].type,
                                          );
                                        }}>
                                        <div className="">
                                          <div className="refferals-list-item new-refferal-list mb-0">
                                            <div className="refferal-content">
                                              <h2>
                                                {chat[1].appointment_data
                                                  .creator_details &&
                                                  fixName(
                                                    chat[1].appointment_data
                                                      .creator_details
                                                      .first_name,
                                                    chat[1].appointment_data
                                                      .creator_details
                                                      .last_name,
                                                  )}
                                              </h2>
                                              <span className="refferal-auther">
                                                {
                                                  chat[1].appointment_data
                                                    .appointment_type_title
                                                }
                                              </span>
                                              {/* <p>Think there may be some synergy</p> */}
                                              <span className="refferal-date">
                                                {/* {moment(item.start_date).format(
                                                'MM/DD/YYYY',
                                              )}{' '} */}
                                                {chat[1].appointment_data
                                                  .duration + 'min'}
                                              </span>
                                            </div>
                                            <div className="refferal-img">
                                              <img
                                                src={
                                                  chat[1].appointment_data
                                                    .creator_details &&
                                                  chat[1].appointment_data
                                                    .creator_details.profile_img
                                                    ? chat[1].appointment_data
                                                        .creator_details
                                                        .profile_img
                                                    : DEFAULT_IMAGE
                                                }
                                                alt=""
                                              />
                                              <p className="refferal-info-que text-end">
                                                {chat[1].appointment_data.cost
                                                  ? '$' +
                                                    chat[1].appointment_data
                                                      .cost
                                                  : 'Free'}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        {/* {ElementDesign('appointment', item)} */}
                                      </div>
                                    )}
                                  {chat[1].type === 'network' &&
                                    chat[1].network_data && (
                                      <div
                                        className="as-link container-fromChat-Shareables network"
                                        onClick={() => {
                                          ShowSelectedComponent(
                                            chat[1].network_data,
                                            chat[1].type,
                                          );
                                        }}>
                                        <div className="">
                                          <div className="profile-img-wrapper align-items-center justify-content-start">
                                            <div className="profile-img">
                                              <img
                                                src={
                                                  chat[1].network_data
                                                    .profile_img
                                                    ? chat[1].network_data
                                                        .profile_img
                                                    : DEFAULT_IMAGE
                                                }
                                                alt=""
                                              />
                                            </div>

                                            <div className="profile-name flex-0">
                                              <div className="profile-name-headings">
                                                <div className="">
                                                  <h2 className="m-0 mb-1 p-bold">
                                                    {chat[1].network_data &&
                                                      chat[1].network_data
                                                        .first_name}
                                                    {chat[1].network_data &&
                                                      chat[1].network_data
                                                        .last_name}
                                                  </h2>
                                                  {chat[1].network_data
                                                    .on_boarding_areas &&
                                                    chat[1].network_data
                                                      .on_boarding_areas
                                                      .length > 0 && (
                                                      <h3 className="p-reg m-0">
                                                        {chat[1].network_data.on_boarding_areas
                                                          .map(
                                                            area => area.title,
                                                          )
                                                          .join(', ')}
                                                      </h3>
                                                    )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        {/* {ElementDesign('profile', item)} */}
                                      </div>
                                    )}
                                  <div className="snapshot-imgs-wrapper">
                                    {chat[1].type === 'snapshot' && (
                                      <div className="d-flex align-items-center chatSnapshots">
                                        {Object.entries(chat[1].picture_url)
                                          .length &&
                                          chat[1].picture_url
                                            .slice(0, 4)
                                            .map((item, index) => {
                                              return (
                                                <div
                                                  className="msg-img as-link snapShotsImg"
                                                  key={`pic-${index}`}
                                                  onClick={() => {
                                                    if (index === 3) {
                                                      handleChatBoxSignal(
                                                        'show-snapshot-detail',
                                                        chat[1].picture_url,
                                                      );
                                                    } else {
                                                      handleChatBoxSignal(
                                                        'show-snapshot-detail',
                                                        chat[1].picture_url,
                                                        '',
                                                        '',
                                                        item.id,
                                                      );
                                                    }
                                                  }}>
                                                  <img
                                                    src={item.images[0]}
                                                    alt=""
                                                  />
                                                  {chat[1].picture_url.length >
                                                    4 &&
                                                    index === 3 && (
                                                      <span
                                                        className="overlay"
                                                        onClick={() => {
                                                          handleChatBoxSignal(
                                                            'show-snapshot-detail',
                                                            chat[1].picture_url,
                                                          );
                                                          // getSpecificAccomplishment(item.id);
                                                        }}>
                                                        {`+${
                                                          chat[1].picture_url
                                                            .length - 4
                                                        }`}
                                                      </span>
                                                    )}
                                                </div>
                                              );
                                            })}
                                      </div>
                                    )}

                                    {chat[1].type === 'image' && (
                                      <div
                                        className="snapshot-imgs-item"
                                        onClick={() => {
                                          handleChatBoxSignal(
                                            'show-image',
                                            chat[1].picture_url,
                                          );
                                        }}>
                                        <img src={chat[1].picture_url} alt="" />
                                      </div>
                                    )}

                                    {chat[1].type === 'video' &&
                                      chat[1].video_url && (
                                        <div className="snapshot-imgs-item">
                                          <video
                                            src={chat[1].video_url}
                                            playsInline
                                            autoPlay
                                            controls
                                          />
                                        </div>
                                      )}

                                    {/* {chat[1].type === 'document' &&
                                  chat[1].document_url && (
                                    <span className="action-icon file-icon">
                                      <a
                                        href={chat[1].document_url}
                                        target="_blank"
                                        style={{color: 'black'}}
                                        download>
                                        <i className="fas fa-file-lines" />
                                      </a>
                                    </span>
                                  )} */}

                                    {chat[1].type === 'poll' && (
                                      <SmartChatPoll
                                        chat={chat[1]}
                                        handlePoll={type => {
                                          handleSelectReply(
                                            type,
                                            chat[1],
                                            chat[0],
                                            item[0],
                                          );
                                        }}
                                        chatStatusOfOpportunity={
                                          chatEnableStatus
                                        }
                                      />
                                    )}
                                    {/* <div className="loadmore-imgs-link">
                                    <span>Load More</span>
                                  </div> */}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="emoji-icons-wrapper">
                              <div className="emoji-icons d-flex align-items-center">
                                {chat[1].hasOwnProperty('emojis') &&
                                  chat[1].emojis.length > 0 &&
                                  getEmojiObject(chat[1].emojis).map(
                                    (emojiObj, index) => (
                                      <div
                                        onClick={() => {
                                          handleChatBoxSignal(
                                            'show-emojis',
                                            chat[1].emojis,
                                          );
                                        }}
                                        className={
                                          emojiObj.count > 1
                                            ? 'emoji-icons-item with-counts'
                                            : 'emoji-icons-item'
                                        }
                                        key={`${emojiObj.emojiName}-${index}`}>
                                        <img src={emojiObj.image} alt="" />
                                        {emojiObj.count > 1 && (
                                          <span className="reaction-count">
                                            {emojiObj.count}
                                          </span>
                                        )}
                                      </div>
                                    ),
                                  )}

                                <div
                                  className="emoji-icons-item add-new-reaction"
                                  onClick={() => {
                                    if (chatStatus) {
                                      handleSelectReply(
                                        'emoji',
                                        chat[1],
                                        chat[0],
                                        item[0],
                                      );
                                    }
                                  }}>
                                  <i className="icon-smiley-plus" />
                                </div>
                              </div>

                              <span
                                className="icons dot-options as-link"
                                onClick={() => {
                                  if (chatStatus) {
                                    handleChatBoxSignal(
                                      'replyOptions',
                                      chat[1],
                                      chat[0],
                                      item[0],
                                    );
                                  }
                                }}>
                                <i className="fas fa-ellipsis-vertical" />
                              </span>
                            </div>

                            {chat[1].hasOwnProperty('replies') &&
                              chat[1].replies.length > 0 &&
                              getLastNonPrivateObject(chat[1].replies)
                                .lastNonPrivateObj && (
                                <SmartChatReplyBox
                                  reply={
                                    getLastNonPrivateObject(chat[1].replies)
                                      .lastNonPrivateObj
                                  }
                                  user={allChatUsers.find(
                                    objs =>
                                      objs.id ===
                                      chat[1].replies.slice(-1)[0]?.sent_by,
                                  )}
                                />
                              )}

                            <div className="msg-user-replies-wrapper">
                              {chat[1].hasOwnProperty('replies') &&
                                chat[1].replies.length > 0 &&
                                getRepliesObject(chat[1].replies).map(
                                  (obj, i) => (
                                    <div
                                      className="person-img"
                                      key={`repImg-${i}`}
                                      onClick={() => {
                                        // handleChatBoxSignal(
                                        //   'show-profile',
                                        //   allChatUsers.find(
                                        //     objs => objs.id == obj.sent_by,
                                        //   ),
                                        // );
                                        handleSelectReply(
                                          'show-reply-users',
                                          chat[1],
                                          chat[0],
                                          item[0],
                                        );
                                      }}>
                                      <img src={obj.profile_img} alt="" />
                                    </div>
                                  ),
                                )}

                              {chat[1].hasOwnProperty('replies') &&
                                chat[1].replies.length > 0 &&
                                getLastNonPrivateObject(chat[1].replies).count >
                                  0 && (
                                  <span
                                    className="reaction-count as-link"
                                    onClick={() => {
                                      // setReplies(chat[1]);
                                      setCurrentComponent('show-replies');
                                      // if (chat[1].type === 'poll') {
                                      //   setHandleData({
                                      //     key: 'update-poll',
                                      //     item: chat[1],
                                      //     cKey: chat[0],
                                      //     pKey: item[0],
                                      //   });
                                      // }
                                      handleChatBoxSignal(
                                        'show-replies',
                                        chat[1],
                                        chat[0],
                                        item[0],
                                      );
                                    }}>
                                    {getLastNonPrivateObject(chat[1].replies)
                                      .count === 1
                                      ? '1 Reply'
                                      : `${
                                          getLastNonPrivateObject(
                                            chat[1].replies,
                                          ).count - 1
                                        }+ Replies`}
                                  </span>
                                )}
                            </div>
                          </div>
                        </div>
                      ) : (
                        // <>
                        //   {/* /////////////////////// */}
                        //   <div
                        //     key={`messages-${chat[1].send_date}-${index}`}
                        //     className="message-item reply-message">
                        //     {chat[1].isPrivate &&
                        //       chat[1].hasOwnProperty('privateChatData') && (
                        //         <span>
                        //           Only sent to{' '}
                        //           {chat[1].privateChatData.first_name}{' '}
                        //           {chat[1].privateChatData.last_name}
                        //         </span>
                        //       )}
                        //     <div className="msg-container">
                        //       {/* New Update */}
                        //       <div className="msg-content">
                        //         <div className="msg-content-left">
                        //           <span className="msg-content-left-text">
                        //             {chat[1].type !== 'poll' && chat[1].message}
                        //           </span>

                        //           {/* This area for start */}
                        //           {chat[1].type === 'image' && (
                        //             <div
                        //               className="msg-img as-link"
                        //               onClick={() => {
                        //                 handleChatBoxSignal(
                        //                   'show-image',
                        //                   chat[1].picture_url,
                        //                 );
                        //               }}>
                        //               <img src={chat[1].picture_url} />
                        //             </div>
                        //           )}

                        //           {chat[1].type === 'snapshot' &&
                        //             Object.entries(chat[1].picture_url)
                        //               .length &&
                        //             chat[1].picture_url.map((item, index) => {
                        //               return (
                        //                 <div
                        //                   onClick={() => {
                        //                     handleChatBoxSignal(
                        //                       'show-snapshot-detail',
                        //                       item.id,
                        //                     );
                        //                     // getSpecificAccomplishment(item.id);
                        //                   }}
                        //                   key={`pic-${index}`}
                        //                   className="msg-img as-link">
                        //                   <img src={item.images[0]} />
                        //                 </div>
                        //               );
                        //             })}

                        //           {chat[1].type === 'video' &&
                        //             chat[1].video_url && (
                        //               <div className="msg-img">
                        //                 <video
                        //                   src={chat[1].video_url}
                        //                   playsInline
                        //                   autoPlay
                        //                   controls
                        //                 />
                        //               </div>
                        //             )}

                        //           {chat[1].type === 'document' &&
                        //             chat[1].document_url && (
                        //               <span className="action-icon file-icon">
                        //                 <a
                        //                   href={chat[1].document_url}
                        //                   target="_blank"
                        //                   style={{color: 'black'}}
                        //                   download>
                        //                   <i className="fas fa-file-lines" />
                        //                 </a>
                        //               </span>
                        //             )}

                        //           {/* {chat[1].type === 'poll' &&
                        //         chat[1].message.question} */}
                        //           {chat[1].type === 'poll' && (
                        //             <SmartChatPoll
                        //               chat={chat[1]}
                        //               handlePoll={type => {
                        //                 handleSelectReply(
                        //                   type,
                        //                   chat[1],
                        //                   chat[0],
                        //                   item[0],
                        //                 );
                        //               }}
                        //             />
                        //           )}

                        //           {/* This area for end */}

                        //           {chat[1].hasOwnProperty('replies') &&
                        //             chat[1].replies.length > 0 && (
                        //               <SmartChatReplyBox
                        //                 reply={chat[1].replies.slice(-1)[0]}
                        //                 user={allChatUsers.find(
                        //                   objs =>
                        //                     objs.id ==
                        //                     chat[1].replies.slice(-1)[0]
                        //                       ?.sent_by,
                        //                 )}
                        //               />
                        //             )}
                        //         </div>
                        //         <div className="msg-content-right">
                        //           {chat[1].isCorrect && (
                        //             <span>
                        //               <i className="fa-solid fa-circle-check"></i>
                        //             </span>
                        //           )}
                        //           {chatStatus && (
                        //             <span
                        //               className="icons as-link"
                        //               onClick={() => {
                        //                 // toggleReplyOptions(!replyOptions);
                        //                 // call back for
                        //                 handleChatBoxSignal(
                        //                   'replyOptions',
                        //                   chat[1],
                        //                   chat[0],
                        //                   item[0],
                        //                 );
                        //               }}>
                        //               <i className="fas fa-ellipsis-vertical" />
                        //             </span>
                        //           )}
                        //         </div>
                        //       </div>
                        //       {/* {chat[1].type === 'image' && (
                        //       <div className="msg-img">
                        //         <img src={chat[1].picture_url} />
                        //       </div>
                        //     )} */}
                        //       <div className="msg-reactions">
                        //         {chat[1].hasOwnProperty('replies') &&
                        //           chat[1].replies.length > 0 && (
                        //             <span
                        //               className="replies-count as-link"
                        //               onClick={() => {
                        //                 setReplies(chat[1]);
                        //                 setCurrentComponent('show-replies');
                        //                 if (chat[1].type === 'poll') {
                        //                   setHandleData({
                        //                     key: 'update-poll',
                        //                     item: chat[1],
                        //                     cKey: chat[0],
                        //                     pKey: item[0],
                        //                   });
                        //                 }
                        //                 handleChatBoxSignal(
                        //                   'show-replies',
                        //                   chat[1],
                        //                   chat[0],
                        //                   item[0],
                        //                 );
                        //               }}>{`${chat[1].replies.length} Replies`}</span>
                        //           )}

                        //         <div className="message-other-users">
                        //           {chat[1].hasOwnProperty('emojis') &&
                        //             chat[1].emojis.length > 0 && (
                        //               <div
                        //                 className="users-imgs as-link"
                        //                 onClick={() => {
                        //                   handleChatBoxSignal(
                        //                     'show-emojis',
                        //                     chat[1].emojis,
                        //                   );
                        //                 }}>
                        //                 {chat[1].emojis
                        //                   .slice(-3)
                        //                   // .reverse()
                        //                   .map((emojiObj, index) => (
                        //                     <img
                        //                       src={
                        //                         S3_EMOJI_DEV +
                        //                         emojiObj.skinTone +
                        //                         '/' +
                        //                         emojiObj.emojiName +
                        //                         '.svg'
                        //                       }
                        //                       key={`${emojiObj.emojiName}-${index}`}
                        //                     />
                        //                   ))}
                        //                 <span className="reaction-count">
                        //                   {chat[1].emojis.length > 3
                        //                     ? `${chat[1].emojis.length - 3}+`
                        //                     : ''}
                        //                 </span>
                        //               </div>
                        //             )}
                        //         </div>
                        //       </div>

                        //       {/* {chatStatus && (
                        //       <div className="msg-heading">
                        //         {chat[1].isCorrect && (
                        //           <span>
                        //             <i className="fa-solid fa-circle-check"></i>
                        //           </span>
                        //         )}
                        //         <DropdownButton
                        //           className="form-icon"
                        //           // align="up"
                        //           title={<i className="fas fa-ellipsis-v" />}
                        //           id="dropdown-menu-align-end"
                        //           onSelect={e => {
                        //             handleSelectReply(
                        //               e,
                        //               chat[1],
                        //               chat[0],
                        //               item[0],
                        //             );
                        //           }}>
                        //           {chatModule === 'qa-chats' &&
                        //             creatorId &&
                        //             !chat[1].isCorrect && (
                        //               <Dropdown.Item
                        //                 as={'span'}
                        //                 eventKey="best-answer">
                        //                 Mark as best answer
                        //               </Dropdown.Item>
                        //             )}
                        //           <Dropdown.Item as={'span'} eventKey="reply">
                        //             Reply
                        //           </Dropdown.Item>
                        //           <Dropdown.Item as={'span'} eventKey="emoji">
                        //             Emoji
                        //           </Dropdown.Item>
                        //         </DropdownButton>
                        //       </div>
                        //     )} */}
                        //       {/* {chat[1].type === 'poll' &&
                        //       chat[1].message.question}
                        //     {chat[1].type === 'poll' && (
                        //       <SmartChatPoll
                        //         chat={chat[1]}
                        //         handlePoll={type => {
                        //           handleSelectReply(
                        //             type,
                        //             chat[1],
                        //             chat[0],
                        //             item[0],
                        //           );
                        //         }}
                        //       />
                        //     )} */}

                        //       {/* {chat[1].type !== 'poll' && chat[1].message}
                        //     {/* {chat[1].picture_url ? (
                        //                 <div className="msg-img">
                        //                     <img src={chat[1].picture_url} />
                        //                 </div>
                        //                 ) : (
                        //                 <></>
                        //                 )} */}

                        //       {/* {chat[1].type === 'image' ? (
                        //       <div className="msg-img">
                        //         <img src={chat[1].picture_url} />
                        //       </div>
                        //     ) : (
                        //       <></>
                        //     )}  */}

                        //       {/* {chat[1].type === 'snapshot' &&
                        //       Object.entries(chat[1].picture_url).length &&
                        //       chat[1].picture_url.map((item, index) => {
                        //         return (
                        //           <div
                        //             onClick={() => {
                        //               handleChatBoxSignal(
                        //                 'show-snapshot-detail',
                        //                 item.id,
                        //               );
                        //               // getSpecificAccomplishment(item.id);
                        //             }}
                        //             key={index}
                        //             className="msg-img">
                        //             <img src={item.images[0]} />
                        //           </div>
                        //         );
                        //       })}

                        //     {chat[1].document_url ? (
                        //       <span className="action-icon file-icon">
                        //         <a
                        //           href={chat[1].document_url}
                        //           target="_blank"
                        //           style={{color: 'black'}}
                        //           download>
                        //           <i className="fas fa-file-lines" />
                        //         </a>
                        //       </span>
                        //     ) : (
                        //       <></>
                        //     )}

                        //     {chat[1].video_url ? (
                        //       <div className="msg-img">
                        //         <video
                        //           src={chat[1].video_url}
                        //           playsInline
                        //           autoPlay
                        //           controls
                        //         />
                        //       </div>
                        //     ) : (
                        //       <></>
                        //     )}
                        //     {chat[1].hasOwnProperty('replies') &&
                        //       chat[1].replies.length > 0 && (
                        //         <SmartChatReplyBox
                        //           reply={chat[1].replies.slice(-1)[0]}
                        //           user={allChatUsers.find(
                        //             objs =>
                        //               objs.id ==
                        //               chat[1].replies.slice(-1)[0]?.sent_by,
                        //           )}
                        //         />
                        //       )}
                        //     {chat[1].hasOwnProperty('emojis') &&
                        //       chat[1].emojis.length > 0 && (
                        //         <span
                        //           onClick={() => {
                        //             handleChatBoxSignal(
                        //               'show-emojis',
                        //               chat[1].emojis,
                        //             );
                        //           }}
                        //           style={{float: 'right'}}>
                        //           <img
                        //             style={{
                        //               border: '1px solid golden',
                        //               borderRadius: '30px',
                        //               width: '25px',
                        //             }}
                        //             src={
                        //               S3_EMOJI_DEV +
                        //               chat[1].emojis[chat[1].emojis.length - 1]
                        //                 .skinTone +
                        //               '/' +
                        //               chat[1].emojis[chat[1].emojis.length - 1]
                        //                 .emojiName +
                        //               '.svg'
                        //             }
                        //             alt=""
                        //           />
                        //           <>{chat[1].emojis.length}</>
                        //         </span>
                        //       )}
                        //     {chat[1].hasOwnProperty('replies') &&
                        //       chat[1].replies.length > 0 && (
                        //         <div
                        //           onClick={() => {
                        //             setReplies(chat[1]);
                        //             setCurrentComponent('show-replies');
                        //             if (chat[1].type === 'poll') {
                        //               setHandleData({
                        //                 key: 'update-poll',
                        //                 item: chat[1],
                        //                 cKey: chat[0],
                        //                 pKey: item[0],
                        //               });
                        //             }
                        //             handleChatBoxSignal(
                        //               'show-replies',
                        //               chat[1],
                        //               chat[0],
                        //               item[0],
                        //             );
                        //           }}>{`${chat[1].replies.length} replies`}</div>
                        //       )} */}
                        //     </div>
                        //     <div className="msg-usr">
                        //       {/* <div className="msg-usr-img">
                        //                 <img
                        //                     className={`showimg-${chat[1].sent_by}`}
                        //                     src={
                        //                     allChatUsers.find((objs) => objs.id == chat[1].sent_by)
                        //                         ?.profile_img
                        //                     }
                        //                     alt=""
                        //                 />
                        //                 </div> */}
                        //       <div className="msg-usr-details">
                        //         {/* <h2 className={`showname-${chat[1].sent_by}`}>
                        //                     {allChatUsers.find((objs) => objs.id == chat[1].sent_by)?.name}
                        //                 </h2> */}
                        //         <span className="msg-date">
                        //           {getTimeFormatForChat(chat[1].send_date)}
                        //         </span>
                        //       </div>
                        //     </div>
                        //   </div>
                        // </>
                        (!chat[1].isPrivate ||
                          (chat[1].isPrivate &&
                            chat[1].privateChatData.sent_to ===
                              localStorage.getItem('user'))) && (
                          <div
                            key={`msg-${chat[1].send_date}-${index}`}
                            className={`message-item new-msg-item ${
                              chat[1].isPrivate ? 'private-msg' : ''
                            }`}>
                            <div className="msg-container">
                              <div className="msg-content">
                                <div className="msg-content-left">
                                  <div
                                    className="msg-usr-img "
                                    onClick={() => {
                                      handleChatBoxSignal(
                                        'show-profile',
                                        allChatUsers.find(
                                          objs => objs.id === chat[1].sent_by,
                                        ),
                                      );
                                    }}>
                                    <img
                                      src={
                                        allChatUsers.find(
                                          objs => objs.id === chat[1].sent_by,
                                        )?.profile_img
                                          ? allChatUsers.find(
                                              objs =>
                                                objs.id === chat[1].sent_by,
                                            )?.profile_img
                                          : DEFAULT_IMAGE
                                      }
                                      alt=""
                                      className='shadow-none'
                                    />
                                  </div>
                                </div>
                                <div className="msg-content-right">
                                  <h2>
                                    {allChatUsers.find(
                                      objs => objs.id === chat[1].sent_by,
                                    )?.first_name &&
                                      fixName(
                                        allChatUsers.find(
                                          objs => objs.id === chat[1].sent_by,
                                        )?.first_name,
                                        allChatUsers.find(
                                          objs => objs.id === chat[1].sent_by,
                                        )?.last_name,
                                      )}{' '}
                                    <span className="msg-date">
                                      {getTimeFormatForChat(chat[1].send_date)}
                                    </span>
                                    {chat[1].isCorrect && (
                                      <span className="icon">
                                        {' '}
                                        <i className="fa-solid fa-circle-check"></i>
                                      </span>
                                    )}
                                  </h2>
                                  <div className="msg-content-text">
                                    <p>
                                      {chat[1].type !== 'poll' &&
                                        !chat[1].type !== 'emoji' &&
                                        chat[1].message}
                                    </p>
                                    <div className="emoji-icons-wrapper">
                                      <div className="emoji-icons d-flex align-items-center">
                                        {chat[1].type === 'emoji' && (
                                          <div className="emoji-icons-item">
                                            <img
                                              src={
                                                S3_EMOJI_DEV +
                                                chat[1].emoji.skinTone +
                                                '/' +
                                                chat[1].emoji.emojiName +
                                                '.svg'
                                              }
                                              alt=""
                                            />
                                          </div>
                                        )}
                                      </div>
                                    </div>

                                    <div className="snapshot-imgs-wrapper">
                                      {chat[1].type === 'snapshot' && (
                                        <div className="d-flex align-items-center chatSnapshots">
                                          {Object.entries(chat[1].picture_url)
                                            .length &&
                                            chat[1].picture_url
                                              .slice(0, 4)
                                              .map((item, index) => {
                                                return (
                                                  <div
                                                    className="msg-img as-link snapShotsImg"
                                                    key={`pic-${index}`}
                                                    onClick={() => {
                                                      if (index === 3) {
                                                        handleChatBoxSignal(
                                                          'show-snapshot-detail',
                                                          chat[1].picture_url,
                                                        );
                                                      } else {
                                                        handleChatBoxSignal(
                                                          'show-snapshot-detail',
                                                          chat[1].picture_url,
                                                          '',
                                                          '',
                                                          item.id,
                                                        );
                                                      }
                                                    }}>
                                                    <img
                                                      src={item.images[0]}
                                                      alt=""
                                                    />
                                                    {chat[1].picture_url
                                                      .length > 4 &&
                                                      index === 3 && (
                                                        <span
                                                          className="overlay"
                                                          onClick={() => {
                                                            handleChatBoxSignal(
                                                              'show-snapshot-detail',
                                                              chat[1]
                                                                .picture_url,
                                                            );
                                                            // getSpecificAccomplishment(item.id);
                                                          }}>
                                                          {`+${
                                                            chat[1].picture_url
                                                              .length - 4
                                                          }`}
                                                        </span>
                                                      )}
                                                  </div>
                                                );
                                              })}
                                        </div>
                                      )}

                                      {chat[1].type === 'image' && (
                                        <div
                                          className="snapshot-imgs-item"
                                          onClick={() => {
                                            handleChatBoxSignal(
                                              'show-image',
                                              chat[1].picture_url,
                                            );
                                          }}>
                                          <img
                                            src={chat[1].picture_url}
                                            alt=""
                                          />
                                        </div>
                                      )}

                                      {chat[1].type === 'video' &&
                                        chat[1].video_url && (
                                          <div className="snapshot-imgs-item">
                                            <video
                                              src={chat[1].video_url}
                                              playsInline
                                              autoPlay
                                              controls
                                            />
                                          </div>
                                        )}

                                      {chat[1].type === 'accomplishment' && (
                                        <div
                                          className="container-fromChat-Shareables shareables-snapshots"
                                          onClick={() => {
                                            // alert('daniyal');
                                            ShowSelectedComponent(
                                              chat[1].accomplishment_data,
                                              chat[1].type,
                                            );
                                          }}>
                                          <div className="as-link">
                                            <div className=" d-flex align-items-center mb-3">
                                              <div className="snapshotsImage as-link">
                                                <img
                                                  src={
                                                    chat[1]
                                                      .accomplishment_data &&
                                                    chat[1].accomplishment_data
                                                      .images[0]
                                                  }
                                                  alt=""
                                                />
                                              </div>
                                              <div
                                                className="snapshots-content as-link"
                                                onClick={() => {
                                                  // setSnapShotId(item);
                                                  // setShowComponent('SnapShotDetail');
                                                }}>
                                                <h2 className="single-line-text">
                                                  {chat[1]
                                                    .accomplishment_data &&
                                                    chat[1].accomplishment_data
                                                      .title}
                                                </h2>
                                                {chat[1].accomplishment_data
                                                  .category_name && (
                                                  <p className="p-reg single-line-text category-name">
                                                    {chat[1]
                                                      .accomplishment_data &&
                                                      chat[1]
                                                        .accomplishment_data
                                                        .category_name}
                                                  </p>
                                                )}
                                              </div>
                                            </div>
                                            {chat[1].accomplishment_data && (
                                              <p className="p-reg truncate_2_lines">
                                                {
                                                  chat[1].accomplishment_data
                                                    .description
                                                }
                                              </p>
                                            )}
                                          </div>
                                          {/* {ElementDesign('snapShot', item)} */}
                                        </div>
                                      )}
                                      {chat[1].type === 'network' &&
                                        chat[1].network_data && (
                                          <div
                                            className="as-link container-fromChat-Shareables network"
                                            onClick={() => {
                                              ShowSelectedComponent(
                                                chat[1].network_data,
                                                chat[1].type,
                                              );
                                            }}>
                                            <div className="">
                                              <div className="profile-img-wrapper align-items-center justify-content-start">
                                                <div className="profile-img">
                                                  <img
                                                    src={
                                                      chat[1].network_data
                                                        .profile_img
                                                        ? chat[1].network_data
                                                            .profile_img
                                                        : DEFAULT_IMAGE
                                                    }
                                                    alt=""
                                                  />
                                                </div>

                                                <div className="profile-name flex-0">
                                                  <div className="profile-name-headings">
                                                    <div className="">
                                                      <h2 className="m-0 mb-1 p-bold">
                                                        {chat[1].network_data &&
                                                          chat[1].network_data
                                                            .first_name}
                                                        {chat[1].network_data &&
                                                          chat[1].network_data
                                                            .last_name}
                                                      </h2>
                                                      {chat[1].network_data
                                                        .on_boarding_areas &&
                                                        chat[1].network_data
                                                          .on_boarding_areas
                                                          .length >
                                                          (0).length >
                                                          0 && (
                                                          <h3 className="p-reg m-0">
                                                            {chat[1].network_data.on_boarding_areas
                                                              .map(
                                                                area =>
                                                                  area.title,
                                                              )
                                                              .join(', ')}
                                                          </h3>
                                                        )}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            {/* {ElementDesign('profile', item)} */}
                                          </div>
                                        )}
                                      {chat[1].type === 'opportunity' &&
                                        chat[1].opportunity_data && (
                                          <ShareAableOpportunity
                                            // key={`opportunity-item-${index}`}
                                            setShowButton={''}
                                            onShare={() => {
                                              // setProperComponent('refer-opportunity');
                                            }}
                                            handleItem={ShowSelectedComponent}
                                            setChatData={setChatData}
                                            //  handleItem={''}
                                            item={chat[1].opportunity_data}
                                            //  ElementDesign={''}
                                            typeMesages={true}
                                          />
                                        )}
                                      {chat[1].type === 'session' &&
                                        chat[1].session_data && (
                                          <ShareAblesSessions
                                            item={chat[1].session_data}
                                            heart={''}
                                            componentDecesion={
                                              ShowSelectedComponent
                                            }
                                            //  ElementDesign={}
                                            typeMesages={true}
                                          />
                                        )}
                                      {chat[1].type === 'event' &&
                                        chat[1].event_data && (
                                          <ShreAblesEvent
                                            //  key={`event-item-${index}`}
                                            item={chat[1].event_data}
                                            //  index={index}
                                            // eventDetails={eventDetails}
                                            componentDecesion={
                                              ShowSelectedComponent
                                            }
                                            //  ElementDesign={ElementDesign}
                                            typeMesages={true}
                                          />
                                        )}
                                      {chat[1].type === 'group_chat' &&
                                        chat[1].group_chat_data && (
                                          <div
                                            className="messages-users-list as-link container-fromChat-Shareables group-chat"
                                            onClick={() => {
                                              handleOpenChatRoom(
                                                chat[1].group_chat_data,
                                              );
                                            }}>
                                            <div
                                              className={
                                                'message-user-item no-link m-0 p-0'
                                              }>
                                              <div
                                                className="user-img as-link"
                                                // onClick={() => {
                                                //   amplitudeEvent('OPEN_CHAT_ROOM');
                                                //   handleOpenChatRoom(item);
                                                // }}
                                              >
                                                <img
                                                  src={
                                                    chat[1].group_chat_data
                                                      .group_image !== ''
                                                      ? chat[1].group_chat_data
                                                          .group_image
                                                      : chat[1].group_chat_data[
                                                          parseInt(
                                                            chat[1]
                                                              .group_chat_data
                                                              .creator_id,
                                                          ) ===
                                                          parseInt(
                                                            localStorage.getItem(
                                                              'user',
                                                            ),
                                                          )
                                                            ? 'user_detail'
                                                            : chat[1].group_chat_data.hasOwnProperty(
                                                                'creator_details',
                                                              )
                                                            ? 'creator_details'
                                                            : 'creator_detail'
                                                        ]['profile_img']
                                                      ? chat[1].group_chat_data[
                                                          parseInt(
                                                            chat[1]
                                                              .group_chat_data
                                                              .creator_id,
                                                          ) ===
                                                          parseInt(
                                                            localStorage.getItem(
                                                              'user',
                                                            ),
                                                          )
                                                            ? 'user_detail'
                                                            : chat[1].group_chat_data.hasOwnProperty(
                                                                'creator_details',
                                                              )
                                                            ? 'creator_details'
                                                            : 'creator_detail'
                                                        ]['profile_img']
                                                      : DEFAULT_IMAGE
                                                  }
                                                  alt=""
                                                />
                                                {chat[1].group_chat_data
                                                  .chat_type === 'question' && (
                                                  <span>
                                                    <i className="fa-solid fa-q"></i>
                                                  </span>
                                                )}
                                                {chat[1].group_chat_data
                                                  .chat_type ===
                                                  'opportunity' && (
                                                  <span className="icon icon-posts"></span>
                                                )}
                                                {chat[1].group_chat_data
                                                  .chat_type === 'event' && (
                                                  <span className="icon-tickets"></span>
                                                )}
                                                {chat[1].group_chat_data
                                                  .chat_type === 'session' && (
                                                  <span className="icon-video-camera-2"></span>
                                                )}
                                                {/* <span className="fa-solid fa-shop"></span> */}
                                              </div>
                                              <div
                                                className="user-content as-link"
                                                onClick={() => {
                                                  // handleOpenChatRoom(item);
                                                }}>
                                                <h2 className="single-line-text">
                                                  {/* {tabType === 'subInvites' &&
                                             !item.hasOwnProperty('read') */}
                                                  {chat[1].group_chat_data
                                                    .group_name !== ''
                                                    ? chat[1].group_chat_data
                                                        .group_name
                                                    : fixName(
                                                        chat[1].group_chat_data[
                                                          parseInt(
                                                            chat[1]
                                                              .group_chat_data
                                                              .creator_id,
                                                          ) ===
                                                          parseInt(
                                                            localStorage.getItem(
                                                              'user',
                                                            ),
                                                          )
                                                            ? 'user_detail'
                                                            : chat[1].group_chat_data.hasOwnProperty(
                                                                'creator_details',
                                                              )
                                                            ? 'creator_details'
                                                            : 'creator_detail'
                                                        ]['first_name'],
                                                        chat[1].group_chat_data[
                                                          parseInt(
                                                            chat[1]
                                                              .group_chat_data
                                                              .creator_id,
                                                          ) ===
                                                          parseInt(
                                                            localStorage.getItem(
                                                              'user',
                                                            ),
                                                          )
                                                            ? 'user_detail'
                                                            : chat[1].group_chat_data.hasOwnProperty(
                                                                'creator_details',
                                                              )
                                                            ? 'creator_details'
                                                            : 'creator_detail'
                                                        ]['last_name'],
                                                      )}
                                                </h2>

                                                {formatString(
                                                  chat[1].group_chat_data
                                                    .last_message,
                                                )}

                                                <div className="message-date-info">
                                                  <span className="message-date">
                                                    <Moment format="MM/DD/YY HH:mm A">
                                                      {chat[1].group_chat_data
                                                        .last_message_time
                                                        ? chat[1]
                                                            .group_chat_data
                                                            .last_message_time
                                                        : chat[1]
                                                            .group_chat_data
                                                            .created_at}
                                                    </Moment>
                                                  </span>
                                                  {/* {renderMessageUsers(item.moreUsers)} */}
                                                </div>
                                              </div>

                                              {/* {!chat[1].group_chat_data.hasOwnProperty('invitation_type') &&
                                             chat[1].group_chat_data.hasOwnProperty('group_detail') && (
                                              //  <OtherUserImages
                                              //    otherUsers={filteringUsers(
                                              //      chat[1].group_chat_data.audience_detail.concat(
                                              //        chat[1].group_chat_data.moderator_detail,
                                              //        chat[1].group_chat_data.creator_detail
                                              //          ? [chat[1].group_chat_data.creator_detail]
                                              //          : [chat[1].group_chat_data.creator_details],
                                              //      ),
                                              //      arrayColumn(
                                              //        chat[1].group_chat_data.group_detail,
                                              //        'id',
                                              //      ),
                                              //    )}
                                              //  />
                                             )} */}
                                              {!chat[1].group_chat_data.hasOwnProperty(
                                                'invitation_type',
                                              ) &&
                                                !chat[1].group_chat_data.hasOwnProperty(
                                                  'opportunity_invitaion_status',
                                                ) &&
                                                chat[1].group_chat_data.hasOwnProperty(
                                                  'opportunity_id',
                                                ) &&
                                                chat[1].group_chat_data
                                                  .chat_type ===
                                                  'opportunity' && (
                                                  <OtherUserImages
                                                    otherUsers={filteringUsers(
                                                      chat[1].group_chat_data.other_persons_opportunity_chat_room.concat(
                                                        chat[1].group_chat_data
                                                          .team_member_details,
                                                        chat[1].group_chat_data
                                                          .creator_detail
                                                          ? [
                                                              chat[1]
                                                                .group_chat_data
                                                                .creator_detail,
                                                            ]
                                                          : [
                                                              chat[1]
                                                                .group_chat_data
                                                                .creator_details,
                                                            ],
                                                      ),
                                                      arrayColumn(
                                                        [
                                                          ...chat[1]
                                                            .group_chat_data
                                                            .other_persons_opportunity_chat_room,
                                                          ...chat[1]
                                                            .group_chat_data
                                                            .team_member_details,
                                                          chat[1]
                                                            .group_chat_data
                                                            .creator_details,
                                                        ],
                                                        'id',
                                                      ),
                                                    )}
                                                  />
                                                )}
                                            </div>
                                            {/* {PageDesign()} */}
                                          </div>
                                        )}
                                      {chat[1].type === 'appointment' &&
                                        chat[1].appointment_data && (
                                          <div
                                            className="refferals-list as-link container-fromChat-Shareables"
                                            onClick={() => {
                                              ShowSelectedComponent(
                                                chat[1].appointment_data,
                                                chat[1].type,
                                              );
                                            }}>
                                            <div className="">
                                              <div className="refferals-list-item new-refferal-list mb-0">
                                                <div className="refferal-content">
                                                  <h2>
                                                    {chat[1].appointment_data
                                                      .creator_details &&
                                                      fixName(
                                                        chat[1].appointment_data
                                                          .creator_details
                                                          .first_name,
                                                        chat[1].appointment_data
                                                          .creator_details
                                                          .last_name,
                                                      )}
                                                  </h2>
                                                  <span className="refferal-auther">
                                                    {
                                                      chat[1].appointment_data
                                                        .appointment_type_title
                                                    }
                                                  </span>
                                                  {/* <p>Think there may be some synergy</p> */}
                                                  <span className="refferal-date">
                                                    {/* {moment(item.start_date).format(
                                                'MM/DD/YYYY',
                                              )}{' '} */}
                                                    {chat[1].appointment_data
                                                      .duration + 'min'}
                                                  </span>
                                                </div>
                                                <div className="refferal-img">
                                                  <img
                                                    src={
                                                      chat[1].appointment_data
                                                        .creator_details &&
                                                      chat[1].appointment_data
                                                        .creator_details
                                                        .profile_img
                                                        ? chat[1]
                                                            .appointment_data
                                                            .creator_details
                                                            .profile_img
                                                        : DEFAULT_IMAGE
                                                    }
                                                    alt=""
                                                  />
                                                  <p className="refferal-info-que text-end">
                                                    {chat[1].appointment_data
                                                      .cost
                                                      ? '$' +
                                                        chat[1].appointment_data
                                                          .cost
                                                      : 'Free'}
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                            {/* {ElementDesign('appointment', item)} */}
                                          </div>
                                        )}

                                      {/* {chat[1].type === 'document' &&
                                  chat[1].document_url && (
                                    <span className="action-icon file-icon">
                                      <a
                                        href={chat[1].document_url}
                                        target="_blank"
                                        style={{color: 'black'}}
                                        download>
                                        <i className="fas fa-file-lines" />
                                      </a>
                                    </span>
                                  )} */}

                                      {chat[1].type === 'poll' && (
                                        <SmartChatPoll
                                          chat={chat[1]}
                                          handlePoll={type => {
                                            handleSelectReply(
                                              type,
                                              chat[1],
                                              chat[0],
                                              item[0],
                                            );
                                          }}
                                          chatStatusOfOpportunity={
                                            chatEnableStatus
                                          }
                                        />
                                      )}
                                      {/* <div className="loadmore-imgs-link">
                                    <span>Load More</span>
                                  </div> */}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="emoji-icons-wrapper">
                                <div className="emoji-icons d-flex align-items-center">
                                  {chat[1].hasOwnProperty('emojis') &&
                                    chat[1].emojis.length > 0 &&
                                    getEmojiObject(chat[1].emojis).map(
                                      (emojiObj, index) => (
                                        <div
                                          onClick={() => {
                                            handleChatBoxSignal(
                                              'show-emojis',
                                              chat[1].emojis,
                                            );
                                          }}
                                          className={
                                            emojiObj.count > 1
                                              ? 'emoji-icons-item with-counts'
                                              : 'emoji-icons-item'
                                          }
                                          key={`${emojiObj.emojiName}-${index}`}>
                                          <img src={emojiObj.image} alt="" />
                                          {emojiObj.count > 1 && (
                                            <span className="reaction-count">
                                              {emojiObj.count}
                                            </span>
                                          )}
                                        </div>
                                      ),
                                    )}

                                  <div
                                    className="emoji-icons-item add-new-reaction"
                                    onClick={() => {
                                      if (chatStatus) {
                                        handleSelectReply(
                                          'emoji',
                                          chat[1],
                                          chat[0],
                                          item[0],
                                        );
                                      }
                                    }}>
                                    <i className="icon-smiley-plus" />
                                  </div>
                                </div>

                                <span
                                  className="icons dot-options as-link"
                                  onClick={() => {
                                    if (chatStatus) {
                                      handleChatBoxSignal(
                                        'replyOptions',
                                        chat[1],
                                        chat[0],
                                        item[0],
                                      );
                                    }
                                  }}>
                                  <i className="fas fa-ellipsis-vertical" />
                                </span>
                              </div>

                              {chat[1].hasOwnProperty('replies') &&
                                chat[1].replies.length > 0 &&
                                getLastNonPrivateObject(chat[1].replies)
                                  .lastNonPrivateObj && (
                                  <SmartChatReplyBox
                                    reply={
                                      getLastNonPrivateObject(chat[1].replies)
                                        .lastNonPrivateObj
                                    }
                                    user={allChatUsers.find(
                                      objs =>
                                        objs.id ===
                                        chat[1].replies.slice(-1)[0]?.sent_by,
                                    )}
                                  />
                                )}

                              <div className="msg-user-replies-wrapper">
                                {chat[1].hasOwnProperty('replies') &&
                                  chat[1].replies.length > 0 &&
                                  getRepliesObject(chat[1].replies).map(
                                    (obj, i) => (
                                      <div
                                        className="person-img"
                                        key={`repImg-${i}`}
                                        onClick={() => {
                                          // handleChatBoxSignal(
                                          //   'show-profile',
                                          //   allChatUsers.find(
                                          //     objs => objs.id == obj.sent_by,
                                          //   ),
                                          // );
                                          handleSelectReply(
                                            'show-reply-users',
                                            chat[1],
                                            chat[0],
                                            item[0],
                                          );
                                        }}>
                                        <img src={obj.profile_img} alt="" />
                                      </div>
                                    ),
                                  )}

                                {chat[1].hasOwnProperty('replies') &&
                                  chat[1].replies.length > 0 &&
                                  getLastNonPrivateObject(chat[1].replies)
                                    .count > 0 && (
                                    <span
                                      className="reaction-count as-link"
                                      onClick={() => {
                                        // setReplies(chat[1]);
                                        setCurrentComponent('show-replies');
                                        // if (chat[1].type === 'poll') {
                                        //   setHandleData({
                                        //     key: 'update-poll',
                                        //     item: chat[1],
                                        //     cKey: chat[0],
                                        //     pKey: item[0],
                                        //   });
                                        // }
                                        handleChatBoxSignal(
                                          'show-replies',
                                          chat[1],
                                          chat[0],
                                          item[0],
                                        );
                                      }}>
                                      {getLastNonPrivateObject(chat[1].replies)
                                        .count === 1
                                        ? '1 Reply'
                                        : `${
                                            getLastNonPrivateObject(
                                              chat[1].replies,
                                            ).count - 1
                                          }+ Replies`}
                                    </span>
                                  )}
                              </div>
                            </div>
                          </div>

                          // <div
                          //   key={`msg-${chat[1].send_date}-${index}`}
                          //   className="message-item">
                          //   <div className="msg-container">
                          //     {/* New Update */}
                          //     <div className="msg-content">
                          //       <div className="msg-content-left">
                          //         {chat[1].type !== 'poll' && chat[1].message}

                          //         {/* This area for start */}
                          //         {chat[1].type === 'image' && (
                          //           <div
                          //             className="msg-img as-link"
                          //             onClick={() => {
                          //               handleChatBoxSignal(
                          //                 'show-image',
                          //                 chat[1].picture_url,
                          //               );
                          //             }}>
                          //             <img src={chat[1].picture_url} />
                          //           </div>
                          //         )}

                          //         {chat[1].type === 'snapshot' &&
                          //           Object.entries(chat[1].picture_url)
                          //             .length &&
                          //           chat[1].picture_url.map((item, index) => {
                          //             return (
                          //               <div
                          //                 onClick={() => {
                          //                   handleChatBoxSignal(
                          //                     'show-snapshot-detail',
                          //                     item.id,
                          //                   );
                          //                   // getSpecificAccomplishment(item.id);
                          //                 }}
                          //                 key={`snap-${index}`}
                          //                 className="msg-img as-link">
                          //                 <img src={item.images[0]} />
                          //               </div>
                          //             );
                          //           })}

                          //         {chat[1].type === 'video' &&
                          //           chat[1].video_url && (
                          //             <div className="msg-img">
                          //               <video
                          //                 src={chat[1].video_url}
                          //                 playsInline
                          //                 autoPlay
                          //                 controls
                          //               />
                          //             </div>
                          //           )}

                          //         {chat[1].type === 'document' &&
                          //           chat[1].document_url && (
                          //             <span className="action-icon file-icon">
                          //               <a
                          //                 href={chat[1].document_url}
                          //                 target="_blank"
                          //                 style={{color: 'black'}}
                          //                 download>
                          //                 <i className="fas fa-file-lines" />
                          //               </a>
                          //             </span>
                          //           )}

                          //         {/* {chat[1].type === 'poll' &&
                          //         chat[1].message.question} */}
                          //         {chat[1].type === 'poll' && (
                          //           <SmartChatPoll
                          //             chat={chat[1]}
                          //             handlePoll={type => {
                          //               handleSelectReply(
                          //                 type,
                          //                 chat[1],
                          //                 chat[0],
                          //                 item[0],
                          //               );
                          //             }}
                          //           />
                          //         )}

                          //         {/* This area for end */}

                          //         {chat[1].hasOwnProperty('replies') &&
                          //           chat[1].replies.length > 0 &&
                          //           getLastNonPrivateObject(chat[1].replies)
                          //             .lastNonPrivateObj && (
                          //             <SmartChatReplyBox
                          //               reply={
                          //                 getLastNonPrivateObject(
                          //                   chat[1].replies,
                          //                 ).lastNonPrivateObj
                          //               }
                          //               // reply={chat[1].replies.slice(-1)[0]}
                          //               user={allChatUsers.find(
                          //                 objs =>
                          //                   objs.id ==
                          //                   chat[1].replies.slice(-1)[0]
                          //                     ?.sent_by,
                          //               )}
                          //             />
                          //           )}
                          //       </div>
                          //       <div className="msg-content-right">
                          //         {chat[1].isCorrect && (
                          //           <span>
                          //             <i className="fa-solid fa-circle-check"></i>
                          //           </span>
                          //         )}
                          //         {chatStatus && (
                          //           <span
                          //             className="icons as-link"
                          //             onClick={() => {
                          //               // toggleReplyOptions(!replyOptions);
                          //               // call back for
                          //               handleChatBoxSignal(
                          //                 'replyOptions',
                          //                 chat[1],
                          //                 chat[0],
                          //                 item[0],
                          //               );
                          //             }}>
                          //             <i className="fas fa-ellipsis-vertical" />
                          //           </span>
                          //         )}
                          //       </div>
                          //     </div>
                          //     {/* {chat[1].type === 'image' && (
                          //     <div className="msg-img">
                          //       <img src={chat[1].picture_url} />
                          //     </div>
                          //   )} */}
                          //     <div className="msg-reactions">
                          //       {chat[1].hasOwnProperty('replies') &&
                          //         chat[1].replies.length > 0 &&
                          //         getLastNonPrivateObject(chat[1].replies)
                          //           .count > 0 && (
                          //           <span
                          //             className="replies-count as-link"
                          //             onClick={() => {
                          //               setReplies(chat[1]);
                          //               setCurrentComponent('show-replies');
                          //               if (chat[1].type === 'poll') {
                          //                 setHandleData({
                          //                   key: 'update-poll',
                          //                   item: chat[1],
                          //                   cKey: chat[0],
                          //                   pKey: item[0],
                          //                 });
                          //               }
                          //               handleChatBoxSignal(
                          //                 'show-replies',
                          //                 chat[1],
                          //                 chat[0],
                          //                 item[0],
                          //               );
                          //             }}>{`${
                          //             getLastNonPrivateObject(chat[1].replies)
                          //               .count
                          //           } Replies`}</span>
                          //         )}

                          //       <div className="message-other-users">
                          //         {chat[1].hasOwnProperty('emojis') &&
                          //           chat[1].emojis.length > 0 && (
                          //             <div
                          //               className="users-imgs as-link"
                          //               onClick={() => {
                          //                 handleChatBoxSignal(
                          //                   'show-emojis',
                          //                   chat[1].emojis,
                          //                 );
                          //               }}>
                          //               {chat[1].emojis
                          //                 .slice(-3)
                          //                 // .reverse()
                          //                 .map((emojiObj, index) => (
                          //                   <img
                          //                     src={
                          //                       S3_EMOJI_DEV +
                          //                       emojiObj.skinTone +
                          //                       '/' +
                          //                       emojiObj.emojiName +
                          //                       '.svg'
                          //                     }
                          //                     key={`${emojiObj.emojiName}-${index}`}
                          //                   />
                          //                 ))}
                          //               <span className="reaction-count">
                          //                 {chat[1].emojis.length > 3
                          //                   ? `${chat[1].emojis.length - 3}+`
                          //                   : ''}
                          //               </span>
                          //             </div>
                          //           )}
                          //       </div>
                          //     </div>

                          //     {/* {chatStatus && (
                          //     <div className="msg-heading">
                          //       {chat[1].isCorrect && (
                          //         <span>
                          //           <i className="fa-solid fa-circle-check"></i>
                          //         </span>
                          //       )}
                          //       <DropdownButton
                          //         className="form-icon"
                          //         // align="up"
                          //         title={<i className="fas fa-ellipsis-v" />}
                          //         id="dropdown-menu-align-end"
                          //         onSelect={e => {
                          //           handleSelectReply(
                          //             e,
                          //             chat[1],
                          //             chat[0],
                          //             item[0],
                          //           );
                          //         }}>
                          //         {chatModule === 'qa-chats' &&
                          //           creatorId &&
                          //           !chat[1].isCorrect && (
                          //             <Dropdown.Item
                          //               as={'span'}
                          //               eventKey="best-answer">
                          //               Mark as best answer
                          //             </Dropdown.Item>
                          //           )}
                          //         <Dropdown.Item as={'span'} eventKey="reply">
                          //           Reply
                          //         </Dropdown.Item>
                          //         <Dropdown.Item as={'span'} eventKey="emoji">
                          //           Emoji
                          //         </Dropdown.Item>
                          //       </DropdownButton>
                          //     </div>
                          //   )} */}
                          //     {/* {chat[1].type === 'poll' &&
                          //     chat[1].message.question}
                          //   {chat[1].type === 'poll' && (
                          //     <SmartChatPoll
                          //       chat={chat[1]}
                          //       handlePoll={type => {
                          //         handleSelectReply(
                          //           type,
                          //           chat[1],
                          //           chat[0],
                          //           item[0],
                          //         );
                          //       }}
                          //     />
                          //   )} */}

                          //     {/* {chat[1].type !== 'poll' && chat[1].message}
                          //   {/* {chat[1].picture_url ? (
                          //               <div className="msg-img">
                          //                   <img src={chat[1].picture_url} />
                          //               </div>
                          //               ) : (
                          //               <></>
                          //               )} */}

                          //     {/* {chat[1].type === 'image' ? (
                          //     <div className="msg-img">
                          //       <img src={chat[1].picture_url} />
                          //     </div>
                          //   ) : (
                          //     <></>
                          //   )}  */}

                          //     {/* {chat[1].type === 'snapshot' &&
                          //     Object.entries(chat[1].picture_url).length &&
                          //     chat[1].picture_url.map((item, index) => {
                          //       return (
                          //         <div
                          //           onClick={() => {
                          //             handleChatBoxSignal(
                          //               'show-snapshot-detail',
                          //               item.id,
                          //             );
                          //             // getSpecificAccomplishment(item.id);
                          //           }}
                          //           key={index}
                          //           className="msg-img">
                          //           <img src={item.images[0]} />
                          //         </div>
                          //       );
                          //     })}

                          //   {chat[1].document_url ? (
                          //     <span className="action-icon file-icon">
                          //       <a
                          //         href={chat[1].document_url}
                          //         target="_blank"
                          //         style={{color: 'black'}}
                          //         download>
                          //         <i className="fas fa-file-lines" />
                          //       </a>
                          //     </span>
                          //   ) : (
                          //     <></>
                          //   )}

                          //   {chat[1].video_url ? (
                          //     <div className="msg-img">
                          //       <video
                          //         src={chat[1].video_url}
                          //         playsInline
                          //         autoPlay
                          //         controls
                          //       />
                          //     </div>
                          //   ) : (
                          //     <></>
                          //   )}
                          //   {chat[1].hasOwnProperty('replies') &&
                          //     chat[1].replies.length > 0 && (
                          //       <SmartChatReplyBox
                          //         reply={chat[1].replies.slice(-1)[0]}
                          //         user={allChatUsers.find(
                          //           objs =>
                          //             objs.id ==
                          //             chat[1].replies.slice(-1)[0]?.sent_by,
                          //         )}
                          //       />
                          //     )}
                          //   {chat[1].hasOwnProperty('emojis') &&
                          //     chat[1].emojis.length > 0 && (
                          //       <span
                          //         onClick={() => {
                          //           handleChatBoxSignal(
                          //             'show-emojis',
                          //             chat[1].emojis,
                          //           );
                          //         }}
                          //         style={{float: 'right'}}>
                          //         <img
                          //           style={{
                          //             border: '1px solid golden',
                          //             borderRadius: '30px',
                          //             width: '25px',
                          //           }}
                          //           src={
                          //             S3_EMOJI_DEV +
                          //             chat[1].emojis[chat[1].emojis.length - 1]
                          //               .skinTone +
                          //             '/' +
                          //             chat[1].emojis[chat[1].emojis.length - 1]
                          //               .emojiName +
                          //             '.svg'
                          //           }
                          //           alt=""
                          //         />
                          //         <>{chat[1].emojis.length}</>
                          //       </span>
                          //     )}
                          //   {chat[1].hasOwnProperty('replies') &&
                          //     chat[1].replies.length > 0 && (
                          //       <div
                          //         onClick={() => {
                          //           setReplies(chat[1]);
                          //           setCurrentComponent('show-replies');
                          //           if (chat[1].type === 'poll') {
                          //             setHandleData({
                          //               key: 'update-poll',
                          //               item: chat[1],
                          //               cKey: chat[0],
                          //               pKey: item[0],
                          //             });
                          //           }
                          //           handleChatBoxSignal(
                          //             'show-replies',
                          //             chat[1],
                          //             chat[0],
                          //             item[0],
                          //           );
                          //         }}>{`${chat[1].replies.length} replies`}</div>
                          //     )} */}
                          //   </div>
                          //   <div className="msg-usr">
                          //     <div
                          //       className="msg-usr-img as-link"
                          //       onClick={() => {
                          //         handleChatBoxSignal(
                          //           'mention-user',
                          //           allChatUsers.find(
                          //             objs => objs.id == chat[1].sent_by,
                          //           ),
                          //         );
                          //       }}>
                          //       <img
                          //         src={
                          //           allChatUsers.find(
                          //             objs => objs.id == chat[1].sent_by,
                          //           )?.profile_img
                          //             ? allChatUsers.find(
                          //                 objs => objs.id == chat[1].sent_by,
                          //               )?.profile_img
                          //             : DEFAULT_IMAGE
                          //         }
                          //         alt=""
                          //       />
                          //     </div>
                          //     <div className="msg-usr-details">
                          //       <h2
                          //         className="as-link"
                          //         onClick={() => {
                          //           handleChatBoxSignal(
                          //             'show-profile',
                          //             allChatUsers.find(
                          //               objs => objs.id == chat[1].sent_by,
                          //             ),
                          //           );
                          //         }}>
                          //         {allChatUsers.find(
                          //           objs => objs.id == chat[1].sent_by,
                          //         )?.first_name &&
                          //           fixName(
                          //             allChatUsers.find(
                          //               objs => objs.id == chat[1].sent_by,
                          //             )?.first_name,
                          //             allChatUsers.find(
                          //               objs => objs.id == chat[1].sent_by,
                          //             )?.last_name,
                          //           )}
                          //       </h2>
                          //       <span className="msg-date">
                          //         {getTimeFormatForChat(chat[1].send_date)}
                          //       </span>
                          //     </div>
                          //   </div>
                          // </div>
                        )
                      ))
                    );
                  })}
              </div>
            ))}
          <div ref={messagesEndRef} />
        </div>
      </div>
      {/* )} */}

      {/* {currentComponent === "show-replies" && replies.hasOwnProperty("replies") &&
        <ReplyList
            onClose={()=>{setCurrentComponent("")}}
            chatx={replies}
            allChatUsers={allChatUsers}
            handlePoll={(type)=>{
                handleSelectReply(type, handleData.item, handleData.cKey, handleData.pKey);
            }}
            messagePath={handleData}
        />
    } */}
    </>
  );
};

export default SmartChatBox;
