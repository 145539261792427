import React, {useEffect, useState} from 'react';
import {CustomModal, CustomSlidingButtons, Pannels} from '../../../Components';
import Sidebar from '../../Sidebar';
import {connect} from 'react-redux';

import {toggleSecondaryPannel} from '../../../Store/Actions/CommanAction';
import {Button} from 'react-bootstrap';
import {
  DollarSign,
  TickSign,
  rewardStar,
  vinceProfile,
} from '../../../Constants/Images';
import Scrollbars from 'react-custom-scrollbars';
import CustomDropdown from '../../../Components/CustomDropdown';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import {Line} from 'react-chartjs-2';
import CreateWorkSpace from '../../../Components/WorkSpace/CreateWorkSpace';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Slider from 'react-slick';
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const Analytics = props => {
  // const [currentComponent, setCurrentComponent] = useState('Team');
  const [showHiddenPanel, toggleHiddenPanel] = useState(false);
  const [showComponent, setShowComponent] = useState('');
  // const [threeDots, setThreeDots] = useState(false);
  const [workSpaceItem, setWorkSpaceItem] = useState([]);
  const [dropDownType, setDropDownType] = useState('overview');
  const [contributorType, setContributorType] = useState('all');
  // const [analycticsSlider, setAnalycticsSlider] = useState(false);

  const consultsData = [
    {id: 1, text: '1 on 1 Consults'},
    {id: 2, text: 'Group Meetups'},
    {id: 2, text: 'Growth Curriculum'},
    {id: 2, text: 'Certifications'},
    {id: 2, text: 'Exclusive Podcast Episodes'},
    // Add more items as needed
  ];

  const dropdownValues = [
    {id: 'overview', title: 'Overview'},
    {id: 'margins', title: 'Margins'},
    {id: 'appointment', title: 'Appointments'},
    {id: 'sales', title: 'Sales'},
    {id: 'leaderboard', title: 'Leaderboard'},
    {id: 'reputation', title: 'Reputation'},
    {id: 'marketing', title: 'Marketing'},
    {id: 'roi_score', title: 'ROI Score'},
  ];

  const contributorDropdown = [
    {id: 'all', title: 'All'},
    {id: 'engagement', title: 'Engagement'},
  ];

  // const [showComponent, setShowComponent] = useState('');

  const chartData = {
    labels: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    datasets: [
      {
        label: '',
        data: [100, 450, 50, 550, 250, 200, 300],
        borderColor: '#333',
        backgroundColor: '',
      },
    ],
  };
  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false, // Hide legend
      },
    },
    elements: {
      line: {
        tension: 0.1, // Set tension to control the curve of the line
        borderWidth: 1, // Set line width
      },
    },
  };

  useEffect(() => {
    if (showComponent !== '') {
      toggleHiddenPanel(true);
    } else {
      toggleHiddenPanel(false);
    }
  }, [showComponent]);
  const listForDropDown = [
    // {title: 'All', id: '1'},
    {title: 'All', id: '1'},
    {title: 'HVAC', id: '2'},
    {title: 'Air Quality', id: '3'},
    {title: 'Plumbing', id: '4'},
    {title: 'Partners', id: '5'},
    // {title: 'Anything', id: '5'},
    // {title: 'Anything', id: '6'},
    // {title: 'Anything', id: '7'},
  ];

  const [areas_Filter, setAreas_Filter] = useState([listForDropDown[0].id]);

  const dropDownSelection = e => {
    const {id} = e.target;

    setAreas_Filter(id);
    // setdropdownValue([innerText]);
  };

  const rewardSlider = {
    // className: "center",
    // centerMode: true,
    dots: false,
    arrows: false,
    infinite: false,
    // centerPadding : "20px",
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    // adaptiveHeight: true,
  };
  const SliderOverview = [
    {
      name: 'Total Sales',
      money: '$2.4k',
      time: 'versus last month',
      svgIcon: rewardStar,
    },

    {
      name: 'Pending Opportunities',
      money: '85%',
      time: 'versus last month',
      svgIcon: rewardStar,
      // icon: 'fa-solid fa-star',
    },

    {
      name: 'Unused Openings',
      money: '85%',
      time: 'versus last month',
      svgIcon: rewardStar,
      // icon: 'fa-solid fa-star',
    },
    {
      name: 'Conversion Rate',
      money: '85%',
      time: 'versus last month',
      svgIcon: rewardStar,
      // icon: 'fa-solid fa-star',
    },
  ];
  const SliderMargins = [
    {
      name: 'Total Revenue',
      money: '$2.4k',
      time: 'versus last month',
      svgIcon: rewardStar,
    },

    {
      name: 'Total Costs',
      money: '85%',
      time: 'versus last month',
      svgIcon: rewardStar,
      // icon: 'fa-solid fa-star',
    },

    {
      name: 'Gross Margin',
      money: '85%',
      time: 'versus last month',
      svgIcon: rewardStar,
      // icon: 'fa-solid fa-star',
    },
    // {
    //   name: 'Gross Margin (%)',
    //   money: '85%',
    //   time: 'versus last month',
    //   svgIcon: rewardStar,
    //   // icon: 'fa-solid fa-star',
    // },
  ];
  const SliderAppointment = [
    {
      name: 'Total Appointments',
      money: '$2.4k',
      time: 'versus last month',
      svgIcon: rewardStar,
    },

    {
      name: 'Missed Appointments',
      money: '85%',
      time: 'versus last month',
      svgIcon: rewardStar,
      // icon: 'fa-solid fa-star',
    },

    {
      name: 'Canceled Appointments',
      money: '85%',
      time: 'versus last month',
      svgIcon: rewardStar,
      // icon: 'fa-solid fa-star',
    },
    {
      name: 'Unused Openings',
      money: '85%',
      time: 'versus last month',
      svgIcon: rewardStar,
      // icon: 'fa-solid fa-star',
    },
  ];
  const SliderSales = [
    {
      name: 'Total Sales',
      money: '$2.4k',
      time: 'versus last month',
      svgIcon: rewardStar,
    },

    {
      name: 'Opportunity',
      money: '85%',
      time: 'versus last month',
      svgIcon: rewardStar,
      // icon: 'fa-solid fa-star',
    },

    {
      name: 'Conversion Rate',
      money: '85%',
      time: 'versus last month',
      svgIcon: rewardStar,
      // icon: 'fa-solid fa-star',
    },
    {
      name: 'Gross Margin',
      money: '85%',
      time: 'versus last month',
      svgIcon: rewardStar,
      // icon: 'fa-solid fa-star',
    },
  ];
  const SliderReputation = [
    {
      name: 'Reviews',
      money: '23',
      time: 'versus last month',
      svgIcon: rewardStar,
    },

    {
      name: 'Ratings',
      money: '4.8',
      time: 'versus last month',
      svgIcon: rewardStar,
      icon: 'fa-solid fa-star',
    },

    {
      name: 'Repurchase',
      money: '65 Days',
      time: 'versus last month',
      svgIcon: rewardStar,
    },
    {
      name: 'Rating',
      money: '4.8',
      time: 'versus last month',
      svgIcon: rewardStar,
    },
  ];
  const SliderLeaderboard = [
    {
      name: 'Level Up',
      money: '$2.4k',
      time: 'versus last month',
      svgIcon: rewardStar,
    },

    {
      name: 'Training',
      money: '85%',
      time: 'versus last month',
      svgIcon: rewardStar,
      // icon: 'fa-solid fa-star',
    },
  ];
  const SliderMarketing = [
    {
      name: 'Campaigns',
      money: '2.4k',
      time: 'versus last month',
      svgIcon: rewardStar,
    },

    {
      name: 'Ad Spend',
      money: '$2.4k',
      time: 'versus last month',
      svgIcon: rewardStar,
      // icon: 'fa-solid fa-star',
    },
    {
      name: 'Traffic ',
      money: '85',
      time: 'versus last month',
      svgIcon: rewardStar,
      // icon: 'fa-solid fa-star',
    },
  ];
  const SliderRoiScore = [
    {
      name: 'Completion ',
      money: '85%',
      time: 'versus last month',
      svgIcon: rewardStar,
    },

    {
      name: 'Courses',
      money: '2.4k',
      time: 'versus last month',
      svgIcon: rewardStar,
      // icon: 'fa-solid fa-star',
    },
    {
      name: 'Quiz Average',
      money: '85%',
      time: 'versus last month',
      svgIcon: rewardStar,
      // icon: 'fa-solid fa-star',
    },
  ];

  const showDataOfSlider =
    dropDownType === 'overview'
      ? SliderOverview
      : dropDownType === 'margins'
      ? SliderMargins
      : dropDownType === 'appointment'
      ? SliderAppointment
      : dropDownType === 'leaderboard'
      ? SliderLeaderboard
      : dropDownType === 'reputation'
      ? SliderReputation
      : dropDownType === 'marketing'
      ? SliderMarketing
      : dropDownType === 'sales'
      ? SliderSales
      : SliderRoiScore;
  return (
    <div className="page-wrapper">
      {!props.eventButton && (
        <Sidebar
          setWorkSpaceItem={setWorkSpaceItem}
          workSpaceItem={workSpaceItem}
          location={props.location}
          setShowComponent={setShowComponent}
          showComponent={showComponent}
        />
      )}

      <div className="page-content-wrapper">
        <div className="sessions-list-wrapper analytcis_page">
          <Pannels
            isFloated={false}
            showHiddenPanel={showHiddenPanel}
            updateHiddenPanelState={nState => toggleHiddenPanel(nState)}
            primary={
              <>
                <div
                  className="ROI-score-screen mb-3 as-link"
                  onClick={() => {
                    setShowComponent('moneyModel');
                  }}>
                  <div className="score-screen-top">
                    <div className="score-number-main">
                      <div className="score-number">Score 12</div>
                      <div className="score-bar">
                        <ProgressBar
                          className="okr-bar"
                          variant="warning"
                          now={48}
                        />
                      </div>
                    </div>

                    <div className="get-started">
                      <div>Tap into your growth potential</div>
                      <span
                        className="as-link"
                        onClick={() => {
                          setShowComponent('moneyModel');
                        }}>
                        Get Started
                      </span>
                    </div>
                  </div>
                </div>

                <div className="analytics_dropdown d-flex mb-3">
                  {/* <div className="select-wrapper w-100">
                      <select
                        className="form-control"
                        onChange={e => {
                          setDropDownType(e.target.value);
                        }}>
                        <option value="overview">Overview</option>
                        <option value="margins">Margins</option>
                        <option value="appointment">Appointments</option>
                        <option value="sales">Sales</option>
                        <option value="leaderboard">Leaderboard</option>
                        <option value="reputation">Reputation </option>
                        <option value="marketing">Marketing </option>
                        <option value="roi_score">ROI Score </option>
                      </select>
                    </div> */}

                  <CustomDropdown
                    setDropdownId={setDropDownType}
                    dropdownId={dropDownType}
                    DropDownValues={dropdownValues}
                    itemName={['title']}
                    className={'flex-1'}
                  />

                  <Button>
                    <i className="fas fa-calendar-days" onClick={() => {}} />
                  </Button>
                </div>
                <div className="serach-filter-wrapper">
                  {/* <div className="search-tabs-wrapper"> */}
                  {/* <div className="d-flex justify-content-between align-items-center mb-2">
                      <div className="event-tabs tabs-style-2 mb-0">
                        <ul>
                          <li>
                            <Button
                              className={
                                currentComponent === 'Team' ? 'active' : ''
                              }
                              onClick={() => setCurrentComponent('Team')}>
                              Team
                            </Button>
                          </li>
                          <li>
                            <Button
                              className={
                                currentComponent === 'Me' ? 'active' : ''
                              }
                              onClick={() => setCurrentComponent('Me')}>
                              Me
                            </Button>
                          </li>
                        </ul>
                      </div>
                      <div className="search-tabs-icons">
                        <span onClick={() => {}} className={'icon'}>
                          <i className="icon-table-lamp" />
                        </span>
                        <span
                          onClick={() => {
                            setThreeDots(!threeDots);
                          }}
                          className={threeDots ? 'icon active' : 'icon'}>
                          <i className="fas fa-ellipsis-vertical" />
                        </span>
                      </div>
                    </div> */}
                  {/* </div> */}

                  {/* {threeDots && ( */}

                  {/* )} */}
                  <div className="category-btns-wrapper category-btns-wrapper-slider mt-3 mb-3">
                    <CustomSlidingButtons
                      items={listForDropDown}
                      activeButtons={areas_Filter}
                      onItemClick={e => {
                        dropDownSelection(e);
                      }}
                      showItems={2}
                    />
                  </div>
                  <div className="Reward_main  ">
                    {/* <span
                      className="analytics_slider_heading "
                      style={{color: analycticsSlider ? '' : '#999999'}}
                      onClick={() => {
                        setAnalycticsSlider(!analycticsSlider);
                      }}>
                      Analytics Row
                    </span>

                    {analycticsSlider && ( */}
                    <div className="reward-top mb-4 ">
                      <Slider {...rewardSlider}>
                        {showDataOfSlider &&
                          showDataOfSlider.length > 0 &&
                          showDataOfSlider.map((item, index) => (
                            <div className="reward-main">
                              <div className="reward-card">
                                <span
                                  style={{fontWeight: 700, fontSize: '13px'}}>
                                  {item.name}
                                </span>
                                <span
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}>
                                  <span style={{fontSize: '17px'}}>
                                    {item.money}
                                  </span>
                                  {item.name === 'Ratings' && (
                                    <img src={item.svgIcon} alt="" />
                                  )}
                                  {item.name === 'Conversion' ? (
                                    <i
                                      className="fa-solid fa-arrow-down"
                                      style={{color: '#BD4141'}}></i>
                                  ) : (
                                    <i
                                      class="fa-solid fa-arrow-up"
                                      style={{color: '#52bd41'}}></i>
                                  )}
                                </span>
                                <div class="message-date-info">
                                  <span
                                    style={{color: '#333'}}
                                    class="message-date">
                                    {item.time}
                                  </span>
                                </div>
                              </div>
                            </div>
                          ))}
                      </Slider>
                    </div>
                    {/* )} */}
                  </div>

                  <Scrollbars className="custom-refferal-vertical-scrollbar">
                    <div className="analytics-chart-wrapper">
                      {/* <div className="analytics-chart-heading">
                        <h3>
                          35k Engaged <i className="fas fa-circle-play" />
                        </h3>
                      </div> */}

                      <div className="analytics-chart">
                        <Line options={chartOptions} data={chartData} />
                      </div>
                    </div>

                    <div className="contributers-search-box">
                      <div className="analytics-chart-heading">
                        <h3>Top Contributers</h3>
                      </div>

                      {/* <div className="search-form d-flex">
                        <div className="select-wrapper w-100">
                          <select className="form-control" onChange={e => {}}>
                            <option value="">All</option>
                            <option>Engagement</option>
                          </select>
                        </div>
                        <Button>
                          <i
                            className="fas fa-ellipsis-vertical"
                            onClick={() => {}}
                          />
                        </Button>
                      </div> */}

                      <div className="analytics_dropdown d-flex ">
                        <CustomDropdown
                          setDropdownId={setContributorType}
                          dropdownId={contributorType}
                          DropDownValues={contributorDropdown}
                          itemName={['title']}
                          className={'flex-1'}
                        />

                        <Button>
                          <i
                            className="fas fa-ellipsis-vertical"
                            onClick={() => {}}
                          />
                        </Button>
                      </div>
                    </div>

                    <div className="contributers-list-wrapper">
                      <div className="contributer-item card">
                        <div className="contributer-content">
                          <h2>Jasmine Banks</h2>
                          <span className="attendees-text">2.5k Attendees</span>
                          <span className="attendees-sub-text">
                            500 new, 1.8k repeat
                          </span>
                        </div>
                        <div className="contributer-img">
                          <img src={vinceProfile} alt="" />
                        </div>
                      </div>

                      <div className="contributer-item card">
                        <div className="contributer-content">
                          <h2>Jasmine Banks</h2>
                          <span className="attendees-text">2.5k Attendees</span>
                          <span className="attendees-sub-text">
                            500 new, 1.8k repeat
                          </span>
                        </div>
                        <div className="contributer-img">
                          <img src={vinceProfile} alt="" />
                        </div>
                      </div>

                      <div className="contributer-item card">
                        <div className="contributer-content">
                          <h2>Jasmine Banks</h2>
                          <span className="attendees-text">2.5k Attendees</span>
                          <span className="attendees-sub-text">
                            500 new, 1.8k repeat
                          </span>
                        </div>
                        <div className="contributer-img">
                          <img src={vinceProfile} alt="" />
                        </div>
                      </div>
                    </div>
                  </Scrollbars>
                </div>

                {/* scndrycomponentData */}
              </>
            }
            secondary={<></>}
          />
          {showComponent === 'moneyModel' && (
            <div className="custom_model_bg_black">
              <CustomModal
                size="medium"
                onClose={() => {
                  setShowComponent('');
                }}>
                <div className="text-center ">
                  <img className="model_img" src={DollarSign} alt="" />
                  <div>
                    <h1
                      className="font_work"
                      style={{
                        color: '#FFF',
                        // fontFamily: 'DunbarTall',
                        fontSize: '21.58px',
                        fontStyle: 'normal',
                        fontWeight: '700',
                        lineHeight: 'normal',
                        marginBlock: '3px',
                      }}>
                      Level Up
                    </h1>
                  </div>
                  <div>
                    <h1
                      className="font_work"
                      style={{
                        color: '#79C258',
                        // fontFamily: 'Dunbar Tall',
                        fontSize: '14.231px',
                        fontStyle: 'normal',
                        fontWeight: '700',
                        lineHeight: 'normal',
                      }}>
                      Academy
                    </h1>
                  </div>
                  <div className="mt-2">
                    <p
                      style={{
                        color: '#F1F1F1',
                        textAlign: 'center',
                        fontFamily: 'Inter',
                        fontSize: '15px',
                        fontStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: 'normal',
                      }}>
                      Change your life and the ones around you through ongoing
                      education and a commitment to greatness
                    </p>
                  </div>
                  {consultsData.map(item => (
                    <div
                      key={item.id}
                      style={{
                        display: 'flex',
                        // alignItems: 'cneter',
                        // justifyContent: 'center',
                        gap: '22px',
                        marginTop: '5px',
                        marginLeft: '29px',
                      }}>
                      <img src={TickSign} alt="" />
                      <span
                        style={{
                          color: '#FFF',
                          fontFamily: 'Inter',
                          fontSize: '15px',
                          fontStyle: 'normal',
                          fontWeight: '600',
                          lineHeight: 'normal',
                        }}>
                        {item.text}
                      </span>
                    </div>
                  ))}
                  <div
                    style={{
                      color: '#F1F1F1',
                      textAlign: 'center',
                      fontFamily: 'Inter',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: '500',
                      lineHeight: 'normal',
                      marginBottom: '10px',
                      marginTop: '10px',
                    }}>
                    Are you ready to take control of your destiny?
                  </div>
                  <div>
                    <Button
                      style={{
                        borderRadius: '7.427px',
                        background: '#52BD41',
                        boxShadow:
                          '0px 0.928px 3.714px 0px rgba(0, 0, 0, 0.25)',
                        marginBottom: '10px',
                        height: '40px',
                        paddingTop: '10px',
                      }}>
                      I’m Ready
                    </Button>
                  </div>
                  <div>
                    <span
                      style={{
                        color: ' #52BD41',
                        textAlign: 'center',
                        fontFamily: 'Inter',
                        fontSize: '15px',
                        fontStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: 'normal',
                        textDecorationLine: 'underline',
                      }}>
                      I’m ok where I am
                    </span>
                  </div>
                </div>
              </CustomModal>
            </div>
          )}
          {showComponent === 'WorkSpace' && (
            <CreateWorkSpace
              onClose={() => {
                setShowComponent('');
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateProps = state => ({
  secondaryPannelState: state.secondaryPannelState,
});

export default connect(mapStateProps, {toggleSecondaryPannel})(Analytics);
