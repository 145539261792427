import React from 'react'
import {Button} from 'react-bootstrap';

const TransparentButton = ({onClick_button,buttonName,disable,className}) => {
  return (
    <div className={className ? className : "filter-btn-wrapper pt-1 pb-1 create-session-btn fixed-width-inputs  fix-width-forms mx-0 bg_transparent bottom-0"}>
      <div className="invite-btn w-100 text-center">
        
          <Button
            disabled={disable ? true : false}
            className={disable ? 'disable btn-dark m-0' : 'btn-dark m-0'}
            type="submit"
            onClick={() => {
              onClick_button();
            }}>
            {buttonName}
          </Button>
        
      </div>
    </div>
  );
}

export default TransparentButton
