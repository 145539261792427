import React, {useState, useEffect} from 'react';
import {Button} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import commonApi from '../../Services/CommonService';
import Moment from 'moment';
import {errorMessage, successMessage} from '../../Utils/ToastMessages';
import CardDetail from '../Forms/CardDetail';
import 'react-datepicker/dist/react-datepicker.css';
import {DEFAULT_IMAGE} from '../../Utils/env';
import moment from 'moment/moment';
import {
  convertTime12To24,
  fixName,
  convertTimeIntoDateTime,
} from '../../Utils/commonUtils';
import LoaderSpinner from '../Modals/LoaderSpinner';
import SlidingPanel from '../SlidingPanel';
import Scrollbars from 'react-custom-scrollbars';
import {amplitudeEvent} from '../../Services/AmplitudeServices';
import CustomModal from '../CustomModal';
import {setItem, getItem} from '../../Utils/LocalStorage';
import ProfileComponent from '../ProfilePage/ProfileComponent';
import {connect} from 'react-redux';
import CalanderModel from '../Modals/CalanderModel';
import {selected_lang} from '../../Utils/env';
import {labels} from '../../Constants/Translations';
import CustomTabs from '../CustomTabs';
import AppointmentItem from './AppointmentItem';
import CustomDropdown from '../CustomDropdown';
import {RoiLogo} from '../../Constants/Images';
import PaymentModal from '../Modals/PaymentModal';

const AppointmentList = props => {
  const [showForm, setShowForm] = useState(false);
  const [payLoadIs, setPayLoadIs] = useState({});
  const [showModaL, setShowModel] = useState(false);
  const [appointmentDisable, setAppointmentDisable] = useState([]);
  const [appointmentType, setAppointmentType] = useState([]);
  const [appointmentDetail, setAppointmentDetail] = useState({
    appointment_type: '',
    cost: '',
    day: '',
    description: '',
    duration: '',
    id: '',
    qr_code: '',
    title: '',
    user_id: '',
    session_type: '',
  });

  const [showComponent, setShowComponent] = useState('');
  const [calendarData, setCalendarData] = useState([]);
  const [showLoader, setshowLoader] = useState(false);
  const [cardDeatil, setCardDeatil] = useState([]);
  const [allSlots, setAllSlots] = useState([]);
  const [scheduleId, setScheduleId] = useState('');
  const [typeId, setTypeId] = useState({});
  const [typeIdValue, setTypeIdValue] = useState('1');
  const [tabType, setTabType] = useState('Upcoming Appointments');
  const [scheduleTitle, setScheduleTitle] = useState('');

  const [availableDates, setAvailableDates] = useState([]);

  const [wholeWeek, setWholeWeek] = useState([]);
  const [weekDays, setWeekDays] = useState([]);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  const [newShow, setNewShow] = useState(false);
  const [newUserId, setNewUserId] = useState('');

  const [serverTime, setServerTime] = useState(new Date());
  const [startDate, setStartDate] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [appointmentData, setAppointmentData] = useState('');
  const handleChange = e => {
    setIsOpen(!isOpen);
    setStartDate(e);
  };

  const handleOnClickOutside = () => {
    setIsOpen(false);
  };
  const handleClick = e => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  // const popover = (
  //   <Popover id="popover-basic">
  //     {/* <Popover.Header as="h3">Popover right</Popover.Header> */}
  //     <Popover.Body>
  //       And here's some <strong>amazing</strong> content. It's very engaging.
  //       right?
  //     </Popover.Body>
  //   </Popover>
  // );

  const renderBlackHeader = () => {
    // Return the JSX element conditionally based on props.permission
    return (
      <>
        <img src={RoiLogo} alt="" />
      </>
    );
  };

  const get_all_calenders = () => {
    var payload = {
      // type: 'outlook',
    };
    commonApi
      .get_all_calendars(payload)
      .then(response => {
        if (response.all_calendars.length > 0) {
          setCalendarData(response.all_calendars);
          setShowComponent('calendar');
        } else {
          props.onClose();
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const getAppointmentType = () => {
    commonApi
      .get_appointment_types()
      .then(res => {
        if (res.status === 200) {
          setTypeId(res.appointment_type);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const getAppointments = () => {
    // setshowLoader(true);
    const paylaod = {
      user_id: props.userData.id,
      appointment_type_id: typeIdValue,
    };
    commonApi
      .get_appointment_type(paylaod)
      .then(response => {
        if (response.success === true) {
          // setshowLoader(false);
          setAppointmentType(response.data);
          if (props.type === 'scheduler' && props.scheduleId !== undefined) {
            setScheduleId(props.scheduleId);
            get_first_available_appointment(props.scheduleId);
          } else if (response.data.length > 0) {
            setScheduleId(response.data[0].id);
            get_first_available_appointment(response.data[0].id);
          }

          if (response.data.length > 0) {
            setAppointmentDetail({
              appointment_type: response.data[0].appointment_type,
              cost: response.data[0].cost,
              day: response.data[0].day,
              description: response.data[0].description,
              duration: response.data[0].duration,
              id: response.data[0].id,
              qr_code: response.data[0].qr_code,
              title: response.data[0].title,
              session_type: response.data[0].session_type,
              user_id: response.data[0].user_id,
            });
          }

          if (response.data.length === 0) {
            setAllSlots([]);
            setshowLoader(false);
          }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const get_first_available_appointment = id => {
    let PayloadData = {
      schedule_id: id,
    };
    // setShowDateModel(true);
    commonApi
      .get_first_available_appointment(PayloadData)
      .then(response => {
        if (response.status === 200) {
          const dates = response.all_available_dates.map(
            dateString => new Date(dateString),
          );

          setWholeWeek(response.whole_week);
          setAvailableDates(dates);

          const daysOfWeek = response.whole_week.map(appointment =>
            appointment.day.toLowerCase(),
          );
          if (daysOfWeek.length > 0) {
          }

          console.log(daysOfWeek, 'daysOfWeeek');

          setWeekDays(daysOfWeek);

          if (daysOfWeek.length > 0) {
            setStartDate(new Date(dates[0]));
          } else {
            setStartDate(new Date());
          }

          // handleHighlightDates()
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const handleHighlightDates = () => {
    // Get the current date
    const currentDate = new Date();

    // Get the current day of the week (0 for Sunday, 1 for Monday, ..., 6 for Saturday)
    const currentDayOfWeek = currentDate.getDay();

    // Calculate the start date of the current week
    const startDateOfWeek = new Date(currentDate);
    startDateOfWeek.setDate(startDateOfWeek.getDate() - currentDayOfWeek); // Start from Sunday

    // Initialize an array to store the highlighted dates
    const highlightedDates = wholeWeek
      .map(appointment => {
        const dayIndex = [
          'sunday',
          'monday',
          'tuesday',
          'wednesday',
          'thursday',
          'friday',
          'saturday',
        ].indexOf(appointment.day.toLowerCase());
        if (dayIndex !== -1) {
          const highlightedDate = new Date(startDateOfWeek);
          highlightedDate.setDate(highlightedDate.getDate() + dayIndex);
          return highlightedDate;
        }
        return null;
      })
      .filter(date => date !== null);

    console.log(highlightedDates, 'highlightedDateshighlightedDates');
  };

  const generateHighlightedDates = year => {
    // Initialize an array to store all highlighted dates
    let highlightedDates = [];

    // Loop over each month
    for (let month = 0; month < 12; month++) {
      const startDateOfMonth = new Date(year, month, 1);
      const endDateOfMonth = new Date(year, month + 1, 0);

      // Generate highlighted dates for each month
      wholeWeek.forEach(appointment => {
        const dayIndex = [
          'sunday',
          'monday',
          'tuesday',
          'wednesday',
          'thursday',
          'friday',
          'saturday',
        ].indexOf(appointment.day.toLowerCase());
        if (dayIndex !== -1) {
          // Find the date of the first occurrence of the appointment day in the month
          let highlightedDate = new Date(startDateOfMonth);
          highlightedDate.setDate(
            highlightedDate.getDate() +
              ((dayIndex - startDateOfMonth.getDay() + 7) % 7),
          );

          // Add the highlighted date for each occurrence in the month
          while (highlightedDate <= endDateOfMonth) {
            highlightedDates.push(new Date(highlightedDate));
            highlightedDate.setDate(highlightedDate.getDate() + 7);
          }
        }
      });
    }

    return highlightedDates;
  };

  const handleYearChange = date => {
    const newYear = date.getFullYear();
    if (currentYear !== newYear) {
      setCurrentYear(newYear);
    }
  };

  const getAppointmentSlots = (sId, sDate) => {
    const date = new Date(sDate);
    const paylaod = {
      creator_id: props.userData.id,
      date: sDate,
      day: Moment(date).format('dddd').toString().toLowerCase(),
      schedule_id: sId,
      // schedule_id: getItem('consult') ? getItem('consult') : sId,
    };
    // setItem('consult', '');
    commonApi
      .get_appointment_slots(paylaod)
      .then(response => {
        if (response.success === true) {
          setshowLoader(false);
          setAppointmentDetail({
            appointment_type: response.schedules.appointment_type,
            cost: response.schedules.cost,
            day: response.schedules.day,
            description: response.schedules.description,
            duration: response.schedules.duration,
            id: response.schedules.id,
            qr_code: response.schedules.qr_code,
            title: response.schedules.title,
            session_type: response.schedules.session_type,
            user_id: response.schedules.user_id,
          });
          setAllSlots(response.data);
          setAppointmentDisable([]);
          setshowLoader(false);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const createAppointmentPayload = data => {
    console.log(data, '------->datadata');
    const date = new Date(data.new_start_time);
    setPayLoadIs({
      complete_time: data.new_start_time.replaceAll('/', '-'),
      date: Moment(date).format('ll').toString(),
      day: Moment(date).format('dddd').toString(),
      mentor_id: data.creator_id,
      slot_id: data.id,
      start_time: data.start_time,
    });

    setAppointmentData({
      summary: scheduleTitle,
      location: '',
      start: {
        dateTime: convertTimeIntoDateTime(
          startDate,
          data.start_time,
        ).toISOString(),
        timeZone: 'America/New_York',
      },
      end: {
        dateTime: convertTimeIntoDateTime(
          startDate,
          data.end_time,
        ).toISOString(),
        timeZone: 'America/New_York',
      },
      visibility: 'public',
      recurrence: [],
      attendees: [],
      reminders: {
        useDefault: false,
        overrides: [
          {method: 'email', minutes: 24 * 60},
          {method: 'popup', minutes: 10},
        ],
      },
    });
    // setShowForm(true);
  };

  const stripePayment = data => {
    setshowLoader(true);
    commonApi
      .stripe_payment(data)
      .then(response => {
        if (response.stripe === true) {
          createAppointment();
          addCard();
        } else {
          errorMessage(response.message);
          setshowLoader(false);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const createAppointment = () => {
    commonApi
      .create_appointment(payLoadIs)
      .then(response => {
        amplitudeEvent('BOOK_APPOINTMENT');
        setshowLoader(false);
        if (response.success === true) {
          setAppointmentDisable(arr => [...arr, payLoadIs.slot_id]);
          successMessage(response.message);
          setPayLoadIs({});
          get_all_calenders();
        } else {
          if (response.occupied) {
            setAppointmentDisable(arr => [...arr, payLoadIs.slot_id]);
            setPayLoadIs({});
          }
          errorMessage(response.message);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  useEffect(() => {
    getAppointmentType();
    // getAppointments('');
    addCard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setshowLoader(true);
    getAppointments('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeIdValue]);
  useEffect(() => {
    if (scheduleId !== '' && startDate !== '') {
      getAppointmentSlots(scheduleId, startDate);
    }

    setPayLoadIs({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleId, startDate]);

  useEffect(() => {
    if (scheduleId !== '') {
      get_first_available_appointment(scheduleId);
    }

    appointmentType.forEach((miniItem, index) => {
      if (miniItem.id === scheduleId) {
        setScheduleTitle(miniItem.title);
      }
    });
  }, [scheduleId]);

  useEffect(() => {
    if (!showForm) {
      setPayLoadIs({});
    }
  }, [showForm]);
  useEffect(() => {
    addCard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showForm]);
  useEffect(() => {
    commonApi.check_server_time().then(res => {
      setServerTime(new Date(res.server_time));
    });
    const interval = setInterval(() => {
      commonApi.check_server_time().then(res => {
        setServerTime(new Date(res.server_time));
      });
    }, 60000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (getItem('consult') === 'consults') {
      } else if (getItem('consult')) {
        getAppointmentSlots(getItem('consult'), startDate);
      }

      setItem('consult', '');
    }, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //   if (new Date(moment().format('MMMM D, YYYY')) < startDate) {
  //
  //   } else {
  //
  //   }
  //
  const addCard = () => {
    commonApi
      .get_my_card()
      .then(res => {
        if (res.status === 200) {
          const deafaultCard = res.data.find(
            item => item.card_type === 'default',
          );
          setCardDeatil(deafaultCard);
        }
      })
      .catch(err => {
        errorMessage(err.response.data.message);
        setshowLoader(false);
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  // const handleModal = () => {

  //   if (cardDeatil != []) {
  //     setShowModel(true);
  //   }
  // };

  const openProfile = id => {
    setNewUserId(id);
    setNewShow(true);
  };
  let cardId =
    typeof cardDeatil === 'object' && !Array.isArray(cardDeatil)
      ? cardDeatil.id
      : '';

  // console.log(availableDates, 'availableDates');

  function getNextDay(currentDate) {
    let nextDate = moment(currentDate).add(1, 'days');
    let currentDay = nextDate.format('dddd').toLowerCase();
    let i = 0;

    // Find the next occurrence of a day in weekDays array
    while (!weekDays.includes(currentDay) && i < 7) {
      nextDate.add(1, 'days');
      currentDay = nextDate.format('dddd').toLowerCase();
      i++;
    }

    return nextDate.toDate();
  }

  function getPreviousDay(currentDate) {
    let previousDate = moment(currentDate).subtract(1, 'days');
    let currentDay = previousDate.format('dddd').toLowerCase();
    let i = 0;

    if (previousDate.isBefore(moment(), 'day')) {
      errorMessage('No Record found');
      return;
    }

    // Find the previous occurrence of a day in weekDays array
    while (!weekDays.includes(currentDay) && i < 7) {
      previousDate.subtract(1, 'days');
      currentDay = previousDate.format('dddd').toLowerCase();
      i++;
    }

    return previousDate.toDate();
  }

  console.log(appointmentDetail, '--------->appointmentDetail');
  console.log(payLoadIs, '-------->payLoadIs');
  return (
    <div>
      {/* {showLoader && <LoaderSpinner />} */}
      <SlidingPanel
        hidePanel={() =>
          props.setShowComponent(oldArr => [{...oldArr, appointment: false}])
        }
        renderData={renderBlackHeader}
        showBlackHeader={true}
        lastDivStyle={{borderRadius: '35px', background: '#f7f6f2'}}
        firstDivStyle={{
          padding: 0,
          background:
            'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)',
        }}>
        <div className="serach-filter-wrapper schedule_user_appointment primary_panel_wraper">
          <div className="fix-width-forms">
            {/* <div
              className={`sidebar-heading-wrapper mt-0 mb-4 ${
                props.windowWidth < 1300 ? 'with-panel' : ''
              }`}>
              <div className="headings mb-0">
                <h2>
                  {fixName(props.userData.first_name, props.userData.last_name)}
                </h2>
              </div>

              <div className="with-user-img">
                <div
                  className="user-profile-img as-link"
                  onClick={() => {
                    openProfile(props.userData.id);
                  }}>
                  <img
                    src={
                      props.userData.profile_img === ''
                        ? DEFAULT_IMAGE
                        : props.userData.profile_img
                    }
                    alt=""
                  />
                </div>
                <span
              className="close as-link"
              onClick={() =>
                props.setShowComponent(oldArr => [
                  {...oldArr, ['appointment']: false},
                ])
              }>
              <i className="fas fa-times" />
            </span>
                <span className="close as-link" onClick={() => props.onClose()}>
                        <i className="fas fa-times" />
                    </span>
              </div>
            </div> */}
          </div>

          <div className="profile-img-wrapper">
            <div className="profile_img position-relative">
              <img
                src={
                  props.userData && props.userData.profile_img
                    ? props.userData.profile_img
                    : DEFAULT_IMAGE
                }
                alt=""
              />
            </div>

            <div className="profile-name" style={{paddingTop: '8px'}}>
              <div
                className={`profile-name-headings ${
                  (props.withHeadingSpace && props.withHeadingSpace) ||
                  props.windowWidth < 1300
                    ? 'with-panel'
                    : ''
                }`}>
                <div className="profile_name">
                  <h2>
                    {/* {fixName(props.userData.first_name, props.userData.last_name)} */}
                    {props.userData.first_name + ' ' + props.userData.last_name}
                  </h2>
                </div>
              </div>
            </div>
          </div>

          <div className="refferal-list-wrapper">
            {showLoader && <LoaderSpinner />}

            <Scrollbars>
              <div className="fix-width-forms">
                {!showForm && (
                  <div className="mt-2">
                    {/* <div className="search-form">
                    <div className="select-wrapper fieldset">
                      <select
                        className="form-control"
                        onChange={e => {
                           getAppointments(e.target.value);
                          setTypeIdValue(e.target.value);
                        }}
                        disabled={typeId.length > 0 ? false : true}>
                        {Object.entries(typeId).length > 0 &&
                          typeId.map((item, index) => (
                            <option
                              id={item.id}
                              value={item.id}
                              key={`ap-type-${item.id}`}>
                              {item.appointment_type}
                            </option>
                          ))}
                      </select>
                      <select className="form-control">
                                <option value="">Category</option>
                                <option>Business Growth Consult</option>
                                <option>Business Growth Consult</option>
                            </select>
                    </div>
                  </div> */}

                    {Object.entries(typeId).length > 0 && (
                      <CustomDropdown
                        disabled={typeId.length > 0 ? false : true}
                        setDropdownId={setTypeIdValue}
                        dropdownId={typeIdValue}
                        DropDownValues={typeId}
                        itemName={['appointment_type']}
                        className={'recruitment_dropdown mb-3'}
                      />
                    )}

                    {/* {appointmentType.length > 0 && (
                    <div className="search-form">
                      <div className="select-wrapper">
                        <select
                          className="form-control"
                          value={scheduleId}
                          onChange={e => {
                            setScheduleId(e.target.value);
                          }}>
                          {Object.entries(appointmentType).length > 0 &&
                            appointmentType.map((item, index) => (
                              <option
                                id={item.id}
                                value={item.id}
                                key={`ap-type-${item.id}`}>
                                {item.title}
                              </option>
                            ))}
                        </select>
                      
                      </div>
                    </div>
                  )} */}

                    {Object.entries(appointmentType).length > 0 && (
                      <CustomDropdown
                        // disabled={props.appointmentData ? true : false}
                        setDropdownId={setScheduleId}
                        dropdownId={scheduleId}
                        DropDownValues={appointmentType}
                        itemName={['title']}
                        className={'recruitment_dropdown'}
                      />
                    )}

                    <div
                      className="choose-appointment-wrapper appointment_list_cost"
                      style={{paddingBottom: '50px'}}>
                      <p>{appointmentDetail.description}</p>

                      <div
                        className="appointment-cost-wrapper"
                        style={{justifyContent: 'space-between'}}>
                        <div className="duration-cost">
                          <label>Duration</label>
                          {appointmentDetail.duration} min
                        </div>
                        <div className="duration-cost">
                          <label>Cost</label>
                          {appointmentDetail.cost
                            ? `$${appointmentDetail.cost}`
                            : 'Free'}
                        </div>
                        <div className="duration-cost">
                          <label>Call Options</label>

                          <div
                            className="icons-wrapper"
                            style={{justifyContent: 'space-between'}}>
                            <span className="icon">
                              <i className="fa-solid fa-video"></i>
                            </span>
                            <span className="icon">
                              <i className="fa-solid fa-phone"></i>
                            </span>
                          </div>
                        </div>
                        {/* <div className="info-icon">
                                    <OverlayTrigger placement="left" overlay={popover}>
                                        <span className="icon-info">
                                            <span className="path1" />
                                            <span className="path2" />
                                            <span className="path3" />
                                        </span>
                                    </OverlayTrigger>
                                </div> */}
                      </div>

                      <div className="appointment-date-wrapper">
                        <div className="date-wrapper">
                          <span
                            onClick={() => {
                              // setStartDate(
                              //   new Date(Moment(startDate).add(-1, 'days')),
                              // );

                              const date = getPreviousDay(startDate);
                              if (date) {
                                setStartDate(date);
                              }
                            }}
                            className="navs fas fa-chevron-left"
                          />
                          <span onClick={handleClick} className="selected-date">
                            {Moment(startDate).format('ll').toString()}
                          </span>
                          <span
                            onClick={() => {
                              if (weekDays.length > 0) {
                                const date = getNextDay(startDate);
                                setStartDate(date);
                              } else {
                                errorMessage('No record found');
                              }

                              // setStartDate(
                              //   new Date(Moment(startDate).add(1, 'days')),
                              // );
                            }}
                            className="navs fas fa-chevron-right"
                          />
                        </div>
                        {isOpen && (
                          <div className="calender-wrapper">
                            <DatePicker
                              selected={startDate}
                              onChange={handleChange}
                              onClickOutside={handleOnClickOutside}
                              // minDate={new Date()}
                              inline
                              onMonthChange={handleYearChange}
                              highlightDates={generateHighlightedDates(
                                currentYear,
                              )}
                            />
                          </div>
                        )}
                        {/* <Form.Control type="date" name="date" value={selectedDate} onChange={(e) => { setSelectedDate(e.target.value); }} /> */}

                        <div className="available-hours justify-content-center my-0">
                          {Object.entries(allSlots).length > 0 ? (
                            Object.entries(allSlots).length > 0 &&
                            allSlots.map((item, index) => (
                              <Button
                                onClick={() => {
                                  createAppointmentPayload(item);
                                  // setAppointmentPayload(item);
                                }}
                                key={`allSlots-${index}`}
                                className={
                                  payLoadIs && payLoadIs.slot_id === item.id
                                    ? 'selected-slot'
                                    : 'cate-btn'
                                }
                                value={item.id}
                                disabled={
                                  item.slot_availability === 'occupied' ||
                                  appointmentDisable.includes(item.id) ||
                                  new Date(
                                    moment(serverTime).format('MMMM D, YYYY'),
                                  ) > startDate ||
                                  (convertTime12To24(
                                    moment(serverTime).format('hh:mm A'),
                                  ) > convertTime12To24(item.start_time) &&
                                    moment(serverTime).format('l') ===
                                      moment(startDate).format('l'))
                                    ? true
                                    : false
                                }>
                                {item.start_time}
                              </Button>
                            ))
                          ) : (
                            <div className="praise-list-item no-border pt-0">
                              <p className="text-center">No Record Found</p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Scrollbars>
          </div>

          {allSlots.length > 0 && (
            <div className="schedule-btn text-center bg_transparent fix-width-forms bottom-0 pt-2 pb-2">
              <Button
                className="btn-dark"
                onClick={() => {
                  if (cardId && appointmentDetail.cost) {
                    // show modal
                    setShowModel(true);
                  } else {
                    if (appointmentDetail.cost) {
                      setShowForm(true);
                    } else {
                      createAppointment();
                    }
                  }
                }}
                disabled={
                  payLoadIs && payLoadIs.slot_id && payLoadIs.slot_id > 0
                    ? false
                    : true
                }>
                Schedule
              </Button>
            </div>
          )}
        </div>
      </SlidingPanel>
      {/* 
      {showForm && payLoadIs && appointmentDetail && (
        <CardDetail
          dataOne={payLoadIs}
          dataTwo={appointmentDetail}
          setShowForm={setShowForm}
          stripePayment={stripePayment}
        />
      )} */}

      {showForm && payLoadIs && appointmentDetail && (
        <PaymentModal
          price={appointmentDetail?.cost}
          transactionId={payLoadIs?.slot_id}
          onClose={() => {
            setShowForm(false);
          }}
          onCloseHitApi={()=>{
            createAppointment();
          }}
          transactionType={'appointment'}
        />
      )}

      {newShow && newUserId && (
        <ProfileComponent
          userId={newUserId}
          show={false}
          closeModal={() => {
            setNewShow(false);
          }}
          userData={[]}
          moderator={false}
          onClose={() => {
            setNewShow(false);
          }}
          VoxeetSDK={[]}
          conferenceInfo={[]}
          jdSpecialM={[]}
          jdSpecialA={[]}
        />
      )}

      {showModaL && (
        <CustomModal onClose={() => setShowModel(false)}>
          <div className="leave-session-content text-left">
            <h3>Please confirm you would like to purchase Consult</h3>
            <p>
              ${appointmentDetail.cost} will be deducted from your card listed
              below.
            </p>
            <div className="mt-3">
              <p>
                Card Last 4 Digits:{' '}
                <b>
                  {typeof cardDeatil === 'object' && !Array.isArray(cardDeatil)
                    ? cardDeatil.card_number
                    : ''}
                </b>
              </p>
            </div>
            <div className="">
              <Button
                className="btn-dark"
                onClick={() => {
                  // setAnotherCard(true)
                  setShowModel(false);
                  // hide modal
                  setShowForm(true);
                }}>
                Use A Different Card
              </Button>
              <Button
                className="btn-dark"
                onClick={() => {
                  let data = {
                    amount: appointmentDetail.cost,
                    card: '',
                    card_id: cardDeatil.id,
                    cardholder_name: cardDeatil.cardholder_name,
                    complete_time: payLoadIs.complete_time,
                    device_type: 'web',
                    id_transaction_type: payLoadIs.slot_id,
                    stripe_token: '',
                    transaction_type: 'appointment',
                    save_card: 'no',
                  };
                  stripePayment(data);
                  setShowModel(false);
                }}>
                Proceed
              </Button>
              <Button
                className="btn-dark"
                onClick={() => {
                  setShowForm(false);
                  setShowModel(false);
                }}>
                I Changed My Mind
              </Button>
            </div>
          </div>
        </CustomModal>
      )}

      {showComponent === 'calendar' && calendarData.length > 0 && (
        <CalanderModel
          calendarData={calendarData}
          typeId={typeId}
          event={appointmentData}
          setShowModel={setShowComponent}
          type={'appointment'}
          onClose={() => {
            setShowComponent('');
          }}
        />
      )}

      {/* <Modal
        show={showModaL}
        onHide={() => {
          setShowModel(false);
        }}>
        <Modal.Header closeButton>
          <Modal.Title>
            Please confirm you would like to purchase Consult
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          ${appointmentDetail.cost} will be deducted from your card listed
          below.
          <div className="mt-3">
            Card Last 4 Digits:
            <br />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              // setAnotherCard(true)
              setShowModel(false);
              // hide modal
              setShowForm(true);
            }}>
            Use A Different Card
          </Button>
          <br />
          <Button
            variant="primary"
            onClick={() => {
              let data = {
                amount: appointmentDetail.cost,
                card: '',
                card_id: cardDeatil.id,
                cardholder_name: cardDeatil.cardholder_name,
                complete_time: payLoadIs.complete_time,
                device_type: 'web',
                id_transaction_type: payLoadIs.slot_id,
                stripe_token: '',
                transaction_type: 'appointment',
                save_card: 'no',
              };
              stripePayment(data);
              setShowModel(false);
            }}>
            PROCEED
          </Button>
          <br />
          <Button
            variant="primary"
            onClick={() => {
              setShowForm(false);
              setShowModel(false);
            }}>
            I Changed My Mind
          </Button>
        </Modal.Footer>
      </Modal> */}
    </div>
  );
};

// AppointmentList.propTypes = {

// }

const mapStateProps = state => ({
  windowWidth: state.windowWidth,
});

export default connect(mapStateProps, {})(AppointmentList);
