import React, {useState} from 'react';
import {Button, Form, Dropdown} from 'react-bootstrap';
import {errorMessage} from '../Utils/ToastMessages';

const MultiSelectionDropdown = ({
  setDropdownIds,
  dropdownIds,
  DropDownValues,
  customFunction,
  itemName,
  className,
  ErrorMessage,
  shadow,
  DropdownType,
}) => {
  const getItemName = (item, keys) => {
    for (let key of keys) {
      if (item[key]) {
        return item[key];
      }
    }
    return 'Unknown';
  };

  
  const handleSelection = eventKey => {
    const groupIds = [...dropdownIds];

    if (eventKey === 'all') {
      if (!groupIds.includes('all')) {
        setDropdownIds(DropDownValues.map(item => item.id));
      } else {
        // If 'All' is deselected, ensure at least one payment type remains selected
        // if (groupIds.length > 1) { // More than just 'All' should be selected
        //   setDropdownIds([]);
        // }
        errorMessage(ErrorMessage);
      }
    } else {
      if (groupIds.includes(eventKey)) {
        const newIds = groupIds.filter(id => id !== eventKey);

        if (newIds.length === 0) {
          errorMessage(ErrorMessage);

          return;
        }

        if (newIds.includes('all')) {
          setDropdownIds(newIds.filter(id => id !== 'all'));
        } else {
          setDropdownIds(newIds);
        }
      } else {
        groupIds.push(eventKey);

        const allSelected = DropDownValues.every(
          item => item.id === 'all' || groupIds.includes(item.id),
        );

        if (allSelected) {
          setDropdownIds([...new Set([...groupIds, 'all'])]);
        } else {
          setDropdownIds(groupIds);
        }
      }
    }
  };

  const displayText = () => {
    if (dropdownIds.includes('all')) {
      return 'All';
    }
    return dropdownIds.length > 0
      ? dropdownIds
          .map(id => {
            const item = DropDownValues.find(item => item.id === id);
            if (!item) return '';

            return customFunction
              ? customFunction(item)
              : getItemName
              ? getItemName(item, itemName)
              : '';
          })
          .join(', ')
      : 'Select Payment Type';
  };

  return (
    <div className="multiselection_dropdown">
      <Dropdown
        className="select-wrapper customized-select-wrapper"
        autoClose="outside"
        onSelect={(eventKey, event) => {
          if (DropdownType && DropdownType === 'all') {
            handleSelection(eventKey);
          } else {
            if (dropdownIds.length === 1 && dropdownIds.includes(eventKey)) {
              errorMessage(ErrorMessage);
            } else {
              const groupIds = [...dropdownIds];
              if (!groupIds.includes(eventKey)) {
                groupIds.push(eventKey);
              } else {
                const index = groupIds.indexOf(eventKey);
                groupIds.splice(index, 1);
              }
              setDropdownIds(groupIds);
            }
          }
        }}
        drop="down">
        {/* <Dropdown.Toggle variant="primary" id="dropdown-autoclose-outside">
          {typeof dropdownIds !=='string' && dropdownIds.length > 0
            ? dropdownIds
                .map(id =>
                  customFunction
                    ? customFunction(
                        DropDownValues.find(item => item.id === id),
                      )
                    : getItemName ? getItemName(
                        DropDownValues.find(item => item.id === id),
                        itemName,
                      ) : '',
                )
                .join(', ')
            : ' '}
        </Dropdown.Toggle> */}

        <Dropdown.Toggle
          variant="primary"
          id="dropdown-autoclose-outside"
          className={shadow ? 'shadow-none' : ''}>
          {/* {Array.isArray(dropdownIds) && dropdownIds.length > 0
            ? dropdownIds
                .map(id => {
                  const item = DropDownValues.find(item => item.id === id);
                  if (!item) return ''; // Handle case where item is not found

                  // Use customFunction if defined, otherwise use getItemName if defined
                  return customFunction
                    ? customFunction(item)
                    : getItemName
                    ? getItemName(item, itemName)
                    : '';
                })
                .join(', ')
            : ' '} */}

          {displayText()}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {DropDownValues.map((item, index) => (
            <Dropdown.Item
              key={`sessionCategories-${index}`}
              eventKey={item.id}
              active={dropdownIds.includes(item.id)}>
              {customFunction
                ? customFunction(item)
                : getItemName(item, itemName)}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default MultiSelectionDropdown;
