import React, {useState,useEffect} from 'react';
import {Form} from 'react-bootstrap';

import Sidebar from '../../Pages/Sidebar';
import {Pannels} from '../../Components';
import {waveform, gridRound, rewardStar} from '../../Constants/Images';

import DiscoverBuilders from '../../Components/SessionList/DiscoverBuilders';
import Scrollbars from 'react-custom-scrollbars';
import CreateWorkSpace from '../../Components/WorkSpace/CreateWorkSpace';
import Slider from 'react-slick';
import SendInvitation from './StaticPages/SendInvitation';
import ProfileComponent from '../../Components/ProfilePage/ProfileComponent';
import AllBuilders from './StaticPages/AllBuilders';
const CallCenter = props => {
  const [threeDots, setThreeDots] = useState(false);

  const [isFloated, toggleFloatedPanel] = useState(false);
  const [showHiddenPanel, toggleHiddenPanel] = useState(false);

  const [showComponent, setShowComponent] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState('');
  const [analycticsSlider, setAnalycticsSlider] = useState(false);
  const [buildersUserId, setBuildersUserId] = useState('');
  const [refresh, setRefresh] = useState([]);

  const handleButtonClick = digit => {
    setPhoneNumber(prevPhoneNumber => prevPhoneNumber + digit);
  };

  const handleDelete = () => {
    setPhoneNumber(prevPhoneNumber => prevPhoneNumber.slice(0, -1));
  };

  const handleCall = () => {
    // Add logic for making a call with the phone number
    alert(`Calling ${phoneNumber}`);
  };

  const roundButtonStyle = {
    width: '60px',
    height: '60px',
    borderRadius: '50%',
    fontSize: '1.2em',
    backgroundColor: '#E8E6DF',
    fontWeight: '700',
    // color: 'black',
    border: 'none',
    margin: '5px',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#000',

    fontFamily: 'Inter',
  };

  const RoundButton = ({children, onClick, style}) => (
    <button onClick={onClick} style={style}>
      {children}
    </button>
  );

  const handleChange = e => {
    const value = e.target.value;
    // Check if the input value is a number and has at most 15 digits
    if (/^\d{0,15}$/.test(value)) {
      setPhoneNumber(value);
    }
  };

  const rewardSlider = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };
  const rewardSliderData = [
    {
      name: 'Response Time',
      money: '$2.4k',
      time: 'versus last month',
      svgIcon: rewardStar,
    },

    {
      name: 'Duration',
      money: '5m 20s',
      time: 'versus last month',
      svgIcon: rewardStar,
    },

    {
      name: 'Frequency',
      money: '45%',
      time: 'versus last month',
      svgIcon: rewardStar,
    },
    {
      name: 'Rating',
      money: '4.8',
      time: 'versus last month',
      svgIcon: rewardStar,
    },
  ];

  const dynamicStyle = {
    padding: '0px 0px 15px',
  };

  const buildersHandle = (t, id) => {
    setBuildersUserId(id);
    setShowComponent(t);
  };
  useEffect(() => {
    if (showComponent !== '') {
      toggleHiddenPanel(true);
    } else {
      toggleHiddenPanel(false);
    }
  }, [showComponent]);

  return (
    <>
      <div className="page-wrapper">
        <Sidebar
          location={props.location}
          setShowComponent={setShowComponent}
          setWorkSpaceItem={setRefresh}
          showComponent={showComponent}
        />
        <div className="page-content-wrapper">
          <div className="sessions-list-wrapper slider-screen-main">
            <div className="Reward_main analytics_slider_bg">
              <span
                className="analytics_slider_heading "
                style={{color: analycticsSlider ? '' : '#999999'}}
                onClick={() => {
                  setAnalycticsSlider(!analycticsSlider);
                }}>
                Analytics Row
              </span>

              {analycticsSlider && (
                <div className="reward-top mt-3 mb-2">
                  <Slider {...rewardSlider}>
                    {rewardSliderData.length > 0 &&
                      rewardSliderData.map((item, index) => (
                        <div className="reward-main">
                          <div className="reward-card">
                            <span style={{fontWeight: 700, fontSize: '13px'}}>
                              {item.name}
                            </span>
                            <span
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}>
                              <span style={{fontSize: '17px'}}>
                                {item.money}
                              </span>
                              {item.name === 'Ratings' && (
                                <img src={item.svgIcon} alt="" />
                              )}
                              {item.name === 'Conversion' ? (
                                <i
                                  className="fa-solid fa-arrow-down"
                                  style={{color: '#BD4141'}}></i>
                              ) : (
                                <i
                                  class="fa-solid fa-arrow-up"
                                  style={{color: '#52bd41'}}></i>
                              )}
                            </span>
                            <div class="message-date-info">
                              <span
                                style={{color: '#333'}}
                                class="message-date">
                                {item.time}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                  </Slider>
                </div>
              )}
            </div>
            <div className="add_border"></div>
            <Pannels
              isFloated={isFloated}
              toggleFloatedPanel={toggleFloatedPanel}
              showHiddenPanel={showHiddenPanel}
              updateHiddenPanelState={nState => toggleHiddenPanel(nState)}
              customCss={dynamicStyle}
              primary={
                <>
                  <div>
                    <DiscoverBuilders
                      buildersHandle={buildersHandle}
                      buildersUserId={buildersUserId}
                      apiHit={refresh}
                    />
                  </div>

                  <Scrollbars autoHide renderTrackHorizontal={props => <div {...props} style={{ display: 'none' }} />}
                        // Hide the vertical scrollbar
                        renderTrackVertical={props => <div {...props} style={{ display: 'none' }} />}>
                    <div className="primary_panel_wraper">
                      <div className="call-center-page">
                        <div className="messages-header mb-2">
                          {/* <div className="event-tabs dark-tabs"> */}
                          <div className="event-tabs tabs-style-2 mb-2">
                            <div className="phone-input">
                              <div>
                                <div className="search-tabs-wrapper  mt-0">
                                  <div className="search-form d-flex mt-0">
                                    <Form.Group className="search-input-location">
                                      <div className="form-control-with-icon phone_placeholder">
                                        <Form.Control
                                          style={{
                                            color:
                                              phoneNumber !== ''
                                                ? ''
                                                : '#AEAEAE',
                                            fontFamily: 'Inter',
                                            fontStyle: 'normal',
                                            fontWeight: '600',
                                            lineHeight: 'normal',
                                            fontSize: '15px',
                                            border: 'none',
                                            outline: 'none',
                                            background: 'none',
                                          }}
                                          value={phoneNumber}
                                          onChange={handleChange}
                                          maxLength={15}
                                          type="number"
                                          placeholder="Enter Phone Number"
                                        />
                                        {phoneNumber !== '' && (
                                          <span
                                            className="input-icon"
                                            onClick={() => {
                                              setPhoneNumber('');
                                            }}>
                                            <i className="fas fa-close" />
                                          </span>
                                        )}
                                      </div>
                                    </Form.Group>
                                  </div>
                                </div>
                              </div>

                              <div>
                                <div
                                  className="phone-call-icon"
                                  onClick={handleCall}>
                                  <i
                                    class="fa-solid fa-phone"
                                    style={{
                                      color: '#ffffff',
                                      fontSize: '1.5em',
                                    }}></i>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="search-tabs-icons">
                            <span
                              onClick={() => {
                                setThreeDots(!threeDots);
                              }}
                              className={
                                threeDots
                                  ? 'icon active threeDots'
                                  : 'icon threeDots'
                              }>
                              <i className="fas fa-ellipsis-vertical" />
                            </span>
                          </div>
                        </div>

                        {/* <Scrollbars
                   className=""
                   
                   autoHide
                  
                  > */}
                        <div>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              padding: '20px',
                            }}>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-around',
                                width: '100%',
                              }}>
                              <RoundButton
                                className="call-center-num-btns"
                                onClick={() => handleButtonClick('1')}
                                style={roundButtonStyle}>
                                1
                              </RoundButton>
                              <RoundButton
                                className="call-center-num-btns"
                                onClick={() => handleButtonClick('2')}
                                style={roundButtonStyle}>
                                2
                                <span className="call-center-num-btns">
                                  A B C
                                </span>
                              </RoundButton>
                              <RoundButton
                                onClick={() => handleButtonClick('3')}
                                style={roundButtonStyle}>
                                3
                                <span className="call-center-num-btns">
                                  D E F
                                </span>
                              </RoundButton>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-around',
                                width: '100%',
                              }}>
                              <RoundButton
                                onClick={() => handleButtonClick('4')}
                                style={roundButtonStyle}>
                                4
                                <span className="call-center-num-btns">
                                  G H I
                                </span>
                              </RoundButton>
                              <RoundButton
                                onClick={() => handleButtonClick('5')}
                                style={roundButtonStyle}>
                                5
                                <span className="call-center-num-btns">
                                  J K L
                                </span>
                              </RoundButton>
                              <RoundButton
                                onClick={() => handleButtonClick('6')}
                                style={roundButtonStyle}>
                                6
                                <span className="call-center-num-btns">
                                  M N O
                                </span>
                              </RoundButton>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-around',
                                width: '100%',
                              }}>
                              <RoundButton
                                onClick={() => handleButtonClick('7')}
                                style={roundButtonStyle}>
                                7
                                <span className="call-center-num-btns">
                                  P Q R
                                </span>
                              </RoundButton>
                              <RoundButton
                                onClick={() => handleButtonClick('8')}
                                style={roundButtonStyle}>
                                8
                                <span className="call-center-num-btns">
                                  S T U
                                </span>
                              </RoundButton>
                              <RoundButton
                                onClick={() => handleButtonClick('9')}
                                style={roundButtonStyle}>
                                9
                                <span className="call-center-num-btns">
                                  W X Y Z
                                </span>
                              </RoundButton>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-around',
                                width: '100%',
                              }}>
                              <RoundButton
                                onClick={handleDelete}
                                style={roundButtonStyle}>
                                *
                              </RoundButton>
                              <RoundButton
                                onClick={() => handleButtonClick('0')}
                                style={roundButtonStyle}>
                                0<span className="call-center-num-btns">+</span>
                              </RoundButton>
                              <RoundButton
                                onClick={() => handleButtonClick('0')}
                                style={roundButtonStyle}>
                                #
                              </RoundButton>
                            </div>
                          </div>
                        </div>

                        <div className="call-center-bottom-icons">
                          <img src={gridRound} alt="" />

                          <i
                            style={{color: '#7E7B7'}}
                            className="icon icon-circles"
                          />

                          <i className="icon-history" />

                          <img src={waveform} alt="" />
                        </div>
                        {/* </Scrollbars> */}
                      </div>
                    </div>
                  </Scrollbars>
                </>
              }
              secondary={
                <>
                  {showComponent === 'inviteOutSideTheBuild' && (
                    <SendInvitation
                      onClose={() => {
                        setShowComponent('');
                      }}
                    />
                  )}
                  {buildersUserId && showComponent === 'profileComponent' && (
                    <div className="pb-4 fix-width-forms">
                      <ProfileComponent
                        userId={buildersUserId}
                        show={false}
                        onClose={() => {
                          setShowComponent('');
                        }}
                        button={''}
                        userData={[]}
                        moderator={false}
                        VoxeetSDK={[]}
                        conferenceInfo={[]}
                        jdSpecialM={[]}
                        jdSpecialA={[]}
                      />
                    </div>
                  )}

{showComponent === 'AllBuilders' && (
                    
                    <div className="pb-4">
                      <AllBuilders
                        onClose={() => {
                          setShowComponent('');
                          
                        }}
                      />
                    </div>
               
                  )}
                </>
              }
            />
            {showComponent === 'WorkSpace' && (
              <CreateWorkSpace
                onClose={() => {
                  setShowComponent('');
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default CallCenter;
