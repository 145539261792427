import React, {useState, useEffect} from 'react';
import {format, addMonths, subMonths} from 'date-fns';
import moment from 'moment';

const Calendar = ({type, days, setStartDaysTime, startDaysTime, closeDaysTime, setCloseDaysTime, showDate = true, showTime = true, onConfirm, minDate, maxDate}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedHour, setSelectedHour] = useState(0);
  const [selectedMinute, setSelectedMinute] = useState(0);
  const [amPm, setAmPm] = useState('AM');
  const [view, setView] = useState('date'); // 'date' or 'time'
  const [selectMultipleDates, setSelectMultipleDates] = useState([]); // 'date' or 'time'
 
  const multipleDatesHandle = (datesArray) => {
    if (!datesArray.length) {
      return "Error: Array is empty.";
    }
  
    const uniqueDates = [...new Set(datesArray)];
  
    const sortedDates = uniqueDates.sort((a, b) => new Date(a) - new Date(b));
  
    setSelectMultipleDates(sortedDates); // Ensure you update the state with sorted dates
  };

  useEffect(() => {
    const updateDayTime = (day, setDayTime) => {
      if (day) {
        const time = moment(`${selectedHour}:${selectedMinute.toString().padStart(2, '0')} ${amPm}`, 'h:mm A');
  
        // Use a dynamic key for 'days' to update specific day
        setDayTime(prevState => ({
          ...prevState,
          [days]: time,  // Dynamically update the time for the given day
        }));
      }
    };
  
    // Assuming you have the day name coming from props
    updateDayTime(startDaysTime, setStartDaysTime);  // For start time
    updateDayTime(closeDaysTime, setCloseDaysTime);  // For close time
    
  }, [selectedHour, selectedMinute, amPm]);


  const selectDate = (day) => {
    const newDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), day);
  
    // Disable selection if the date is outside the allowed range
    if ((minDate && newDate <= minDate) || (maxDate && newDate >= maxDate)) {
      return; // Don't allow selection if it's outside the min or max date range
    }
  
    setSelectedDate(newDate); // Otherwise, update the selected date
  };

  // Check if a day is within the allowed date range
  const isDaySelectable = (day) => {
    const newDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), day);
    const isWithinRange = (!minDate || newDate >= minDate) && (!maxDate || newDate <= maxDate);
    return isWithinRange;
  };


  useEffect(() => {
    if (showDate) {
      setView('date');
    } else {
      setView('time');
    }
  }, []);

  const clearSelection = () => {
    setSelectedDate(new Date()); // Reset to today's date
    setSelectedHour(12);
    setSelectedMinute(0);
    setAmPm('AM');
    setIsOpen(false);
    setSelectMultipleDates([])
  };

// Month Navigation with range checking

  const prevMonth = () => {
    const newDate = subMonths(selectedDate, 1);
    if (!minDate || newDate >= new Date(minDate.getFullYear(), minDate.getMonth(), 1)) {
      setSelectedDate(newDate);
    }
  };

  const nextMonth = () => {
    const newDate = addMonths(selectedDate, 1);
    if (!maxDate || newDate <= new Date(maxDate.getFullYear(), maxDate.getMonth() + 1, 0)) {
      setSelectedDate(newDate);
    }
  };


  const handleHourChange = hour => {
    setSelectedHour(hour);
  };

  const handleMinuteChange = minute => {
    setSelectedMinute(minute);
  };

  const toggleAmPm = () => {
    setAmPm(prev => (prev === 'AM' ? 'PM' : 'AM'));
  };

  // New function for handling OK button
  const handleOkClick = () => {
    
    setIsOpen(false); // Close the calendar

    // (Optional) If you want to pass the selected date/time to a parent component
    if (onConfirm) {
      onConfirm({
        date: selectedDate,
        hour: selectedHour,
        minute: selectedMinute,
        period: amPm,
      });
    }

    console.log("Selected Date and Time:", {
      date: selectedDate,
      time: `${selectedHour}:${selectedMinute.toString().padStart(2, "0")} ${amPm}`,
    });
  };

  const daysOfWeek = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
  const daysInMonth = Array.from(
    {
      length: new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth() + 1,
        0,
      ).getDate(),
    },
    (_, i) => i + 1,
  );


  const hours = Array.from({length: 12}, (_, i) => i + 1);
  const minutes = Array.from({length: 60}, (_, i) => i);
  
  const firstDayOfMonth = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1).getDay();


  return (
    <div className="calendar-custom-relative-holder">
      <div className="relative">
        <button 
        style={{maxWidth: type === 'multiple' && '100%'}}
          onClick={()=>{
            setIsOpen(!isOpen)
          }}
          className="flex items-center justify-between w-full max-w-xs p-3 bg-white border rounded-md shadow-sm focus:outline-none transition duration-300 ease-in-out">
          <span className="clnd-date-time-txt">
            {showDate && type !== 'multiple' && format(selectedDate, 'MM/dd/yyyy')}
            {showTime &&
              ` ${selectedHour}:${selectedMinute
                .toString()
                .padStart(2, '0')} ${amPm}`}
          {showDate && type === 'multiple' && selectMultipleDates.length > 0 ? (
            selectMultipleDates.length === 1 
              ? selectMultipleDates[0] 
              : selectMultipleDates.join(', ')
          ) : showDate && type === 'multiple' && (
            "Select unavailable dates"
          )
        }

            
          </span>
          <svg
            className={`w-5 h-5 text-gray-600 transition-transform duration-300 ease-in-out ${
              isOpen ? 'rotate-0' : ''/*rotate 180*/
            }`}
            fill="none"
            stroke="#9b9787"
            viewBox="0 0 24 24">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M8 7V3m8 4V3m-7 8h6m-6 4h6m-8 6h10a2 2 0 002-2V7a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
            />
          </svg>
        </button>

        <div
          className={`absolute top-14 left-0 w-80 bg-white rounded-lg shadow-lg z-10 transform transition-transform duration-300 ease-in-out ${
            isOpen
              ? 'scale-100 opacity-100'
              : 'scale-95 opacity-0 pointer-events-none'
          }`}>
          {/* Header */}
          <div className="text-white rounded-t-lg p-2 date-time-pannel-head">
            <div className="text-center text-sm uppercase">
              {/* Select {showDate && 'Date'}
              {showTime && showDate && ' and '}
              {showTime && 'Time'} */}
            </div>
            <div className="text-center font-semibold">
              {showDate && format(selectedDate, 'EEE, MM/dd/yyyy')}
              {showTime &&
                ` ${selectedHour}:${selectedMinute
                  .toString()
                  .padStart(2, '0')} ${amPm}`}
            </div>
          </div>

          {showDate && showTime && (
            <div className="flex justify-center items-center p-2 bg-white border-b date-time-combn-section">
              <button
                onClick={() => setView('date')}
                className={`p-2 w-1/2 text-center font-semibold ${
                  view === 'date'
                    ? 'roi-bg-green font-semibold'
                    : 'hover:roi-bg-green'
                }`}>
                Date
              </button>
              <button
                onClick={() => setView('time')}
                className={`p-2 w-1/2 text-center font-semibold time-btn ${
                  view === 'time'
                    ? 'roi-bg-green font-semibold'
                    : 'hover:roi-bg-green'
                }`} >
                Time
              </button>
            </div>
          )}

          {view === 'date' && showDate && (
            <>
              {/* Calendar Controls */}
              <div className="flex justify-between items-center bg-white p-2 border-b full-date-section">
                <button
                  className="p-1 rounded-full hover:bg-gray-100 transition duration-300 ease-in-out"
                  onClick={prevMonth}>
                  <svg
                    className="w-5 h-5"
                    fill="none"
                    stroke="#9b9787"
                    viewBox="0 0 24 24">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M15 19l-7-7 7-7"
                    />
                  </svg>
                </button>
                <div className="text-lg font-medium exact-date-holder">
                {format(selectedDate, 'MMMM yyyy')} 
                </div>
                <button
                  className="p-1 rounded-full hover:bg-gray-100 transition duration-300 ease-in-out"
                  onClick={nextMonth}>
                  <svg
                    className="w-5 h-5"
                    fill="none"
                    stroke="#9b9787"
                    viewBox="0 0 24 24">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </button>
              </div>

              {/* Days of the Week */}
              <div className="grid grid-cols-7 text-center text-gray-500 bg-white py-2 day-name-section">
  {daysOfWeek.map(day => (
    <div key={day} className="text-sm font-medium day-name-color">
      {day}
    </div>
  ))}
</div>

{/* Calendar Days */}
<div className="grid grid-cols-7 text-center bg-white py-0">
  {/* Add empty days to offset the first day of the month */}
  {Array.from({ length: firstDayOfMonth }).map((_, index) => (
    <div key={`empty-${index}`} className="py-2"></div>
  ))}

  {/* Render days of the month */}
  {daysInMonth.map(day => {
    const currentDayDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), day);
    const isDisabled = currentDayDate < minDate || currentDayDate > maxDate;
    const isSelectedDay = selectedDate.getDate() === day && !isDisabled;
    const isSelectable = !isDisabled && isDaySelectable(day);
    const isUnavailable = selectMultipleDates.includes(format(currentDayDate, 'yyyy/MM/dd'));

    return (
      <div
        key={day}
        className={`round-custom-time transition duration-300 ease-in-out ${
          isSelectedDay 
            ? 'roi-bg-green text-white cursor-pointer' 
            : isUnavailable 
            ? 'roi-bg-green text-white cursor-pointer' 
            : isSelectable 
            ? 'hover:roi-bg-green cursor-pointer' 
            : 'text-gray-400 cursor-not-allowed'
        }`}
        style={{backgroundColor: (type === 'multiple' && isUnavailable) && '#429734'}}
        onClick={() => {
          if (isSelectable) {
            if (type !== 'multiple') {
              selectDate(day);
              setIsOpen(!isOpen);
            } else {
              const formattedDate = format(currentDayDate, 'yyyy/MM/dd');
              if (isUnavailable) {
                setSelectMultipleDates(prev => prev.filter(date => date !== formattedDate));
              } else {
                multipleDatesHandle([...selectMultipleDates, formattedDate]);
              }
            }
          }
        }}
      >
        {day}
      </div>
    );
})}
</div>

            </>
          )}

          {view === 'time' && showTime && (
            <div className="flex justify-center items-center p-4 border-t time-corner-date-time-calander">
              {/* Hour Picker */}
              <div className="flex flex-col items-center">
                <div className="text-sm text-gray-600 mb-2 font-medium clnd-hour-txt">Hour</div>
                <div
                  className="px-2 py-2 h-24 w-14 bg-gray-50 rounded-md overflow-y-auto overflow-x-hidden snap-y"
                  style={{scrollBehavior: 'smooth'}}>
                  {hours.map(hour => (
                    <div
                      key={hour}
                      className={`round-custom-time cursor-pointer p-2 text-center transition duration-300 ease-in-out snap-start ${
                        selectedHour === hour
                          ? 'roi-bg-green text-white'
                          : ''
                      }`}
                      onClick={() => handleHourChange(hour)}>
                      {hour}
                    </div>
                  ))}
                </div>
              </div>

              {/* Minute Picker */}
              <div className="flex flex-col items-center mx-4">
                <div className="text-sm text-gray-600 mb-2 font-medium clnd-hour-txt">Minute</div>
                <div
                  className="px-2 py-2 h-24 w-14 bg-gray-50 rounded-md overflow-y-auto snap-y overflow-x-hidden"
                  style={{scrollBehavior: 'smooth'}}>
                  {minutes.map(minute => (
                    <div
                      key={minute}
                      className={`round-custom-time cursor-pointer p-2 text-center transition duration-300 ease-in-out rounded-full snap-start ${
                        selectedMinute === minute
                          ? 'roi-bg-green text-white'
                          : ''
                      }`}
                      onClick={() => handleMinuteChange(minute)}>
                      {minute.toString().padStart(2, '0')}
                    </div>
                  ))}
                </div>
              </div>

              {/* AM/PM Picker */}
              <div className="flex flex-col items-center">
                <div className="text-sm text-gray-600 mb-2 font-medium clnd-hour-txt">AM/PM</div>
                <div
                  className="px-2 py-2 h-24 w-14 bg-gray-50 rounded-md overflow-y-auto snap-y overflow-x-hidden"
                  style={{scrollBehavior: 'smooth'}}>
                  {['AM', 'PM'].map(period => (
                    <div
                      key={period}
                      className={`round-custom-time cursor-pointer p-2 text-center transition duration-300 ease-in-out rounded-full snap-start ${
                        amPm === period
                          ? 'roi-bg-green text-white'
                          : ''
                      }`}
                      onClick={() => setAmPm(period)}>
                      {period}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          <div className="flex justify-between p-1 bg-gray-50 rounded-b-lg ok-clear-btn-section">
            <button
              onClick={clearSelection}
              className="px-4 py-2 text-sm text-gray-600 rounded-lg transition duration-300 ease-in-out date-custom-clear-btn">
              Clear
            </button>
            <button
              onClick={handleOkClick}
              className="px-4 py-2 text-sm text-gray-600 rounded-lg transition duration-300 ease-in-out date-custom-clear-btn">
              Okay
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Calendar;
