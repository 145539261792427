import React, { useState, useEffect, useRef } from 'react';
import { Button, Col, Form } from 'react-bootstrap';
import moment from 'moment/moment';
import 'react-toastify/dist/ReactToastify.css';
import LoaderSpinner from '../Modals/LoaderSpinner';
import commonApi from '../../Services/CommonService';
import Sidebar from '../../Pages/Sidebar';
import Pannels from '../Pannels';
import { DEFAULT_IMAGE } from '../../Utils/env';
import Appointments from '../../Pages/LandingPage/Appointments';
import {
  chatroomInfo,
  checkPermissions,
  fixName,
  newSingleChatroom,
} from '../../Utils/commonUtils';
import Scrollbars from 'react-custom-scrollbars';
import AppoinmentHistoryByType from './AppoinmentHistoryByType';
import { rewardStar } from '../../Constants/Images';

import AppointmentCallDetail from './AppointmentCallDetail';
import { errorMessage, successMessage } from '../../Utils/ToastMessages';
import { getItem } from '../../Utils/LocalStorage';
import OpportunityScreen from '../FrontScreens/OpportunityScreen';
import EditAppointment from './EditAppointment';
import CustomModal from '../CustomModal';
import ScheduleAppointmemt from './ScheduleAppointmemt';
import CreateWorkSpace from '../WorkSpace/CreateWorkSpace';
import {
  useHistory,
  useLocation,
} from 'react-router-dom/cjs/react-router-dom.min';
import CardDetail from '../Forms/CardDetail';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import { ENCRYPTION_KEY } from '../../Utils/env';
import PraiseCoution from '../SessionDetails/PraiseCoution';
import Slider from 'react-slick';
import { useSelector, useDispatch } from 'react-redux';
import { labels } from '../../Constants/Translations';
import { selected_lang } from '../../Utils/env';

import {
  ArrowIcon,
  FireIcon,
  MachineIcon,
  WhitePenIcon,
  timeIcon,
  bagIcon,
  heartIcon,
  Bandage,
} from '../../Constants/Images';
import DatePicker from 'react-datepicker';

import {
  filterAppointmentDataByDate,
  extractStartDatesAsDate,
} from '../../Utils/commonUtils';
import SmartChatPage from '../Common/SmartChatPage';
import { getAllAppointments } from '../../Store/Actions/AppointmentAction';
import AppointmentList from './AppointmentList';
import CustomSlidingButtons from '../CustomSlidingButtons';
import PaymentModal from '../Modals/PaymentModal';

const CALENDER_HEIGHT = 2400;
const offset = CALENDER_HEIGHT / 24;
const LEFT_MARGIN = 70;

const AppointmentPage = props => {
  const [subTabType, setSubTabsType] = useState('appointment');
  const [isFloated, toggleFloatedPanel] = useState(false);
  const [showHiddenPanel, toggleHiddenPanel] = useState(false);
  const [showLoader, setshowLoader] = useState(true);
  const [showComponent, setShowComponent] = useState('');
  // const [appointmentList, setAppointmentList] = useState([]);
  const [appointmentCalendarList, setAppointmentCalendarList] = useState([]);
  const [appointmentData, setAppointmentData] = useState(null);
  const [selectedFilterValue, setSelectedFilterValue] = useState('to_me');
  const [value_filter, setValue_filter] = useState('to_me');
  const [showFilter, setShowFilter] = useState(false);
  const [threeDots, setThreeDots] = useState(false);
  const [appointmentSearch, setAappointmentSearch] = useState('');
  const [appointmentHistorySearch, setAppointmentHistorySearch] = useState('');
  const [UpAappointment, setUpAappointment] = useState('');
  // const [buildersUserId, setBuildersUserId] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [payLoadIs, setPayLoadIs] = useState({});
  const [workspaceData, setWorkspaceData] = useState([]);
  const [idForPraiseCaution, setIdForPraiseCaution] = useState('');
  const [showPraise, setShowPraise] = useState(false);
  const [analycticsSlider, setAnalycticsSlider] = useState(false);
  const [appointmentDetail, setAppointmentDetail] = useState({
    appointment_type: '',
    cost: '',
    day: '',
    description: '',
    duration: '',
    id: '',
    qr_code: '',
    title: '',
    user_id: '',
    session_type: '',
  });

  const [packedEvents, setPackedEvents] = useState([]);
  const [userInfo, setUserInfo] = useState({});

  const [currentIndex, setCurrentIndex] = useState(0);

  const [availableDates, setAvailableDates] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [selectedEvent, setSelectedEvent] = useState('');
  const [showSelectedEvent, setShowSelectedEvent] = useState(false);
  const [chatType, setChatType] = useState([]);
  const [chatDetails, setChatDetails] = useState([]);
  const [allChatUsers, setAllChatUsers] = useState([]);
  const [chatRoomName, setChatRoomName] = useState('');
  const [chatRoomId, setChatRoomId] = useState('');
  const [showModel, setShowModel] = useState('');
  const [userApStatus, setUserApStatus] = useState('');
  const [changedStatusAppointment, setChangedStatusAppointment] = useState({});
  const [userselectStatus, setUserselectStatus] = useState('');
  const [appointmentTypes, setAppointmentTypes] = useState([]);
  const [appointmentTypesId, setAppointmentTypesId] = useState([]);

  const slotRef = useRef(null);

  const { pathname } = window.location;
  const urlParts = pathname.split('/').filter(Boolean);


  const currentURL = window.location.href;

  const survey = localStorage.getItem('survey');
  const url = new URL(currentURL);
  const scheduleId = url.searchParams.get('id');

  const location = useLocation();
  const history = useHistory();

  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  const handleChange = e => {
    setIsOpen(!isOpen);
    setStartDate(e);
    const index = availableDates.findIndex(d =>
      moment(e).isSame(moment(d), 'day'),
    );

    setCurrentIndex(index);
  };
  const handleClick = e => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const handleOnClickOutside = () => {
    setIsOpen(false);
  };

  const menuPermesions = useSelector(state => state.menuPermesionList);
  const appointmentList = useSelector(state => state.appointmentList);
  const menuItems = useSelector(state => state.menuActiveList);

  const filterListArray = [
    { id: '1', value: 'from_me', title: 'From me' },
    { id: '2', value: 'to_me', title: 'To me' },
  ];
  const Appointment_Status_List = [
    { id: '1', title: 'Running Late' },
    { id: '2', title: 'On my way' },
    { id: '3', title: "I'm outside" },
    { id: '4', title: "I'm Done" },
  ];

  //  let value_filter = 'to_me';
  const handleAlphabetSelect = alphabet => {
    // getMyAppointmentsByType(alphabet);
    // setSelectedFilterValue(alphabet);
    setValue_filter(alphabet);
  };

  const onSubmitFilter = alphabet => {
    if (subTabType === 'appointment' || subTabType === 'calendar') {
      // alert('hehe 3')
      getMyAppointmentsByType(alphabet);
    }

    setShowFilter(false);
  };
  useEffect(() => {
    get_appointment_types();
    get_consult_by_user_id();
    const slot_Id = localStorage.getItem('slot_id');
    // getTabstatus();

    if (
      (location && location.state && location.state.slot_id) ||
      (slot_Id !== null && slot_Id !== '')
    ) {
      is_valid_url(
        location.state.slot_id
          ? location.state.slot_id
          : localStorage.getItem('slot_id')
            ? localStorage.getItem('slot_id')
            : '',
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (
      showComponent === '' &&
      (subTabType === 'appointment' || subTabType === 'calendar')
    ) {
      getMyAppointmentsByType(selectedFilterValue, '', appointmentTypesId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subTabType, appointmentTypesId]);

  const is_valid_url = slotId => {
    const data = { type: 'consult', type_id: slotId };
    commonApi
      .is_valid_url(data)
      .then(response => {
        // setshowLoader(false);
        if (response.meet_detail.creator_id === localStorage.getItem('id')) {
          errorMessage('You cant purchase your own slot');
        } else if (
          response.meet_detail.user_id !== localStorage.getItem('id')
        ) {
          errorMessage('Sorry, this content is not available for you');
        }
        if (response.payment_status === true) {
          successMessage('Your payment has already been paid');
        } else {
          setAppointmentDetail({
            appointment_type: response.meet_detail.appointment_type,
            cost: response.meet_detail.cost,
            // day: response.meet_detail.day,
            description: response.meet_detail.description,
            duration: response.meet_detail.duration,
            id: response.meet_detail.id,
            // qr_code: response.meet_detail.qr_code,
            title: response.meet_detail.title,
            session_type: response.meet_detail.session_type,
            user_id: response.meet_detail.user_id,
          });
          setPayLoadIs({
            complete_time: response.meet_detail.new_start_time.replaceAll(
              '/',
              '-',
            ),
            // date: Moment(date).format('ll').toString(),
            // day: Moment(date).format('dddd').toString(),
            // mentor_id: data.creator_id,
            slot_id: slotId,
            start_time: response.meet_detail.start_time,
          });
          setShowForm(true);
        }
        localStorage.setItem('slot_id', '');
      })
      .catch(err => {
        console.log('Err', err);
        return { type: 'error', message: err.message };
      });
  };

  const getMyAppointmentsByType = (type, srchQry, appointmentTyId) => {
    if (!srchQry) {
      setshowLoader(true);
    }
    const filteredAppointmentTyId =
      appointmentTyId &&
        appointmentTyId.length > 0 &&
        appointmentTyId.includes('all')
        ? appointmentTyId.filter(id => id !== 'all')
        : appointmentTyId;
    const data = {
      type: type,
      search_query: srchQry ? srchQry : '',
      schedule_types: filteredAppointmentTyId,
    };

    const response = dispatch(getAllAppointments(data))
      .then(response => {
        if (response.success === true) {
          setTimeout(() => {
            setshowLoader(false);
          }, 1000);
        } else {
          alert('Error');
        }
      })
      .catch(error => {
        console.error(error);
        alert('Error');
      });

    // commonApi
    //   .get_my_appointments_by_type(data)
    //   .then(res => {
    //     setAppointmentList(res.appointments);

    //     setTimeout(() => {
    //       setshowLoader(false);
    //     }, 1000);
    //   })
    //   .catch(err => {
    //     console.log('Err', err);
    //     return {type: 'error', message: err.message};
    //   });
  };

  const handleApStatus = alphabet => {
    // getMyAppointmentsByType(alphabet);
    // setSelectedFilterValue(alphabet);
    setUserApStatus(alphabet);
  };

  const changeUserMeetingStatus = status => {
    const data = { meeting_id: changedStatusAppointment.id, user_status: status };

    commonApi
      .change_button_status(data)
      .then(res => {
        const data = { type: selectedFilterValue, search_query: '' };

        if (!showSelectedEvent) {
          dispatch(getAllAppointments(data));
        } else if (showSelectedEvent && subTabType === 'calendar') {
          const updatedAppointmentList = [...appointmentList];

          const index = updatedAppointmentList.findIndex(
            appointment => appointment.id === selectedEvent.id,
          );

          if (index !== -1) {
            const updatedAppointment = { ...updatedAppointmentList[index] };

            selectedFilterValue === 'to_me'
              ? (updatedAppointment.creator_status = status)
              : (updatedAppointment.user_status = status);

            setSelectedEvent(updatedAppointment);
            updatedAppointmentList[index] = updatedAppointment;

            dispatch(
              getAllAppointments({
                type: 'UPDATE',
                appointmentList: updatedAppointmentList,
              }),
            );
          }
        }

        successMessage(res.message);
      })
      .catch(err => {
        console.log('Err', err);
        return { type: 'error', message: err.message };
      });
  };

  const get_consult_by_user_id = (type, srchQry) => {
    const data = { user_id: localStorage.getItem('id') };
    commonApi
      .get_consult_by_user_id(data)
      .then(res => {
        setUpAappointment(res);
      })
      .catch(err => {
        console.log('Err', err);
        return { type: 'error', message: err.message };
      });
  };
  const get_appointment_types = () => {
    commonApi
      .get_appointment_types()
      .then(res => {
        const pushAll = {
          id: 'all',
          appointment_type: 'All',
          created_at: '2022-07-25 03:38:50',
          updated_at: '2022-07-25 03:38:50',
        };

        // Assuming res.appointment_type is an array
        setAppointmentTypes([pushAll, ...res.appointment_type]);
      })
      .catch(err => {
        console.log('Err', err);
        return { type: 'error', message: err.message };
      });
  };
  const handleShowDtail = item => {
    setAppointmentData(item);
    if (subTabType === 'calendar') {
      setShowComponent('appointmentCall_calendar');
    } else {
      setShowComponent('appointmentCall');
    }

  };
  const getRefreshToken = async (id, all_calenders_events) => {
    commonApi.get_refresh_token().then(response => {
      // getAccessTokenFromRefreshToken(
      //   response.refresh_token[0].refresh_token,
      //   id,
      //   all_calenders_events,
      // );

      delete_google_calendar_event(id);

      for (const item of all_calenders_events) {
        const { google_calender_email } = item;

        if (google_calender_email) {
          // Find the matching token for the current calendar email
          const matchingToken = response.refresh_token.find(
            token => token.email === google_calender_email,
          );

          if (matchingToken && matchingToken.refresh_token) {
            getAccessTokenFromRefreshToken(
              matchingToken.refresh_token,
              item.calendar_id,
              item.calendar_event_id,
            );

            // You can use the accessToken as needed
          } else {
          }
        }
      }
    });
  };
  const getAccessTokenFromRefreshToken = async (
    refreshToken,
    calendar_id,
    calendar_event_id,
  ) => {
    const decrypted = CryptoJS.AES.decrypt(
      refreshToken,
      ENCRYPTION_KEY,
    ).toString(CryptoJS.enc.Utf8);

    const requestBody = {
      grant_type: 'refresh_token',
      client_id:
        '556170493308-2o3iobkftj6d5ppk7uh1vtc8c3fn84g4.apps.googleusercontent.com',
      client_secret: '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',
      refresh_token: decrypted,
    };

    try {
      const response = await axios.post(
        'https://oauth2.googleapis.com/token',
        requestBody,
      );
      // setAppointmentIds(item.appointment_id)
      // setCalendersData(item.all_calenders_events && item.all_calenders_events)

      // console.log(
      //   all_calenders_events,
      //   'all_calenders_eventsall_calenders_eventsall_calenders_events',
      // );
      // all_calenders_events.map((miniItem, index) => {
      deleteEventFromCalendar(
        response.data.access_token,
        calendar_id,
        calendar_event_id,
      );
      // });

      // delete_google_calendar_event(id);

      //   return response.data;
    } catch (error) {
      return error;
    }
  };
  const deleteEventFromCalendar = async (token, calendarId, eventId) => {
    const headers = {
      Authorization: `Bearer ${token}`,
      authorization: `Bearer ${token}`,
    };

    try {
      const config = {
        headers,
        method: 'DELETE',
        url: `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events/${eventId}`,
      };
      const response = await axios(config);
      return response.data;
    } catch (error) {
      return error;
    }
  };

  const delete_google_calendar_event = id => {
    let payLoad = {
      type_id: id,
      type: 'appointment',
    };
    commonApi
      .delete_google_calendar_event(payLoad)
      .then(res => {
        if (res.success === true) {
          // successMessage(res.message);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return { type: 'error', message: err.message };
      });
  };
  const cancelAppointment = (id, all_calenders_events) => {
    let payLoad = {
      appointment_id: id,
      appointment_status: 'canceled',
    };

    commonApi
      .cancel_appointment(payLoad)
      .then(res => {
        if (res.success === true) {
          successMessage(res.message);
          // getMyAppointmentsByType(selectedFilterValue);
          // setAppointmentList(previous =>
          //   previous.filter(item => item.appointment_id !== id),
          // );
          if (all_calenders_events && all_calenders_events.length > 0) {
            getRefreshToken(id, all_calenders_events);
          }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return { type: 'error', message: err.message };
      });
  };

  const getTabstatus = () => {
    commonApi
      .get_tab_status()
      .then(res => {
        setshowLoader(false);
        // setShowSpinner(false);
        //
        if (res.status === 200) {
          // setShowComponent('firstVist');
          const item = res.tab_status;
          if (item.length === 0) {
            setShowComponent('firstVist');
          } else {
            const newArray = [];
            if (Object.entries(item).length > 0) {
              item.forEach((item, index) => {
                newArray.push(item.tab);
              });
            }
            const found = newArray.find(element => element === 'appointment');
            if (found === 'appointment') {
              // setShowComponent('');
            } else {
              setShowComponent('firstVist');
            }
          }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return { type: 'error', message: err.message };
      });
  };

  const stripePayment = data => {
    setshowLoader(true);
    commonApi
      .stripe_payment(data)
      .then(response => {
        if (response.stripe === true) {
          // createAppointment();
          // addCard();
        } else {
          errorMessage(response.message);
          // setshowLoader(false);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return { type: 'error', message: err.message };
      });
  };

  // const buildersHandle = (t, id) => {
  //   setBuildersUserId(id);
  //   setShowComponent(t);
  //   // setPlusButton(true);
  //   // setMapButton(false);
  // };

  // useEffect(() => {
  //   if (workspaceData.length > 0) {
  //     // alert('hehe 4')
  //     getMyAppointmentsByType(selectedFilterValue);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [workspaceData]);

  useEffect(() => {
    if (showComponent !== '' && showComponent !== 'firstVist') {
      toggleHiddenPanel(true);
    } else {
      toggleHiddenPanel(false);
    }
  }, [showComponent]);

  useEffect(() => {
    //

    if (location && location.state && location.state.close_session === 'yes') {
      // const historyIs = createHistory();
      if (
        history.location &&
        history.location.state &&
        history.location.state.close_session
      ) {
        const state = { ...history.location.state };
        delete state.close_session;
        history.replace({ ...history.location, state });
      }
      if (
        history.location &&
        history.location.state &&
        history.location.state.creator_id
      ) {
        const state = { ...history.location.state };
        setIdForPraiseCaution(state.creator_id);
        delete state.creator_id;
        history.replace({ ...history.location, state });

        // setShowComponent('praiseCaution');
      }
      // localStorage.setItem('session_started', '');
      // successMessage('Session is closed by moderator.');
    }

    // if (
    //   locationWeb &&
    //   locationWeb.state &&
    //   locationWeb.state.banu_user === 'yes'
    // ) {
    //   errorMessage('Youre banned for this session.');
    // }
    // if (
    //   locationWeb &&
    //   locationWeb.state &&
    //   locationWeb.state.typeIs === 'events'
    // ) {
    //   if (
    //     history.location &&
    //     history.location.state &&
    //     history.location.state.typeIs
    //   ) {
    //     const state = {...history.location.state};
    //     delete state.typeIs;
    //     history.replace({...history.location, state});
    //   }
    //   // locationWeb.state = undefined;
    //   setTypeIs(false);
    //   setCurrentComponent('events');
    //   // getApiSessionList(sessionType, "", true, pageNo);
    // } else {
    //   getApiSessionList(sessionType, '', !typeIs, pageNo);
    // }

    // handleNotificationDirection();

    // dispatch(getSessionCategory());
    // // getDiscoverBuilders();
    // setTimeout(() => {
    //   setShowPreSpinner(false);
    // }, 1000);
    // amplitudeEvent('VISIT_MEETUPS');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const rewardSlider = {
    // className: "center",
    // centerMode: true,
    dots: false,
    arrows: false,
    infinite: false,
    // centerPadding : "20px",
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    // adaptiveHeight: true,
  };
  const rewardSliderData = [
    {
      name: 'Attendance',
      money: '$2.4k',
      time: 'versus last month',
      svgIcon: rewardStar,
    },

    {
      name: 'Duration',
      money: '5m 20s',
      time: 'versus last month',
      svgIcon: rewardStar,
      // icon: 'fa-solid fa-star',
    },

    {
      name: 'Frequency (average days)',
      money: '45%',
      time: 'versus last month',
      svgIcon: rewardStar,
    },
    {
      name: 'Rating',
      money: '4.8',
      time: 'versus last month',
      svgIcon: rewardStar,
    },
  ];

  const dynamicStyle = {
    padding: 0,
    // Add other styles as needed
  };

  console.log(menuPermesions, 'menuPermesions');
  const AppointmentPermessions =
    menuPermesions && checkPermissions(menuPermesions, 'Scheduler');

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuPermesions]);



  useEffect(() => {
    if (urlParts[2] === 'schedule' && /^\d+$/.test(urlParts[1])) {
      getUserData(urlParts[1])
    }
  }, [])
  const getUserData = (userId) => {
    var data = { user_id: userId };
    commonApi
      .user_info(data)
      .then(res => {
        if (res) {
          setUserInfo(res);
          setShowComponent('userAppointment')

        }
      })
      .catch(err => {
        console.log('Err', err);
        return { type: 'error', message: err.message };
      });
  };


  let divStyle = {
    color: '#1A1818',
  };

  const generateTimeSlots = () => {
    const timeSlots = [];
    for (let hour = 0; hour <= 23; hour++) {
      const time = `${hour % 12 === 0 ? 12 : hour % 12} ${hour < 12 ? 'AM' : 'PM'
        }`;

      const timeStyle = {
        top: offset * hour - 8,
        position: 'absolute',
      };
      const lineStyle = {
        top: offset * hour,
        position: 'absolute',
        marginLeft: 60,
      };
      timeSlots.push(
        <div key={hour} className="activities-listing">
          <div className="time" style={timeStyle}>
            {time}
          </div>
          <div className="activity-line" style={lineStyle}>
            {/* Add your activity content or styling here */}
          </div>
        </div>,
      );
    }
    return timeSlots;
  };

  const populateEvents = (events, screenWidth) => {
    let lastEnd;
    let columns;
    let calculatedEvents = [];

    events = events
      .map((ev, index) => ({ ...ev, index: index }))
      .sort((a, b) => {
        if (a.start < b.start) return -1;
        if (a.start > b.start) return 1;
        if (a.end < b.end) return -1;
        if (a.end > b.end) return 1;
        return 0;
      });

    columns = [];
    lastEnd = null;

    events.forEach((ev, index) => {
      if (lastEnd !== null && ev.start >= lastEnd) {
        pack(columns, screenWidth, calculatedEvents);
        columns = [];
        lastEnd = null;
      }

      let placed = false;
      for (let i = 0; i < columns.length; i++) {
        let col = columns[i];
        if (!collision(col[col.length - 1], ev)) {
          col.push(ev);
          placed = true;
          break;
        }
      }

      if (!placed) {
        columns.push([ev]);
      }

      if (lastEnd === null || ev.end > lastEnd) {
        lastEnd = ev.end;
      }
    });

    if (columns.length > 0) {
      pack(columns, screenWidth, calculatedEvents);
    }
    return calculatedEvents;
  };

  const pack = (columns, width, calculatedEvents) => {
    const colLength = columns.length;

    for (let i = 0; i < colLength; i++) {
      let col = columns[i];
      for (let j = 0; j < col.length; j++) {
        let colSpan = expand(col[j], i, columns);
        // let L = i / colLength * width;
        let L = i * width;
        let W = (width * colSpan) / colLength - 10;

        calculatedEvents.push(buildEvent(col[j], L, W));
      }
    }
  };

  const collision = (a, b) => {
    return a.end > b.start && a.start < b.end;
  };

  const expand = (ev, column, columns) => {
    let colSpan = 1;

    for (let i = column + 1; i < columns.length; i++) {
      const col = columns[i];

      for (let j = 0; j < col.length; j++) {
        let ev1 = col[j];
        if (collision(ev, ev1)) {
          return colSpan;
        }
      }
      colSpan++;
    }

    return colSpan;
  };

  const buildEvent = (column, left, width) => {
    const startTime = moment(column.start);
    const endTime = column.end
      ? moment(column.end)
      : startTime.clone().add(1, 'hour');
    const diffHours = startTime.diff(
      startTime.clone().startOf('day'),
      'hours',
      true,
    );

    column.top = diffHours * offset;
    column.height = endTime.diff(startTime, 'hours', true) * offset;
    column.left = left;
    return column;
  };

  function convertEndTimeToMatchStartDate(appointments) {
    return appointments.map(appointment => {
      const { start_date, end_time, ...rest } = appointment;
      const endDate = new Date(start_date);
      const [hours, minutes] = end_time.split(':').map(Number);
      endDate.setHours(hours);
      endDate.setMinutes(minutes);
      endDate.setSeconds(0); // Assuming seconds should be set to 0
      return {
        ...rest,
        end: endDate.toISOString(),
      };
    });
  }

  useEffect(() => {
    const appointments = appointmentList.map(appointment => {
      const { start_date, end_time, ...rest } = appointment;

      const endDate = moment(start_date).set({
        hour: parseInt(end_time.split(':')[0]),
        minute: parseInt(end_time.split(':')[1]),
        second: 0,
        millisecond: 0,
      });
      const formattedEndDate = endDate.format('YYYY-MM-DD HH:mm:ss');
      return {
        ...rest,
        start: start_date,
        end: formattedEndDate,
      };
    });

    setAppointmentCalendarList(appointments);

    console.log(appointments, 'appointmentsevets');

    const filterdata = filterAppointmentDataByDate(appointments, new Date());

    console.log(filterdata, 'filterdatafilterdata');

    const packedEvents = populateEvents(filterdata, 300);

    setPackedEvents(packedEvents);

    const dates = extractStartDatesAsDate(appointmentList);

    //  const uniqueDates = [...new Set(dates)];
    setAvailableDates(dates);

    var todayDate = moment(new Date());
    var availabledates = dates.map(date => moment(date));

    var startDateNotIncluded = availabledates.some(date =>
      todayDate.isSame(date, 'date'),
    );

    console.log(startDateNotIncluded, 'startDateNotIncluded');

    if (!startDateNotIncluded) {
      setStartDate(new Date(moment(availableDates[currentIndex])));
    } else {
      setStartDate(new Date());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentList]);

  useEffect(() => {
    if (showComponent === 'appointmentCall' && appointmentData) {
      appointmentList.forEach(appointment => {
        if (appointment.id === appointmentData.id) {
          setAppointmentData(appointment);
        }
      });
    }
  }, [appointmentList]);

  useEffect(() => {
    if (startDate && startDate !== '') {
      console.log(appointmentCalendarList, 'appointmentCalendarList');
      console.log(startDate, 'startDate');
      const filterdata = filterAppointmentDataByDate(
        appointmentCalendarList,
        startDate,
      );

      console.log(filterdata, 'filterdatafilterdata');

      const packedEvents = populateEvents(filterdata, 300);

      setPackedEvents(packedEvents);
    }
  }, [startDate]);

  const renderEvents = () => {
    const events = packedEvents.map((event, i) => {
      const style = {
        height: event.height,
        top: event.top,
        left: event.left,
        marginLeft: LEFT_MARGIN,
      };

      console.log(event, 'eventevent');
      return (
        <div
          className="activity-item"
          style={style}
          onClick={() => onSlotClick(event, event.left, event.top)}
          onBlur={() => {
            setShowSelectedEvent(false);
          }}>
          <h2 className="scheduler_name">
            {selectedFilterValue === 'to_me'
              ? event?.user_details?.first_name +
              ' ' +
              event?.user_details?.last_name
              : event?.creator_details?.first_name +
              ' ' +
              event?.creator_details?.last_name}
          </h2>
          {/* <p class="single-line-text">Service</p> */}
          {/* <p class="single-line-text">McLean, VA</p> */}
        </div>
      );
    });

    return <>{events}</>;
  };

  const onSlotClick = (event, offset, top) => {
    // if(!showSelectedEvent){
    const sel = { ...event, top: top + 25, left: offset };
    // setSelected(event.id);
    setSelectedEvent(sel);
    setShowSelectedEvent(true);
  };

  const handleClickOutside = event => {
    if (slotRef.current && !slotRef.current.contains(event.target)) {
      if (showModel !== 'calendar_status_model') {
        setShowSelectedEvent(false);
        setSelectedEvent({});
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [showModel]);

  // useEffect(() => {
  //   const handleClick = event => {
  //     handleClickOutside(event);
  //   };

  //   if (showSelectedEvent) {
  //     document.addEventListener('click', handleClick);
  //   }

  //   return () => {
  //     document.removeEventListener('click', handleClick);
  //   };
  // }, [showSelectedEvent, showModel]);

  const handleBackDate = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      setStartDate(new Date(moment(availableDates[currentIndex - 1])));
    } else {
      errorMessage('No record found');
    }
  };

  const handleNextDate = () => {
    if (currentIndex < availableDates.length - 1) {
      setCurrentIndex(currentIndex + 1);

      setStartDate(new Date(moment(availableDates[currentIndex + 1])));
    } else {
      errorMessage('No record found');
    }
  };

  // console.log(selectedEvent, 'selectedEvent');

  // console.log(availableDates, 'selectedDates');

  // console.log(isOpen, 'isOpen');

  const checkChatRoom = id => {
    let payLoad = {
      user_id: id,
    };
    commonApi
      .check_chat_room(payLoad)
      .then(response => {
        if (response.status === 200) {
          if (response.exist) {
            const allChatUsers = [];
            allChatUsers.push(response.chat_detail.creator_detail);
            allChatUsers.push(response.chat_detail.user_detail);
            setChatType('personal-single');
            setChatDetails(response.chat_detail);
            setAllChatUsers(allChatUsers);
            setChatRoomName(response.chat_room_name);
            setChatRoomId(response.chat_room_id);
            setShowComponent(subTabType === 'calendar' ? 'single_chat_calendar' : 'single_chat');
          } else {
            setChatType('new-personal-single');
            setChatDetails([]);
            setAllChatUsers([userInfo]);
            setChatRoomName('');
            setChatRoomId('');
            setShowComponent(subTabType === 'calendar' ? 'single_chat_calendar' : 'single_chat');
          }
        }
      })
      .catch(error => {
        return { type: 'error', message: error.message };
      });
  };

  const statusOfUserAppointment = (item, type) => {
    if (type === 'to_me') {
      setUserApStatus(item.creator_status);
    } else if (type === 'from_me') {
      setUserApStatus(item.user_status);
    }

    setChangedStatusAppointment(item);
  };
  // console.log(appointmentList, 'allAppointmentsfromredux');

  // console.log(changedStatusAppointment, 'changedStatusAppointment');
  console.log(selectedEvent, 'selectedEventselectedEvent');

  const checkPermesionByCompanyOwner =
    AppointmentPermessions &&
      (AppointmentPermessions === 'allow' ||
        AppointmentPermessions?.create === true)
      ? true
      : false;

  const handleClickButtons = e => {
    const { id } = e.target;

    if (id === 'all') {
      // If 'all' is clicked
      if (appointmentTypesId.includes('all')) {
        // If 'all' is already selected, deselect it
        setAppointmentTypesId([]);
      } else {
        // Select 'all'
        const allIds = appointmentTypes.map(type => type.id);
        setAppointmentTypesId(allIds);
      }
    } else {
      // If a specific type is clicked
      let newAppointmentTypesId;
      if (appointmentTypesId.includes(id)) {
        // If the id is already selected, deselect it
        newAppointmentTypesId = appointmentTypesId.filter(item => item !== id);
      } else {
        // Select the id
        newAppointmentTypesId = [...appointmentTypesId, id];
      }

      // If all specific types are selected, ensure 'all' is included
      const allIds = appointmentTypes.map(type => type.id);
      if (
        newAppointmentTypesId.length === allIds.length &&
        !newAppointmentTypesId.includes('all')
      ) {
        newAppointmentTypesId.push('all');
      }

      setAppointmentTypesId(newAppointmentTypesId);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      const { pathname } = window.location;
      const urlPart = pathname.split('/').filter(Boolean);

      if (
        Object.keys(workspaceData).length > 0 &&
        urlPart.length === 1 &&
        showComponent !== ''
      ) {
        setShowComponent('');
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, 1000);
  }, [workspaceData]);

  return (
    <>
      <div className="page-wrapper">
        <Sidebar
          setWorkSpaceItem={setWorkspaceData}
          location={props.location}
          setShowComponent={setShowComponent}
          showComponent={showComponent}
        />
        <div className="page-content-wrapper">
          <div className="sessions-list-wrapper  slider-screen-main">
            {/* <DiscoverBuilders   buildersHandle={buildersHandle} /> */}

            {showComponent !== 'firstVisit' && (
              <>
                {AppointmentPermessions &&
                  (AppointmentPermessions === 'allow' ||
                    AppointmentPermessions?.analytics_row === true) && (
                    <>
                      <div className="Reward_main analytics_slider_bg">
                        <span
                          className="analytics_slider_heading "
                          style={{ color: analycticsSlider ? '' : '#999999' }}
                          onClick={() => {
                            setAnalycticsSlider(!analycticsSlider);
                          }}>
                          Analytics Row
                        </span>

                        {analycticsSlider && (
                          <div className="reward-top mt-3 mb-2">
                            <Slider {...rewardSlider}>
                              {rewardSliderData.length > 0 &&
                                rewardSliderData.map((item, index) => (
                                  <div className="reward-main">
                                    <div className="reward-card">
                                      <span
                                        style={{
                                          fontWeight: 700,
                                          fontSize: '13px',
                                        }}>
                                        {item.name}
                                      </span>
                                      <span
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                        }}>
                                        <span style={{ fontSize: '17px' }}>
                                          {item.money}
                                        </span>
                                        {item.name === 'Ratings' && (
                                          <img src={item.svgIcon} alt="" />
                                        )}
                                        {item.name === 'Conversion' ? (
                                          <i
                                            className="fa-solid fa-arrow-down"
                                            style={{ color: '#BD4141' }}></i>
                                        ) : (
                                          <i
                                            class="fa-solid fa-arrow-up"
                                            style={{ color: '#52bd41' }}></i>
                                        )}
                                      </span>
                                      <div class="message-date-info">
                                        <span
                                          style={{ color: '#333' }}
                                          class="message-date">
                                          {item.time}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                            </Slider>
                          </div>
                        )}
                      </div>
                      <div className="add_border"></div>
                    </>
                  )}
              </>
            )}

            {subTabType === 'appointment' && (
              <Pannels
                isFloated={isFloated}
                toggleFloatedPanel={toggleFloatedPanel}
                showHiddenPanel={showHiddenPanel}
                updateHiddenPanelState={nState => toggleHiddenPanel(nState)}
                customCss={dynamicStyle}
                primary={
                  <>
                    {/* {showComponent !== 'firstVisit' && (
                    <>
                      <div className="Reward_main analytics_slider_bg">
                        <span
                          className="analytics_slider_heading "
                          style={{color: analycticsSlider ? '' : '#999999'}}
                          onClick={() => {
                            setAnalycticsSlider(!analycticsSlider);
                          }}>
                          Analytics Row
                        </span>

                        {analycticsSlider && (
                          <div className="reward-top mt-3 mb-2">
                            <Slider {...rewardSlider}>
                              {rewardSliderData.length > 0 &&
                                rewardSliderData.map((item, index) => (
                                  <div className="reward-main">
                                    <div className="reward-card">
                                      <span
                                        style={{
                                          fontWeight: 700,
                                          fontSize: '13px',
                                        }}>
                                        {item.name}
                                      </span>
                                      <span
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                        }}>
                                        <span style={{fontSize: '17px'}}>
                                          {item.money}
                                        </span>
                                        {item.name === 'Ratings' && (
                                          <img src={item.svgIcon} alt="" />
                                        )}
                                        {item.name === 'Conversion' ? (
                                          <i
                                            className="fa-solid fa-arrow-down"
                                            style={{color: '#BD4141'}}></i>
                                        ) : (
                                          <i
                                            class="fa-solid fa-arrow-up"
                                            style={{color: '#52bd41'}}></i>
                                        )}
                                      </span>
                                      <div class="message-date-info">
                                        <span
                                          style={{color: '#333'}}
                                          class="message-date">
                                          {item.time}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                            </Slider>
                          </div>
                        )}
                      </div>
                    </>
                  )} */}
                    {/* <Scrollbars className="custom-scroll"> */}
                    {showComponent === 'firstVist' && (
                      <OpportunityScreen
                        onClose={() => {
                          setShowComponent('');
                        }}
                        type={'appointment'}
                        setShowComponent={setShowComponent}
                      />
                    )}
                    {showComponent !== 'firstVist' && (
                      <div className="primary_panel_wraper primary_panel_content">
                        <div className="appointment-header appointment_schedule_activities">
                          <div className="d-flex justify-content-between align-items-center mb-2">
                            <div className="event-tabs tabs-style-2 mb-0">
                              <ul>
                                <li>
                                  <Button
                                    className={
                                      subTabType === 'appointment'
                                        ? 'active'
                                        : ''
                                    }
                                    onClick={() => {
                                      setshowLoader(true);
                                      setSubTabsType('appointment');
                                      // getMyAppointmentsByType('from_me');
                                      setShowComponent('');
                                    }}>
                                    List
                                  </Button>
                                </li>

                                <li>
                                  <Button
                                    className={
                                      subTabType === 'calendar' ? 'active' : ''
                                    }
                                    onClick={() => {
                                      setSubTabsType('calendar');
                                      // getMyAppointmentsByType('to_me');
                                      getMyAppointmentsByType(
                                        selectedFilterValue,
                                      );
                                      setAappointmentSearch('');
                                      setShowComponent('');
                                    }}>
                                    {labels.CALENDAR[selected_lang]}
                                  </Button>
                                </li>

                                {/* <li>
                                  <Button
                                    className={
                                      subTabType === 'history' ? 'active' : ''
                                    }
                                    onClick={() => {
                                      setSubTabsType('history');
                                      // getMyAppointmentsByType('to_me');
                                      setShowComponent('');
                                    }}>
                                    History
                                  </Button>
                                </li> */}
                              </ul>
                            </div>
                            <div className="search-tabs-icons">
                              <span
                                className={` mt-0 ${UpAappointment &&
                                    !UpAappointment.already_added
                                    ? 'disabled-Class'
                                    : showComponent === 'create-appointment'
                                      ? 'icon active'
                                      : 'icon'
                                  }`}>
                                {checkPermesionByCompanyOwner && (
                                  <i
                                    className="icon-plus"
                                    onClick={() => {
                                      if (
                                        showComponent === 'create-appointment'
                                      ) {
                                        setShowComponent('');
                                      } else {
                                        setShowComponent('create-appointment');
                                      }
                                    }}
                                  />
                                )}
                              </span>

                              <span
                                onClick={() => {
                                  setThreeDots(!threeDots);
                                }}
                                className={` mt-0  ${threeDots ? 'icon active' : 'icon'
                                  }`}>
                                <i className="fas fa-ellipsis-vertical" />
                              </span>
                            </div>
                          </div>
                          {threeDots && (
                            <div className="person-search-wrapper mb-3 mt-3 messages-users-wrapper">
                              <div className="search-form d-flex">
                                <Form.Group className="search-input">
                                  <Form.Control
                                    type="text"
                                    placeholder="Search"
                                    value={appointmentSearch}
                                    onChange={e => {
                                      setAappointmentSearch(e.target.value);
                                      if (subTabType === 'appointment') {
                                        getMyAppointmentsByType(
                                          selectedFilterValue,
                                          e.target.value,
                                        );
                                      } else {
                                        setAppointmentHistorySearch(
                                          e.target.value,
                                        );
                                      }
                                    }}
                                  />
                                </Form.Group>
                                <Button>
                                  <i
                                    className="icon-filter-icon"
                                    onClick={() => setShowFilter(true)}
                                  />
                                </Button>
                              </div>

                              <div className="category-btns-wrapper category-btns-wrapper-slider">
                                <CustomSlidingButtons
                                  items={appointmentTypes}
                                  activeButtons={appointmentTypesId}
                                  onItemClick={e => {
                                    handleClickButtons(e);
                                  }}
                                  showItems={2}
                                  label={'appointment_type'}
                                />
                              </div>
                            </div>
                          )}
                        </div>

                        {/* <div className="custom-scroll-content pt-0 appointment-page-content"> */}
                        {/* <div className="sidebar-heading-wrapper m-0"></div> */}
                        <div className="refferal-list-wrapper">
                          {showLoader && <LoaderSpinner />}
                          <Scrollbars className="custom-scroll" autoHide>
                            {subTabType === 'appointment' &&
                              selectedFilterValue === 'to_me' && (
                                <div className="pb-4">
                                  {Object.entries(appointmentList).length >
                                    0 ? (
                                    appointmentList.map((item, index) => (
                                      <div
                                        className="refferals-list as-link"
                                        key={'www' + index}>
                                        <div className="payment-card-main">
                                          <div
                                            className="payment-content-card"
                                            style={{ height: '86px' }}
                                            onClick={() => {
                                              handleShowDtail(item);
                                            }}>
                                            <div
                                              className="task-profile-img"
                                              onClick={() => {
                                                handleShowDtail(item);
                                              }}>
                                              <img
                                                src={
                                                  item.user_details &&
                                                    item.user_details.profile_img
                                                    ? item.user_details
                                                      .profile_img
                                                    : DEFAULT_IMAGE
                                                }
                                                alt=""
                                              />
                                            </div>

                                            <div
                                              className="task-content"
                                              onClick={() => {
                                                handleShowDtail(item);
                                              }}>
                                              <h1
                                                style={{
                                                  color: '#000',
                                                  fontFamily: 'Inter',
                                                  fontSize: '15px',
                                                  fontStyle: 'normal',
                                                  fontWeight: '700',
                                                  lineHeight: 'normal',
                                                  marginBottom: '4px',
                                                }}>
                                                {item.user_details.first_name +
                                                  ' ' +
                                                  item.user_details.last_name}
                                              </h1>

                                              <span
                                                style={{
                                                  color: '#000',
                                                  fontFamily: 'Inter',
                                                  fontSize: '15px',
                                                  fontStyle: 'normal',
                                                  fontWeight: '500',
                                                  lineHeight: 'normal',
                                                  marginBottom: '4px',
                                                }}>
                                                {item.user_details
                                                  .current_location
                                                  ? item.user_details
                                                    .current_location
                                                  : ''}
                                              </span>
                                              <div className="payment-card-3rd-text">
                                                <span
                                                  className="project-title"
                                                  style={{
                                                    color: '#707070',
                                                    fontFamily: 'Inter',
                                                    fontSize: '13px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: 'normal',
                                                    marginBottom: '4px',
                                                  }}>
                                                  {/* ETA 45 Min */}

                                                  {item.user_status}
                                                </span>

                                                {/* <div>5:00 PM</div> */}
                                                <div>
                                                  {moment(
                                                    item.start_date,
                                                  ).format('h:mm A')}{' '}
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div
                                            className="payment-service-icons"
                                            onClick={() => {
                                              handleShowDtail(item);
                                            }}>
                                            <div className="roi-service-icons">
                                              <div className="service-icon">
                                                <img
                                                  src={WhitePenIcon}
                                                  alt=""
                                                />
                                              </div>

                                              <div className="service-icon-time">
                                                <img
                                                  className=""
                                                  src={timeIcon}
                                                  alt=""
                                                />
                                                <span>5D</span>
                                              </div>

                                              <div className="service-icon w-25">
                                                <img src={FireIcon} alt="" />
                                              </div>

                                              <div className="service-icon w-29">
                                                <img src={MachineIcon} alt="" />
                                              </div>

                                              <div className="service-icon w-29">
                                                <img src={ArrowIcon} alt="" />
                                              </div>

                                              <div className="service-icon w-29">
                                                <img src={Bandage} alt="" />
                                              </div>

                                              <div className="service-icon w-28">
                                                <img src={heartIcon} alt="" />
                                              </div>

                                              <div className="service-icon w-29">
                                                <img src={bagIcon} alt="" />
                                              </div>
                                              <div className="w-29">
                                                <i
                                                  className="fa-solid fa-ellipsis"
                                                  style={{
                                                    color: '#0a0a0a',
                                                    paddingTop: '15px',
                                                  }}></i>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="payment-card-bottom">
                                            <div className="payment-card-icons">
                                              <div
                                                className="payment-icon"
                                                onClick={() => {
                                                  handleShowDtail(item);
                                                }}>
                                                <i class="fa-solid fa-phone"></i>
                                              </div>

                                              <div
                                                className="payment-icon"
                                                onClick={() => {
                                                  setUserInfo(
                                                    item.user_details,
                                                  );
                                                  checkChatRoom(
                                                    item.user_details.id,
                                                  );
                                                }}>
                                                <i className="fa-solid icon-plane"></i>
                                              </div>
                                            </div>

                                            <div
                                              onClick={() => {
                                                setShowModel('status_model');
                                                statusOfUserAppointment(
                                                  item,
                                                  'to_me',
                                                );
                                              }}>
                                              <Button className="new-btn btn-green ml-3 ">
                                                {item.creator_status}
                                              </Button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))
                                  ) : (
                                    <div className="no-results-wrapper no-appointments mt-5">
                                      <div className="img-bg-wrapper">
                                        <img
                                          src={require('../../Assets/images/norecord/no-appointments.png')}
                                          alt=""
                                        />
                                      </div>
                                      <div className="no-results-content">
                                        <p className="noRecord-title">
                                          {UpAappointment &&
                                            UpAappointment.already_added
                                            ? 'No Appointments Pending'
                                            : 'No Appointments Pending'}
                                        </p>
                                        <p className="noRecord-desc">
                                          {UpAappointment &&
                                            UpAappointment.already_added
                                            ? 'Create an appointment and start engaging'
                                            : 'Create an appointment and start engaging'}
                                        </p>

                                        {checkPermesionByCompanyOwner && (
                                          <div className="btn-wrapper mt-3">
                                            <Button
                                              className={
                                                'btn btn-dark'
                                                // getItem('userRole') === 'guest'
                                                //   ? 'grey-class-btn'
                                                //   : 'btn btn-dark'
                                              }
                                              onClick={() => {
                                                if (
                                                  UpAappointment &&
                                                  UpAappointment.already_added
                                                ) {
                                                  setShowComponent(
                                                    'create-appointment',
                                                  );
                                                } else {
                                                  setShowComponent(
                                                    'addAppointment',
                                                  );
                                                }
                                              }}>
                                              {UpAappointment &&
                                                UpAappointment.already_added
                                                ? 'Schedule Appointments'
                                                : 'Setup Appointments'}
                                            </Button>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}
                            {subTabType === 'appointment' &&
                              selectedFilterValue === 'from_me' && (
                                <div className="mb-3">
                                  {Object.entries(appointmentList).length >
                                    0 ? (
                                    appointmentList.map((item, index) => (
                                      <div
                                        className="refferals-list as-link"
                                        key={'ABSCD' + index}>
                                        <div className="payment-card-main">
                                          <div
                                            className="payment-content-card"
                                            style={{ height: '86px' }}
                                            onClick={() => {
                                              handleShowDtail(item);
                                            }}>
                                            <div
                                              className="task-profile-img"
                                              onClick={() => {
                                                handleShowDtail(item);
                                              }}>
                                              <img
                                                src={
                                                  item.creator_details &&
                                                    item.creator_details
                                                      .profile_img
                                                    ? item.creator_details
                                                      .profile_img
                                                    : DEFAULT_IMAGE
                                                }
                                                alt=""
                                              />
                                            </div>

                                            <div
                                              className="task-content"
                                              onClick={() => {
                                                handleShowDtail(item);
                                              }}>
                                              <h1
                                                style={{
                                                  color: '#000',
                                                  fontFamily: 'Inter',
                                                  fontSize: '15px',
                                                  fontStyle: 'normal',
                                                  fontWeight: '700',
                                                  lineHeight: 'normal',
                                                  marginBottom: '4px',
                                                }}>
                                                {item.creator_details
                                                  .first_name +
                                                  ' ' +
                                                  item.creator_details
                                                    .last_name}
                                              </h1>

                                              <span
                                                style={{
                                                  color: '#000',
                                                  fontFamily: 'Inter',
                                                  fontSize: '15px',
                                                  fontStyle: 'normal',
                                                  fontWeight: '500',
                                                  lineHeight: 'normal',
                                                  marginBottom: '4px',
                                                }}>
                                                {item.creator_details
                                                  .current_location
                                                  ? item.creator_details
                                                    .current_location
                                                  : ''}
                                              </span>
                                              <div className="payment-card-3rd-text">
                                                <span
                                                  className="project-title"
                                                  style={{
                                                    color: '#707070',
                                                    fontFamily: 'Inter',
                                                    fontSize: '13px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: 'normal',
                                                    marginBottom: '4px',
                                                  }}>
                                                  {/* ETA 45 Min */}
                                                  {item.creator_status}
                                                </span>

                                                {/* <div>5:00 PM</div> */}
                                                <div>
                                                  {moment(
                                                    item.start_date,
                                                  ).format('h:mm A')}{' '}
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div
                                            className="payment-service-icons"
                                            onClick={() => {
                                              handleShowDtail(item);
                                            }}>
                                            <div className="roi-service-icons">
                                              <div className="service-icon">
                                                <img
                                                  src={WhitePenIcon}
                                                  alt=""
                                                />
                                              </div>

                                              <div className="service-icon-time">
                                                <img
                                                  className=""
                                                  src={timeIcon}
                                                  alt=""
                                                />
                                                <span>5D</span>
                                              </div>

                                              <div className="service-icon w-25">
                                                <img src={FireIcon} alt="" />
                                              </div>

                                              <div className="service-icon w-29">
                                                <img src={MachineIcon} alt="" />
                                              </div>

                                              <div className="service-icon w-29">
                                                <img src={ArrowIcon} alt="" />
                                              </div>

                                              <div className="service-icon w-29">
                                                <img src={Bandage} alt="" />
                                              </div>

                                              <div className="service-icon w-28">
                                                <img src={heartIcon} alt="" />
                                              </div>

                                              <div className="service-icon w-29">
                                                <img src={bagIcon} alt="" />
                                              </div>
                                              <div className="w-29">
                                                <i
                                                  className="fa-solid fa-ellipsis"
                                                  style={{
                                                    color: '#0a0a0a',
                                                    paddingTop: '15px',
                                                  }}></i>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="payment-card-bottom">
                                            <div className="payment-card-icons">
                                              <div
                                                className="payment-icon"
                                                onClick={() => {
                                                  handleShowDtail(item);
                                                }}>
                                                <i class="fa-solid fa-phone"></i>
                                              </div>

                                              <div
                                                className="payment-icon"
                                                onClick={() => {
                                                  setUserInfo(
                                                    item.user_details,
                                                  );
                                                  checkChatRoom(
                                                    item.user_details.id,
                                                  );
                                                }}>
                                                <i className="fa-solid icon-plane"></i>
                                              </div>
                                            </div>

                                            <div
                                              className="pay-btn calendar-btn"
                                              onClick={() => {
                                                setShowModel('status_model');
                                                statusOfUserAppointment(
                                                  item,
                                                  'from_me',
                                                );
                                              }}>
                                              <Button className="new-btn btn-green ml-3 ">
                                                {item.user_status}
                                              </Button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))
                                  ) : (
                                    <div className="no-results-wrapper no-appointments mt-5">
                                      <div className="img-bg-wrapper">
                                        <img
                                          src={require('../../Assets/images/norecord/no-appointments.png')}
                                          alt=""
                                        />
                                      </div>
                                      <div className="no-results-content">
                                        <p className="noRecord-title">
                                          No Upcoming 1-on-1s
                                        </p>
                                        <p className="noRecord-desc">
                                          You don't have any upcoming
                                          appointments
                                        </p>
                                        {AppointmentPermessions &&
                                          (AppointmentPermessions === 'allow' ||
                                            AppointmentPermessions?.create ===
                                            true) && (
                                            <div className="btn-wrapper mt-3">
                                              <Button
                                                className={
                                                  getItem('userRole') ===
                                                    'guest'
                                                    ? 'grey-class-btn'
                                                    : 'btn btn-dark'
                                                }
                                                onClick={() => {
                                                  setShowComponent(
                                                    'create-appointment',
                                                  );
                                                }}>
                                                Setup Appointment
                                              </Button>
                                            </div>
                                          )}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}
                            {subTabType === 'history' && (
                              <AppoinmentHistoryByType
                                subTabType={selectedFilterValue}
                                setShowComponent={setShowComponent}
                                search={appointmentHistorySearch}
                                Tab={subTabType}
                              />
                            )}
                          </Scrollbars>
                        </div>
                        {/* </div> */}
                      </div>
                    )}
                    {/* </Scrollbars> */}
                    {showFilter && (
                      <CustomModal
                        size="medium"
                        onClose={() => {
                          setShowFilter(false);
                        }}>
                        <div className="filter-wrapper">
                          <div className="filter-category-list">
                            <ul>
                              {Object.entries(filterListArray).length > 0 &&
                                filterListArray.map((item, index) => (
                                  <li key={`sessionC-${index}`}>
                                    <Form.Label>
                                      {item.title}
                                      <input
                                        type="checkbox"
                                        id={item.id}
                                        value={item.value}
                                        checked={value_filter === item.value}
                                        onChange={() =>
                                          handleAlphabetSelect(item.value)
                                        }
                                      />
                                      <span />
                                    </Form.Label>
                                  </li>
                                ))}
                            </ul>
                            <div className="filter-btn-wrapper text-center">
                              <Button
                                type="submit"
                                className="btn-dark"
                                onClick={() => {
                                  onSubmitFilter(value_filter);
                                  setSelectedFilterValue(value_filter);
                                }}>
                                Select
                              </Button>
                            </div>
                          </div>
                        </div>
                      </CustomModal>
                    )}
                    {showModel === 'status_model' && (
                      <CustomModal
                        size="medium"
                        onClose={() => {
                          setShowModel('');
                        }}>
                        <div className="filter-wrapper">
                          <div className="filter-category-list">
                            <ul>
                              {Object.entries(Appointment_Status_List).length >
                                0 &&
                                Appointment_Status_List.map((item, index) => (
                                  <li key={`sessionC-${index}`}>
                                    <Form.Label>
                                      {item.title}
                                      <input
                                        type="checkbox"
                                        id={item.title}
                                        value={item.title}
                                        checked={userApStatus === item.title}
                                        onChange={() =>
                                          handleApStatus(item.title)
                                        }
                                      />
                                      <span />
                                    </Form.Label>
                                  </li>
                                ))}
                            </ul>
                            <div className="filter-btn-wrapper text-center">
                              <Button
                                type="submit"
                                className="btn-dark"
                                onClick={() => {
                                  changeUserMeetingStatus(userApStatus);
                                  setShowModel('');
                                }}>
                                Select
                              </Button>
                            </div>
                          </div>
                        </div>
                      </CustomModal>
                    )}
                  </>
                }
                secondary={
                  <>
                    {/* {showForm && payLoadIs && appointmentDetail && (
                      <CardDetail
                        dataOne={payLoadIs}
                        dataTwo={appointmentDetail}
                        setShowForm={setShowForm}
                        stripePayment={stripePayment}
                      />
                    )} */}

{showForm && payLoadIs && appointmentDetail && (
        <PaymentModal
          price={appointmentDetail?.cost}
          transactionId={payLoadIs?.slot_id}
          onClose={() => {
            setShowForm(false);
          }}
          onCloseHitApi={()=>{
            // createAppointment();
          }}
          transactionType={'appointment'}
        />
      )}
                    {showComponent === 'single_chat' && (
                      <SmartChatPage
                        allmsg={true}
                        type={chatType}
                        item={[]}
                        chatroomInfo={
                          chatDetails.length > 0
                            ? chatroomInfo(chatDetails)
                            : newSingleChatroom(userInfo)
                        }
                        oppoDetails={chatDetails}
                        chatDetails={chatDetails}
                        allChatUsers={allChatUsers}
                        chatRoomName={chatRoomName}
                        chatRoomId={chatRoomId}
                        onClose={() => {
                          setShowComponent('');
                        }}
                        userData={userInfo}
                      // handleCommonChat={(data, type)=>{handleCommonChat(data, type)}}
                      />
                    )}
                    {showComponent === 'addAppointment' && (
                      <EditAppointment
                        onClose={() => setShowComponent('')}
                        refreshConsults={get_consult_by_user_id}
                      />
                    )}
                    {showComponent === 'appointment' && (
                      <Appointments
                        onClose={() => {
                          setShowComponent('');
                        }}
                      />
                    )}
                    {/* {showComponent === 'appointmentHistory' && (
                  <AppoinmentHistoryByType
                    onClose={() => {
                      setShowComponent('');
                    }}
                    subTabType={subTabType}
                  />
                )} */}
                    {showComponent === 'create-appointment' && (
                      <ScheduleAppointmemt
                        getMyAppointmentsByType={getMyAppointmentsByType}
                        subTabType={subTabType}
                        type={selectedFilterValue}
                        onClose={() => {
                          setShowComponent('');
                        }}
                      />
                    )}
                    {showComponent === 'appointmentCall' && appointmentData && (
                      <AppointmentCallDetail
                        onClose={() => {
                          setShowComponent('');
                        }}
                        setShowComponent={setShowComponent}
                        setShowPraise={setShowPraise}
                        showPraise={showPraise}
                        subTabType={selectedFilterValue}
                        appointmentData={appointmentData}
                        getMyAppointmentsByType={getMyAppointmentsByType}
                        appointmentList={appointmentList}
                      />
                    )}

                    {showComponent === 'praiseCaution' &&
                      localStorage.getItem('creator_id') !== 'undefined' &&
                      localStorage.getItem('creator_id') !==
                      localStorage.getItem('id') && (
                        <PraiseCoution
                          setShowComponent={setShowComponent}
                          userData={
                            idForPraiseCaution !== ''
                              ? idForPraiseCaution
                              : {
                                id: localStorage.getItem('creator_id'),
                                profile_img:
                                  localStorage.getItem('profilePic'),
                                first_name: localStorage.getItem('firstName'),
                                last_name: localStorage.getItem('lastName'),
                              }
                          }
                          type={'consult'}
                        />
                      )}

                    {showComponent === 'userAppointment' && (
                      <AppointmentList
                        setShowComponent={setShowComponent}
                        userData={userInfo.data}
                        type={'scheduler'}
                        scheduleId={scheduleId}
                      />
                    )}

                    {/* {buildersUserId && showComponent === 'profileComponent' && (
                  <div className="pb-4 fix-width-forms">
                    <ProfileComponent
                      userId={buildersUserId}
                      show={false}
                      onClose={() => {
                        setShowComponent('');
                      }}
                      // hideCloseButton={true}
                      button={button}
                      userData={[]}
                      moderator={false}
                      // setParticipantType={setParticipantType}
                      VoxeetSDK={[]}
                      conferenceInfo={[]}
                      jdSpecialM={[]}
                      jdSpecialA={[]}
                    />
                  </div>
                )} */}
                  </>
                }
              />
            )}

            {subTabType === 'calendar' && (
              <>
                <div style={{ display: 'flex', flex: 1 }}>
                  <div className="schedule-activities-page primary_panel_content schedule-activities-page-calendar">
                    <div
                      className="d-flex justify-content-between align-items-center mb-2"
                      style={{ maxWidth: '385px' }}>
                      <div className="event-tabs tabs-style-2">
                        <ul>
                          <li>
                            <Button
                              className={
                                subTabType === 'appointment' ? 'active' : ''
                              }
                              onClick={() => {
                                setshowLoader(true);
                                setSubTabsType('appointment');
                                // getMyAppointmentsByType('from_me');
                                setShowComponent('');
                                // setStartDate(new Date());
                              }}>
                              List
                            </Button>
                          </li>

                          <li>
                            <Button
                              className={
                                subTabType === 'calendar' ? 'active' : ''
                              }
                              onClick={() => {
                                setSubTabsType('calendar');
                                // getMyAppointmentsByType('to_me');
                                setShowComponent('');
                              }}>
                              {labels.CALENDAR[selected_lang]}
                            </Button>
                          </li>

                          {/* <li>
                                  <Button
                                    className={
                                      subTabType === 'history' ? 'active' : ''
                                    }
                                    onClick={() => {
                                      setSubTabsType('history');
                                      // getMyAppointmentsByType('to_me');
                                      setShowComponent('');
                                    }}>
                                    History
                                  </Button>
                                </li> */}
                        </ul>
                      </div>

                      {/* <span className="add-icon">
                          <i
                            className="icon-plus"
                            onClick={() => setSecondComponent("group-component")}
                          />
                        </span>
                        <span className="add-icon">
                          <i
                            className="icon-filter-icon"
                            onClick={() => setSearchFilterModal(true)}
                          />
                        </span> */}

                      <div className="search-tabs-icons">
                        <span
                          className={
                            UpAappointment && !UpAappointment.already_added
                              ? 'disabled-Class'
                              : showComponent === 'create-appointment'
                                ? 'icon active'
                                : 'icon'
                          }>
                          {AppointmentPermessions &&
                            (AppointmentPermessions === 'allow' ||
                              AppointmentPermessions?.create === true) && (
                              <i
                                className="icon-plus"
                                onClick={() => {
                                  if (
                                    showComponent ===
                                    'create-appointment-calendar'
                                  ) {
                                    setShowComponent('');
                                  } else {
                                    setShowComponent(
                                      'create-appointment-calendar',
                                    );
                                  }
                                }}
                              />
                            )}
                        </span>

                        <span
                          onClick={() => {
                            setThreeDots(!threeDots);
                          }}
                          className={threeDots ? 'icon active' : 'icon'}>
                          <i className="fas fa-ellipsis-vertical" />
                        </span>
                      </div>
                    </div>
                    {threeDots && (
                      <div
                        className="person-search-wrapper mb-3 mt-2 messages-users-wrapper"
                        style={{ maxWidth: '385px' }}>
                        <div className="appointment-date-wrapper">
                          <div className="date-wrapper">
                            <span
                              onClick={() => {
                                // setStartDate(
                                //   new Date(moment(startDate).add(-1, 'days')),
                                // );

                                handleBackDate();
                              }}
                              className="navs fas fa-chevron-left"
                            />
                            <span
                              onClick={handleClick}
                              className="selected-date as-link">
                              {moment(startDate).format('ll').toString()}
                            </span>
                            <span
                              onClick={() => {
                                // setStartDate(
                                //   new Date(moment(startDate).add(1, 'days')),
                                // );
                                handleNextDate();
                              }}
                              className="navs fas fa-chevron-right"
                            />
                          </div>
                          {isOpen && (
                            <div className="calender-wrapper">
                              <DatePicker
                                selected={startDate}
                                onChange={handleChange}
                                onClickOutside={handleOnClickOutside}
                                inline
                                highlightDates={availableDates}
                                readOnly
                                filterDate={date => {
                                  // Check if the date is included in the availableDates array
                                  return availableDates.some(highlightedDate =>
                                    moment(date).isSame(
                                      moment(highlightedDate),
                                      'day',
                                    ),
                                  );
                                }}
                              // minDate={new Date()}
                              />
                            </div>
                          )}
                        </div>
                        <div className="search-form d-flex">
                          <Form.Group className="search-input">
                            <Form.Control
                              type="text"
                              placeholder="Search"
                              value={appointmentSearch}
                              onChange={e => {
                                setAappointmentSearch(e.target.value);
                                if (
                                  subTabType === 'appointment' ||
                                  subTabType === 'calendar'
                                ) {
                                  getMyAppointmentsByType(
                                    selectedFilterValue,
                                    e.target.value,
                                  );
                                }
                              }}
                            />
                          </Form.Group>
                          <Button>
                            <i
                              className="icon-filter-icon"
                              onClick={() => setShowFilter(true)}
                            />
                          </Button>
                        </div>
                        <div className="category-btns-wrapper category-btns-wrapper-slider">
                          <CustomSlidingButtons
                            items={appointmentTypes}
                            activeButtons={appointmentTypesId}
                            onItemClick={e => {
                              handleClickButtons(e);
                            }}
                            showItems={2}
                            label={'appointment_type'}
                          />
                        </div>
                      </div>
                    )}

                    {appointmentList.length > 0 ? (
                      <div className="refferal-list-wrapper mt-4">
                        <div style={{ maxWidth: '385px' }}>
                          {showLoader && <LoaderSpinner />}
                        </div>
                        <Scrollbars className="" autoHide
                        >
                          <div className="activities-main mt-3">
                            {generateTimeSlots()}
                            {renderEvents()}

                            {showSelectedEvent && (
                              <div
                                className="payment-card-main"
                                style={{
                                  top: selectedEvent.top,
                                  left: selectedEvent.left,
                                  position: 'absolute',
                                  marginLeft: LEFT_MARGIN,
                                  width: '300px'
                                }}
                                ref={slotRef}>
                                <div className='as-link' onClick={() => {
                                  handleShowDtail(selectedEvent);
                                }}>
                                  <div className="payment-content-card">

                                    {selectedFilterValue === 'to_me' && (
                                      <div className="task-profile-img">
                                        <img
                                          src={
                                            selectedEvent.user_details &&
                                              selectedEvent.user_details.profile_img
                                              ? selectedEvent?.user_details
                                                ?.profile_img
                                              : DEFAULT_IMAGE
                                          }
                                          alt=""
                                        />
                                      </div>
                                    )}

                                    {selectedFilterValue === 'from_me' && (
                                      <div className="task-profile-img">
                                        <img
                                          src={
                                            selectedEvent.creator_details &&
                                              selectedEvent.creator_details
                                                .profile_img
                                              ? selectedEvent?.creator_details
                                                ?.profile_img
                                              : DEFAULT_IMAGE
                                          }
                                          alt=""
                                        />
                                      </div>
                                    )}

                                    <div className="task-content">
                                      <h1
                                        style={{
                                          color: '#000',
                                          fontFamily: 'Inter',
                                          fontSize: '15px',
                                          fontStyle: 'normal',
                                          fontWeight: '700',
                                          lineHeight: 'normal',
                                          marginBottom: '4px',
                                        }}>
                                        {selectedFilterValue === 'to_me'
                                          ? selectedEvent?.user_details
                                            ?.first_name +
                                          ' ' +
                                          selectedEvent?.user_details?.last_name
                                          : selectedEvent?.creator_details
                                            ?.first_name +
                                          ' ' +
                                          selectedEvent?.creator_details
                                            ?.last_name}
                                      </h1>

                                      {selectedFilterValue === 'to_me' && (
                                        <span
                                          style={{
                                            color: '#000',
                                            fontFamily: 'Inter',
                                            fontSize: '15px',
                                            fontStyle: 'normal',
                                            fontWeight: '500',
                                            lineHeight: 'normal',
                                            marginBottom: '4px',
                                          }}>
                                          {selectedEvent.user_details &&
                                            selectedEvent.user_details
                                              .current_location
                                            ? selectedEvent.user_details
                                              .current_location
                                            : ''}
                                        </span>
                                      )}

                                      {selectedFilterValue === 'from_me' && (
                                        <span
                                          style={{
                                            color: '#000',
                                            fontFamily: 'Inter',
                                            fontSize: '15px',
                                            fontStyle: 'normal',
                                            fontWeight: '500',
                                            lineHeight: 'normal',
                                            marginBottom: '4px',
                                          }}>
                                          {selectedEvent.creator_details &&
                                            selectedEvent.creator_details
                                              .current_location
                                            ? selectedEvent.creator_details
                                              .current_location
                                            : ''}
                                        </span>
                                      )}
                                      <div className="payment-card-3rd-text">
                                        <span
                                          className="project-title"
                                          style={{
                                            color: '#707070',
                                            fontFamily: 'Inter',
                                            fontSize: '13px',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            lineHeight: 'normal',
                                            marginBottom: '4px',
                                          }}>
                                          {/* ETA 45 Min */}
                                        </span>

                                        <div>
                                          {moment(selectedEvent?.start).format(
                                            'h:mm A',
                                          )}{' '}
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="payment-service-icons">
                                    <div className="roi-service-icons">
                                      <div className="service-icon">
                                        <img src={WhitePenIcon} alt="" />
                                      </div>

                                      <div className="service-icon-time">
                                        <img className="" src={timeIcon} alt="" />
                                        <span>5D</span>
                                      </div>

                                      <div className="service-icon w-25">
                                        <img src={FireIcon} alt="" />
                                      </div>

                                      <div className="service-icon w-29">
                                        <img src={MachineIcon} alt="" />
                                      </div>

                                      <div className="service-icon w-29">
                                        <img src={ArrowIcon} alt="" />
                                      </div>

                                      <div className="service-icon w-29">
                                        <img src={Bandage} alt="" />
                                      </div>

                                      <div className="service-icon w-28">
                                        <img src={heartIcon} alt="" />
                                      </div>

                                      <div className="service-icon w-29">
                                        <img src={bagIcon} alt="" />
                                      </div>
                                      <div className="w-29">
                                        <i
                                          className="fa-solid fa-ellipsis"
                                          style={{
                                            color: '#0a0a0a',
                                            paddingTop: '15px',
                                          }}></i>
                                      </div>
                                    </div>
                                  </div>

                                </div>

                                <div className="payment-card-bottom">
                                  <div className="payment-card-icons">
                                    <div className="payment-icon">
                                      <i class="fa-solid fa-phone"></i>
                                    </div>

                                    <div className="payment-icon as-link" onClick={() => {
                                      setUserInfo(
                                        selectedEvent.user_details,
                                      );
                                      checkChatRoom(
                                        selectedEvent.user_details.id,
                                      );
                                    }}>
                                      <i className="fa-solid icon-plane"></i>
                                    </div>
                                  </div>

                                  <div className="pay-btn calendar-btn">
                                    <Button
                                      className="new-btn btn-green ml-3 "
                                      onClick={() => {
                                        setShowModel('calendar_status_model');
                                        statusOfUserAppointment(
                                          selectedEvent,
                                          selectedEvent.created_by ===
                                            localStorage.getItem('id')
                                            ? 'to_me'
                                            : 'from_me',
                                        );
                                      }}>
                                      {selectedEvent.created_by ===
                                        localStorage.getItem('id') &&
                                        selectedEvent.creator_status}
                                      {selectedEvent.created_by !==
                                        localStorage.getItem('id') &&
                                        selectedEvent.user_status}
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </Scrollbars>
                      </div>
                    ) : (
                      <div
                        style={{ maxWidth: '379px', marginTop: '33px' }}
                        className="no-results-wrapper no-appointments " >
                        <div className="img-bg-wrapper">
                          <img
                            src={require('../../Assets/images/norecord/no-appointments.png')}
                            alt=""
                          />
                        </div>
                        <div className="no-results-content">
                          <p className="noRecord-title">
                            {UpAappointment && UpAappointment.already_added
                              ? 'No Appointments Pending'
                              : 'No Appointments Pending'}
                          </p>
                          <p className="noRecord-desc">
                            {UpAappointment && UpAappointment.already_added
                              ? 'Create an appointment and start engaging'
                              : 'Create an appointment and start engaging'}
                          </p>

                          <div className="btn-wrapper mt-3">
                            <Button
                              className={
                                'btn btn-dark'
                                // getItem('userRole') === 'guest'
                                //   ? 'grey-class-btn'
                                //   : 'btn btn-dark'
                              }
                              onClick={() => {
                                if (
                                  UpAappointment &&
                                  UpAappointment.already_added
                                ) {
                                  setShowComponent('create-appointment-calendar');
                                } else {
                                  setShowComponent(
                                    'addAppointment_in_calenadar',
                                  );
                                }
                              }}>
                              {UpAappointment && UpAappointment.already_added
                                ? 'Schedule Appointments'
                                : 'Setup Appointments'}
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {showFilter && (
                  <CustomModal
                    size="medium"
                    onClose={() => {
                      setShowFilter(false);
                    }}>
                    <div className="filter-wrapper">
                      <div className="filter-category-list">
                        <ul>
                          {Object.entries(filterListArray).length > 0 &&
                            filterListArray.map((item, index) => (
                              <li key={`sessionC-${index}`}>
                                <Form.Label>
                                  {item.title}
                                  <input
                                    type="checkbox"
                                    id={item.id}
                                    value={item.value}
                                    checked={value_filter === item.value}
                                    onChange={() =>
                                      handleAlphabetSelect(item.value)
                                    }
                                  />
                                  <span />
                                </Form.Label>
                              </li>
                            ))}
                        </ul>
                        <div className="filter-btn-wrapper text-center">
                          <Button
                            type="submit"
                            className="btn-dark"
                            onClick={() => {
                              onSubmitFilter(value_filter);
                              setSelectedFilterValue(value_filter);
                            }}>
                            Select
                          </Button>
                        </div>
                      </div>
                    </div>
                  </CustomModal>
                )}
              </>
            )}

            {showModel === 'calendar_status_model' && (
              <CustomModal
                size="medium"
                onClose={() => {
                  setShowModel('');
                }}>
                <div className="filter-wrapper">
                  <div className="filter-category-list">
                    <ul>
                      {Object.entries(Appointment_Status_List).length > 0 &&
                        Appointment_Status_List.map((item, index) => (
                          <li key={`sessionC-${index}`}>
                            <Form.Label>
                              {item.title}
                              <input
                                type="checkbox"
                                id={item.title}
                                value={item.title}
                                checked={userApStatus === item.title}
                                onChange={() => handleApStatus(item.title)}
                              />
                              <span />
                            </Form.Label>
                          </li>
                        ))}
                    </ul>
                    <div className="filter-btn-wrapper text-center">
                      <Button
                        type="submit"
                        className="btn-dark"
                        onClick={() => {
                          changeUserMeetingStatus(userApStatus);
                          setShowModel('');
                        }}>
                        Select
                      </Button>
                    </div>
                  </div>
                </div>
              </CustomModal>
            )}

            {showComponent === 'create-appointment-calendar' && (
              <ScheduleAppointmemt
                getMyAppointmentsByType={getMyAppointmentsByType}
                subTabType={subTabType}
                type={selectedFilterValue}
                onClose={() => {
                  setShowComponent('');
                }}
              />
            )}

            {showComponent === 'addAppointment_in_calenadar' && (
              <EditAppointment
                onClose={() => setShowComponent('')}
                refreshConsults={get_consult_by_user_id}
              />
            )}
            {showComponent === 'WorkSpace' && (
              <CreateWorkSpace
                onClose={() => {
                  setShowComponent('');
                }}
              />
            )}

            {showComponent === 'single_chat_calendar' && (
              <SmartChatPage
                allmsg={true}
                type={chatType}
                item={[]}
                chatroomInfo={
                  chatDetails.length > 0
                    ? chatroomInfo(chatDetails)
                    : newSingleChatroom(userInfo)
                }
                oppoDetails={chatDetails}
                chatDetails={chatDetails}
                allChatUsers={allChatUsers}
                chatRoomName={chatRoomName}
                chatRoomId={chatRoomId}
                onClose={() => {
                  setShowComponent('');
                }}
                userData={userInfo}
              // handleCommonChat={(data, type)=>{handleCommonChat(data, type)}}
              />
            )}

            {showComponent === 'appointmentCall_calendar' && appointmentData && (
              <AppointmentCallDetail
                onClose={() => {
                  setShowComponent('');
                }}
                setShowComponent={setShowComponent}
                setShowPraise={setShowPraise}
                showPraise={showPraise}
                subTabType={selectedFilterValue}
                appointmentData={appointmentData}
                getMyAppointmentsByType={getMyAppointmentsByType}
                appointmentList={appointmentList}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default AppointmentPage;
