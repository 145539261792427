import React, {useState, useEffect, useRef} from 'react';
import {Button, Col, Form, Row, Container} from 'react-bootstrap';
import {labels} from '../../Constants/Translations';
import {selected_lang} from '../../Utils/env';
import moment from 'moment/moment';
import commonApi from '../../Services/CommonService';
import {successMessage, errorMessage} from '../../Utils/ToastMessages';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import {geocodeByPlaceId} from 'react-google-places-autocomplete';
import {getLatLng} from 'react-google-places-autocomplete';
import $ from 'jquery';
import SlidingPanel from '../SlidingPanel';
import LoaderSpinner from '../Modals/LoaderSpinner';
import Scrollbars from 'react-custom-scrollbars';
import CustomModal from '../CustomModal';
import NewCustomTabs from '../NewCustomTabs';
import {DEFAULT_IMAGE} from '../../Utils/env';
import {AddHttps, fixName} from '../../Utils/commonUtils';
import SelectedUsers from '../../Pages/Front/StaticPages/SelectedUsers';
import CircleListBuilders from '../../Pages/Front/StaticPages/CircleListBuilders';
import {getDomainFromUrl} from '../../Utils/commonUtils';
import {list} from '../../Utils/commonUtils';
import Invite from '../SingleComponent/Invite';
import SendInvitation from '../../Pages/Front/StaticPages/SendInvitation';
import CropImage from '../../Components/Cropper/CropImage';
import PersonalCreateModel from '../Modals/PersonalCreateModel';
import {useDispatch, useSelector} from 'react-redux';
import {get_my_all_associate_workspaces} from '../../Store/Actions/SessionAction';
import AlertModel from '../Modals/AlertModel';
import ImagePreview from '../Modals/ImagePreview';
import {capitalizeFirst} from '../../Utils/commonUtils';
import CustomDropdown from '../CustomDropdown';

import { RoiLogo } from '../../Constants/Images';
const AddNewSnapShot = props => {

  const [disableBtn, setDisableBtn] = useState(false)


  const [date, setDate] = useState('');
  const [location, setLocation] = useState('');
  const [topIcons, setTopIcons] = useState({
    location: false,
    personal: false,
  });
  const [validated, setValidated] = useState(false);
  const [title, setTitle] = useState('');
  const [category, setCategory] = useState('');
  const [showLoader, setshowLoader] = useState(true);
  const [discription, setDiscription] = useState('');
  const [ticketImg, setTicketImg] = useState('');
  const [cat, setCat] = useState('');
  const [website, setWebsite] = useState('');
  const [imagePath, setImagePath] = useState([]);
  const [imageArr, setImageArr] = useState([]);
  const [show, setShow] = useState(false);
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [showError, setShowError] = useState(false);
  const [showModalButtons, setShowModalButtons] = useState(false);
  const [showModelDecesion, setShowModelDecesion] = useState('');
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [hideShowSuggestion, setHideShowSuggestion] = useState(false);
  const buttonRef = useRef(null);
  const allowedImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];

  const [tabType, setTabType] = useState('general');
  const [showComponent, setShowComponent] = useState('');
  const [subTabs, setSubTabs] = useState('Leadership');

  const [leadership, setLeadership] = useState([]);
  const [members, setMembers] = useState([]);
  const [partners, setPartners] = useState([]);
  const [selectedpartners, setSelectedPartners] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [selectedLeadership, setSelectedLeadership] = useState([]);
  const [investing, setInvesting] = useState(false);
  const [clearAllModal, setclearAllModal] = useState(false);

  const [apiResponse, setApiResponse] = useState([]);
  const [modelImg, setModelImg] = useState('');

  const [rollId, setRollId] = useState([]);
  const [industryId, setIndustryId] = useState([]);
  const [stageId, setStageId] = useState([]);
  const [fundingStageId, setFundingStageId] = useState([]);
  const [desiredRiseId, setDesiredRiseId] = useState([]);
  const [annualRevenueId, setAnnualRevenueId] = useState('');
  const [totalCustomersId, setTotalCustomersId] = useState('');
  const [investmentId, setInvestmentId] = useState('');
  const [association, setAssociation] = useState('');
  const [userRoll, setUserRoll] = useState([]);
  const [userAssociation, setUserAssociation] = useState([]);

  const [filterAssociations, setFilterAssociations] = useState([]);
  const [filterRoll, setFilterRoll] = useState([]);

  const [invitesAssociation, setInvitesAssociation] = useState([]);
  const [invitesAssociationId, setInvitesAssociationId] = useState('');

  const [invitesRoll, setInvitesRoll] = useState([]);
  const [invitesRollId, setInvitesRollId] = useState('');

  const [foundedAmountId, setFoundedAmountId] = useState('');

  const [currentInviteUser, setCurrentInviteUser] = useState(null);
  const [objectOfInviteUser, setObjectOfInviteUser] = useState([]);

  const [acceptedUsersIds, setAcceptedUsersIds] = useState([]);

  const [pendingUsers, setPendingUsers] = useState([]);
  const [pendingUsersIds, setPendingUsersIds] = useState([]);
  const [removePendigUsers, setRemovePendigUsers] = useState([]);
  const [removePendingUserModel, setRemovePendingUserModel] = useState(false);
  const [idForRemovePendingUser, setIdForRemovePendingUser] = useState('');

  const [showEmailModel, setShowEmailModel] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [idsOfCircle, setIdsOfCircle] = useState([]);
  const [modelOfCircle, setModelOfCircle] = useState(false);

  const [searchQryPending, setSearchQryPending] = useState([]);

  const [showTraction, setShowTraction] = useState(false);
  const [activeInvesting, setActiveInvesting] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [stageValues, setStageValues] = useState([]);
  const [fundingValues, setFundingValues] = useState([]);
  const [desiredRiseValues, setDesiredRiseValues] = useState([]);

  const [selectedModelName, setSetselectedModelNamewModel] = useState('');
  const [showModel, setShowModel] = useState(false);
  const [portfolioSizeId, setPortfolioSizeId] = useState('');
  const [availableCapitalId, setAvailableCapitalId] = useState('');
  const [averageInvestmentId, setAverageInvestmentId] = useState('');
  const [recipientFundingId, setRecipientFundingId] = useState('');
  const [desiredRoundId, setDesiredRoundId] = useState('');
  const [contact, setContact] = useState([]);
  const [originalDataOfLeadership, setOriginalDataOfLeadership] = useState([]);
  const [originalDataOfMember, setOriginalDataOfMember] = useState([]);
  const [originalDataOfPartner, setOriginalDataOfPartner] = useState([]);

  const [workSpaceIds, setWorkSpaceIds] = useState('');

  const [showSelectedUsers, setShowSelectedUsers] = useState('');

  const [cropImg, setCropImg] = useState(false);
  const [file, setFile] = useState(null);
  const [imgUrl, setImgUrl] = useState();

  const [showModelCompoenet, setShowModelCompoenet] = useState('');
  const [indexOfImage, setIndexOfImage] = useState('');

  const [inviteLoader, setInviteLoader] = useState(true);
  const [inviteFollowers, setInviteFollowers] = useState([]);
  const [followersList, setFollowersList] = useState([]);
  const [inviteQry, setInviteQry] = useState('');

  const listWorspacerator = useSelector(
    state => state.hitAssociateWorkSpace.data,
  );
  const personal = useSelector(state => state.hitAssociateWorkSpace.personal);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      get_my_all_associate_workspaces({token: localStorage.getItem('token')}),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (workSpaceIds.length > 0) {
    } else {
      // Filter the workspaces with status "active" from the API response
      const userExist = listWorspacerator.filter(
        workspace => workspace.status === 'active',
      );

      // Extract the 'id' values from 'userExist'
      const updatedIds = userExist.map(workspace => workspace.id);

      if (personal === 'yes') {
        updatedIds.push('personal'); // Add 'personal' to the ids array if needed
      }

      // setActiveWorkspacesId(updatedIds);
      setWorkSpaceIds(updatedIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listWorspacerator]);

  const handleShowModel = type => {
    setSetselectedModelNamewModel(type);
    setShowModel(true);
  };
  const handleForPutIdsInArray = (e, type) => {
    const {id, value, checked} = e.target;
    if (type === 'stage') {
      if (!checked) {
        setStageId(stageId.filter(item => item !== id));
        setStageValues(stageValues.filter(item => item !== value));
      } else if (
        (checked && stageValues.length > 0 && industryId === '28') ||
        industryId === '1'
      ) {
        setStageId([...stageId, id]);
        setStageValues([...stageValues, value]);
      } else if (checked && stageValues.length === 0) {
        setStageId([...stageId, id]);
        setStageValues([...stageValues, value]);
      } else {
        errorMessage('Please select one');
      }
    }
    if (type === 'desired_raise') {
      if (!checked) {
        setDesiredRiseId(desiredRiseId.filter(item => item !== id));
        setDesiredRiseValues(desiredRiseValues.filter(item => item !== value));
      } else if (
        (checked && desiredRiseValues.length > 0 && industryId === '28') ||
        industryId === '1'
      ) {
        setDesiredRiseId([...desiredRiseId, id]);
        setDesiredRiseValues([...desiredRiseValues, value]);
      } else if (checked && desiredRiseValues.length === 0) {
        setDesiredRiseId([...desiredRiseId, id]);
        setDesiredRiseValues([...desiredRiseValues, value]);
      } else {
        errorMessage('Please select one');
      }
    }
    if (type === 'funding_stage') {
      if (!checked) {
        setFundingStageId(fundingStageId.filter(item => item !== id));
        setFundingValues(fundingValues.filter(item => item !== value));
      } else if (
        (checked && fundingValues.length > 0 && industryId === '28') ||
        industryId === '1'
      ) {
        setFundingStageId([...fundingStageId, id]);
        setFundingValues([...fundingValues, value]);
      } else if (checked && fundingValues.length === 0) {
        setFundingStageId([...fundingStageId, id]);
        setFundingValues([...fundingValues, value]);
      } else {
        errorMessage('Please select one');
      }
    }
  };
  const getSuggestions = (qry, category) => {
    const body = {
      name: qry,
      type:
        category === '1' || category === '7'
          ? 'business_employment'
          : category === '5'
          ? 'education'
          : '',
    };
    commonApi
      .get_suggestions(body)
      .then(res => {
        if (res.status === 200) {
          setFilteredSuggestions(res.data);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const getAccomplishmentCategory = () => {
    commonApi
      .get_accomplishment_category()
      .then(res => {
        if (res.status === 200) {
          setCat(res.accomplishment_categories);
          setCategory(res.accomplishment_categories[0].id);
          setUserAssociation(res.accomplishment_sub_category);
          setUserRoll(res.accomplishment_role);
          setApiResponse(res);

          setPortfolioSizeId(res.portfolio_size[0].id);
          setAvailableCapitalId(res.available_capital[0].id);
          setAverageInvestmentId(res.average_investment[0].id);
          setRecipientFundingId(res.recipient_funding_stage[0].id);
          setDesiredRoundId(res.desired_round_raise[0].id);

          setFoundedAmountId(res.funded_amount[0].id);
          setAnnualRevenueId(res.annual_revenue[0].id);

          setTotalCustomersId(res.total_customers[0].id);
          setInvestmentId(res.investment_size[0].id);
          if (props.snapShotData) {
            if (props.snapShotData.invited_users.length > 0) {
              const members = props.snapShotData.invited_users.filter(
                item =>
                  item.accomplishment_sub_category_title === 'Member' &&
                  item.status === 'accepted',
              );

              const partners = props.snapShotData.invited_users.filter(
                item =>
                  item.accomplishment_sub_category_title === 'Partner' &&
                  item.status === 'accepted',
              );

              const leaders = props.snapShotData.invited_users.filter(
                item =>
                  item.accomplishment_sub_category_title === 'Leadership' &&
                  item.status === 'accepted',
              );
              const pending = props.snapShotData.invited_users.filter(
                item => item.status === 'pending',
              );

              if (pending.length > 0) {
                pending.forEach(leader => {
                  setPendingUsersIds(previous => [...previous, leader.id]);
                  setPendingUsers(previous => [...previous, leader]);
                  setSearchQryPending(previous => [...previous, leader]);
                });
              }

              if (leaders.length > 0) {
                leaders.forEach(leader => {
                  setAcceptedUsersIds(previous => [...previous, leader.id]);
                  setLeadership(previous => [...previous, leader.id]);
                  setSelectedLeadership(previous => [...previous, leader]);
                });
              }
              if (members.length > 0) {
                members.forEach(leader => {
                  setAcceptedUsersIds(previous => [...previous, leader.id]);
                  setMembers(previous => [...previous, leader.id]);
                  setSelectedMembers(previous => [...previous, leader]);
                });
              }
              if (partners.length > 0) {
                partners.forEach(leader => {
                  setAcceptedUsersIds(previous => [...previous, leader.id]);
                  setPartners(previous => [...previous, leader.id]);
                  setSelectedPartners(previous => [...previous, leader]);
                });
              }
            }
            if (props.snapShotData.invited_users.email) {
              setUserEmail(props.snapShotData.invited_users.email);
            }

            const stageArray = props.snapShotData.stage.split(',');
            const updatedTitles = res.stage
              .filter(item => stageArray.includes(item.id.toString()))
              .map(item => item.title);
            const fundingAmountArray =
              props.snapShotData.funded_stage.split(',');
            const fundedAmountTitles = res.funded_stage
              .filter(item => fundingAmountArray.includes(item.id.toString()))
              .map(item => item.title);

            const desiredRaiseArray =
              props.snapShotData.desired_funds.split(',');
            const desiredRaiseTitles = res.desired_funds
              .filter(item => desiredRaiseArray.includes(item.id.toString()))
              .map(item => item.title);

            setStageValues(updatedTitles);
            setStageId(stageArray);

            setFundingStageId(fundingAmountArray);
            setFundingValues(fundedAmountTitles);

            setDesiredRiseId(desiredRaiseArray);
            setDesiredRiseValues(desiredRaiseTitles);

            setFoundedAmountId(props.snapShotData.funded_amount);
            setAnnualRevenueId(props.snapShotData.annual_revenue);

            setTotalCustomersId(props.snapShotData.total_customers);
            setInvestmentId(props.snapShotData.investment_size);

            setInvesting(
              props.snapShotData.seeking_funding === 'yes' ? true : false,
            );
            setActiveInvesting(
              props.snapShotData.actively_investing === 'yes' ? true : false,
            );

            setPortfolioSizeId(props.snapShotData.portfolio_size);
            setAvailableCapitalId(props.snapShotData.available_capital);
            setAverageInvestmentId(props.snapShotData.average_investment);
            setRecipientFundingId(props.snapShotData.recipient_funding_stage);
            setDesiredRoundId(props.snapShotData.desired_round_raise);

            setFilterAssociations(
              res.accomplishment_sub_category.filter(
                association =>
                  association.accomplishment_category_id ===
                  props.snapShotData.accomplishment_category_id,
              ),
            );
            setAssociation(props.snapShotData.association);

            setFilterRoll(
              res.accomplishment_role.filter(
                obj =>
                  obj.accomplishment_category_id ===
                    props.snapShotData.accomplishment_category_id &&
                  obj.accomplishment_sub_category_id ===
                    props.snapShotData.association,
              ),
            );

            setInvitesRoll(
              res.accomplishment_role.filter(
                association =>
                  association.accomplishment_category_id ===
                  res.accomplishment_categories[0].id,
              ),
            );
            setRollId(props.snapShotData.role);

            setIndustryId(props.snapShotData.industry);

            setInvitesAssociation(
              res.accomplishment_sub_category.filter(
                association =>
                  association.accomplishment_category_id ===
                  res.accomplishment_categories[0].id,
              ),
            );
            setInvitesRollId(res.accomplishment_role[0].id);
            setInvitesRoll(
              res.accomplishment_role.filter(
                association =>
                  association.accomplishment_category_id ===
                  res.accomplishment_categories[0].id,
              ),
            );
            setInvitesAssociationId(res.accomplishment_sub_category[0].id);
          } else {
            const geetingAssociation = res.accomplishment_sub_category.filter(
              association =>
                association.accomplishment_category_id ===
                res.accomplishment_categories[0].id,
            );
            const geetingRoll = res.accomplishment_role.filter(
              obj =>
                obj.accomplishment_category_id ===
                  res.accomplishment_categories[0].id &&
                obj.accomplishment_sub_category_id === '1',
            );

            setFilterAssociations(geetingAssociation);

            setAssociation(geetingAssociation[0].id);
            setInvitesAssociation(geetingAssociation);
            setInvitesRollId(geetingRoll[0].id);
            setInvitesRoll(geetingRoll);

            setInvitesAssociationId(geetingAssociation[0].id);

            setFilterRoll(geetingRoll);
            setRollId(geetingRoll[0].id);

            setIndustryId(res.on_boarding_categories[0].id);
          }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const handleAccomplishmentChange = event => {
    setObjectOfInviteUser([]);
    setSelectedLeadership([]);
    setLeadership([]);

    setCategory(event);
    const filteredAssociations =
      userAssociation &&
      userAssociation.length > 0 &&
      userAssociation.filter(
        association => association.accomplishment_category_id === event,
      );
    if (filteredAssociations.length > 0) {
      setFilterAssociations(filteredAssociations);
      setAssociation(filteredAssociations[0].id);
    } else {
      setFilterAssociations([]);
      setAssociation('');
    }
  };

  const handleAssociationChange = event => {
    setAssociation(event);

    const filteredRoll =
      userRoll &&
      userRoll.length > 0 &&
      userRoll.filter(
        roll =>
          roll.accomplishment_category_id === category &&
          roll.accomplishment_sub_category_id === event,
      );
    if (filteredRoll.length > 0) {
      setFilterRoll(filteredRoll);
      setRollId(filteredRoll[0].id);
    } else {
      setFilterRoll([]);
      setRollId('');
    }
  };
  const handleInvitesAssociation = event => {
    // console.log(event, 'snapshotid')
    setInvitesAssociationId(event);
    const filteredUsersRoll =
      userRoll &&
      userRoll.length > 0 &&
      userRoll.filter(
        roll =>
          roll.accomplishment_category_id === category &&
          roll.accomplishment_sub_category_id === event,
      );
    if (filteredUsersRoll.length > 0) {
      // setFilterRoll(filteredRoll);
      // setRollId(filteredRoll[0].id);
      setInvitesRoll(filteredUsersRoll);
      setInvitesRollId(filteredUsersRoll[0].id);
    } else {
      setInvitesRoll([]);
      setInvitesRollId('');
    }
  };

  const changeTicketImg = e => {
    if (e.target.files.length === 0) return false;
    const file = e.target.files[0];

    // If no image selected, return
    if (!/^image\//.test(file.type)) {
      errorMessage(`File is not an image.`);
      return false;
    } else if (allowedImageTypes.includes(file.type)) {
      setFile(file);
      setCropImg(true);
      setShowModel('modal');

      e.target.value = null;

      // const filePath = e.target.files[0];
      // const objectUrl = URL.createObjectURL(filePath);
      // let reader = new FileReader();
      // reader.readAsDataURL(filePath);
      // reader.onload = e => {
      //   uploadAccomplishmentImage(e.target.result);
      // };

      // setTicketImg(objectUrl);
    } else {
      errorMessage('The file format is not supported');
      e.target.value = null; // reset the file input
    }
  };
  const editAccomplishment = (lat, lng) => {

    setDisableBtn(false)


    const websiteUrl = urlPattern(website, '');
    const emailUrl = urlPattern('', website);

    var adress =
      typeof location === 'object'
        ? location.label
        : typeof location === 'string' && location !== ''
        ? location
        : '';
    var payload = {
      accomplishment_id: props.id,
      accomplishment_category_id: category,
      date: date === '0000-00-00' ? '' : date,
      description: discription,
      images: imageArr,
      latitude:
        typeof location === 'object'
          ? lat
          : typeof location === 'string' && location !== ''
          ? latitude
          : '',
      location: adress,
      longitude:
        typeof location === 'object'
          ? lng
          : typeof location === 'string' && location !== ''
          ? longitude
          : '',

      title: title,
      website_url: websiteUrl,
      stage: category === '1' && showTraction ? stageId : [],
      funded_stage: category === '1' && showTraction ? fundingStageId : [],
      funded_amount: category === '1' && showTraction ? foundedAmountId : '',
      annual_revenue: category === '1' && showTraction ? annualRevenueId : '',
      desired_funds: category === '1' && showTraction ? desiredRiseId : [],
      interested_stages: '',
      available_amount: '',
      prospect_minimums_revenue: '',
      total_customers: category === '1' && showTraction ? totalCustomersId : '',
      investment_size:
        category === '1' && showTraction && investing ? investmentId : '',
      industry: [industryId],
      association: association,
      role: rollId,
      invited_users: objectOfInviteUser,
      accomplishment_type:
        investing && category === '1' && showTraction
          ? 'actively_investing '
          : !investing && category === '1' && showTraction
          ? 'seeking_funding'
          : '',
      portfolio_size: category === '1' && showTraction ? portfolioSizeId : '',
      available_capital:
        category === '1' && showTraction ? availableCapitalId : '',
      average_investment:
        category === '1' && showTraction ? averageInvestmentId : '',
      recipient_funding_stage:
        category === '1' && showTraction ? recipientFundingId : '',
      desired_round_raise:
        category === '1' && showTraction ? desiredRoundId : '',
      seeking_funding:
        category === '1' && showTraction && investing ? 'yes' : 'no',
      actively_investing:
        category === '1' && showTraction && activeInvesting ? 'yes' : 'no',
      remove_pending_users: removePendigUsers,
      email: userEmail ? userEmail + '@' + emailUrl : '',

      status: association === '1' ? 'inactive' : 'active',
      traction: showTraction,
      workspace_ids: workSpaceIds.toString(','),
    };

    commonApi
      .add_accomplishment(payload)
      .then(res => {
        if (res.status === 200) {
          setDisableBtn(false)
          successMessage(res.message);

          props.onClose();
        }else{
          errorMessage(res.message)
          setDisableBtn(true)
        }
      })
      .catch(err => {
        console.log('Err', err);
        setDisableBtn(true)
        return {type: 'error', message: err.message};
      });
  };

  const handleChange = e => {
    const value = e.target.value;
    const sanitizedValue = value.replace(/[^a-zA-Z0-9]+/g, '');

    setUserEmail(sanitizedValue);
  };
  // const handleKeyDown = (e) => {
  //   if (e.key === ' ' || e.key === '@') {
  //     e.preventDefault();
  //   }
  // };



  const urlPattern = (input, url)=>{

    if(input){
    const addUrlFormat = AddHttps(input)
    getDomainFromUrl(addUrlFormat)
    console.log(addUrlFormat, 'addUrlFormat')
    return addUrlFormat

  }else{
    const addUrlFormat = AddHttps(url)
    const domain = getDomainFromUrl(addUrlFormat)
    console.log(domain, 'domaindomain')
    return domain
  }
  }


  const addAccomplishment = (lat, lng) => {

    setDisableBtn(false)

    const websiteUrl = urlPattern(website, '')
    const emailUrl = urlPattern('', website)

    setshowLoader(true);
    var adress = location ? location.label : '';

    var payload = {
      accomplishment_category_id: category,
      date: date === '0000-00-00' ? '' : date,
      description: discription,
      images: imageArr,
      latitude: lat,
      location: adress,
      longitude: lng,
      title: title,
      website_url: websiteUrl,
      stage: category === '1' && showTraction ? stageId : [],
      funded_stage: category === '1' && showTraction ? fundingStageId : [],
      funded_amount: category === '1' && showTraction ? foundedAmountId : '',
      annual_revenue: category === '1' && showTraction ? annualRevenueId : '',
      desired_funds: category === '1' && showTraction ? desiredRiseId : [],
      interested_stages: '',
      available_amount: '',
      prospect_minimums_revenue: '',
      total_customers: category === '1' && showTraction ? totalCustomersId : '',
      investment_size:
        category === '1' && showTraction && investing ? investmentId : '',
      industry: [industryId],
      association: association,
      role: rollId,
      invited_users: objectOfInviteUser,
      accomplishment_type:
        investing && category === '1'
          ? 'actively_investing '
          : !investing && category === '1'
          ? 'seeking_funding'
          : '',
      portfolio_size: category === '1' && showTraction ? portfolioSizeId : '',
      available_capital:
        category === '1' && showTraction ? availableCapitalId : '',
      average_investment:
        category === '1' && showTraction ? averageInvestmentId : '',
      recipient_funding_stage:
        category === '1' && showTraction ? recipientFundingId : '',
      desired_round_raise:
        category === '1' && showTraction ? desiredRoundId : '',
      seeking_funding:
        category === '1' && showTraction && investing ? 'yes' : 'no',
      actively_investing:
        category === '1' && showTraction && activeInvesting ? 'yes' : 'no',

      email: userEmail ? userEmail + '@' + emailUrl : '',

      status: association === '1' ? 'inactive' : 'active',
      traction: showTraction,
      workspace_ids: workSpaceIds.toString(','),
    };

    commonApi
      .add_accomplishment(payload)
      .then(res => {
        if (res.status === 200) {

          if (props.type === 'AddSnapShot') {
            props.setimage(res);
            props.setTitle(res.title);
            props.setSnapShotId(res.accomplishment_id);
            props.setHideAddSnapshot(true);
          }
          successMessage(res.message);
          setDisableBtn(false)
          props.onClose();
          setshowLoader(false);
        }else{
          errorMessage(res.message)
          setDisableBtn(true)
        }
      })
      .catch(err => {
        setDisableBtn(true)
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  // const uploadAccomplishmentImage = img => {
  //   setshowLoader(true);
  //   var bodyFormData = new FormData();
  //   bodyFormData.append(
  //     'images',
  //     img.replace(/^data:image\/[a-z]+;base64,/, ''),
  //   );

  //   commonApi
  //     .upload_accomplishment_image(bodyFormData)
  //     .then(res => {
  //       if (res.status === 200) {
  //         setImagePath(res.image);
  //       }
  //     })
  //     .catch(err => {
  //       console.log('Err', err);
  //       return {type: 'error', message: err.message};
  //     });
  // };

  useEffect(() => {
    setTimeout(() => {
      imageIsusue();
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imagePath]);
  // useEffect(() => {
  //   setInvesting(false);
  //   setStageValues([]);
  //   setFundingValues([]);
  //   setDesiredRiseValues([]);
  //   setFoundedAmountId('');
  //   setAnnualRevenueId('');
  //   setTotalCustomersId('');
  //   setInvestmentId('');
  // }, [category]);

  useEffect(() => {
    setshowLoader(true);
    getAccomplishmentCategory();

    if (props.id) {
      setTimeout(() => {
        detailAccomplishment();
      }, 1200);
    } else {
      setTimeout(() => {
        setshowLoader(false);
      }, 1500);
    }
    // get_sub_category();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let jugar =
    typeof location === 'object'
      ? location.label
      : typeof location === 'string' && location !== ''
      ? location
      : 'Type city and state';

  const imageIsusue = () => {
    setshowLoader(false);

    imagePath.forEach(item => {
      setImageArr(current => [...current, item.image]);
    });
  };

  const handle = event => {
   
    const form = event.currentTarget;
    if (
      form.checkValidity() === false ||
      (location === '' && topIcons.location)
    ) {
      event.preventDefault();
      event.stopPropagation();
      // setLocation("")
      // {
      // setShowError(true);
      // setDesiredRaiseError(true);
      // setFundedStagError(true);
      // setStageError(true);
      // }
      setShowError(true);

      setValidated(true);
    } else if (imageArr.length === 0) {
      event.preventDefault();
      errorMessage('Please select image');
    }
    // else if (
    //   (desiredRiseValues.length === 0 ||
    //     fundingValues.length === 0 ||
    //     stageValues.length === 0) &&
    //   category === '1'
    // ) {
    //   event.preventDefault();

    //   setDesiredRaiseError(true);

    //   setFundedStagError(true);
    //   setStageError(true);
    //   errorMessage('Traction are required.');
    // }
    else if (!userEmail && association === '1' && !props.id) {
      event.preventDefault();
      setShowEmailModel(true);
    }
    // else if (workSpaceIds.length == 0) {
    //   event.preventDefault();
    //   errorMessage('Please select workspace');
    // }
    else {
      event.preventDefault();

      setShowError(false);
      var place_id =
        typeof location === 'object'
          ? location.value.place_id
          : typeof location === 'string'
          ? ''
          : '';

      if (place_id) {
        geocodeByPlaceId(place_id)
          .then(results => getLatLng(results[0]))
          .then(({lat, lng}) => {
            props.id
              ? editAccomplishment(lat, lng)
              : addAccomplishment(lat, lng);
          });
      } else {
        props.id ? editAccomplishment('', '') : addAccomplishment('', '');
      }

      handleResetForm();
      setValidated(false);
    }
  };

  const handleResetForm = () => {
    setLocation('');

    setTopIcons({
      location: false,
    });

    setTicketImg('');

    setDate('');
  };

  const detailAccomplishment = () => {
    var payLoad = {
      accomplishment_id: props.id,
    };
    commonApi
      .detail_accomplishment(payLoad)
      .then(res => {
        if (res.status === 200) {
          setTitle(res.accomplishment_detail.title);
          setDate(res.accomplishment_detail.date);
          setDiscription(res.accomplishment_detail.description);
          setWebsite(res.accomplishment_detail.website_url);
          setCategory(res.accomplishment_detail.accomplishment_category_id);
          setLocation(res.accomplishment_detail.location);
          setImageArr(res.accomplishment_detail.images);
          setTicketImg(res.accomplishment_detail.images);
          setLocation(res.accomplishment_detail.location);
          setLatitude(res.accomplishment_detail.latitude);
          setLongitude(res.accomplishment_detail.longitude);
          // setAssociation();
          setTotalCustomersId(res.accomplishment_detail.total_customers);
          setAnnualRevenueId(res.accomplishment_detail.annual_revenue);
          setFoundedAmountId(res.accomplishment_detail.funded_amount);

          setShowTraction(
            res.accomplishment_detail.traction === 'true' ? true : false,
          );
          if (res.accomplishment_detail?.workspace_ids) {
            setWorkSpaceIds(res.accomplishment_detail.workspace_ids.split(','));
          }

          if (res.accomplishment_detail?.personal === 'yes') {
            // alert('@@@@@@@@@@@')

            setWorkSpaceIds(previous => [...previous, 'personal']);
            // workSpaceIds.push('personal');
          }
          if (res.accomplishment_detail.location !== '') {
            setTopIcons({...topIcons, location: true});
            setTimeout(() => {
              $('.css-1wa3eu0-placeholder').css('color', 'black');
            }, 1000);
          }

          // setTicketImg(res.accomplishment_detail.images[0])

          setshowLoader(false);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const clearAll = () => {
    setLocation('');
    //
    $('.css-1uccc91-singleValue').html('Type city and state');
  };
  // let today = moment().format("DD/MM/YYYY");
  let today = moment().format('YYYY-MM-DD');
  let maxDate = moment().format('2099-12-30');

  useEffect(() => {
    getSuggestions(title, category);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category, title]);

  const objectValues = item => {
    setTitle(item.name);
    setWebsite(item.url);
    if (imageArr.length > 0) {
      setImageArr([item.image, ...imageArr]);
    } else {
      setImageArr([item.image]);
    }
    setTicketImg(item.image);
    setHideShowSuggestion(false);
  };

  const deleteImage = index => {
    const newImages = imageArr.filter((image, i) => i !== index);
    setImageArr(newImages);
  };
  // useEffect(() => {
  //   setFolowers(
  //     newFolowers.filter(
  //       item =>
  //         !leadership.includes(item.id) &&
  //         !members.includes(item.id) &&
  //         !partners.includes(item.id) &&
  //         !pendingUsersIds.includes(item.id),
  //     ),
  //   );
  // }, [leadership, members, partners]);

  const handleSlectedUser = (user, type) => {
    if (type === 'LeaderShip' && !leadership.includes(user.id)) {
      setObjectOfInviteUser(previous => [...previous, {user_id: user.id}]);
      setLeadership(previous => [...previous, user.id]);
      setSelectedLeadership(previous => [...previous, user]);
      setOriginalDataOfLeadership(previous => [...previous, user]);

      // setSelectedModData(previous => [...previous, user]);
    } else if (type === 'LeaderShip' && leadership.includes(user.id)) {
      // setObjectOfInviteUser(previous => [...previous, {user_id: user.id}]);
      // setLeadership(previous => [...previous, user.id]);
      // setSelectedLeadership(previous => [...previous, user]);

      setLeadership(previous => previous.filter(id => id !== user.id));
      setObjectOfInviteUser(previous =>
        previous.filter(miniitem => miniitem.user_id !== user.id),
      );
      setSelectedLeadership(previous =>
        previous.filter(miniitem => miniitem.id !== user.id),
      );
      setOriginalDataOfLeadership(previous =>
        previous.filter(miniitem => miniitem.id !== user.id),
      );
    }

    if (type === 'Partner') {
      setPartners(previous => previous.filter(id => id !== user.id));
      setObjectOfInviteUser(previous =>
        previous.filter(miniitem => miniitem.user_id !== user.id),
      );
      setSelectedPartners(previous =>
        previous.filter(miniitem => miniitem.id !== user.id),
      );
      setOriginalDataOfPartner(previous =>
        previous.filter(miniitem => miniitem.id !== user.id),
      );
    }

    if (type === 'Member') {
      setMembers(previous => previous.filter(id => id !== user.id));
      setObjectOfInviteUser(previous =>
        previous.filter(miniitem => miniitem.user_id !== user.id),
      );
      setSelectedMembers(previous =>
        previous.filter(miniitem => miniitem.id !== user.id),
      );
      setOriginalDataOfMember(previous =>
        previous.filter(miniitem => miniitem.id !== user.id),
      );
    }

    if (type === 'Members') {
      setObjectOfInviteUser(previous => [...previous, {user_id: user.id}]);
      setMembers(previous => [...previous, user.id]);
      setSelectedMembers(previous => [...previous, user]);
    } else if (type === 'Partners') {
      setObjectOfInviteUser(previous => [...previous, {user_id: user.id}]);
      setPartners(previous => [...previous, user.id]);
      setSelectedPartners(previous => [...previous, user]);
    }
  };

  const CircleUserListData = item => {
    if (item.length > 0 && category === '1') {
      setIdsOfCircle(item);
      setModelOfCircle(true);
    } else {
      if (subTabs === 'Leadership' && props.snapShotData) {
        item.forEach((miniItem, index) => {
          if (
            !leadership.includes(miniItem.id) &&
            !members.includes(miniItem.id) &&
            !partners.includes(miniItem.id) &&
            !pendingUsersIds.includes(miniItem.id)
          ) {
            setLeadership(previous => [...previous, miniItem.id]);
            // setSelectedLeadership(previous => [...previous, miniItem]);
            // setOriginalDataOfLeadership(previous => [...previous, miniItem]);
          }
        });
      }
      if (subTabs === 'Partners' && props.snapShotData) {
        item.forEach((miniItem, index) => {
          if (
            !leadership.includes(miniItem.id) &&
            !members.includes(miniItem.id) &&
            !partners.includes(miniItem.id) &&
            !pendingUsersIds.includes(miniItem.id)
          ) {
            setPartners(previous => [...previous, miniItem.id]);
            // setSelectedPartners(previous => [...previous, miniItem]);
            // setOriginalDataOfPartner(previous => [...previous, miniItem]);
          }
        });
      }
      if (subTabs === 'Members' && props.snapShotData) {
        item.forEach((miniItem, index) => {
          if (
            !leadership.includes(miniItem.id) &&
            !members.includes(miniItem.id) &&
            !partners.includes(miniItem.id) &&
            !pendingUsersIds.includes(miniItem.id)
          ) {
            setMembers(previous => [...previous, miniItem.id]);
            // setSelectedMembers(previous => [...previous, miniItem]);
            // setOriginalDataOfMember(previous => [...previous, miniItem]);
          }
        });
      }
      item.forEach((miniItem, index) => {
        if (
          !leadership.includes(miniItem.id) &&
          !members.includes(miniItem.id) &&
          !partners.includes(miniItem.id) &&
          !pendingUsersIds.includes(miniItem.id)
        ) {
          setObjectOfInviteUser(previous => [
            ...previous,
            {
              user_id: miniItem.id,
            },
          ]);
        }
      });
    }
    if (!props.snapShotData) {
      item.forEach((miniItem, index) => {
        if (!leadership.includes(miniItem.id)) {
          setLeadership(previous => [...previous, miniItem.id]);
          setSelectedLeadership(previous => [...previous, miniItem]);
          setOriginalDataOfLeadership(previous => [...previous, miniItem]);
        }
      });
    }
  };

  const handleInviteCurrentUser = (item, rollid, associatid) => {
    setObjectOfInviteUser(previous => [
      ...previous,
      {
        user_id: item.id,
        accomplishment_sub_category_id: associatid,
        accomplishment_role_id: rollid,
      },
    ]);

    // setLeadership(previous => [...previous, item.id]);
    // setSelectedLeadership(previous => [...previous, item]);

    if (associatid === '1' && props.snapShotData) {
      setLeadership(previous => [...previous, item.id]);
      setSelectedLeadership(previous => [...previous, item]);
      setOriginalDataOfLeadership(previous => [...previous, item]);
    }
    if (!props.snapShotData) {
      setLeadership(previous => [...previous, item.id]);
      setSelectedLeadership(previous => [...previous, item]);
      setOriginalDataOfLeadership(previous => [...previous, item]);
    }
    if (associatid === '3' && props.snapShotData) {
      setPartners(previous => [...previous, item.id]);
      setSelectedPartners(previous => [...previous, item]);
      setOriginalDataOfPartner(previous => [...previous, item]);
    }
    if (associatid === '2' && props.snapShotData) {
      setMembers(previous => [...previous, item.id]);
      setSelectedMembers(previous => [...previous, item]);
      setOriginalDataOfMember(previous => [...previous, item]);
    }

    setclearAllModal(false);
  };

  useEffect(() => {
    // if (clearAllModal) {
    //   setInvitesAssociationId(invitesAssociation[0].id)
    //   setInvitesRollId(invitesRoll[0].id)
    // }
    handleInvitesAssociation('1')
    
    
  }, [clearAllModal]);

  const removeUserFromPending = id => {
    setPendingUsers(pendingUsers.filter(user => user.id !== id));
    setPendingUsersIds(pendingUsersIds.filter(user => user !== id));
    setRemovePendigUsers(previous => [...previous, id]);
    setRemovePendingUserModel(false);
  };
  const handleAddUserFromCircle = (idsArray, rollid, associatid) => {
    if (associatid === '1' && props.id) {
      idsArray.forEach((miniItem, index) => {
        if (
          !leadership.includes(miniItem.id) &&
          !members.includes(miniItem.id) &&
          !partners.includes(miniItem.id) &&
          !pendingUsersIds.includes(miniItem.id)
        ) {
          setLeadership(previous => [...previous, miniItem.id]);
          setSelectedLeadership(previous => [...previous, miniItem]);
          setOriginalDataOfLeadership(previous => [...previous, miniItem]);
        }
      });
    }
    // if (!props.id ) {
    //   idsArray.map((miniItem, index) => {
    //     if (
    //       !leadership.includes(miniItem.id) &&
    //       !members.includes(miniItem.id) &&
    //       !partners.includes(miniItem.id)
    //     ) {
    //       setLeadership(previous => [...previous, miniItem.id]);
    //       setSelectedLeadership(previous => [...previous, miniItem]);
    //     }
    //   });

    // }
    if (associatid === '3' && props.id) {
      idsArray.forEach((miniItem, index) => {
        if (
          !leadership.includes(miniItem.id) &&
          !members.includes(miniItem.id) &&
          !partners.includes(miniItem.id) &&
          !pendingUsersIds.includes(miniItem.id)
        ) {
          setPartners(previous => [...previous, miniItem.id]);
          setSelectedPartners(previous => [...previous, miniItem]);
          setOriginalDataOfPartner(previous => [...previous, miniItem]);
        }
      });
    }
    if (associatid === '2' && props.id) {
      idsArray.forEach((miniItem, index) => {
        if (
          !leadership.includes(miniItem.id) &&
          !members.includes(miniItem.id) &&
          !partners.includes(miniItem.id) &&
          !pendingUsersIds.includes(miniItem.id)
        ) {
          setMembers(previous => [...previous, miniItem.id]);
          setSelectedMembers(previous => [...previous, miniItem]);
          setOriginalDataOfMember(previous => [...previous, miniItem]);
        }
      });
    }

    idsArray.forEach((miniItem, index) => {
      if (
        !leadership.includes(miniItem.id) &&
        !members.includes(miniItem.id) &&
        !partners.includes(miniItem.id) &&
        !pendingUsersIds.includes(miniItem.id)
      ) {
        setObjectOfInviteUser(previous => [
          ...previous,
          {
            user_id: miniItem.id,
            accomplishment_sub_category_id: associatid,
            accomplishment_role_id: rollid,
          },
        ]);
      }
    });
    if (!props.id) {
      idsArray.forEach((miniItem, index) => {
        if (!leadership.includes(miniItem.id)) {
          setLeadership(previous => [...previous, miniItem.id]);
          setSelectedLeadership(previous => [...previous, miniItem]);
        }
      });
    }

    setModelOfCircle(false);
  };

  const handlePendingSearch = (array, query) => {
    setPendingUsers(
      array.filter(el => {
        return (
          el.first_name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
          el.last_name.toLowerCase().indexOf(query.toLowerCase()) !== -1
        );
      }),
    );
  };
  const handleCircle = () => {
    setShowComponent('CircleList');
  };

  const handleIndustry = id => {
    if (id === '1' || id === '28') {
    } else {
      setStageId([]);
      setStageValues([]);
      setFundingValues([]);
      setFundingStageId([]);
      setDesiredRiseId([]);
      setDesiredRiseValues([]);
    }
  };

  useEffect(() => {
    
    if(contact.length > 0){
      setInviteLoader(true)
      setInviteQry('')
    }
  }, [contact])


  const renderHeader = () => {
    
    return (
      <>
        
          <img src={RoiLogo} alt="" />
        
      </>
    );
  };
  return (
    <>
      {/* {showLoader && <LoaderModal show={showLoader} />} */}
     

      <SlidingPanel hidePanel={() => props.onClose()}
         renderData={renderHeader}
         showBlackHeader={true}
         headerPadding={'24px 20px'}
         lastDivStyle={{borderRadius: '35px', background: '#f7f6f2'}}
         firstDivStyle={{
           padding: 0,
           background:
             'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)',
         }}
         >
        <div className="serach-filter-wrapper">
        {showLoader && <LoaderSpinner className={'curved-spinner-bg'} />}
          <div className="create-ticket-wrapper fixed-width-inputs  black-head-form-pad-left pt-2">
            {/* <div className="ticket-icons-options">
              <ul>
                <li
                 
                  className="active"
                  onClick={() => {
                    setTopIcons({
                      ...topIcons,
                      ['personal']: !topIcons.personal,
                    });
                    setShowComponent('persnal-model');
                  }}>
                 
                  <PersnalSvg fill={'white'} />
                  
                </li>
                <li
                  className={topIcons.location ? 'active' : ''}
                  onClick={() => {
                    setShowModelDecesion('location');
                    setShowModalButtons(true);
                  }}>
                  <i className="fas fa-map-marker-alt" />
                </li>
              </ul>
            </div> */}
            <div className="m-0 w-100 d-flex align-items-center justify-content-between fix-width-forms create_group_tabs">
              <div className="d-flex">
                <p className="p-bold f_600_h">
                  {' '}
                  {props.snapShotData
                    ? 'Update Credential'
                    : 'Create Credential'}
                </p>
              </div>
              <div className="d-flex"></div>
            </div>
          </div>
          <div
            className="fixed-width-inputs w-100"
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}>
            <div className="create-ticket-tabs">
              <NewCustomTabs
                darkmode
                tabs={[
                  labels.sessions_type_general[selected_lang],
                  labels.sessions_type_invites[selected_lang],
                  labels.traction[selected_lang],
                ]}
                // onSelect={item => setTabType(item)}
                onSelect={(item) => {setTabType(item)
                  if((item === labels.sessions_type_general[selected_lang] || item === labels.traction[selected_lang]) && inviteQry !==''){
                    setInviteFollowers(followersList)
                    setInviteQry('')
                  }
                }}
                active={tabType}
                check={category}
                showTraction={showTraction}
              />
            </div>
          </div>
          <Scrollbars>
            <div className="create-ticket-wrapper fixed-width-inputs  black-head-form-pad-left">
              <div className="create-ticket-form  create-ticket-form-padd-bottom">
                <Form noValidate validated={validated} onSubmit={handle}>
                  {tabType === labels.sessions_type_general[selected_lang] && (
                    <>
                      <div className="fieldset">
                        <div className="d-flex align-items-center justify-content-between">
                          <Form.Label className="w-auto">Type</Form.Label>

                          <div className="d-flex align-items-center">
                            {category === '1' && (
                              <Form.Check
                                // disabled={props.id ? true : false}
                                className="p-0"
                                type="switch"
                                id="custom-switch"
                                label={'Traction'}
                                value={showTraction ? true : false}
                                checked={showTraction ? true : false}
                                onClick={() => setShowTraction(!showTraction)}
                              />
                            )}
                          </div>
                        </div>

                        {/* <div className="select-wrapper snapshot-slect-wrapper">
                          <select
                            className="form-control"
                            disabled={props.id ? true : false}
                            value={category}
                            onChange={handleAccomplishmentChange}>
                            {Object.entries(cat).length > 0 ? (
                              Object.entries(cat).length > 0 &&
                              cat.map((item, index) => (
                                // { (index === '') ?
                                //   setData(index[0])
                                // :()
                                // }
                                <option
                                  id={item.id}
                                  value={item.id}
                                  key={`cat-${index}`}>
                                  {item.category_name}
                                </option>
                              ))
                            ) : !showLoader ? (
                              <div>{labels.no_record_found[selected_lang]}</div>
                            ) : (
                              <></>
                            )}
                          </select>
                        </div> */}

                        {cat.length > 0 && (
                          <CustomDropdown
                            type={'credential'}
                            setDropdownId={setAssociation}
                            dropdownId={category}
                            DropDownValues={cat}
                            itemName={['category_name']}
                            className={'mb-3 recruitment_dropdown'}
                            handleDropdownValue={handleAccomplishmentChange}
                            disabled={props.id ? true : false}
                          />
                        )}
                      </div>
                      <div className="fieldset">
                        <Form.Label>Industry</Form.Label>

                        {apiResponse?.on_boarding_categories &&
                          apiResponse.on_boarding_categories.length > 0 && (
                            <CustomDropdown
                              type={'credential'}
                              setDropdownId={setIndustryId}
                              dropdownId={industryId}
                              DropDownValues={
                                apiResponse.on_boarding_categories
                              }
                              itemName={['title']}
                              className={'mb-3 recruitment_dropdown'}
                              handleDropdownValue={handleIndustry}
                              disabled={props.id ? true : false}
                            />
                          )}

                        {/* <div className="select-wrapper snapshot-slect-wrapper">
                          <select
                            className="form-control"
                            disabled={props.id ? true : false}
                            value={industryId}
                            onChange={e => {
                              setIndustryId(e.target.value);
                              if (
                                e.target.value === '1' ||
                                e.target.value === '28'
                              ) {
                              } else {
                                setStageId([]);
                                setStageValues([]);
                                setFundingValues([]);
                                setFundingStageId([]);
                                setDesiredRiseId([]);
                                setDesiredRiseValues([]);
                              }
                            }}>
                            {apiResponse &&
                            apiResponse.on_boarding_categories &&
                            Object.entries(apiResponse.on_boarding_categories)
                              .length > 0 ? (
                              Object.entries(apiResponse.on_boarding_categories)
                                .length > 0 &&
                              apiResponse.on_boarding_categories.map(
                                (item, index) => (
                                  // { (index === '') ?
                                  //   setData(index[0])
                                  // :()
                                  // }
                                  <option
                                    id={item.id}
                                    value={item.id}
                                    key={`on_boarding_categories-${index}`}>
                                    {item.title}
                                  </option>
                                ),
                              )
                            ) : !showLoader ? (
                              <div>{labels.no_record_found[selected_lang]}</div>
                            ) : (
                              <></>
                            )}
                          </select>

                          
                         
                        </div> */}
                      </div>
                      {category && filterAssociations.length > 0 && (
                        <div className="fieldset">
                          <Form.Label>Association</Form.Label>
                          {/* <div className="select-wrapper snapshot-slect-wrapper">
                            <select
                              className="form-control"
                              disabled={props.id ? true : false}
                              value={association}
                              onChange={handleAssociationChange}>
                              {filterAssociations &&
                              Object.entries(filterAssociations).length > 0 ? (
                                Object.entries(filterAssociations).length > 0 &&
                                filterAssociations.map((item, index) => (
                                  // { (index === '') ?
                                  //   setData(index[0])
                                  // :()
                                  // }
                                  <option
                                    id={item.id}
                                    value={item.id}
                                    key={`apiResponse.accomplishment_sub_category-${index}`}>
                                    {item.title}
                                  </option>
                                ))
                              ) : !showLoader ? (
                                <div>
                                  {labels.no_record_found[selected_lang]}
                                </div>
                              ) : (
                                <></>
                              )}
                            </select>
                          </div> */}

                          <CustomDropdown
                            type={'credential'}
                            setDropdownId={setAssociation}
                            dropdownId={association}
                            DropDownValues={filterAssociations}
                            itemName={['title']}
                            className={'mb-3 recruitment_dropdown'}
                            handleDropdownValue={handleAssociationChange}
                            disabled={props.id ? true : false}
                          />
                        </div>
                      )}
                      {association && filterRoll && filterRoll.length > 0 && (
                        <div className="fieldset">
                          <Form.Label>Role</Form.Label>
                          {/* <div className="select-wrapper snapshot-slect-wrapper">
                            <select
                              className="form-control"
                              disabled={props.id ? true : false}
                              value={rollId}
                              onChange={e => {
                                setRollId(e.target.value);
                              }}>
                              {filterRoll &&
                              Object.entries(filterRoll).length > 0 ? (
                                Object.entries(filterRoll).length > 0 &&
                                filterRoll.map((item, index) => (
                                  // { (index === '') ?
                                  //   setData(index[0])
                                  // :()
                                  // }
                                  <option
                                    id={item.id}
                                    value={item.id}
                                    key={`cat-${index}`}>
                                    {item.title}
                                  </option>
                                ))
                              ) : !showLoader ? (
                                <div>
                                  {labels.no_record_found[selected_lang]}
                                </div>
                              ) : (
                                <></>
                              )}
                            </select>
                          </div> */}

                          <CustomDropdown
                            type={'credential'}
                            setDropdownId={setRollId}
                            dropdownId={rollId}
                            DropDownValues={filterRoll}
                            itemName={['title']}
                            className={'mb-3 recruitment_dropdown'}
                            // handleDropdownValue={handleAssociationChange}
                            disabled={props.id ? true : false}
                          />
                        </div>
                      )}

                      <div className="fieldset">
                        <Form.Label controlid="validationCustom01">
                          Give this credential a title?
                        </Form.Label>
                        <Form.Control
                          disabled={props.id ? true : false}
                          name="title"
                          value={title}
                          pattern="^\S.*$"
                          required
                          placeholder="Keep It simple"
                          onChange={e => {
                            setTitle(e.target.value);
                            setHideShowSuggestion(true);
                          }}
                        />
                        {title &&
                          hideShowSuggestion &&
                          filteredSuggestions.length > 0 && (
                            <ul className="custom-suggestions">
                              {filteredSuggestions.map(suggestion => (
                                <li
                                  className="as-link"
                                  key={'suggestion' + suggestion.id}
                                  onClick={() => {
                                    objectValues(suggestion);
                                  }}>
                                  {capitalizeFirst(suggestion.name)}
                                </li>
                              ))}
                            </ul>
                          )}
                        <Form.Control.Feedback type="invalid">
                          Title is required.
                        </Form.Control.Feedback>
                      </div>

                      <>
                        <div className="fieldset">
                          <Form.Label>Date (optional)</Form.Label>
                          <Form.Control
                            value={date}
                            name="date"
                            type="date"
                            min={today}
                            max={maxDate}
                            placeholder="Date"
                            onChange={e => {
                              setDate(e.target.value);
                            }}
                          />
                        </div>

                        {/* {topIcons.location && ( */}
                        <div className="fieldset">
                          <div className="d-flex align-items-center justify-content-between">
                            <Form.Label>Location</Form.Label>
                            {/* {location !== '' && (
                                <span
                                  className="clear-btn"
                                  onClick={() => {
                                    clearAll();
                                  }}>
                                  Clear
                                </span>
                              )} */}
                          </div>
                          <div className="location-field form-control form-control-with-icon snap_location">
                            <GooglePlacesAutocomplete
                              location
                              selectProps={{
                                value: location,
                                onChange: setLocation,
                                name: 'city',
                                placeholder: jugar,
                                noOptionsMessage: () => null,
                              }}
                            />

                            {location !== '' && (
                              <span
                                className="location_clear_icon"
                                onClick={() => {
                                  clearAll();
                                }}>
                                <i
                                  className="fas fa-close"
                                  // style={{paddingLeft: '210px'}}
                                />
                              </span>
                            )}
                          </div>

                          {showError === true && location === '' ? (
                            <div className="text-danger textforerror">
                              Location is required.
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                        {/* )} */}

                        <div className="fieldset">
                          <Form.Label>Description</Form.Label>
                          <Form.Control
                            required
                            value={discription}
                            as="textarea"
                            rows={6}
                            placeholder="Tell others about this Credential. "
                            onChange={e => {
                              setDiscription(e.target.value);
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Description is required.
                          </Form.Control.Feedback>
                        </div>
                        <div className="fieldset">
                          <Form.Label>Website</Form.Label>
                          <Form.Control
                            disabled={props.id ? true : false}
                            required
                            // pattern="https://.*"
                            value={website}
                            placeholder="Website"
                            onChange={e => {
                              setWebsite(e.target.value);
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            {website ? 'Invalid url.' : 'Website is required.'}
                          </Form.Control.Feedback>
                        </div>
                        <Button
                          type="submit"
                          style={{display: 'none'}}
                          ref={buttonRef}></Button>
                      </>

                      <Col sm={2}>
                        <div className="ticket-img-wrapper mb-3">
                          {imageArr.length > 0 && (
                            <p
                              onClick={() => {
                                handleShow();
                              }}>
                              Onclick
                            </p>
                          )}
                          <input
                            type="file"
                            accept="image/*"
                            onChange={e => changeTicketImg(e)}
                            // onClick={() => {
                            //   setShowModel('modal');
                            // }}
                          />
                          {imageArr.length > 0 && (
                            <img src={imageArr[imageArr.length - 1]} alt="" />
                          )}
                          <i className="icon-camera" />
                        </div>
                      </Col>
                      {/* {imageArr.length > 0 && (
                        <span
                          className="as-link info-text mb-3 mt-0 text_green"
                          onClick={handleShow}>
                          Preview Images
                        </span>
                      )} */}
                    </>
                  )}
                  {tabType === labels.sessions_type_invites[selected_lang] && (
                    <div className="">
                      {props.snapShotData &&
                        props.snapShotData.accomplishment_category_id ===
                          '1' && (
                          <div className="event-tabs tabs-style-2 mb-0">
                            <ul>
                              <li>
                                <Button
                                  className={
                                    subTabs === 'Leadership' ? 'active' : ''
                                  }
                                  onClick={() => {
                                    setSubTabs('Leadership');
                                  }}>
                                  Leadership
                                </Button>
                              </li>
                              <li>
                                <Button
                                  className={
                                    subTabs === 'Members' ? 'active' : ''
                                  }
                                  onClick={() => {
                                    setSubTabs('Members');
                                  }}>
                                  Members
                                </Button>
                              </li>
                              <li>
                                <Button
                                  className={
                                    subTabs === 'Partners' ? 'active' : ''
                                  }
                                  onClick={() => {
                                    setSubTabs('Partners');
                                  }}>
                                  Partners
                                </Button>
                              </li>
                              <li>
                                <Button
                                  className={
                                    subTabs === 'Pending' ? 'active' : ''
                                  }
                                  onClick={() => {
                                    setSubTabs('Pending');
                                  }}>
                                  Pending
                                </Button>
                              </li>
                            </ul>
                          </div>
                        )}
                      {props.snapShotData &&
                        props.snapShotData.accomplishment_category_id !==
                          '1' && (
                          <div className="event-tabs tabs-style-2 mb-0">
                            <ul>
                              <li>
                                <Button
                                  className={
                                    subTabs === 'Leadership' ? 'active' : ''
                                  }
                                  onClick={() => {
                                    setSubTabs('Leadership');
                                  }}>
                                  Member
                                </Button>
                              </li>
                              <li>
                                <Button
                                  className={
                                    subTabs === 'Pending' ? 'active' : ''
                                  }
                                  onClick={() => {
                                    setSubTabs('Pending');
                                  }}>
                                  Pending
                                </Button>
                              </li>
                            </ul>
                          </div>
                        )}
                      {/* {subTabs === 'Pending' ? (
                        <div className="invite-page-search">
                          <div className="search-form">
                            <Form.Group className="search-input">
                              <Form.Control
                                onChange={e => {
                                  handlePendingSearch(
                                    searchQryPending,
                                    e.target.value,
                                  );
                                }}
                                // value={searchQryPending}
                                type="text"
                                placeholder="Search"
                              />
                            </Form.Group>
                          </div>
                        </div>
                      ) : (
                        <div className="invite-page-search">
                          <div className="search-form">
                            <Form.Group className="search-input">
                              <Form.Control
                                onChange={e => {
                                  setSearchQuery(e.target.value);
                                  // getAllUsers(e.target.value);

                                  handleSearchQry(e.target.value);
                                }}
                                value={searchQuery}
                                type="text"
                                placeholder="Search"
                              />
                            </Form.Group>
                          </div>
                        </div>
                      )} */}

                      {subTabs === 'Pending' && (
                        <div className="invite-page-search">
                          <div className="search-form">
                            <Form.Group className="search-input">
                              <Form.Control
                                onChange={e => {
                                  handlePendingSearch(
                                    searchQryPending,
                                    e.target.value,
                                  );
                                }}
                                // value={searchQryPending}
                                type="text"
                                placeholder="Search"
                              />
                            </Form.Group>
                          </div>
                        </div>
                      )}

                      {subTabs === 'Leadership' &&
                        props.snapShotData &&
                        props.snapShotData.accomplishment_category_id !==
                          '1' && (
                            <div className="refferal-list-wrapper">
                            {inviteLoader && (<div className="invite_loader_hieght">
                            <LoaderSpinner />
                            </div>
                           )}
                          <Invite
                            type={'snapshot'}
                            moderator={leadership}
                            setModerator={setLeadership}
                            selectedModData={selectedLeadership}
                            setSelectedModData={setSelectedLeadership}
                            pendingUsersIds={pendingUsersIds}
                            category={category}
                            handleSlectedUser={handleSlectedUser}
                            contact={contact}
                            setContact={setContact}
                            originalDataOfMod={originalDataOfLeadership}
                            setOriginalDataOfMod={setOriginalDataOfLeadership}
                            handleCircle={handleCircle}
                            onClick={() => {
                              setShowComponent('inviteUser')
                              setTimeout(() => {
                                if(contact.length ===0 && inviteQry !==''){
                                  setInviteFollowers(followersList)
                                        setInviteQry('')
                                } 
                              }, 1000);
                             
                            }}
                            forApiHit={showComponent}
                            showLoader={inviteLoader}
                            setshowLoader={setInviteLoader}
                            followers={inviteFollowers}
                            setFollowers={setInviteFollowers}
                            setFollowersList={setFollowersList}
                            followersList={followersList}
                            setSearchQry={setInviteQry}
                            searchQry={inviteQry}
                          />
                          </div>
                        )}
                      {subTabs === 'Pending' && (
                        <div className="invite-persons-wrapper">
                          <div className="invite-persons-list">
                            <Container>
                              <Row>
                                {Object.entries(pendingUsers).length > 0 ? (
                                  Object.entries(pendingUsers).length > 0 &&
                                  pendingUsers.map((item, index) => (
                                    <Col key={`f-item-${index}`} md={4} xl={3}>
                                      <div
                                        className="stage-list-item "
                                        key={`Pending-${index}`}>
                                        <div
                                          className="stage-person-img as-link"
                                          onClick={() => {
                                            setIdForRemovePendingUser(item);
                                            setRemovePendingUserModel(true);
                                          }}>
                                          <img
                                            src={
                                              item.profile_img === ''
                                                ? DEFAULT_IMAGE
                                                : item.profile_img
                                            }
                                            alt=""
                                            id={item.id}
                                          />
                                        </div>
                                        <div
                                          className="stage-person-name as-link"
                                          onClick={() => {
                                            setIdForRemovePendingUser(item);
                                            setRemovePendingUserModel(true);
                                          }}>
                                          <h3>
                                            {fixName(
                                              item.first_name,
                                              item.last_name,
                                            )}
                                          </h3>
                                        </div>
                                      </div>
                                    </Col>
                                  ))
                                ) : (
                                  <p className="text-center">No user found.</p>
                                )}
                              </Row>
                            </Container>
                          </div>
                        </div>
                      )}
                      {subTabs === 'Leadership' &&
                        props.snapShotData &&
                        props.snapShotData.accomplishment_category_id ===
                          '1' && (

                            <div className="refferal-list-wrapper">
                            {inviteLoader && (<div className="invite_loader_hieght">
                            <LoaderSpinner />
                            </div>
                           )}
                          <Invite
                            type={'snapshot'}
                            moderator={leadership}
                            setModerator={setLeadership}
                            selectedModData={selectedLeadership}
                            setSelectedModData={setSelectedLeadership}
                            pendingUsersIds={pendingUsersIds}
                            members={members}
                            partners={partners}
                            category={category}
                            setclearAllModal={setclearAllModal}
                            setCurrentInviteUser={setCurrentInviteUser}
                            handleSlectedUser={handleSlectedUser}
                            contact={contact}
                            setContact={setContact}
                            originalDataOfMod={originalDataOfLeadership}
                            setOriginalDataOfMod={setOriginalDataOfLeadership}
                            handleCircle={handleCircle}
                            onClick={() => {
                              setShowComponent('inviteUser')
                              setTimeout(() => {
                                if(contact.length ===0 && inviteQry !==''){
                                  setInviteFollowers(followersList)
                                        setInviteQry('')
                                } 
                              }, 1000);
                             
                            }}
                            forApiHit={showComponent}
                            showLoader={inviteLoader}
                            setshowLoader={setInviteLoader}
                            followers={inviteFollowers}
                            setFollowers={setInviteFollowers}
                            setFollowersList={setFollowersList}
                            followersList={followersList}
                            setSearchQry={setInviteQry}
                            searchQry={inviteQry}
                          />
                          </div>
                        )}

                      {subTabs === 'Partners' &&
                        props.snapShotData &&
                        props.snapShotData.accomplishment_category_id ===
                          '1' && (
                            <div className="refferal-list-wrapper">
                            {inviteLoader && (<div className="invite_loader_hieght">
                            <LoaderSpinner />
                            </div>
                           )}
                          <Invite
                            type={'snapshot'}
                            tab={'Partners'}
                            moderator={partners}
                            setModerator={setPartners}
                            leadership={leadership}
                            selectedModData={selectedpartners}
                            setSelectedModData={setSelectedPartners}
                            pendingUsersIds={pendingUsersIds}
                            members={members}
                            category={category}
                            setclearAllModal={setclearAllModal}
                            setCurrentInviteUser={setCurrentInviteUser}
                            handleSlectedUser={handleSlectedUser}
                            contact={contact}
                            setContact={setContact}
                            originalDataOfMod={originalDataOfPartner}
                            setOriginalDataOfMod={setOriginalDataOfPartner}
                            handleCircle={handleCircle}
                            onClick={() => {
                              setShowComponent('inviteUser')
                              setTimeout(() => {
                                if(contact.length ===0 && inviteQry !==''){
                                  setInviteFollowers(followersList)
                                        setInviteQry('')
                                } 
                              }, 1000);
                             
                            }}

                            showLoader={inviteLoader}
                            setshowLoader={setInviteLoader}
                            followers={inviteFollowers}
                            setFollowers={setInviteFollowers}
                            setFollowersList={setFollowersList}
                            followersList={followersList}
                            setSearchQry={setInviteQry}
                            searchQry={inviteQry}
                          />
                          </div>
                        )}
                      {subTabs === 'Members' &&
                        props.snapShotData &&
                        props.snapShotData.accomplishment_category_id ===
                          '1' && (
                            <div className="refferal-list-wrapper">
                            {inviteLoader && (<div className="invite_loader_hieght">
                            <LoaderSpinner />
                            </div>
                           )}
                          <Invite
                            type={'snapshot'}
                            tab={'Members'}
                            moderator={members}
                            setModerator={setMembers}
                            leadership={leadership}
                            selectedModData={selectedMembers}
                            setSelectedModData={setSelectedMembers}
                            pendingUsersIds={pendingUsersIds}
                            partners={partners}
                            category={category}
                            setclearAllModal={setclearAllModal}
                            setCurrentInviteUser={setCurrentInviteUser}
                            handleSlectedUser={handleSlectedUser}
                            contact={contact}
                            setContact={setContact}
                            originalDataOfMod={originalDataOfMember}
                            setOriginalDataOfMod={setOriginalDataOfMember}
                            handleCircle={handleCircle}
                            onClick={() => {
                              setShowComponent('inviteUser')
                              setTimeout(() => {
                                if(contact.length ===0 && inviteQry !==''){
                                  setInviteFollowers(followersList)
                                        setInviteQry('')
                                } 
                              }, 1000);
                             
                            }}
                            forApiHit={showComponent}
                            showLoader={inviteLoader}
                            setshowLoader={setInviteLoader}
                            followers={inviteFollowers}
                            setFollowers={setInviteFollowers}
                            setFollowersList={setFollowersList}
                            followersList={followersList}
                            setSearchQry={setInviteQry}
                            searchQry={inviteQry}
                          />
                          </div>
                        )}
                      {!props.id && showSelectedUsers === '' && (
                         <div className="refferal-list-wrapper">
                         {inviteLoader && (<div className="invite_loader_hieght">
                         <LoaderSpinner />
                         </div>
                        )}
                        <Invite
                          type={'snapshot'}
                          moderator={leadership}
                          setModerator={setLeadership}
                          selectedModData={selectedLeadership}
                          setSelectedModData={setSelectedLeadership}
                          pendingUsersIds={pendingUsersIds}
                          category={category}
                          setclearAllModal={setclearAllModal}
                          setCurrentInviteUser={setCurrentInviteUser}
                          handleSlectedUser={handleSlectedUser}
                          contact={contact}
                          setContact={setContact}
                          originalDataOfMod={originalDataOfLeadership}
                          setOriginalDataOfMod={setOriginalDataOfLeadership}
                          handleCircle={handleCircle}
                          onClick={() => {
                            setShowComponent('inviteUser')
                            setTimeout(() => {
                              if(contact.length ===0 && inviteQry !==''){
                                setInviteFollowers(followersList)
                                      setInviteQry('')
                              } 
                            }, 1000);
                           
                          }}
                          forApiHit={showComponent}
                          setShowSelectedUsers={setShowSelectedUsers}
                          showLoader={inviteLoader}
                          setshowLoader={setInviteLoader}
                          followers={inviteFollowers}
                          setFollowers={setInviteFollowers}
                          setFollowersList={setFollowersList}
                          followersList={followersList}
                          setSearchQry={setInviteQry}
                          searchQry={inviteQry}
                        />
                        </div>
                      )}
                    </div>
                  )}

                  {tabType === labels.traction[selected_lang] && (
                    <>
                      {industryId !== '1' && (
                        <>
                          <div className="fieldset">
                            <div className="d-flex align-items-center justify-content-between">
                              <Form.Label
                                className="w-auto"
                                controlid="validationCustom01">
                                Company stage
                              </Form.Label>
                              <div className="d-flex align-items-center">
                                <Form.Check
                                  g="p-0"
                                  type="switch"
                                  id="custom-switch"
                                  label={'Seeking Funding'}
                                  value={investing ? true : false}
                                  checked={investing ? true : false}
                                  onClick={() => setInvesting(!investing)}
                                />
                              </div>
                            </div>
                            <div className="select-wrapper">
                            <Form.Control
                              className="as-link"
                              readOnly={'readOnly'}
                              value={
                                stageValues.length > 1 &&
                                (industryId === '28' || industryId === '1')
                                  ? stageValues[0] +
                                    ' +' +
                                    (stageValues.length - 1)
                                  : stageValues
                              }
                              placeholder="Select company stage"
                              onClick={() => {
                                handleShowModel('stage');
                              }}
                            />
                            </div>
                          </div>
                          <div className="fieldset">
                            <Form.Label>Annual revenue</Form.Label>

                            <CustomDropdown
                              setDropdownId={setAnnualRevenueId}
                              dropdownId={annualRevenueId}
                              DropDownValues={
                                apiResponse ? apiResponse.annual_revenue : []
                              }
                              itemName={['title']}
                              className={'mb-3 recruitment_dropdown'}
                            />
                          </div>
                          <div className="fieldset">
                            <Form.Label>Total customers</Form.Label>

                            <CustomDropdown
                              setDropdownId={setTotalCustomersId}
                              dropdownId={totalCustomersId}
                              DropDownValues={
                                apiResponse ? apiResponse.total_customers : []
                              }
                              itemName={['title']}
                              className={'mb-3 recruitment_dropdown'}
                            />
                          </div>
                          <div className="fieldset">
                            <Form.Label>Previously funded </Form.Label>
                            <CustomDropdown
                              setDropdownId={setFoundedAmountId}
                              dropdownId={foundedAmountId}
                              DropDownValues={
                                apiResponse ? apiResponse.funded_amount : []
                              }
                              itemName={['title']}
                              className={'mb-3 recruitment_dropdown'}
                            />
                          </div>
                          <div className="fieldset">
                            <Form.Label controlid="validationCustom01">
                              Fuding stage
                            </Form.Label>
                            <div className="select-wrapper">
                              <Form.Control
                                className="as-link"
                                readOnly={'readOnly'}
                                value={
                                  fundingValues.length > 1 &&
                                  (industryId === '28' || industryId === '1')
                                    ? fundingValues[0] +
                                      ' +' +
                                      (fundingValues.length - 1)
                                    : fundingValues
                                }
                                placeholder="Select category"
                                onClick={() => {
                                  handleShowModel('funding_stage');
                                }}
                              />
                            </div>
                          </div>
                          <div className="fieldset">
                            <Form.Label controlid="validationCustom01">
                              Desired raise
                            </Form.Label>
                            <div className="select-wrapper">
                            <Form.Control
                              className="as-link"
                              readOnly={'readOnly'}
                              value={
                                desiredRiseValues.length > 1 &&
                                (industryId === '28' || industryId === '1')
                                  ? desiredRiseValues[0] +
                                    ' +' +
                                    (desiredRiseValues.length - 1)
                                  : desiredRiseValues
                              }
                              placeholder="Select category"
                              onClick={() => {
                                handleShowModel('desired_raise');
                              }}
                            />
                            </div>
                          </div>{' '}
                        </>
                      )}
                      {industryId === '1' && (
                        <>
                          <div className="fieldset">
                            <div className="d-flex align-items-center justify-content-between">
                              <Form.Label
                                className="w-auto"
                                controlid="validationCustom01">
                                Company Stage
                              </Form.Label>
                              <div className="d-flex align-items-center">
                                <Form.Check
                                  className="p-0"
                                  type="switch"
                                  id="custom-switch"
                                  label={'Actively Investing'}
                                  value={activeInvesting ? true : false}
                                  checked={activeInvesting ? true : false}
                                  onClick={() =>
                                    setActiveInvesting(!activeInvesting)
                                  }
                                />
                              </div>
                            </div>
                            <Form.Control
                              className="as-link"
                              readOnly={'readOnly'}
                              value={
                                stageValues.length > 1 &&
                                (industryId === '28' || industryId === '1')
                                  ? stageValues[0] +
                                    ' +' +
                                    (stageValues.length - 1)
                                  : stageValues
                              }
                              placeholder="Select category"
                              onClick={() => {
                                handleShowModel('stage');
                              }}
                            />
                          </div>

                          <div className="fieldset">
                            <Form.Label>Total investment </Form.Label>
                            <div className="select-wrapper">
                              <select
                                value={investmentId}
                                onChange={e => {
                                  setInvestmentId(e.target.value);
                                }}>
                                {apiResponse &&
                                apiResponse.investment_size &&
                                Object.entries(apiResponse.investment_size)
                                  .length > 0 ? (
                                  Object.entries(apiResponse.investment_size)
                                    .length > 0 &&
                                  apiResponse.investment_size.map(
                                    (item, index) => (
                                      <option
                                        id={item.id}
                                        value={item.id}
                                        key={`apiResponse.investment_size-${index}`}>
                                        {item.title}
                                      </option>
                                    ),
                                  )
                                ) : (
                                  <div>
                                    {labels.no_record_found[selected_lang]}
                                  </div>
                                )}
                              </select>
                            </div>
                          </div>

                          <div className="fieldset">
                            <Form.Label>Portfolio size</Form.Label>
                            <div className="select-wrapper">
                              <select
                                value={portfolioSizeId}
                                onChange={e => {
                                  setPortfolioSizeId(e.target.value);
                                }}>
                                {apiResponse &&
                                  apiResponse.portfolio_size &&
                                  Object.entries(apiResponse.portfolio_size)
                                    .length > 0 &&
                                  Object.entries(apiResponse.portfolio_size)
                                    .length > 0 &&
                                  apiResponse.portfolio_size.map(
                                    (item, index) => (
                                      <option
                                        id={item.id}
                                        value={item.id}
                                        key={`apiResponse.investment_size-${index}`}>
                                        {item.title}
                                      </option>
                                    ),
                                  )}
                              </select>
                            </div>
                          </div>

                          <div className="fieldset">
                            <Form.Label>Available capital</Form.Label>
                            <div className="select-wrapper">
                              <select
                                value={availableCapitalId}
                                onChange={e => {
                                  setAvailableCapitalId(e.target.value);
                                }}>
                                {apiResponse &&
                                  apiResponse.available_capital &&
                                  Object.entries(apiResponse.available_capital)
                                    .length > 0 &&
                                  Object.entries(apiResponse.available_capital)
                                    .length > 0 &&
                                  apiResponse.available_capital.map(
                                    (item, index) => (
                                      <option
                                        id={item.id}
                                        value={item.id}
                                        key={`apiResponse.investment_size-${index}`}>
                                        {item.title}
                                      </option>
                                    ),
                                  )}
                              </select>
                            </div>
                          </div>
                          <div className="fieldset">
                            <Form.Label>Average investment</Form.Label>
                            <div className="select-wrapper">
                              <select
                                value={averageInvestmentId}
                                onChange={e => {
                                  setAverageInvestmentId(e.target.value);
                                }}>
                                {apiResponse &&
                                  apiResponse.average_investment &&
                                  Object.entries(apiResponse.average_investment)
                                    .length > 0 &&
                                  Object.entries(apiResponse.average_investment)
                                    .length > 0 &&
                                  apiResponse.average_investment.map(
                                    (item, index) => (
                                      <option
                                        id={item.id}
                                        value={item.id}
                                        key={`apiResponse.investment_size-${index}`}>
                                        {item.title}
                                      </option>
                                    ),
                                  )}
                              </select>
                            </div>
                          </div>
                          <div className="fieldset">
                            <Form.Label>Recipient funding stage</Form.Label>
                            <div className="select-wrapper">
                              <select
                                value={recipientFundingId}
                                onChange={e => {
                                  setRecipientFundingId(e.target.value);
                                }}>
                                {apiResponse &&
                                  apiResponse.recipient_funding_stage &&
                                  Object.entries(
                                    apiResponse.recipient_funding_stage,
                                  ).length > 0 &&
                                  Object.entries(
                                    apiResponse.recipient_funding_stage,
                                  ).length > 0 &&
                                  apiResponse.recipient_funding_stage.map(
                                    (item, index) => (
                                      <option
                                        id={item.id}
                                        value={item.id}
                                        key={`apiResponse.investment_size-${index}`}>
                                        {item.title}
                                      </option>
                                    ),
                                  )}
                              </select>
                            </div>
                          </div>

                          <div className="fieldset">
                            <Form.Label>Desired funding stage</Form.Label>
                            <div className="select-wrapper">
                              <select
                                value={desiredRoundId}
                                onChange={e => {
                                  setDesiredRoundId(e.target.value);
                                }}>
                                {apiResponse &&
                                  apiResponse.desired_round_raise &&
                                  Object.entries(
                                    apiResponse.desired_round_raise,
                                  ).length > 0 &&
                                  Object.entries(
                                    apiResponse.desired_round_raise,
                                  ).length > 0 &&
                                  apiResponse.desired_round_raise.map(
                                    (item, index) => (
                                      <option
                                        id={item.id}
                                        value={item.id}
                                        key={`apiResponse.investment_size-${index}`}>
                                        {item.title}
                                      </option>
                                    ),
                                  )}
                              </select>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </Form>
              </div>
            </div>
          </Scrollbars>
          { !showLoader && tabType === labels.sessions_type_general[selected_lang] && (
            <div className="filter-btn-wrapper text-center pt-2 fixed-width-inputs bg_transparent bottom-0">
              <Button
                className= {disableBtn ? 'disable btn-dark' : 'btn-dark'}
                disabled={disableBtn ? true : false}
                onClick={() => buttonRef.current.click()}>
                {props.id ? 'Update Credential' : 'Create Credential'}
              </Button>
            </div>
          )}
        </div>
      </SlidingPanel>

      {show && (
        <SlidingPanel hidePanel={() => handleClose()}>
          <div className="serach-filter-wrapper">
            {/* <CustomModal size="medium" onClose={() => handleClose()}> */}
            <div className="permission-modal-content text-left">
              <h3>Images</h3>
            </div>

            <Scrollbars>
              <div className="snapshot-preview-wrapper fixed-width-inputs">
                <Row>
                  {Object.entries(imageArr).length > 0 ? (
                    Object.entries(imageArr).length > 0 &&
                    imageArr.map((item, index) => {
                      return (
                        <Col md={4} sm={4} key={`imagem-${index}`}>
                          <div className="snapshot-item">
                            <span
                              className="remove-icon"
                              onClick={() => {
                                // setImageArr(prevImgs => prevImgs.slice(1));
                                setIndexOfImage(index);
                                setShowModelCompoenet('image_remove_alert');
                              }}>
                              <i className="fas fa-times" />
                            </span>

                            <img
                              className="as-link"
                              src={item}
                              alt=""
                              onClick={() => {
                                setModelImg(item);
                                setShowModelCompoenet('image_pre');
                              }}
                            />
                          </div>
                        </Col>
                      );
                    })
                  ) : (
                    <p className="text-center">No Images Found</p>
                  )}
                </Row>
              </div>
            </Scrollbars>
          </div>
          {/* </CustomModal> */}
        </SlidingPanel>
      )}
      {showModelCompoenet === 'image_pre' && modelImg && (
        <ImagePreview
          modelImg={modelImg}
          onClose={() => {
            setShowModelCompoenet('');
          }}
        />
      )}
      {showModelCompoenet === 'image_remove_alert' && (
        <AlertModel
          // modelImg={modelImg}
          onClose={() => {
            setShowModelCompoenet('');
          }}
          removeImage={() => {
            deleteImage(indexOfImage);
            setImgUrl('');

            imagePath.length === 0 && handleClose();
          }}
        />
      )}

      {removePendingUserModel && (
        <CustomModal
          size="small"
          onClose={() => setRemovePendingUserModel(false)}>
          <div className="permission-modal-content text-center">
            <h2>Alert</h2>
            <p>
              Are yo sure you want to remove{' '}
              {fixName(
                idForRemovePendingUser.first_name,
                idForRemovePendingUser.last_name,
              )}
              ?
            </p>
            <div className="event-btn-wrapper permission-modal-btns">
              <Button
                variant="secondary"
                onClick={() => {
                  removeUserFromPending(idForRemovePendingUser.id);
                }}>
                Yes
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  setRemovePendingUserModel(false);
                }}>
                No Thanks!
              </Button>
            </div>
          </div>
        </CustomModal>
      )}
      {showEmailModel && (
        <CustomModal
          size="small"
          onClose={() => {
            setShowEmailModel(false);
            setUserEmail('');
          }}>
          <div className="permission-modal-content text-center">
            <h3>Verify Association</h3>
            <p>
              This role requires you to verify your email. Please enter your
              valid email below.
            </p>

            <div className="invite-page-search invite-snapshot-page-search">
              <div className="search-form">
                <Form.Group className="search-input">
                  <Form.Control
                    required
                    onChange={handleChange}
                    // onKeyDown={handleKeyDown}
                    value={userEmail}
                    type="text"
                    placeholder="Enter email"
                  />
                </Form.Group>
                {'@' + urlPattern('', website)}
              </div>
            </div>
            <div className="event-btn-wrapper">
              <Button
              className= {disableBtn && 'disable'}
                variant="secondary"
                disabled={userEmail || !disableBtn ? false : true}
                onClick={() => buttonRef.current.click()}>
                Send Verification
              </Button>
            </div>
          </div>
        </CustomModal>
      )}
      {clearAllModal && currentInviteUser && (
        <CustomModal size="small" onClose={() => setclearAllModal(false)}>
          <div className="permission-modal-content text-center">
            <h3 className="text-center">Select Role</h3>
            {invitesAssociation.length > 0 && (
              <>
                <Form.Label className="d-block text-left">
                  Association
                </Form.Label>

                <CustomDropdown
                  type={'credential'}
                  setDropdownId={setInvitesAssociationId}
                  dropdownId={invitesAssociationId}
                  DropDownValues={invitesAssociation}
                  itemName={['title']}
                  className={'mb-3 recruitment_dropdown'}
                  handleDropdownValue={handleInvitesAssociation}
                  disabled={props.id ? true : false}
                />
              </>
            )}
            {invitesRoll && invitesRoll && invitesRoll.length > 0 && (
              <>
                <Form.Label className="d-block text-left">Role</Form.Label>

                <CustomDropdown
                  // type={'credential'}
                  setDropdownId={setInvitesRollId}
                  dropdownId={invitesRollId}
                  DropDownValues={invitesRoll}
                  itemName={['title']}
                  className={
                    'recruitment_dropdown recruitement_dropdown_setInvites'
                  }
                  // handleDropdownValue={handleInvitesAssociation}
                  disabled={props.id ? true : false}
                />

                <Button
                  variant="primary"
                  className="btn-dark btn btn-primary"
                  onClick={() => {
                    handleInviteCurrentUser(
                      currentInviteUser,
                      invitesRollId,
                      invitesAssociationId,
                    );
                  }}>
                  Add
                </Button>
              </>
            )}
          </div>
        </CustomModal>
      )}
      {modelOfCircle && idsOfCircle && (
        <CustomModal size="small" onClose={() => setModelOfCircle(false)}>
          <div className="permission-modal-content text-center">
            <h3 className="text-center">Select Role</h3>
            {invitesAssociation.length > 0 && (
              <>
                <Form.Label className="d-block text-left">
                  Association
                </Form.Label>
                <div className="select-wrapper">
                  <select
                    value={invitesAssociationId}
                    onChange={handleInvitesAssociation}>
                    {invitesAssociation &&
                    Object.entries(invitesAssociation).length > 0 ? (
                      Object.entries(invitesAssociation).length > 0 &&
                      invitesAssociation.map((item, index) => (
                        // { (index === '') ?
                        //   setData(index[0])
                        // :()
                        // }
                        <option
                          id={item.id}
                          value={item.id}
                          key={`apiResponse.accomplishment_sub_category-${index}`}>
                          {item.title}
                        </option>
                      ))
                    ) : !showLoader ? (
                      <div>{labels.no_record_found[selected_lang]}</div>
                    ) : (
                      <></>
                    )}
                  </select>
                </div>
              </>
            )}
            {invitesRoll && invitesRoll && invitesRoll.length > 0 && (
              <>
                <Form.Label className="d-block text-left">Role</Form.Label>
                <div className="select-wrapper">
                  <select
                    value={invitesRollId}
                    onChange={e => {
                      setInvitesRollId(e.target.value);
                    }}>
                    {invitesRoll && Object.entries(invitesRoll).length > 0 ? (
                      Object.entries(invitesRoll).length > 0 &&
                      invitesRoll.map((item, index) => (
                        // { (index === '') ?
                        //   setData(index[0])
                        // :()
                        // }
                        <option
                          id={item.id}
                          value={item.id}
                          key={`cat-${index}`}>
                          {item.title}
                        </option>
                      ))
                    ) : !showLoader ? (
                      <div>{labels.no_record_found[selected_lang]}</div>
                    ) : (
                      <></>
                    )}
                  </select>
                </div>
                <Button
                  variant="primary"
                  className="btn-dark btn btn-primary"
                  onClick={() => {
                    handleAddUserFromCircle(
                      idsOfCircle,
                      invitesRollId,
                      invitesAssociationId,
                    );
                  }}>
                  Add
                </Button>
              </>
            )}
          </div>
        </CustomModal>
      )}

      {showModalButtons && (
        <CustomModal size="small" onClose={() => setShowModalButtons(false)}>
          <div className="permission-modal-content text-center">
            {/* {showModelDecesion === 'snapshot' && (
              <h3>
                <i className="fa-solid fa-shop"></i>
                Host
              </h3>
            )} */}
            {/* {showModelDecesion === 'dollar' && (
              <h3>
                <i className="icon-dollar-sign" />
                Paid
              </h3>
            )} */}
            {/* {showModelDecesion === 'focus' && (
              <h3>
                <i className="icon-tickets" />
                Event
              </h3>
            )} */}
            {/* {showModelDecesion === 'visibility' && (
              <h3>
                <i className="fas fa-lock" />
                Private
              </h3>
            )} */}
            {showModelDecesion === 'location' && (
              <h3>
                <i className="fas fa-map-marker-alt" />
                Location
              </h3>
            )}

            {/* {showModelDecesion === 'dollar' && (
              <p>This feature is required payments.</p>
            )} */}
            {/* {showModelDecesion === 'snapshot' && (
              <p>Are you sure you want to enable this feature?</p>
            )} */}
            {/* {showModelDecesion === 'focus' && (
              <p>This will convert your session to an event.</p>
            )} */}
            {/* {showModelDecesion === 'visibility' && (
              <p>This feature is for invites only.</p>
            )} */}
            {showModelDecesion === 'location' && (
              <p>This feature enables location.</p>
            )}

            <div className="event-btn-wrapper">
              <Button
                className="btn-dark"
                onClick={() => {
                  // if (showModelDecesion === 'snapshot') {
                  //   setTopIcons({
                  //     ...topIcons,
                  //     ['focus']: true,
                  //   });
                  //   setNewButton(true);
                  //   // setShowComponent('CreateSnapShot');
                  // }
                  // if (showModelDecesion === 'focus') {
                  //   setTopIcons({
                  //     ...topIcons,
                  //     ['focus']: !topIcons.focus,
                  //     ['location']: topIcons.focus ? false : true,
                  //     ['store']: topIcons.focus ? true : false,
                  //   });
                  // }
                  // if (showModelDecesion === 'dollar') {
                  //   setTopIcons({
                  //     ...topIcons,
                  //     ['dollar']: !topIcons.dollar,
                  //   });
                  // }

                  // if (showModelDecesion === 'visibility') {
                  //   setTopIcons({
                  //     ...topIcons,
                  //     ['vaccinate']: !topIcons.vaccinate,
                  //   });
                  //   // privatee();
                  // }
                  if (showModelDecesion === 'location') {
                    setTopIcons({
                      ...topIcons,
                      location: !topIcons.location,
                    });
                    clearAll();
                  }

                  setShowModalButtons(false);
                }}>
                {/* {hostId === '' && showModelDecesion === 'snapshot' && ' ENABLE'} */}
                {/* {topIcons.focus && showModelDecesion === 'focus'
                  ? 'DISABLE'
                  : !topIcons.focus &&
                    showModelDecesion === 'focus' &&
                    ' ENABLE'} */}
                {/* {topIcons.dollar && showModelDecesion === 'dollar'
                  ? 'DISABLE'
                  : !topIcons.dollar &&
                    showModelDecesion === 'dollar' &&
                    ' ENABLE'} */}
                {/* {topIcons.vaccinate && showModelDecesion === 'visibility'
                  ? 'DISABLE'
                  : !topIcons.vaccinate &&
                    showModelDecesion === 'visibility' &&
                    ' ENABLE'} */}
                {topIcons.location && showModelDecesion === 'location'
                  ? 'Disable'
                  : !topIcons.location &&
                    showModelDecesion === 'location' &&
                    'Enable'}
              </Button>
              <div className=" text-center mt-2 as-link">
                <span
                  onClick={() => {
                    setShowModalButtons(false);
                  }}>
                  Nevermind
                </span>
              </div>
              {/* {showModelDecesion === 'dollar' && 
              <div className=" text-center mt-2 as-link">
                <span
                  onClick={() => {
                    setShowModalButtons(false);
                  }}>
                  Update Banking Details
                </span>
              </div>} */}
            </div>
          </div>
        </CustomModal>
      )}
      {showModel && (
        <CustomModal size="medium" onClose={() => setShowModel(false)}>
          <div className="filter-wrapper">
            {selectedModelName === 'funding_stage' && (
              <>
                <div className="filter-headings">
                  <h2 className="text-center"> Selected Fuding Stage</h2>
                </div>
                <div className="filter-category-list">
                  <ul>
                    {apiResponse &&
                      apiResponse.funded_stage &&
                      Object.entries(apiResponse.funded_stage).length > 0 &&
                      apiResponse.funded_stage.map((item, index) => (
                        <li key={`apiResponse.funded_stage-${index}`}>
                          <Form.Label>
                            {item.title}
                            <input
                              type="checkbox"
                              id={item.id}
                              value={item.title}
                              onChange={e => {
                                handleForPutIdsInArray(e, 'funding_stage');
                              }}
                              checked={
                                fundingStageId.includes(item.id) ||
                                fundingValues.includes(item.title)
                              }
                            />
                            <span />
                          </Form.Label>
                        </li>
                      ))}
                  </ul>
                  {/* <Button
                    className="btn-dark"
                    type="submit"
                    onClick={() => {
                      setShowModel(false);
                    }}>
                    Ok
                  </Button> */}
                </div>
              </>
            )}
            {selectedModelName === 'stage' && (
              <>
                <div className="filter-headings">
                  <h2 className="text-center">Selected Stage</h2>
                </div>
                <div className="filter-category-list">
                  <ul>
                    {apiResponse &&
                      apiResponse.stage &&
                      Object.entries(apiResponse.stage).length > 0 &&
                      apiResponse.stage.map((item, index) => (
                        <li key={`apiResponse.stage-${index}`}>
                          <Form.Label>
                            {item.title}
                            <input
                              type="checkbox"
                              id={item.id}
                              value={item.title}
                              onChange={e => {
                                handleForPutIdsInArray(e, 'stage');
                              }}
                              checked={
                                stageId.includes(item.id) ||
                                stageValues.includes(item.title)
                              }
                            />
                            <span />
                          </Form.Label>
                        </li>
                      ))}
                  </ul>
                  {/* <Button
                    className="btn-dark"
                    type="submit"
                    onClick={() => {
                      setShowModel(false);
                    }}>
                    Ok
                  </Button> */}
                </div>
              </>
            )}
            {selectedModelName === 'desired_raise' && (
              <>
                <div className="filter-headings">
                  <h2 className="text-center">Selected Desired Raise</h2>
                </div>
                <div className="filter-category-list">
                  <ul>
                    {apiResponse &&
                      apiResponse.desired_funds &&
                      Object.entries(apiResponse.desired_funds).length > 0 &&
                      apiResponse.desired_funds.map((item, index) => (
                        <li key={` apiResponse.desired_funds-${index}`}>
                          <Form.Label>
                            {item.title}
                            <input
                              type="checkbox"
                              id={item.id}
                              value={item.title}
                              onChange={e => {
                                handleForPutIdsInArray(e, 'desired_raise');
                              }}
                              checked={
                                desiredRiseId.includes(item.id) ||
                                desiredRiseValues.includes(item.title)
                              }
                            />
                            <span />
                          </Form.Label>
                        </li>
                      ))}
                  </ul>
                  {/* <Button
                    className="btn-dark"
                    type="submit"
                    onClick={() => {
                      setShowModel(false);
                    }}>
                    Ok
                  </Button> */}
                </div>
              </>
            )}
          </div>
        </CustomModal>
      )}

      {showModel === 'modal' && file && (
        <CustomModal
          size="large"
          onClose={() => {
            setShowModel('');
            setFile(null);
          }}>
          <CropImage
            type="snapshot"
            ratio={428 / 428}
            file={file}
            setFile={setFile}
            setCropImg={setShowModel}
            cropImg={cropImg}
            setImgUrl={setImagePath}
            imgUrl={imgUrl}
            sliderImage={imageArr}
            setSliderImage={setImageArr}
            setshowLoader={setshowLoader}
            ticketImg={ticketImg}
          />
        </CustomModal>
      )}
      {showComponent === 'CircleList' && (
        <CircleListBuilders
          onClose={() => {
            setShowComponent('');
          }}
          // setInviteCircle={setInviteCircle}
          hide={true}
          CircleUserListData={CircleUserListData}
        />
      )}
      {showComponent === 'inviteUser' && (
        <SendInvitation
          onClose={() => {
            setShowComponent('');
          }}
          contact={contact}
          setContact={setContact}
        />
      )}

      {leadership.length > 0 && showComponent === 'selectedLeader' && (
        <SelectedUsers
          onClose={() => {
            setShowComponent('');
          }}
          selectedAllUser={selectedLeadership}
          setSelectedAllUser={setSelectedLeadership}
          setUsers={setLeadership}
          users={leadership}
          alreadyAddedUser={acceptedUsersIds}
          removeObject={objectOfInviteUser}
          setRemoveObject={setObjectOfInviteUser}
          //   enterSession={enterSession}
        />
      )}
      {partners.length > 0 && showComponent === 'selectedPartners' && (
        <SelectedUsers
          onClose={() => {
            setShowComponent('');
          }}
          selectedAllUser={selectedpartners}
          setSelectedAllUser={setSelectedPartners}
          setUsers={setPartners}
          users={partners}
          alreadyAddedUser={acceptedUsersIds}
          removeObject={objectOfInviteUser}
          setRemoveObject={setObjectOfInviteUser}
          //   enterSession={enterSession}
        />
      )}

      {showComponent === 'persnal-model' && (
        <PersonalCreateModel
          list={list}
          setWorkSpaceIds={setWorkSpaceIds}
          workSpaceIds={workSpaceIds}
          onClose={() => {
            setShowComponent('');
            setTopIcons({
              ...topIcons,
              personal: !topIcons.personal,
            });
          }}
        />
      )}
      {members.length > 0 && showComponent === 'selectedMember' && (
        <SelectedUsers
          onClose={() => {
            setShowComponent('');
          }}
          selectedAllUser={selectedMembers}
          setSelectedAllUser={setSelectedMembers}
          setUsers={setMembers}
          users={members}
          alreadyAddedUser={acceptedUsersIds}
          removeObject={objectOfInviteUser}
          setRemoveObject={setObjectOfInviteUser}
          //   enterSession={enterSession}
        />
      )}
    </>
  );
};
export default AddNewSnapShot;
