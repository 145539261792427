import React, {useState, useRef, useEffect} from 'react';
import SlidingPanel from '../SlidingPanel';
import Scrollbars from 'react-custom-scrollbars';
import {Button, Col, Dropdown, Form, Row} from 'react-bootstrap';
import {labels} from '../../Constants/Translations';
import {selected_lang} from '../../Utils/env';
import {
  capitalizeFirst,
  catalogsTotalCostForAllType,
  transformDealArray,
} from '../../Utils/commonUtils';
import {errorMessage, successMessage} from '../../Utils/ToastMessages';
import commonApi from '../../Services/CommonService';
import {formatHoursMinutesTime} from '../../Utils/commonUtils';
import NorecordFound from '../NoRecord/NorecordFound';
import LoaderSpinner from '../Modals/LoaderSpinner';
import {useSelector, useDispatch} from 'react-redux';
import {getPipelineData} from '../../Store/Actions/ProfileAction';
import {
  catalogsTotalCost,
  formatPaymentDescription,
} from '../../Utils/commonUtils';
import {getDealsData} from '../../Store/Actions/ProfileAction';
import ConfirmationModel from '../ConfirmationModel/ConfirmationModel';
import {getUpdateDataOfDeals} from '../../Store/Actions/ProfileAction';
import {FormattedText} from '../../Utils/Helpers';

import SendInvitation from '../../Pages/Front/StaticPages/SendInvitation';
import CircleListBuilders from '../../Pages/Front/StaticPages/CircleListBuilders';
import Invite from '../SingleComponent/Invite';
import CustomModal from '../CustomModal';
import MembershipDealItem from './MembershipDealItem';
import {NoOfferingsFound} from '../../Utils/NoRecordConstant';
import { RoiLogo } from '../../Constants/Images';

const CreateDeal = props => {
  const [dealName, setDealName] = useState('');
  const [validated, setValidated] = useState(false);
  const [tabType, setTabType] = useState('search');

  const dispatch = useDispatch();

  const [showSearchProduct, setShowSearchProduct] = useState('');
  const [productDetail, setProductDetail] = useState([]);
  const [dealsIds, setDealsIds] = useState([]);
  const [selectedArray, setSelectedArray] = useState([]);

  const [deletedId, setDeletedId] = useState('');
  const [dealsList, setDealsList] = useState([]);
  const [showComponent, setShowComponent] = useState('');
  const [showLoader, setShowLoader] = useState(true);
  // const [totalCost, setTotalCost] = useState('');
  const buttonRef = useRef(null);
  // const pipelineList = useSelector(state => state.pipelineList);

  const [pipelineList, setPipelineList] = useState([]);
  const [pieplineId, setPieplineId] = useState('');

  const [showMore, setShowMore] = useState(false);
  const showMoreClass = showMore ? 'show-all' : '';
  const [addUser, setAddUser] = useState('');

  const [invoiceUsers, setInvoiceUsers] = useState([]);
  const [builders, setBuilders] = useState([]);

  const [allBuilders, setAllBuilders] = useState([]);
  const [originalDataOfMod, setOriginalDataOfMod] = useState([]);
  const [contact, setContact] = useState([]);
  const [hitApiAddedUser, setHitApiAddedUser] = useState();
  const [followers, setFollowers] = useState([]);
  const [membershipList, setMembershipList] = useState([]);
  const [membershipList2, setMembershipList2] = useState([]);

  const [selectedDurationIds, setSelectedDurationIds] = useState([]);
  const [inviteLoader, setInviteLoader] = useState(true);
  const [inviteFollowers, setInviteFollowers] = useState([]);
  const [followersList, setFollowersList] = useState([]);
  const [inviteQry, setInviteQry] = useState('');

  const handle = e => {
    const form = e.currentTarget;

    if (
      form.checkValidity() === false ||
      dealsIds.length === 0 ||
      (props.type === 'pipeline' && builders.length === 0)
    ) {
      e.preventDefault();
      e.stopPropagation();

      // console.log(form.checkValidity() ,'form.checkValidity()')
      if (props.type === 'pipeline' && builders.length === 0) {
        errorMessage('Please Select Customer');
      } else if (dealsIds.length === 0) {
        errorMessage('Please Add Catalogs');
      }
      // else if (selectedAliasTeamIds.length === 0) {
      //   errorMessage('Please select one team');
      // } else if (selectedAliasCustomerIds.length === 0) {
      //   errorMessage('Please select one customer');
      // } else if (!premesionExist) {
      //   errorMessage('Please select permesions');
      // }

      setValidated(true);
    } else {
      e.preventDefault();
      create_deal();
    }
  };

  const get_Pipeline_Data = () => {
    // const payload = {search_query: value};
    commonApi
      .get_pipeline_details({})
      .then(res => {
        if (res.status === 200) {
          setPipelineList(res.data);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  useEffect(() => {
    // dispatch(getPipelineData({user_id: props.userData && props.userData.id}));
    get_Pipeline_Data();
    // setShowLoader(true);
    get_catalogs_for_invoices('');
  }, []);

  const get_catalogs_for_invoices = value => {
    const payload = {search_query: value};
    commonApi
      .get_catalogs_for_invoices(payload)
      .then(res => {
        if (res.status === 200) {
          setProductDetail(res.catalog_data);

          setTimeout(() => {
            setShowLoader(false);
          }, 500);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const create_deal = () => {
    const totalCost = catalogsTotalCost(dealsList);

    console.log(totalCost, 'totalCosttotalCost');

    const payload = {
      pipeline_id: pieplineId,
      user_id:
        props.type === 'pipeline' && builders.length > 0
          ? builders.toString(',')
          : props?.userData?.id,
      deal_name: dealName,
      deal_value: totalCost.toString(),
      catalog_ids: selectedArray.length > 0 ? selectedArray : [],
      phase_id: props.type === 'pipeline' ? props.phaseId : '',
      deal_id: props.editDealItem ? props.editDealItem.id : '',
    };

    console.log('payload', payload);
    commonApi
      .create_deal(payload)
      .then(res => {
        if (res.status === 200) {
          var payload = {
            user_id:
              props.userData && props.userData.id ? props.userData.id : '',
          };

          if (props.editDealItem) {
            dispatch(getUpdateDataOfDeals(true));
          }
          props.userData && dispatch(getDealsData(payload));
          props.getUserData && props.getUserData();
          successMessage(res.message);
          if (props.type === 'pipeline') {
            dispatch(getPipelineData());
          }
          props.onClose();
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const AddCataloginDeal = item => {
    // if(item.catalog_details && item.catalog_details.length > 0 && !groupIds.includes(item.id)){
    //   setGroupIds(prev => [...prev, item.catalog_ids]);
    // }
    if (item.catalog_details && item.catalog_details.length > 0) {
      let memberShip = false;
      item.catalog_details.forEach((miniItem, index) => {
        if (!dealsIds.includes(miniItem.id)) {
          if (miniItem.catalog_type === 'membership') {
            setMembershipList(prev => [
              ...prev,
              {
                ...miniItem,

                level: {
                  level_id:
                    miniItem.membership_levels &&
                    miniItem.membership_levels.length > 0
                      ? miniItem.membership_levels[0].id
                      : '',
                  frequency_id:
                    miniItem.membership_levels &&
                    miniItem.membership_levels.length > 0 &&
                    miniItem.membership_levels[0].frequencies.length > 0
                      ? miniItem?.membership_levels[0]?.frequencies[0]?.id
                      : '',
                },
                cost:
                  miniItem.membership_levels &&
                  miniItem.membership_levels.length > 0 &&
                  miniItem.membership_levels[0].frequencies.length > 0
                    ? miniItem?.membership_levels[0]?.frequencies[0]
                        ?.payment_amount
                    : '',
              },
            ]);

            memberShip = true;
          } else {
            setDealsList(prev => [...prev, miniItem]);
            setDealsIds(prev => [...prev, miniItem.id]);
            setSelectedArray(prev => [
              ...prev,
              {type: miniItem.catalog_type, id: miniItem.id},
            ]);
          }
        }
      });
      if (memberShip === true) {
        setShowComponent('membershipLevel');
      }
    } else {
      if (item.catalog_type === 'membership') {
        setMembershipList([
          {
            ...item,

            level: {
              level_id:
                item.membership_levels && item.membership_levels.length > 0
                  ? item.membership_levels[0].id
                  : '',
              frequency_id:
                item.membership_levels &&
                item.membership_levels.length > 0 &&
                item.membership_levels[0].frequencies.length > 0
                  ? item?.membership_levels[0]?.frequencies[0]?.id
                  : '',
            },
            cost:
              item.membership_levels &&
              item.membership_levels.length > 0 &&
              item.membership_levels[0].frequencies.length > 0
                ? item?.membership_levels[0]?.frequencies[0]?.payment_amount
                : '',
          },
        ]);

        setShowComponent('membershipLevel');
      } else {
        setDealsIds(previous => [...previous, item.id]);
        setDealsList(previous => [...previous, item]);
        setSelectedArray(prev => [
          ...prev,
          {type: item.catalog_type, id: item.id},
        ]);
      }
    }

    // Remove the item from dealsList and its corresponding ID from dealsIds
    // setDealsList(previous => previous.filter(deal => deal.id !== item.id));
    // setDealsIds(previous => previous.filter(id => id !== item.id));
  };
  const DeleteCataloginDeal = item => {
    setDealsList(previous => previous.filter(deal => deal.id !== item.id));
    setDealsIds(previous => previous.filter(id => id !== item.id));
    setSelectedArray(previous =>
      previous.filter(miniItem => miniItem.id !== item.id),
    );
    setShowComponent('');
  };

  useEffect(() => {
    if (pipelineList.length > 0) {
      if (!props.editDealItem) {
        if (props.type === 'pipeline') {
          setPieplineId(props.PieplineId);
        } else {
          setPieplineId(pipelineList.length > 0 ? pipelineList[0].id : '');
        }
      }
    }
  }, [pipelineList]);

  useEffect(() => {
    if (props.editDealItem) {
      const selectedArray = transformDealArray(
        props.editDealItem.catalog_details,
      );
      setPieplineId(props.editDealItem.pipeline_id);
      setDealName(props.editDealItem.deal_name);
      setDealsIds(props.editDealItem.catalog_details.map(({id}) => id));
      setSelectedArray(selectedArray);
      setDealsList(props.editDealItem.catalog_details);
    }
  }, [props.editDealItem]);

  useEffect(() => {
    if (contact.length > 0) {
      setInviteLoader(true);
      setInviteQry('');
    }
  }, [contact]);

  const handleCircle = () => {
    setShowComponent('CircleList');
  };

  const CircleUserListData = item => {
    const followersIds = inviteFollowers.map(({id}) => id);
    if (item.length > 0) {
      item.forEach((miniItem, index) => {
        if (
          !builders.includes(miniItem.id) &&
          followersIds.includes(miniItem.id)
        ) {
          setBuilders(previous => [...previous, miniItem.id]);
          setAllBuilders(previous => [...previous, miniItem]);
          setOriginalDataOfMod(previous => [...previous, miniItem]);
        }
      });
    }
  };

  // const MembershipItem = ({
  //   item,
  //   ItemIndex,
  //   membershipList,
  //   membershipList2,
  //   setMembershipList,
  //   setMembershipList2,
  // }) => {
  //   const [levels, setLevels] = useState([]);

  //   const [durations, setDuartions] = useState([]);
  //   const [levelType, setLevelType] = useState('');
  //   const [durationType, setDurationType] = useState('');
  //   const [durationItem, setDurationItem] = useState({});
  //   const [levelItem, setLevelItem] = useState({});
  //   const [itemCost, setItemCost] = useState(item.cost ? item.cost : '');

  //   const handleAmount = item => {
  //     setDurationItem(item ? item : {});
  //     if (item.payment_amount && item.payment_amount !== '') {
  //       setItemCost(item.payment_amount);
  //     } else {
  //       setItemCost('0.00');
  //     }
  //   };

  //   useEffect(() => {
  //     if (item.catalog_type === 'membership') {
  //       console.log(
  //         item.membership_levels,
  //         'item.membership_levelsitem.membership_levels',
  //       );
  //       const filterLevel = item.membership_levels.filter(
  //         item => item.selected === true,
  //       );
  //       if (filterLevel.length > 0) {
  //         console.log(filterLevel, '=====>filterLevelfilterLevel');
  //         const selectedLevel = filterLevel.length > 0 ? filterLevel[0] : {};
  //         setLevelItem(selectedLevel);
  //         setLevels(
  //           item.membership_levels && item.membership_levels.length > 0
  //             ? item.membership_levels
  //             : [],
  //         );
  //         setLevelType(selectedLevel.id);
  //       } else {
  //         setLevelItem(
  //           item.membership_levels && item.membership_levels.length > 0
  //             ? item.membership_levels[0]
  //             : [],
  //         );
  //         setLevels(
  //           item.membership_levels && item.membership_levels.length > 0
  //             ? item.membership_levels
  //             : [],
  //         );
  //         setLevelType(
  //           item.membership_levels && item.membership_levels.length > 0
  //             ? item.membership_levels[0].id
  //             : '',
  //         );
  //       }
  //     }
  //   }, []);

  //   useEffect(() => {
  //     if (item.catalog_type === 'membership' && levelType !== '') {
  //       const selectedLevel = levels.filter(item => item.id === levelType);
  //       console.log(selectedLevel, 'selectedLevelselectedLevel');
  //       setDuartions(
  //         item.membership_levels.length > 0 &&
  //           selectedLevel[0].frequencies.length > 0
  //           ? selectedLevel[0].frequencies
  //           : [],
  //       );

  //       if (selectedLevel.length > 0 && selectedLevel[0].selected) {
  //         // setDurationType(item?.level?.frequency?.id)
  //         const filterFrequency =
  //           selectedLevel[0].frequencies.length > 0 &&
  //           selectedLevel[0].frequencies.filter(item => item.selected === true);
  //         setDurationType(filterFrequency.length > 0 && filterFrequency[0].id);
  //         handleAmount(filterFrequency[0]);
  //       } else {
  //         setDurationType(
  //           item.membership_levels.length > 0 &&
  //             selectedLevel[0].frequencies.length > 0
  //             ? selectedLevel[0].frequencies[0].id
  //             : '',
  //         );
  //         handleAmount(selectedLevel[0].frequencies[0]);
  //       }

  //       let durationId =
  //         item.membership_levels.length > 0 &&
  //         selectedLevel[0].frequencies.length > 0
  //           ? selectedLevel[0].frequencies[0].id
  //           : '';

  //       // handleSelectionChange(levelType, durationId);
  //     }
  //   }, [levelType]);

  //   const handleSelectionChange = (levelId, durationId) => {
  //     const updatedGroup = membershipList2.map((item, index) => {
  //       if (index === ItemIndex) {
  //         return {
  //           ...item,
  //           selectedLevelId: levelType,
  //           selectedDurationId: durationId,
  //         };
  //       }
  //       return item;
  //     });
  //     setMembershipList2(updatedGroup);
  //   };

  //   return (
  //     <div>
  //       <h1 className="text-left mb-2">{item.title}</h1>

  //       <div className="mb-3">
  //         <Form.Label controlid="validationCustom01">Select Level </Form.Label>
  //         <Dropdown
  //           className="select-wrapper"
  //           onSelect={(eventKey, event) => {
  //             var item = JSON.parse(eventKey);
  //             setLevelItem(item);
  //             setLevelType(item.id);
  //           }}
  //           drop="down">
  //           <Dropdown.Toggle variant="primary">
  //             {levelType
  //               ? levels.find(item => item.id === levelType).level_name
  //               : 'Select Role'}
  //           </Dropdown.Toggle>

  //           <Dropdown.Menu>
  //             {levels.map((item, index) => (
  //               <Dropdown.Item
  //                 key={`st-${index}`}
  //                 eventKey={JSON.stringify(item)}
  //                 active={item.id === levelType}>
  //                 {item.level_name}
  //               </Dropdown.Item>
  //             ))}
  //           </Dropdown.Menu>
  //         </Dropdown>
  //       </div>

  //       <div className="mb-3">
  //         <Form.Label controlid="validationCustom01">
  //           Select Duration{' '}
  //         </Form.Label>
  //         <Dropdown
  //           className="select-wrapper"
  //           onSelect={(eventKey, event) => {
  //             var item = JSON.parse(eventKey);
  //             handleAmount(item);
  //             setDurationType(item.id);
  //           }}
  //           drop="down">
  //           <Dropdown.Toggle variant="primary">
  //             {formatPaymentDescription(
  //               durationType
  //                 ? durations.find(item => item.id === durationType)
  //                 : 'Select Role',
  //             )}
  //           </Dropdown.Toggle>

  //           <Dropdown.Menu>
  //             {durations.map((item, index) => (
  //               <Dropdown.Item
  //                 key={`st-${index}`}
  //                 eventKey={JSON.stringify(item)}
  //                 // eventKey={item.id.toString()}
  //                 active={item.id === durationType}>
  //                 {formatPaymentDescription(item)}
  //               </Dropdown.Item>
  //             ))}
  //           </Dropdown.Menu>
  //         </Dropdown>
  //       </div>
  //     </div>
  //   );
  // };

  const handleMembershipChange = (index, levelId, durationId) => {
    // Update membershipList with levelId and durationId for the specific item
    const updatedList = [...membershipList];
    updatedList[index] = {
      ...updatedList[index],
      selectedLevelId: levelId,
      selectedDurationId: durationId,
    };
    setMembershipList(updatedList);
  };

  const handleSaveMemberShip = () => {
    console.log(membershipList, 'membershipListmembershipListmembershipList');
    // alert('')
    const ids = membershipList.map(({id}) => id);
    const flattenedArrayids = ids.flat(1);
    setDealsIds(previous => [...previous, ...flattenedArrayids]);
    const flattenedArrayMemberShip = membershipList.flat(1);
    setDealsList(prev => [...prev, ...flattenedArrayMemberShip]);

    const newArray = membershipList.map(item => ({
      type: item.catalog_type,
      id: item.id,
      level: item.level,
    }));

    const flattenedArray = newArray.flat(1);

    setSelectedArray(prev => [...prev, ...flattenedArray]);

    setShowComponent('');
    setMembershipList([]);
  };

  const renderHeader = () => {
  
    return (
      <>
        
          <img src={RoiLogo} alt="" />
        
      </>
    );
  };


  console.log(dealsList, 'dealsListdealsListdealsList');

  console.log(membershipList, 'membershipListmembershipList');
  console.log(selectedArray, 'selectedArrayselectedArrayselectedArray');
  // console.log(groupIds, 'groupids');
  // console.log(dealsIds, 'dealsids');
  // console.log(productDetail, 'productDetail');

  console.log(dealsList, 'dealsListdealsListdealsListdealsList');

  useEffect(() => {
    setShowSearchProduct('');
  }, [tabType]);
  return (
    <>
      {' '}
      
      <SlidingPanel
        hidePanel={() => {
          props.onClose();
        }}
        // closeIconRight={props.closeIconRight ? props.closeIconRight : false}
        renderData={renderHeader}
        showBlackHeader={true}
        headerPadding={'24px 20px'}
        lastDivStyle={{borderRadius: '35px', background: '#f7f6f2'}}
        firstDivStyle={{
          padding: 0,
          background:
            'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)',
        }}>
          {showLoader && <LoaderSpinner className={'curved-spinner-bg'} />}
        <div className="serach-filter-wrapper create-sessionEvents">
          <div className="fix-width-forms fixed-width-inputs black-head-form-pad-left pt-3">
            <div className="deal_total_cost">
              {dealsList.length > 0 ? '$' + catalogsTotalCost(dealsList) : '$0'}
            </div>
          </div>
          {pipelineList.length > 1 && (
            <div className="roi-form-dropdown fixed-width-inputs black-head-form-pad-left fix-width-forms mb-3 roi-custom-form-dropdown">
              <div className="select-wrapper select-deal-wrapper">
                <Dropdown
                  onSelect={(eventKey, event) => {
                    setPieplineId(eventKey);
                  }}
                  drop="down">
                  <Dropdown.Toggle variant="primary">
                    {pieplineId
                      ? pipelineList.find(item => item.id === pieplineId)?.title
                      : ' '}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {pipelineList.map((item, index) => (
                      <Dropdown.Item
                        key={`st-${index}`}
                        eventKey={item.id}
                        active={item.id === pieplineId}>
                        {item.title}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          )}

          <div className="create-ticket-form  primary_panel_content ">
            <div className="create_deal_screen primary_panel_content">
              <Form
                noValidate
                validated={validated}
                onSubmit={handle}
                className="primary_panel_content">
                <div className="fieldset fix-width-forms fixed-width-inputs black-head-form-pad-left">
                  <div className="d-flex justify-content-between">
                    <Form.Label
                      className="w-auto deal_heading"
                      controlid="validationCustom01">
                      Deal Name
                    </Form.Label>

                    {props.type && props.type === 'pipeline' && (
                      <div
                        style={{color: '#52bd41'}}
                        className="template-text pb-1 as-link fit_content  text-custom-13 f_600"
                        onClick={() => {
                          setAddUser('addUser');
                        }}>
                        {/* {builders &&
                          builders.length > 0 &&
                          builders.length + ' '}{' '} */}
                        Select Customer
                      </div>
                    )}
                  </div>

                  <div className="roi-form-input">
                    <Form.Control
                      type="text"
                      value={dealName}
                      pattern="^\S.*$"
                      required
                      placeholder="Deal Name"
                      onChange={e => {
                        setDealName(e.target.value);
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Name is required.
                    </Form.Control.Feedback>
                  </div>
                </div>

                <div className=" fix-width-forms fixed-width-inputs black-head-form-pad-left event-tabs tabs-style-2 mb-3">
                  {' '}
                  <ul>
                    <li>
                      <Button
                        className={tabType === 'search' ? 'active' : ''}
                        onClick={() => {
                          setTabType('search');
                        }}>
                        {labels.search_tab_text[selected_lang]}
                      </Button>
                    </li>

                    <li>
                      <Button
                        className={tabType === 'added' ? 'active' : ''}
                        onClick={() => {
                          setTabType('added');
                        }}>
                        {labels.added_tab_text[selected_lang]}
                      </Button>
                    </li>
                  </ul>
                </div>

                {tabType === 'search' && (
                  <div className="fix-width-forms fixed-width-inputs black-head-form-pad-left fieldset">
                    <Form.Label
                      className="w-auto deal_heading"
                      controlid="validationCustom01">
                      Offerings
                    </Form.Label>

                    <div className="roi-form-input">
                      <Form.Control
                        type="text"
                        value={showSearchProduct}
                        pattern="^\S.*$"
                        placeholder="Offerings"
                        onChange={e => {
                          setShowSearchProduct(e.target.value);
                          get_catalogs_for_invoices(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                )}
                <div className="refferal-list-wrapper">
                  <Scrollbars>
                    <div className="fix-width-forms  fixed-width-inputs black-head-form-pad-left search_deal_list">
                      {tabType === 'search' && productDetail?.length > 0 ? (
                        <>
                          {productDetail.map((item, index) => {
                            const shouldDisplayItem = !dealsIds.includes(
                              item.id,
                            );
                            const shouldDisplayCatalogDetails =
                              item.catalog_details?.length > 0
                                ? item.catalog_details.some(
                                    catalogItem =>
                                      !dealsIds.includes(catalogItem.id),
                                  )
                                : true;

                            if (
                              shouldDisplayItem &&
                              shouldDisplayCatalogDetails
                            ) {
                              return (
                                <div
                                  className="deal_item  as-link"
                                  key={index}
                                  onClick={() => {
                                    AddCataloginDeal(item);
                                  }}>
                                  <div className="f_600_h text_overflow_container">
                                    {item.title}
                                  </div>

                                  <FormattedText
                                    className={`profile-description ${showMoreClass}`}
                                    style={'margin'}
                                    content={
                                      item.description ? item.description : ''
                                    }
                                  />
                                  <div className="d-flex justify-content-between">
                                    <span className="deal_time">
                                      {item.duration
                                        ? formatHoursMinutesTime(item.duration)
                                        : ''}
                                    </span>
                                    {item.catalog_details &&
                                    item.catalog_details.length > 0 ? (
                                      <span className="deal_cost f_600_h">
                                        $
                                        {catalogsTotalCost(
                                          item.catalog_details,
                                        )}
                                      </span>
                                    ) : (
                                      item.cost && (
                                        <span className="deal_cost f_600_h">
                                          ${item.cost}
                                        </span>
                                      )
                                    )}
                                  </div>
                                </div>
                              );
                            }
                            return null; // If conditions not met, don't render anything
                          })}
                          {/* Check if all items are excluded from display */}
                          {productDetail.every(item => {
                            if (item.catalog_details?.length > 0) {
                              // If there are catalog details, check each one against dealsIds
                              return item.catalog_details.every(catalogItem =>
                                dealsIds.includes(catalogItem.id),
                              );
                            } else {
                              // If there are no catalog details, check the item id against dealsIds
                              return dealsIds.includes(item.id);
                            }
                          }) && (
                            <NorecordFound noRecordData={NoOfferingsFound} />
                          )}
                        </>
                      ) : (
                        tabType === 'search' &&
                        !showLoader && (
                          <NorecordFound noRecordData={NoOfferingsFound} />
                        )
                      )}

                      {tabType === 'added' && productDetail?.length > 0 ? (
                        <div className="added_deals_list">
                          {productDetail.map((item, index) => {
                            if (dealsIds.includes(item.id)) {
                              return (
                                <>
                                  <div className="d-flex justify-content-center align-items-center  gap-4">
                                    <div
                                      className="deal_item mb-0 flex-1"
                                      key={index}>
                                      <div
                                        className="deal_info"
                                        // onClick={() => DeleteCataloginDeal(item)}
                                      >
                                        <div className="f_600_h text_overflow_container">
                                          {item.title}
                                        </div>
                                        <FormattedText
                                          className={`profile-description ${showMoreClass}`}
                                          style={'margin'}
                                          content={
                                            item.description
                                              ? item.description
                                              : ''
                                          }
                                        />
                                        <div className="d-flex justify-content-between">
                                          <span className="deal_time">
                                            {item.duration
                                              ? formatHoursMinutesTime(
                                                  item.duration,
                                                )
                                              : ''}
                                          </span>
                                          {item.catalog_details &&
                                          item.catalog_details.length > 0 ? (
                                            <span className="deal_cost f_600_h">
                                              {catalogsTotalCostForAllType(
                                                item.catalog_details,
                                                dealsList,
                                              )}
                                            </span>
                                          ) : (
                                            <span className="deal_cost f_600_h">
                                              {catalogsTotalCostForAllType(
                                                [item],
                                                dealsList,
                                              )}
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="remove_deal as-link"
                                      onClick={() => {
                                        setDeletedId(item);
                                        setShowComponent('show_delete_model');
                                      }}>
                                      <i className="fas fa-times cross_icon"></i>
                                    </div>
                                  </div>
                                </>
                              );
                            }
                            return null; // If ID not included, don't render anything
                          })}
                          {/* Check if any items were rendered */}
                          {productDetail.every(
                            item => !dealsIds.includes(item.id),
                          ) && (
                            <NorecordFound noRecordData={NoOfferingsFound} />
                          )}
                        </div>
                      ) : (
                        tabType === 'added' &&
                        !showLoader && (
                          <NorecordFound noRecordData={NoOfferingsFound} />
                        )
                      )}
                    </div>
                  </Scrollbars>
                </div>

                <Button
                  className="btn-dark"
                  type="submit"
                  style={{display: 'none'}}
                  ref={buttonRef}>
                  Save
                </Button>
              </Form>
            </div>
          </div>

          <div className="filter-btn-wrapper fix-width-forms ">
            <div
              className="invite-btn text-center set_opacity fix-width-forms fixed-width-inputs  bg_transparent pb-2"
              style={{bottom: 0}}>
              <Button
                style={{padding: '13px 70px'}}
                className="btn-dark mb-0 mt-2"
                //   type="submit"
                onClick={() => {
                  buttonRef.current.click();
                }}>
                {props.editDealItem ? 'Update' : 'Save'}
              </Button>
            </div>
          </div>
        </div>
      </SlidingPanel>
      {showComponent === 'show_delete_model' && deletedId && (
        <ConfirmationModel
          closeModel={() => {
            setShowComponent('');
          }}
          discription={'Are you sure you want to remove this product'}
          buttonOne={'Yes'}
          buttonTwo={'No'}
          ButtonOneAction={() => {
            DeleteCataloginDeal(deletedId);
          }}
        />
      )}
      {addUser === 'addUser' && (
        <SlidingPanel
          hidePanel={() => {
            setAddUser('');
            if (invoiceUsers.length !== builders.length) {
              setBuilders(invoiceUsers);
            }
            if (inviteQry !== '') {
              setInviteFollowers(followersList);
              setInviteQry('');
            }
            setFollowers([]);
          }}>
          <div className="primary_panel_content">
            <div className="refferal-list-wrapper ">
              {inviteLoader && (
                <div className="invite_loader_hieght">
                  <LoaderSpinner />
                </div>
              )}
              <Scrollbars>
                <div
                  className="fix-width-forms fixed-width-inputs black-head-form-pad-left"
                  style={{paddingBottom: '40px'}}>
                  <Invite
                    type={'refer'}
                    component={'invoice'}
                    pipeline={true}
                    moderator={builders}
                    setModerator={setBuilders}
                    selectedModData={allBuilders}
                    setSelectedModData={setAllBuilders}
                    originalDataOfMod={originalDataOfMod}
                    setOriginalDataOfMod={setOriginalDataOfMod}
                    hideCircleIcon={true}
                    onClose={() => {
                      setAddUser('');
                      if (builders.length > 0) {
                        setInvoiceUsers(builders);
                      }
                    }}
                    onClick={() => {
                      setShowComponent('inviteUser');
                      setTimeout(() => {
                        if (contact.length === 0 && inviteQry !== '') {
                          setInviteFollowers(followersList);
                          setInviteQry('');
                        }
                      }, 1000);
                    }}
                    contact={contact}
                    setContact={setContact}
                    forApiHit={showComponent}
                    handleCircle={handleCircle}
                    setShowComponent={setShowComponent}
                    hitApiAddedUser={hitApiAddedUser}
                    setOriginalFolowers={setFollowers}
                    pieplineId={pieplineId}
                    showLoader={inviteLoader}
                    setshowLoader={setInviteLoader}
                    followers={inviteFollowers}
                    setFollowers={setInviteFollowers}
                    setFollowersList={setFollowersList}
                    followersList={followersList}
                    setSearchQry={setInviteQry}
                    searchQry={inviteQry}
                  />
                </div>
              </Scrollbars>
            </div>
            {inviteFollowers.length > 0 && (
              <div className="filter-btn-wrapper pt-2 fix-width-forms fixed-width-inputs  bg_transparent bottom-0">
                <div className="invite-btn text-center ">
                  <Button
                    className="btn-dark"
                    type="button"
                    onClick={() => {
                      setAddUser('');
                      if (builders.length > 0) {
                        setInvoiceUsers(builders);
                      }
                      setFollowers([]);
                    }}>
                    Select
                  </Button>
                </div>
              </div>
            )}
          </div>
        </SlidingPanel>
      )}
      {showComponent === 'inviteUser' && (
        <SendInvitation
          setContact={setContact}
          setHitApiAddedUser={setHitApiAddedUser}
          onClose={() => {
            setShowComponent('');
          }}
        />
      )}
      {showComponent === 'CircleList' && (
        <CircleListBuilders
          onClose={() => {
            setShowComponent('');
          }}
          hide={true}
          CircleUserListData={CircleUserListData}
        />
      )}
      {showComponent === 'membershipLevel' && (
        <CustomModal
          size="small"
          onClose={() => {
            setMembershipList([]);
            setShowComponent('');
          }}
          className="availability-modal">
          <div
            className="ticket-dates-wrapper  meetup_date_repeat"
            style={{width: '-webkit-fill-available'}}>
            {/* <div className="new-session-modal estimate_name_modal "> */}
            {/* <div className='refferal-list-wrapper'> */}
            {/* <Scrollbars> */}
            {membershipList.length > 0 &&
              membershipList.map((item, index) => (
                <MembershipDealItem
                  item={item}
                  ItemIndex={index}
                  // onSelectionChange={(index, levelId, durationId)=>{
                  //   handleMembershipChange(index, levelId, durationId)
                  // }}
                  setMembershipList={setMembershipList}
                  setMembershipList2={setMembershipList2}
                  membershipList={membershipList}
                  membershipList2={membershipList2}
                />
              ))}
            {/* </Scrollbars> */}
            {/* </div> */}

            <div className="invite-btns  p-0 pt-3 text-center">
              <Button
                className="px-5"
                onClick={() => {
                  handleSaveMemberShip();
                }}>
                Select
              </Button>
            </div>

            {/* <Button
                className="no-thanks m-0"
                onClick={() => {
                 
                }}>
                Delete
              </Button> */}
            {/* </div> */}
          </div>
        </CustomModal>
      )}
    </>
  );
};

export default CreateDeal;
