import React, {useState, useEffect} from 'react';
import {Button,Dropdown} from 'react-bootstrap';
import Scrollbars from 'react-custom-scrollbars';

import ProfileComponent from '../ProfilePage/ProfileComponent';
import {DEFAULT_IMAGE} from '../../Utils/env';
import commonApi from '../../Services/CommonService';
import {errorMessage, successMessage} from '../../Utils/ToastMessages';
import ListUsers from '../Common/ListUsers';
import MineTickets from './MineTickets';
import EventTickets from './EventTickets';
import moment from 'moment';
import ReferOpportunity from './ReferOpportunity';
import SlidingPanel from '../SlidingPanel';
import LoaderSpinner from '../Modals/LoaderSpinner';
import SmartChatPage from '../Common/SmartChatPage';
import API from '../../Services/SessionServices';
import Carousel, {consts} from 'react-elastic-carousel';
import {chatroomInfo, collectingUsers} from '../../Utils/commonUtils';
import CustomModal from '../CustomModal';
import {connect} from 'react-redux';
import {fixName} from '../../Utils/commonUtils';
import { labels } from '../../Constants/Translations';
import { selected_lang } from '../../Utils/env';

const PurchaseTicket = props => {
  const [profileId, setProfileId] = useState();
  const [sliderImg, setSliderImg] = useState([]);
  const [heartClass, setHeartClass] = useState(props.item.favourite);
  const [showLoader, setshowLoader] = useState(false);
  const [rsvp, setRsvp] = useState([]);
  const [eventData, setEventData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [allTickets, setAllTickets] = useState([]);
  const [minCost, setMinCost] = useState('');
  const [maxCost, setMaxCost] = useState('');
  const [numOfTickets, setNumOfTickets] = useState('1');
  const [showComponent, setShowComponent] = useState('');
  const [recursionData, setRecursionData] = useState([]);
  const [idOfRecursion, setIdOfRecursion] = useState('');
  const [ticketId, setTicketId] = useState('');
  const [chatDetail, setChatDetail] = useState({});
  const [paidTickets, setPaidTickets] = useState([]);
  const [mediaMoment, setMediaMoment] = useState([]);
  const [modelImg, setModelImg] = useState('');
  const [audienceIds, setAudienceIds] = useState([]);
  const [modratorIds, setModratorIds] = useState([]);

  const [selectedTicket, setSelectedTicket] = useState(1);
  // const [destination, setDestination] = useState(props.item.address);
  const destination = props.item.address;

  // let image = props.item.image;
  // const openInNewTab = url => {
  //   window.open(url, '_blank', 'noopener,noreferrer');
  // };

  // const imgSliderSettings = {
  //   dots: true,
  //   arrows: false,
  //   infinite: false,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  // };
  useEffect(() => {
    if (showComponent === '') {
      // setshowLoader(true);
      eventDetail();
      recursion();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showComponent, props.item]);
  // useEffect(() => {
  //
  //   setAudienceIds(props.item.audience_ids)
  // }, [props.item]);

  const manageRsvpDolby = () => {
    const newData = paidTickets.map(
      ({event_ticket_type_id, total_tickets}) => ({
        event_ticket_type_id,
        total_tickets,
      }),
    );
    var data = {
      event_id:
        props.type === 'refer' ? props.item.refer_type_id : props.item.id,
      recursion_id: idOfRecursion,
      tickets:
        newData.length > 0
          ? newData
          : [{total_tickets: numOfTickets, event_ticket_type_id: ticketId}],
    };

    commonApi
      .manage_rsvp_dolby(data)
      .then(res => {
        if (res.success === true) {
          eventDetail();
          successMessage(res.message);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const getAvailabletickets = (id, conferenceid) => {
    setIdOfRecursion(id);
    setshowLoader(true);
    var data = {
      conference_id: conferenceid,
      recursion_id: id,
    };
    commonApi
      .get_available_tickets(data)
      .then(res => {
        if (res) {
          //
          setshowLoader(false);
          // successMessage(res.message);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const favouriteOpportunity = id => {
    setshowLoader(true);
    var data = {conference_id: id};
    commonApi
      .manage_favourite(data)
      .then(res => {
        if (res) {
          //
          setshowLoader(false);
          successMessage(res.message);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const manageShareTickets = () => {
    var payLoad = {
      user_ticket_id: props.allTickets && props.allTickets.id,
    };
    commonApi

      .manage_share_tickets(payLoad)
      .then(response => {
        if (response.status === 200) {
          successMessage(response.message);
          props.onClose();
        }
      })
      .catch(err => {
        // setShowLoader(false);
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const recursion = () => {
    // setshowLoader(true);
    var payLoad = {
      conference_id:
        props.type === 'refer' ? props.item.refer_type_id : props.item.id,
    };
    commonApi

      .get_conference_recursion(payLoad)
      .then(response => {
        if (response.status === 200) {
          setRecursionData(response.recursion_list);
          setshowLoader(false);
        }
      })
      .catch(err => {
        // setShowLoader(false);
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const eventDetail = () => {
    var payLoad = {
      event_id:
        props.type === 'refer' ? props.item.refer_type_id : props.item.id,
    };
    commonApi

      .event_detail(payLoad)
      .then(response => {
        if (response.status === 200) {
          setSliderImg(response.event_slider_images);
          setEventData(response.event_detail);
          setAllTickets(response.tickets);
          setAllData(response);
          setMediaMoment(response.event_moment_media);
          if (
            response.group_chat_det.audience_ids &&
            response.group_chat_det.audience_ids.length > 0
          ) {
            setAudienceIds(response.group_chat_det.audience_ids);
          }
          if (
            response.group_chat_det.moderator_ids &&
            response.group_chat_det.moderator_ids.length > 0
          ) {
            setModratorIds(response.group_chat_det.moderator_ids);
          }

          //   setSearchEventAud(response.pending_audience);
          //   setSerachEventData(response.audience_details);
          //   setSearchModDetail(response.moderators_details)
          setRsvp(response.rsvp);
          //   setSearchQry(response.rsvp);
          //   setPenMod(response.pending_moderator);
          //   setSearchPenMod(response.pending_moderator)
          //   setSessionData(response.event_detail);
          //   setModAud(response);
          //   setModDetail(response.moderators_details)
          if (props.type === 'refer') {
            props.getReferrals('', '', props.arrowButton, 1);
          } else {
            if (!props.type) {
              props.getApiSessionList(props.sessionType, '', !props.typeIs);
            }
          }

          setshowLoader(false);
        }
      })
      .catch(err => {
        // setShowLoader(false);
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const handleCommonChat = (data, type) => {};

  const chatHandle = () => {
    showMessagesAll();
  };

  const showMessagesAll = () => {
    const chatroome =
      'web_chatroom_event_' + (Math.random() + 1).toString(36).substring(3);
    let audienceidsEvent = audienceIds;
    if (
      audienceidsEvent &&
      audienceidsEvent.length > 0 &&
      audienceidsEvent.includes(localStorage.getItem('id'))
    ) {
    } else {
      audienceidsEvent.push(localStorage.getItem('id'));
    }
    let data = {
      audience_ids:
        audienceidsEvent && audienceidsEvent.length > 0
          ? audienceidsEvent.toString()
          : localStorage.getItem('id'),
      chat_room_name: chatroome,
      creator_id: props.item.creator_id,
      group_image: props.item.image.slider_images,
      group_name: props.item.session_title,
      moderator_ids: modratorIds.length > 0 ? modratorIds.toString() : '',
      type: 'event',
      // type_id: props.item.id,
      type_id:
        props.type === 'refer' ? props.item.refer_type_id : props.item.id,
      left_removed_users: '',
    };

    API.create_session_group_chat_dolby(data)
      .then(res => {
        if (res) {
          setChatDetail(res.group_chat_detail);

          setTimeout(() => {
            setShowComponent('SmartChatPage');
          }, 500);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const date = new Date(eventData.start_time);
  const endDate = new Date(eventData.end_time);

  //  const dateU = new Date(props.refersnapShotId.created_at )
  if (allTickets.length > 0) {
    var min = Math.min(...allTickets.map(item => item.cost));
    var max = Math.max(...allTickets.map(item => item.cost));
    setTimeout(() => {
      setMinCost(min);
      setMaxCost(max);
    }, 500);
  }

  //

  // for (let i = 0; i < allData.event_tickets_detail.length; i++) {
  //   const element = array[...index];

  // }
  const numbers = [];

  for (let i = 1; i <= allData.event_tickets_detail; i++) {
    numbers.push(i);
  }

  const purchaseTicketHandle = () => {
    manageRsvpDolby();
  };

  const breakPoints = [
    {width: 1, itemsToShow: 3},
    {width: 1, itemsToShow: 3},
    {width: 550, itemsToShow: 6},
    {width: 850, itemsToShow: 8},
    {width: 1150, itemsToShow: 8},
    {width: 1450, itemsToShow: 12},
    {width: 1750, itemsToShow: 14},
  ];

  const myArrow = ({type, onClick, isEdge}) => {
    const pointer =
      type === consts.PREV ? (
        <i className="fas fa-angle-left"></i>
      ) : (
        <i className="fas fa-angle-right"></i>
      );
    return (
      <button className="slider-nav" onClick={onClick} disabled={isEdge}>
        {pointer}
      </button>
    );
  };

  const handleNavigate = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const origin = `${position.coords.latitude},${position.coords.longitude}`;
          const destinationFormatted = destination.replace(' ', '+');
          const url = `https://www.google.com/maps/dir/?api=1&origin=${origin}&destination=${destinationFormatted}&travelmode=driving`;
          window.open(url, '_blank');
          // history.push('/directions'); // navigate to new URL that will display directions
        },
        error => {
          console.error('Error getting user location', error);
        },
      );
    } else {
      console.error('Geolocation not supported');
    }
  };
  // const [sliderRef, setSliderRef] = useState('');

  const foundObjectofRsvp = rsvp.find(
    obj => obj.id === localStorage.getItem('id'),
  );

  const imgHandle = item => {
    setModelImg(item);
    setShowComponent('imagePrewie');
  };
  const remainingTickets = Math.max(0, 10 - allData.user_all_tickets);
  //
  // const renderDropdownOptions = () => {
  //   if (remainingTickets === 0) {
  //     return <option disabled>Tickets</option>;
  //   } else {
  //     const options = [];
  //     for (let i = 1; i <= remainingTickets; i++) {
  //       options.push(
  //         <option key={i} value={i}>
  //           {i + '- Ticket'}
  //         </option>,
  //       );
  //     }
  //     return options;
  //   }
  // };

  const handleSelect = eventKey => {
    setSelectedTicket(eventKey);
    setNumOfTickets(eventKey);
  };


  const renderDropdownOptions = () => {
    if (remainingTickets === 0) {
      return <Dropdown.Item disabled>Tickets</Dropdown.Item>;
    } else {
      const options = [];
      for (let i = 1; i <= remainingTickets; i++) {
        options.push(
          <Dropdown.Item
            key={i}
            eventKey={i}
            active={i === Number(selectedTicket)}>
            {i + '- Ticket'}
          </Dropdown.Item>,
        );
      }
      return options;
    }
  };

  // console.log(allData,'alldata------>')

  // console.log(props.item,'props.itemprops.item------>')
  return (
    <>
      <SlidingPanel hidePanel={() => props.onClose()}>
        {showLoader && <LoaderSpinner />}
        {/* {showLoader && <LoaderModal show={showLoader} />} */}

        {showComponent === '' && !showLoader && (
          <div className="serach-filter-wrapper w-100">
            <div className="fix-width-forms">
              <div
                className={`sidebar-heading-wrapper mt-0 ${
                  props.withHeadingSpace || props.windowWidth <= 1295
                    ? 'with-panel'
                    : ''
                }`}>
                <div className="heading">
                  <h2>{eventData.session_title}</h2>
                  {/* <h2>{props.item.creator_detail.creator_name}</h2> */}
                </div>
                <div className="with-user-img">
                  <div
                    className="user-profile-img no-margin as-link"
                    onClick={() => {
                      setProfileId(eventData.created_by);
                      setShowComponent('ProfileComponent');
                    }}>
                    <img
                      src={
                        allData.creator_image 
                          ? allData.creator_image
                          : DEFAULT_IMAGE
                      }
                      alt=""
                    />
                  </div>
                  {/* <span
                    className="close as-link"
                    onClick={() => props.onClose()}>
                    <i className="fas fa-times" />
                  </span> */}
                </div>
              </div>
            </div>

            <Scrollbars autoHide>
              <div className="fix-width-forms" style={{paddingBottom : eventData?.session_description && eventData?.session_description.length > 73 ? '60px':''}} >
                <div className="events-wrapper">
                  <div className="events-img-slider">
                    {Object.entries(sliderImg).length > 0 &&
                      sliderImg.map((item, index) => (
                        <img src={item} alt="" key={index} />
                      ))}
                  </div>

                  <div className="events-icons-wrapper">
                    <div className="left-icons">
                      <div className="events-headings m-0">
                        {date !== 'Invalid date' &&
                          endDate !== 'Invalid date' && (
                            <span className="category-name date">
                              {moment(date).format('MMMM Do YYYY, h:mmA ')} -
                              {moment(endDate).format(' MMMM Do YYYY, h:mmA')}
                            </span>
                          )}
                      </div>
                    </div>
                    <div className="right-icons d-flex align-items-center">
                      <span
                        onClick={() => {
                          favouriteOpportunity(eventData.id);
                          setHeartClass(!heartClass);
                        }}
                        className={
                          heartClass
                            ? 'fas fa-heart as-link'
                            : 'far fa-heart  as-link'
                        }></span>
                      <span
                        className="icon-share-option as-link"
                        onClick={() => {
                          setShowComponent('ReferProfile');
                        }}></span>
                    </div>
                  </div>

                  <div className="events-headings">
                    {/* <h2>{props.item.creator_name}</h2> */}
                    <div className="d-flex align-item-center justify-content-between">
                      <span className="category-name">
                        {eventData.category_name}
                      </span>
                    </div>
                  </div>

                  {Object.entries(recursionData).length > 0 && (
                    <div className="events-time-wrapper discover-builders-slider mt-4 mb-4">
                      <Carousel
                        // ref={ref => setSliderRef(ref)}
                        renderArrow={myArrow}
                        breakPoints={breakPoints}
                        itemsToScroll={1}
                        itemPadding={[0, 10, 0, 10]}
                        pagination={false}>
                        {Object.entries(recursionData).length > 0 &&
                          recursionData.map((item, index) => (
                            // <img src={item} alt="" key={index} />
                            <div
                              className={
                                idOfRecursion === item.id
                                  ? 'events-time-item as-link active selected'
                                  : 'events-time-item as-link active'
                              }
                              key={index}
                              onClick={() => {
                                getAvailabletickets(
                                  item.id,
                                  item.conference_id,
                                );
                              }}>
                              <span className="day">{item.days}</span>
                              <span className="date">
                                {moment(item.recursion_date_time).format('D')}
                              </span>
                              <span className="month-year mb-0">
                                {moment(item.recursion_date_time).format(
                                  "MMM 'YY",
                                )}
                              </span>
                              <span className="time">
                                {moment(eventData.start_time).format('h A')} -{' '}
                                {moment(eventData.end_time).format('h A')}
                              </span>
                            </div>
                          ))}
                      </Carousel>
                    </div>
                  )}

                  <div className="events-desc-wrapper">
                    <p>{eventData.session_description}</p>

                    <span className="event-address">
                      {eventData &&
                        eventData.complete_address &&
                        eventData.complete_address}
                    </span>

                    <div className="message-other-users">
                      {/* <span className="icon icon-link as-link" /> */}
                      <span
                        className="icon icon-car as-link"
                        onClick={handleNavigate}
                      />
                      {foundObjectofRsvp && !props.acceptTicket && (
                        <span
                          onClick={() => {
                            chatHandle();
                          }}
                          className="icon icon-plane as-link"
                        />
                      )}

                      <div className="users-imgs events-mini-images">
                        {Object.entries(rsvp).length > 0 &&
                        eventData.paid_status === 'no' ? (
                          Object.entries(rsvp).length > 0 &&
                          rsvp.slice(0, 3).map((item, index) => (
                            <img
                              src={
                                item.profile_img
                                  ? item.profile_img
                                  : DEFAULT_IMAGE
                              }
                              alt=""
                              key={index}
                              onClick={() => {
                                rsvp.length > 0 &&
                                  setShowComponent('ListUsers');
                              }}
                            />
                          ))
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <div className="images-list large-size mt-3">
                      <div className="images-list-item">
                        {/* <div className="camera-icon">
                          <i className="icon-camera-outline" />
                          <input type="file" />
                        </div> */}
                      </div>

                      {mediaMoment &&
                        Object.entries(mediaMoment).length > 0 &&
                        mediaMoment?.map((item, index) => {
                          return (
                            <div
                              onClick={() => {
                                imgHandle(item);
                              }}
                              className="images-list-item"
                              key={'mediaImger-' + index}>
                              <img src={item} alt="" />
                            </div>
                          );
                        })}
                    </div>

                    <div className="event-happening pb-1">
                      {eventData.paid_status === 'no' ? (
                        <span
                          className="as-link"
                          onClick={() => {
                            rsvp.length > 0 && setShowComponent('ListUsers');
                          }}>
                          RSVP ({rsvp.length})
                        </span>
                      ) : (
                        eventData.paid_status === 'yes' && (
                          <span
                            className="as-link"
                            onClick={() => {
                              allData.going.length > 0 &&
                                setShowComponent('ListUsersgoing');
                            }}>
                            Going (
                            {allData.going === [] ? 0 : allData.going.length})
                          </span>
                        )
                      )}

                      {(allData.user_all_tickets !== '0' ||
                        allData.ticket_exist) &&
                        !props.acceptTicket && (
                          <span
                            className="as-link"
                            onClick={() => {
                              setShowComponent('MineTickets');
                            }}>
                            <i className="icon-tickets" /> My Ticket(s)
                          </span>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </Scrollbars>

            <div className="filter-btn-wrapper pt-2 pb-2 bg_transparent bottom-0">
              <div className="fix-width-forms ">
                <form>
                  <div className="d-flex align-items-center justify-content-between">
                    {!props.acceptTicket ? (
                      <>
                        <div className="event-btn-wrapper m-0 as-link">
                          {eventData.paid_status &&
                          allData.tickets &&
                          allData.tickets.length === 0 ? (
                            <Button
                            className='m-0'
                              disabled={
                                props.item.status !== 'finished' &&
                                allData.user_all_tickets !== '10'
                                  ? false
                                  : true
                              }
                              onClick={() => {
                                numOfTickets !== '' && purchaseTicketHandle();
                              }}>
                              Purchase Ticket
                            </Button>
                          ) : (
                            <Button
                            className='m-0'
                              disabled={
                                props.item.status === 'finished' ||
                                allData.user_all_tickets >= 10
                                  ? true
                                  : false
                              }
                              onClick={() => {
                                //   ||
                                // props.item.tickets == '0'
                                
                                    setShowComponent('EventTickets');
                                 
                                
                              }}>
                              Choose Ticket
                            </Button>
                          )}
                        </div>
                        <div className="ticket-free-wrapper">
                          {allData.tickets && allData.tickets.length === 0 ? (
                            <div className="w-40 select-wrapper" onClick={()=>{
                              if(
                                allData.user_all_tickets ==='10' ){
                                  errorMessage(labels.tickets_sold_err[selected_lang])
                                }
                            }}>
                              {/* <select
                                className="form-control"
                                onChange={e => setNumOfTickets(e.target.value)}
                                disabled={
                                  props.item.status !== 'finished' &&
                                  allData.user_all_tickets !== '10'
                                    ? false
                                    : true
                                }>
                                {remainingTickets === 0 ? (
                                  <option>Tickets</option>
                                ) : (
                                  renderDropdownOptions()
                                )}
                            
                              </select> */}

                              <Dropdown
                                onSelect={handleSelect}>
                                <Dropdown.Toggle variant="primary" className={`${props.item.status !== 'finished' &&
                                  allData.user_all_tickets !== '10'
                                    ? ''
                                    : 'disabled'}`}>
                                  {remainingTickets === 0 ? 'Tickets' :  selectedTicket
                                    ? `${selectedTicket}- Ticket`
                                    : 'Select Ticket'}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  {renderDropdownOptions()}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          ) : (
                            <h3>
                              {parseInt(maxCost) && minCost === maxCost
                                ? '$' + minCost
                                : parseInt(maxCost) &&
                                  minCost < maxCost &&
                                  minCost !== 0
                                ? `$${minCost} - $${maxCost}`
                                : parseInt(maxCost) &&
                                  minCost < maxCost &&
                                  minCost === 0
                                ? `Free - $${maxCost}`
                                : 'Free'}{' '}
                            </h3>
                          )}
                        </div>
                      </>
                    ) : (
                      <div className="event-btn-wrapper as-link">
                        <p>
                          {props.allTickets &&
                            props.allTickets.sender_detail &&
                            fixName(
                              props.allTickets.sender_detail.first_name,
                              props.allTickets.sender_detail.last_name,
                            )}{' '}
                          send you a ticket to{' '}
                          {props.allTickets &&
                            props.allTickets.event_details &&
                            props.allTickets.event_details.session_title}
                          <br />
                          Accept this invite to receive your ticket.
                        </p>
                        <p>
                          {props.allTickets && props.allTickets.ticket_detail
                            ? '$' +
                              props.allTickets.ticket_detail.cost +
                              ' ' +
                              '(Paid)'
                            : 'Free'}
                        </p>
                        <Button
                          onClick={() => {
                            manageShareTickets();
                          }}>
                          {' '}
                          Accept Invite
                        </Button>
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </SlidingPanel>

      {showComponent === 'ProfileComponent' && profileId && (
        // <SlidingPanel
        //   hidePanel={() => {
        //     setShowComponent('');
        //   }}>
        //   <Scrollbars>
        <ProfileComponent
          userId={profileId}
          show={false}
          onClose={() => {
            setShowComponent('');
          }}
          closeModal={() => {
            setShowComponent('');
          }}
          userData={[]}
          moderator={false}
          // setParticipantType={setParticipantType}
          VoxeetSDK={[]}
          conferenceInfo={[]}
          jdSpecialM={[]}
          jdSpecialA={[]}
        />
        //   </Scrollbars>
        // </SlidingPanel>
      )}

      {showComponent === 'EventTickets' && (
        <EventTickets
          onClose={() => {
            setShowComponent('');
          }}
          manageRsvpDolby={manageRsvpDolby}
          eventData={eventData.id}
          setNumOfTickets={setNumOfTickets}
          setTicketId={setTicketId}
          setPaidTickets={setPaidTickets}
          creatorId={eventData.creator_id}
          totalPurchaseTickets={allData.user_all_tickets}
        />
      )}

      {showComponent === 'MineTickets' && (
        <MineTickets
          onClose={() => {
            setShowComponent('');
          }}
          userData={props.item}
          eventData={eventData.id}
          idOfRecursion={idOfRecursion}
          creatorId={eventData.creator_id}
          // apiCall={ props.getApiSessionList(props.sessionType, '', !props.typeIs)}
        />
      )}

      {showComponent === 'ReferProfile' && (
        // <SlidingPanel
        //   hidePanel={() => {
        //     setShowComponent('');
        //   }}>
        //   <Scrollbars>
        <ReferOpportunity
          onClose={() => {
            setShowComponent('');
          }}
          userData={props.item}
          type={'event'}
        />
        //   </Scrollbars>
        // </SlidingPanel>
      )}

      {showComponent === 'ListUsers' && (
        <ListUsers
          allUsers={rsvp}
          onClose={() => {
            setShowComponent('');
          }}
          type={false}
        />
      )}
      {showComponent === 'ListUsersgoing' && (
        <ListUsers
          allUsers={allData.going}
          onClose={() => {
            setShowComponent('');
          }}
          type={false}
        />
      )}

      {showComponent === 'SmartChatPage' && (
        <SmartChatPage
          allmsg={true}
          type={'event-chats'}
          item={[]}
          chatroomInfo={chatroomInfo(chatDetail)}
          oppoDetails={chatDetail}
          chatDetails={chatDetail}
          allChatUsers={collectingUsers(chatDetail)}
          chatRoomName={chatDetail.chat_room_name}
          chatRoomId={chatDetail.id}
          onClose={() => {
            // setChatButton(false);
            setShowComponent('');
          }}
          handleCommonChat={(data, type) => {
            handleCommonChat(data, type);
          }}
          eventDetail={() => {
            eventDetail();
          }}
        />
      )}

      {showComponent === 'imagePrewie' && (
        <CustomModal
          size="medium"
          onClose={() => {
            setShowComponent('');
          }}>
          <div className="chat-img-preview">
            <img src={modelImg} alt="" />
          </div>
        </CustomModal>
      )}
    </>
  );
};

const mapStateProps = state => ({
  windowWidth: state.windowWidth,
});

export default connect(mapStateProps, {})(PurchaseTicket);
