import React, {useState, useRef, useEffect} from 'react';
import {Button, Col, Form, Row} from 'react-bootstrap';
import Scrollbars from 'react-custom-scrollbars';
import commonApi from '../../Services/CommonService';
import moment from 'moment';
import SlidingPanel from '../SlidingPanel';
import DiscoverBuilders from '../SessionList/DiscoverBuilders';
import { RoiLogo } from '../../Constants/Images';

const CreatePoll = props => {
  const [timeDate, setTimeDate] = useState(false);
  const [validated, setValidated] = useState(false);
  const [pollDescription, setPollDescription] = useState('');
  const [originalData, setOriginalData] = useState([]);
  const [allBuilders, setAllBuilders] = useState([]);
  const [builders, setBuilders] = useState([]);
  const [option1Data, setOption1Data] = useState([]);
  const [option2Data, setOption2Data] = useState([]);
  const [option3Data, setOption3Data] = useState([]);
  const [option4Data, setOption4Data] = useState([]);
  const [option5Data, setOption5Data] = useState([]);

  const optionDataArray = [
    option1Data,
    option2Data,
    option3Data,
    option4Data,
    option5Data,
  ];

  // const maxDate = new Date();
  // maxDate.setFullYear(maxDate.getFullYear() + 10);
  // const minDate = new Date();
  // minDate.setFullYear(minDate.getFullYear() - 10);

  const [allOpts, setAllOpts] = useState({
    option1: {option: '', time: ''},
    option2: {option: '', time: ''},
    option3: {option: '', time: ''},
    option4: {option: '', time: ''},
    option5: {option: '', time: ''},
  });

  console.log(allOpts);

  const buttonRef = useRef(null);

  const convertTime = (date, time) => {
    if (date && time) {
      return moment(new Date(date + ' ' + time)).format();
    } else {
      return '';
    }
  };
  useEffect(() => {
    if (props.allChatUsers) {
      props.allChatUsers.forEach(items => {
        if (!builders.includes(items.id)) {
          setBuilders(previous => [...previous, items.id]);
          setAllBuilders(previous => [...previous, items]);
          setOriginalData(previous => [...previous, items]);
        }
      });
    }
  }, []);

  const buildersHandle = (type, item) => {
    if (type === 'profileComponent' && !builders.includes(item.id)) {
      setBuilders(previous => [...previous, item.id]);
      setAllBuilders(previous => [...previous, item]);
      setOriginalData(previous => [...previous, item]);
    } else if (type === 'Add_all_attendees') {
      const allItemsPresent = item.every(item => builders.includes(item.id));
      if (allItemsPresent && item.length === builders.length) {
        // Remove all item if the lengths match
        setBuilders([]);
        setAllBuilders([]);
        setOriginalData([]);
      } else {
        // Add all item if not already present
        item.forEach(items => {
          if (!builders.includes(items.id)) {
            setBuilders(previous => [...previous, items.id]);
            setAllBuilders(previous => [...previous, items]);
            setOriginalData(previous => [...previous, items]);
          }
        });
      }
    } else {
      if (type === 'profileComponent' && builders.includes(item.id)) {
        setBuilders(previous =>
          previous.filter(miniItem => miniItem !== item.id),
        );
        setAllBuilders(previous =>
          previous.filter(miniItem => miniItem.id !== item.id),
        );
        setOriginalData(previous =>
          previous.filter(miniItem => miniItem.id !== item.id),
        );
      }
    }
  };

  useEffect(() => {
    if (timeDate) {
      get_survey_time();
    }
  }, [builders,timeDate]);

  const handle = event => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      // alert('1')
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      // alert('11')
      event.preventDefault();

      if (timeDate) {
        const optionsData = {
          participants: timeDate && builders.length > 0 ? builders : [],
          option1: {
            option: convertTime(allOpts.option1.option, allOpts.option1.time),
            userIds: [],
          },
          option2: {
            option: convertTime(allOpts.option2.option, allOpts.option2.time),
            userIds: [],
          },
          option3: {
            option: convertTime(allOpts.option3.option, allOpts.option3.time),
            userIds: [],
          },
          option4: {
            option: convertTime(allOpts.option4.option, allOpts.option4.time),
            userIds: [],
          },
          option5: {
            option: convertTime(allOpts.option5.option, allOpts.option5.time),
            userIds: [],
          },
        };

        createPoll(optionsData);
      } else {
        const optionsData = {
          option1: {option: allOpts.option1.option, userIds: []},
          option2: {option: allOpts.option2.option, userIds: []},
          option3: {option: allOpts.option3.option, userIds: []},
          option4: {option: allOpts.option4.option, userIds: []},
          option5: {option: allOpts.option5.option, userIds: []},
        };

        createPoll(optionsData);
      }
    }
  };

  const reset = () => {
    setAllOpts({
      option1: {option: '', time: ''},
      option2: {option: '', time: ''},
      option3: {option: '', time: ''},
      option4: {option: '', time: ''},
      option5: {option: '', time: ''},
    });
  };

  const createPoll = opt => {
    var data = {
      chat_id: props.chatId,
      date_time: timeDate ? 'yes' : 'no',
      options: JSON.stringify(opt),
      poll_question: pollDescription,
    };
    commonApi
      .create_poll(data)
      .then(res => {
        if (res) {
          const msg = {
            dateAndTime: timeDate,
            participants: timeDate && builders.length > 0 ? builders : [],
            options: {
              option1: {option: opt.option1.option},
              option2: {option: opt.option2.option},
              option3: {option: opt.option3.option},
              option4: {option: opt.option4.option},
              option5: {option: opt.option5.option},
            },
            pollId: res.poll_id,
            question: pollDescription,
            status: 'open',
          };

          props.handleCreatePoll(msg);

          // res.poll_id
          props.onClose();
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const get_survey_time = opt => {
    const payload = {
      all_user_ids: builders,
    };

    commonApi
      .get_survey_time(payload)
      .then(res => {
        if (res.status === 200 && res.date?.length > 0) {
          const allOptions = res.date.slice(0, 5).reduce((acc, date, index) => {
            const momentDate = moment(date);
            acc[`option${index + 1}`] = {
              option: momentDate.format('YYYY-MM-DD'),
              time: momentDate.format('HH:mm'),
            };
            return acc;
          }, {});
          setAllOpts(allOptions);
        }
      })
      .catch(err => {
        console.error('Error:', err);
        return {type: 'error', message: err.message};
      });
  };

  useEffect(() => {
    if (builders.length > 0) {
      if (allOpts.option1.option !== '' && allOpts.option1.time !== '') {
        getUserAvailabilty(allOpts.option1, 'option1');
      }
      if (allOpts.option2.option !== '' && allOpts.option2.time !== '') {
        getUserAvailabilty(allOpts.option2, 'option2');
      }
      if (allOpts.option3.option !== '' && allOpts.option3.time !== '') {
        getUserAvailabilty(allOpts.option3, 'option3');
      }

      if (allOpts.option4.option !== '' && allOpts.option4.time !== '') {
        getUserAvailabilty(allOpts.option4, 'option4');
      }

      if (allOpts.option5.option !== '' && allOpts.option5.time !== '') {
        getUserAvailabilty(allOpts.option5, 'option5');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [builders]);

  const getUserAvailabilty = (date, type) => {
    const time = moment(`${date.option} ${date.time}`).format(
      'YYYY-MM-DD HH:mm:ss',
    );

    const Payload = {
      user_ids: builders,
      date_time: time,
    };

    commonApi
      .get_user_availabilty(Payload)
      .then(response => {
        if (response.success === true) {
          if (type === 'option1') {
            setOption1Data(response.data);
          } else if (type === 'option2') {
            setOption2Data(response.data);
          } else if (type === 'option3') {
            setOption3Data(response.data);
          } else if (type === 'option4') {
            setOption4Data(response.data);
          } else if (type === 'option5') {
            setOption5Data(response.data);
          }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const getUserData = data => {
    const availableUsers = data.filter(user => user.available_status === true);
    return ` ${availableUsers.length} out of ${builders.length}`;
  };

  const hitApiFunc = (item, e, type, data) => {
    var date = {};

    if (type === 'option') {
      date = {
        option: e.target.value,
        time: data,
      };
    } else {
      date = {
        option: data,
        time: e.target.value,
      };
    }

    if (builders.length > 0) {
      if (
        item === 'option1' &&
        (allOpts.option1.option !== '' || allOpts.option1.time !== '')
      ) {
        getUserAvailabilty(date, 'option1');
      }
      if (
        item === 'option2' &&
        (allOpts.option2.option !== '' || allOpts.option2.time !== '')
      ) {
        getUserAvailabilty(date, 'option2');
      }
      if (
        item === 'option3' &&
        (allOpts.option3.option !== '' || allOpts.option3.time !== '')
      ) {
        getUserAvailabilty(date, 'option3');
      }

      if (
        item === 'option4' &&
        (allOpts.option4.option !== '' || allOpts.option4.time !== '')
      ) {
        getUserAvailabilty(date, 'option4');
      }

      if (
        item === 'option5' &&
        (allOpts.option5.option !== '' || allOpts.option5.time !== '')
      ) {
        getUserAvailabilty(date, 'option5');
      }
    }
  };
  const renderProfileTagsButtons = () => {
    // Return the JSX element conditionally based on props.permission
    return (
      <>
       
          <img src={RoiLogo} alt="" />
      
      </>
    );
  };

  return (
    <>
      <SlidingPanel 
          renderData={renderProfileTagsButtons}
          showBlackHeader={true}
          lastDivStyle={{borderRadius: '35px', background: '#f7f6f2'}}
          firstDivStyle={{
            padding: 0,
            background:
              'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)',
          }}
      hidePanel={() => props.onClose()} marginTop={true}>
        <div className="serach-filter-wrapper pl-23">
          <div className="headings d-flex align-items-center justify-content-between fixed-width-inputs fix-width-forms pt-3 black-head-form-pad-left black-head-form-pad-right">
            <p className="p-bold m-0 f_600_h">Create Survey</p>
            <div className="d-flex align-items-center">
              <Form.Check
                type="switch"
                id="enable-sessions"
                label="Date / Time"
                checked={timeDate ? 'checked' : ''}
                onChange={() => {
                  setTimeDate(!timeDate);
                  reset();
                }}
              />
            </div>
          </div>
          <Scrollbars>
            <div className="fixed-width-inputs  black-head-form-pad-left black-head-form-pad-right">
              <Form noValidate validated={validated} onSubmit={handle}>
                <Form.Group className="fieldset">
                  <div className="d-flex align-items-center justify-content-between">
                    <Form.Label>Question</Form.Label>
                  </div>
                  <Form.Control
                    autoFocus
                    required
                    as="textarea"
                    rows={8}
                    placeholder="Enter Your Question "
                    //   value={pollDescription}
                    onChange={e => {
                      setPollDescription(e.target.value);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Description is required.
                  </Form.Control.Feedback>
                </Form.Group>

                {timeDate && (
                  <Form.Group className="fieldset">
                    <div className="d-flex align-items-center justify-content-between">
                      <Form.Label className="w-auto">Attendees</Form.Label>
                      {/* <p className="p-reg m-0 text-yellow cursor-pointer">
                        View All
                      </p> */}
                    </div>
                    <div>
                      <DiscoverBuilders
                        type="poll"
                        buildersHandle={buildersHandle}
                        builders={builders}
                        originalData={originalData}
                        allBuilders={allBuilders}
                        allChatUsers={props.allChatUsers}
                      />
                    </div>
                  </Form.Group>
                )}

                {['option1', 'option2', 'option3', 'option4', 'option5'].map(
                  (item, index) => {
                    const optionData = optionDataArray[index];

                    return (
                      <Row key={item}>
                        {timeDate && (
                          <Form.Group className="fieldset">
                            <Form.Label>{`Option ${index + 1} ${
                              index > 1 ? '(optional)' : ''
                            }`}</Form.Label>
                            <div className="row">
                              <Col sm={6}>
                                <Form.Group className="">
                                  <Form.Control
                                    required={index > 1 ? false : true}
                                    type="date"
                                    // min={ moment(minDate).format("YYYY-MM-DD")}
                                    // max={ moment(maxDate).format("YYYY-MM-DD")}
                                    placeholder="Enter first option"
                                    value={allOpts[item].option}
                                    onChange={e => {
                                      hitApiFunc(
                                        item,
                                        e,
                                        'option',
                                        allOpts[item].time,
                                      );
                                      setAllOpts(current => {
                                        return {
                                          ...current,
                                          [item]: {
                                            ...current[item],
                                            option: e.target.value,
                                          },
                                        };
                                      });
                                    }}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {`Option ${index + 1} date is required.`}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Col>

                              <Col sm={6}>
                                <Form.Group className="">
                                  <Form.Control
                                    required={index > 1 ? false : true}
                                    type="time"
                                    placeholder="Time"
                                    value={allOpts[item].time}
                                    onChange={e => {
                                      hitApiFunc(
                                        item,
                                        e,
                                        'time',
                                        allOpts[item].option,
                                      );
                                      setAllOpts(current => {
                                        return {
                                          ...current,
                                          [item]: {
                                            ...current[item],
                                            time: e.target.value,
                                          },
                                        };
                                      });
                                    }}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {`Option ${index + 1} time is required.`}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Col>
                            </div>
                            <p className="p-reg my-2" style={{color: 'black'}}>
                              {optionData.length > 0 && getUserData(optionData)}
                            </p>
                          </Form.Group>
                        )}

                        {!timeDate && (
                          <Col sm={12}>
                            <Form.Group className="fieldset">
                              <Form.Label>{`Option ${index + 1} ${
                                index > 1 ? '(optional)' : ''
                              }`}</Form.Label>
                              <Form.Control
                                required={index > 1 ? false : true}
                                type="text"
                                placeholder={`Enter option ${index + 1}`}
                                value={allOpts[item].option}
                                onChange={e => {
                                  setAllOpts(current => {
                                    return {
                                      ...current,
                                      [item]: {
                                        ...current[item],
                                        option: e.target.value,
                                      },
                                    };
                                  });
                                }}
                              />
                              <Form.Control.Feedback type="invalid">
                                {`Option ${index + 1} is required.`}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        )}
                      </Row>
                    );
                  },
                )}
                <Button
                  type="submit"
                  style={{display: 'none'}}
                  ref={buttonRef}></Button>
              </Form>
            </div>
          </Scrollbars>

          <div className="filter-btn-wrapper">
            <div className="invite-btns p-0 pt-3">
              <Button
                className="btn-dark"
                type="submit"
                onClick={() => buttonRef.current.click()}>
                Start Poll
              </Button>
            </div>
          </div>
        </div>
      </SlidingPanel>
    </>
  );
};
export default CreatePoll;
