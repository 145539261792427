import React, {useState, useEffect, useRef} from 'react';
import {Button, Form} from 'react-bootstrap';
import API from '../../Services/SessionServices';
import ProfileBlock from '../ProfilePage/ProfileBlock';
import ProfileGallery from '../ProfilePage/ProfileGallery';
// import messageService from '../../Services/MessageService';
import commonApi from '../../Services/CommonService';
import SnapshotDetail from '../Snapshot/SnapshotDetail';
import FollowersList from '../SessionDetails/FollowersList';
import OpportunityDetail from '../OpportunitiesList/OpportunityDetail';
import SessionDetail from '../SessionDetails/SessionDetail';
import AppointmentList from '../AppointmentPage/AppointmentList';
import PraiseCoution from '../SessionDetails/PraiseCoution';
import ReferProfile from '../Referral/ReferProfile';
import ReferOpportunity from '../Referral/ReferOpportunity';
import UserAppointments from '../AppointmentPage/UserAppointments';
import LoaderSpinner from '../Modals/LoaderSpinner';
import Scrollbars from 'react-custom-scrollbars';
import SlidingPanel from '../SlidingPanel';
import {getItem} from '../../Utils/LocalStorage';
import {amplitudeEvent} from '../../Services/AmplitudeServices';
import {useLocation} from 'react-router-dom';
import SmartChatPage from '../Common/SmartChatPage';
import {
  chatroomInfo,
  fixName,
  newSingleChatroom,
} from '../../Utils/commonUtils';
import {connect, useDispatch} from 'react-redux';
import {db} from '../../Firebase/firebase';
import {ref, set} from 'firebase/database';
import CircleListBuilders from '../../Pages/Front/StaticPages/CircleListBuilders';
// import {successMessage} from '../../Utils/ToastMessages';
import {chatroomInfoOppo} from '../../Utils/commonUtils';
import {list} from '../../Utils/commonUtils';
import CreatePipeLine from '../PipeLine/CreatePipeLine';
import CreateDeal from '../Deals/CreateDeal';
import CreateInvoice from '../InvoiceEstimate/CreateInvoice';
import {
  BanDisable,
  InfoDotsActive,
  InfoDotsDisable,
  MakeCreatorDisable,
  MakeModeratorDisable,
  MakeVipGrey,
  MakeVipYellow,
  RemoveBadgeActive,
  SheildDisable,
  StageMicDisable,
  StarDisable,
} from '../../Constants/Images';
import OpportunityMessageList from '../OpportunitiesList/OpportunityMessageList';
import ProfileNotes from './ProfileNotes';
import PersonalModal from '../Modals/PersonalModal';
import TransformDetail from '../Transformation/TransformDetail';
import {errorMessage, successMessage} from '../../Utils/ToastMessages';
import EditUserProfile from './EditUserProfile';
import {arrayColumn} from '../../Utils/commonUtils';
import CustomModal from '../CustomModal';
import {useSelector} from 'react-redux';

import CheckoutForm from '../SessionDetails/CheckoutForm';
import {loadStripe} from '@stripe/stripe-js';

import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import CreateSession from '../SessionDetails/CreateSession';
import AppointmentCallDetail from '../AppointmentPage/AppointmentCallDetail';
import {getUserProfileAppointments} from '../../Store/Actions/AppointmentAction';
import ActionDetail from '../Actions/ActionDetail';
import CreateAction from '../Snapshot/CreateAction';
import DealDetail from '../Deals/DealDetail';

import EditOpportunities from '../OpportunitiesList/EditOpportunities';
import ProfileTags from './ProfileTags';
import CreateTag from '../Tags/CreateTag';
import { labels } from '../../Constants/Translations';
import { selected_lang } from '../../Utils/env';
function ProfileComponent(props) {
  const [showComponent, setShowComponent] = useState({
    followers: false,
    following: false,
    snapshot: false,
    opportunity: false,
    session: false,
    appointment: false,
    mineAppointment: false,
    praise: false,
    circle: false,
    share: false,
    referOpportunity: false,
    chat: false,
    profilenotes: false,
    personal: false,
    Profile_view: false,
    transformation: false,
    editProfile: false,
    snapshot_filter: false,
    stripe_modal: false,
    follow_up: false,
    appointment_detail: false,
    appointment_meeting_status: false,
    follow_up_detail: false,
    create_follow_up: false,
    create_task: false,
    pipeline: false,
    create_deal: false,
    deal_detail: false,
    change_phase_status: false,
    payment_filter: false,
    create_invoice: false,
    task_filter: false,
    appointment_filter: false,
    create_session: false,
    session_filter: false,
    create_recruitment: false,
    recruitment_filter: false,
    create_tag : false,
    view_tag : false
  });
  const [profileId, setProfileId] = useState({});
  // const [profileType, setProfileType] = useState('');
  const [snapshotId, setSnapshotId] = useState({});
  const [opportunityData, setOpportunityItemData] = useState({});
  const [sessionData, setSessionData] = useState({});
  // const [show, setShow] = useState(props.show);
  const [average, setAverage] = useState('');

  var dataOfPersons = props.userData;
  const [userInfo, setUserInfo] = useState([]);
  const [conferenceInfo, setconferenceInfo] = useState([]);
  const [showUserInfo, setShowUserInfo] = useState(false);
  const [UserisModerator, setUserisModerator] = useState(false);
  const [audio, setaudio] = useState(true);

  const [userSnapshots, setSnapshotData] = useState([]);
  const [showSnapshots, setShowSnapshot] = useState(false);

  const [userUpcomming, setUpcommingData] = useState([]);
  const [showUpcomming, setShowUpcomming] = useState(false);

  const [userOpportunity, setOpportunityData] = useState([]);
  const [showOpportunity, setShowOpportunity] = useState(false);
  const [showLoader, setshowLoader] = useState(true);
  const [checkAppoinmentData, setCheckAppoinmentData] = useState([]);

  const [chatType, setChatType] = useState([]);
  const [chatDetails, setChatDetails] = useState([]);
  const [allChatUsers, setAllChatUsers] = useState([]);
  const [chatRoomName, setChatRoomName] = useState('');
  const [chatRoomId, setChatRoomId] = useState('');
  const [userTypeInSession, setuserTypeInSession] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isVipOpen, setIsVipOpen] = useState(false);
  const [dataofTransFormation, setDataofTransFormation] = useState([]);
  const [categoriesArr, setCategoriesArr] = useState([]);
  const [filterCat, setFilterCat] = useState([]);
  const [appointmentData, setAppointmentData] = useState(null);
  const [changedStatusAppointment, setChangedStatusAppointment] = useState({});
  const [userApStatus, setUserApStatus] = useState('');
  const [userMeetingStatus, setUserMeetingStatus] = useState('');
  const [itemOfActionData, setItemOfActionData] = useState([]);
  const [futureDate, setFutureDate] = useState('');
  const [followUpType, setFollowUpType] = useState('');
  const [selectedPhase, setSelectedPhase] = useState('');

  const [payment_filter, setPayment_filter] = useState('to_me');
  const [selectedPaymentFilter, setSelectedPaymentFilter] = useState('to_me');

  const [selectedFilterValue, setSelectedFilterValue] = useState('to_me');
  const [value_filter, setValue_filter] = useState('to_me');

  const [filterOptionValue, setFilterOptionValue] = useState([]);
  const [checkFilterOption, setCheckFilterOption] = useState(['1']);
  const [sessionFilterValue, setSessionFilterValue] = useState('all');
  const [session_filter, setSession_filter] = useState('all');
  const filterListArray = [
    {id: '1', value: 'from_me', title: 'From me'},
    {id: '2', value: 'to_me', title: 'To me'},
  ];
  const sessionFilterArray = [
    {id: '1', value: 'all', title: 'All'},
    {id: '2', value: 'mine', title: 'Mine'},
    {id: '3', value: 'others', title: 'Others'},
  ];

  const ActionCategories = [
    {id: '1', title: 'All'},
    {id: '2', title: 'Me'},
    {id: '3', title: 'Watching'},
  ];

  const Appointment_Status_List = [
    {id: '1', title: 'Running Late'},
    {id: '2', title: 'On my way'},
    {id: '3', title: "I'm outside"},
    {id: '4', title: "I'm Done"},
  ];

  const [isCheckAll, setIsCheckAll] = useState(true);
  const [isCheck, setIsCheck] = useState(['5', '4', '2', '1', '3']);
  const [list, setList] = useState([]);

  const dispatch = useDispatch();

  const [selectedPrice, setSelectedPrice] = useState('');
  const [paymentId, setPaymentId] = useState('');
  const stripePromise = loadStripe(
    'pk_test_51PEB5a2K8W7Cs25henyw4Ahf3eGMQgtDgRztlGV4POXFghfMEH5DDg5PtNpT916JChWA9ELeby2Znh5mu2IjXyuY00TWUVZN1V',
  );

  const hitApiRef = useRef();
  const locationWeb = useLocation();
  const snapshotCategories = useSelector(
    state => state.accomplishmentCategories,
  );
  // const history = useHistory();

  const handleSnapshotSelection = event => {
    const {value, checked} = event.target;
    const itemObj = JSON.parse(value);

    if (itemObj.id === 'All') {
      //   setCategoriesName(['All']);
      if (!checked) {
        setCategoriesArr([]);
      } else {
        let categories = arrayColumn(snapshotCategories, 'id');
        setCategoriesArr(categories);
      }
    } else {
      if (checked) {
        // setCategoriesName(prev => [...prev, itemObj.title]);
        setCategoriesArr(prev => [...prev, itemObj.id]);
      } else {
        // setCategoriesName(prev =>
        //   prev.filter(title => title !== itemObj.title),
        // );
        setCategoriesArr(prev => prev.filter(id => id !== itemObj.id));
      }
    }
  };

  const closeModal = () => {
    // setShow(false);
    props.closeModal();
    setshowLoader(false);
  };
  // const sendMessage = type => {
  //   // send message to subscribers via observable subject
  //   messageService.sendMessage('update_api');
  // };
  const mute = () => {
    userMuted(props.userId);
    var data = {
      user_id: props.userId,
      conference_id: conferenceInfo.id,
      mic: 'off',
    };
    API.update_mic_status(data)
      .then(res => {
        if (res) {
          // props.VoxeetSDK.command
          //   .send({dataPayload: {id: props.userId, name: 'moderator_mute'}})
          //   .then(succ => {
          //

          //   })
          //   .catch(err => {
          //     console.log(err);
          //   });
          const obj = JSON.stringify({
            id: props.userId,
            type: 'user_muted',
            sent_at: new Date().getTime(),
          });
          UpdateBroadcastMessage('sessionEvent', 'user_muted', obj);
          dataOfPersons.audio = false;
          setaudio(false);
          closeModal();
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  // const unmute = stage => {
  //   // var data = { conference_id: props.conferenceInfo.id };
  //   var data = {
  //     user_id: props.userData.info.externalId,
  //     conference_id: conferenceInfo.id,
  //     mic: 'off',
  //   };
  //   if (stage) {
  //     data.mic_status = 'approved';
  //     updateUser(props.userId, 'stage');
  //   }
  //   API.update_mic_status(data)
  //     .then(res => {
  //       if (res) {
  //         if (stage) {
  //           closeModal();
  //           props.VoxeetSDK.command
  //             .send({
  //               dataPayload: {id: props.userId, name: 'request_accepted'},
  //             })
  //             .then(succ => {

  //             })
  //             .catch(err => {
  //               console.log(err);
  //             });
  //         } else {
  //           props.VoxeetSDK.command
  //             .send({
  //               dataPayload: {id: props.userId, name: 'moderator_unmute'},
  //             })
  //             .then(succ => {

  //               dataOfPersons.audio = true;
  //               setaudio(true);
  //               sendMessage();
  //               // props.VoxeetSDK.command
  //               //   .send({
  //               //     dataPayload: {id: props.userId, name: 'request_accepted'},
  //               //   })
  //               //   .then(succ => {
  //               //     console.log(succ);
  //               //   })
  //               //   .catch(err => {
  //               //     console.log(err);
  //               //   });
  //             })
  //             .catch(err => {
  //               console.log(err);
  //             });
  //         }
  //       }
  //     })
  //     .catch(err => {
  //       console.log('Err', err);
  //       return {type: 'error', message: err.message};
  //     });
  // };
  const getUserData = () => {
    var data = {user_id: props.userId, conference_id: props.conferenceInfo.id};
    commonApi
      .user_info(data)
      .then(res => {
        if (res) {
          if(!res.exist && !res.success){
            errorMessage(res.message)
            props.onClose()
          }
          if (props.type === 'opportunityDetail') {
            props.userData.profile_img = res.data.profile_img;
          }
          setuserTypeInSession(res.user_type);
          setUserInfo(res);
          setShowUserInfo(true);
          if(res.data.exist_in_build === 'no'){
            setshowLoader(false)
          }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const handleTaskFilter = (id, value) => {
    setCheckFilterOption([id]);
  };
  // const switchCreator = () => {
  //   switchCreatorNative(props.userId);
  //   var data = {
  //     user_id: props.userId,
  //     conference_id: props.conferenceInfo.id,
  //   };
  //   API.switch_creator(data)
  //     .then(res => {
  //       if (res) {
  //         successMessage(res.message);
  //         sendMessage('update_api');
  //         props.VoxeetSDK.command
  //           .send({dataPayload: {id: props.userId, name: 'update_api'}})
  //           .then(succ => {
  //             props.VoxeetSDK.command.send({
  //               dataPayload: {
  //                 id: props.userId,
  //                 type: 'congratulation',
  //               },
  //             });
  //             closeModal();
  //           })
  //           .catch(err => {
  //             console.log(err);
  //           });
  //       }
  //     })
  //     .catch(err => {
  //       console.log('Err', err);
  //       return {type: 'error', message: err.message};
  //     });
  // };
  // const Ban = () => {
  //   banUser(props.userId);
  //   var data = {
  //     conference_id: props.conferenceInfo.id,
  //     user_id: props.userId,
  //   };
  //   API.ban(data)
  //     .then(res => {
  //       if (res) {
  //         messageService.sendMessage({
  //           user_id: props.userId,
  //           name: 'ban_user',
  //           login_id: localStorage.getItem('user'),
  //         });
  //         props.VoxeetSDK.command.send({
  //           dataPayload: {id: props.userId, name: 'ban_alert'},
  //         });
  //         closeModal();
  //       }
  //     })
  //     .catch(err => {
  //       console.log('Err', err);
  //       return {type: 'error', message: err.message};
  //     });
  // };

  const getAppointmentType = () => {
    const paylaod = {
      user_id: props.userId,
    };
    commonApi
      .get_appointment_type(paylaod)
      .then(res => {
        setCheckAppoinmentData(res.data);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  //

  // const micOff = () => {
  //   var data = {
  //     user_id: props.userId,
  //     conference_id: conferenceInfo.id,
  //     mic: 'off',
  //   };
  //   API.update_mic_status(data).then(res => {});
  // };

  const changeUserRights = async role => {
    if (role === 'moderator' || role === 'audience' || role === 'stage') {
      setshowLoader(true);
      if (role === 'audience') {
        // for web
        const body = {
          conference_id: props.conferenceInfo.id,
          user_id: props.userId,
          mic: 'off',
        };
        UpdateBroadcastMessage(
          'sessionEvent',
          'close_large_video',
          JSON.stringify({
            type: 'close_large_video',
            user_id: props.userId,
            sent_at: new Date().getTime(),
          }),
        );
        await commonApi.change_user_mic_status(body);
      }
      const body = {
        mic: 'on',
        user_id: props.userId,
        conference_id: props.conferenceInfo.id,
        mic_status: 'approved',
      };
      await API.update_mic_status(body);

      const data = {
        user_id: props.userId,
        user_type: role,
        conference_id: props.conferenceInfo.id,
      };
      const response = await API.manage_user(data);
      //

      if (response) {
        const obj = JSON.stringify({
          id: props.userId,
          type: role,
          sent_at: new Date().getTime(),
        });
        UpdateBroadcastMessage('sessionEvent', role, obj);

        if (role === 'audience') {
          //  isModerator = false;
          const obj1 = JSON.stringify({
            type: 'reload_api',
            sent_at: new Date().getTime(),
          });
          UpdateBroadcastMessage('sessionEvent', 'reload_api', obj1);
        }
      }
    } else if (role === 'ban') {
      setshowLoader(true);
      const data = {
        user_id: props.userId,
        conference_id: props.conferenceInfo.id,
      };
      const response = await API.ban(data);
      //

      if (response) {
        const obj = JSON.stringify({
          id: props.userId,
          type: 'ban_user',
          sent_at: new Date().getTime(),
        });
        UpdateBroadcastMessage('sessionEvent', 'ban_user', obj);
      }
    } else if (role === 'creator') {
      setshowLoader(true);
      const data = {
        user_id: props.userId,
        conference_id: props.conferenceInfo.id,
      };
      const response = await API.switch_creator(data);

      var check_previous = props.jdSpecialM.includes(props.userId)
        ? 'moderator'
        : '';
      check_previous = props.jdSpecialA.includes(props.userId)
        ? 'audience'
        : check_previous;
      check_previous = props.jdSpecialS.includes(props.userId)
        ? 'stage'
        : check_previous;
      props.jdSpecialA.includes(props.userId) && setUserisModerator(false);
      if (response) {
        const obj = JSON.stringify({
          id: props.userId,
          type: 'switch_creator',
          sent_at: new Date().getTime(),
          previous_type: check_previous,
        });
        UpdateBroadcastMessage('sessionEvent', 'switch_creator', obj);
      }
      const obj1 = JSON.stringify({
        type: 'reload_api',
        sent_at: new Date().getTime(),
      });
      UpdateBroadcastMessage('sessionEvent', 'reload_api', obj1);
    } else if (role === 'user_muted') {
      const obj = JSON.stringify({
        id: props.userId,
        type: 'user_muted',
        sent_at: new Date().getTime(),
      });
      UpdateBroadcastMessage('sessionEvent', 'user_muted', obj);
    }

    setTimeout(() => {
      closeModal();
    }, 5000);
  };
  // const manageUser = type => {
  //   // var typeTf = (type === 'audience') ? false : true;
  //   // props.setParticipantType(typeTf);
  //   updateUser(props.userId, type);
  //   if (type == 'audience') {
  //     mute();
  //   }
  //   var data = {
  //     user_id: props.userId,
  //     conference_id: props.conferenceInfo.id,
  //     user_type: type,
  //   };
  //   API.manage_user(data)
  //     .then(res => {
  //       if (res) {
  //         sendMessage(type);
  //         props.VoxeetSDK.command
  //           .send({
  //             dataPayload: {
  //               id: props.userId,
  //               name: 'update_api',
  //               type: type,
  //               moderator: UserisModerator,
  //             },
  //           })
  //           .then(succ => {
  //             closeModal();
  //           })
  //           .catch(err => {
  //             console.log(err);
  //           });
  //       }
  //     })
  //     .catch(err => {
  //       console.log('Err', err);
  //       return {type: 'error', message: err.message};
  //     });
  // };

  const UpdateBroadcastMessage = (type, subType, message) => {
    const database = ref(
      db,
      type + '/' + subType + '/' + localStorage.getItem('conf_id'),
    );

    set(database, message);
  };

  // const updateUser = (id, role) => {
  //   const obj = JSON.stringify({
  //     id: id,
  //     type: role,
  //     sent_at: new Date().getTime(),
  //   });
  //   UpdateBroadcastMessage('sessionEvent', role, obj);
  // };

  // const banUser = id => {
  //   const obj = JSON.stringify({
  //     id: id,
  //     type: 'ban_user',
  //     sent_at: new Date().getTime(),
  //   });
  //   UpdateBroadcastMessage('sessionEvent', 'ban_user', obj);
  // };

  // const switchCreatorNative = id => {
  //   const obj = JSON.stringify({
  //     id: id,
  //     type: 'switch_creator',
  //     sent_at: new Date().getTime(),
  //   });
  //   UpdateBroadcastMessage('sessionEvent', 'switch_creator', obj);
  // };

  const userMuted = id => {
    const obj = JSON.stringify({
      id: id,
      type: 'user_muted',
      sent_at: new Date().getTime(),
    });
    UpdateBroadcastMessage('sessionEvent', 'user_muted', obj);
  };

  const getSnapshotData = () => {
    var data = {
      user_id: props.userId,
      categories: '',
    };
    commonApi
      .get_accomplishment(data)
      .then(res => {
        if (res) {
          setSnapshotData(res.accomplishment);
          setShowSnapshot(true);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const getUpcommingData = () => {
    var data = {
      user_id: props.userId,
      type: 'mine',
    };
    commonApi
      .get_user_sessions(data)
      .then(res => {
        if (res.status === 200) {
          setUpcommingData(res.user_session);
          setShowUpcomming(true);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const getOpportunityData = () => {
    // setshowLoader(true);
    var data = {
      user_id: props.userId,
      type: 'mine',
    };
    commonApi
      .get_user_available_opportunities(data)
      .then(res => {
        setTimeout(() => {
          setshowLoader(false);
        }, 1500);
        // setshowLoader(false);
        if (res) {
          setOpportunityData(res.user_available_opportunity);
          setShowOpportunity(true);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  // const CheckPersonIsModerator = id => {
  //   var found = false;
  //   if (props.conferenceInfo.moderator_list.length > 0) {
  //     props.conferenceInfo.moderator_list.forEach(element => {
  //       if (element.id == id) {
  //         found = true;
  //       }
  //     });
  //     if (props.conferenceInfo.creator_id == id) {
  //       found = true;
  //     }
  //     return found;
  //   }
  // };

  // const CheckPersonIsAudiance = id => {
  //   var found = false;
  //   if (props.conferenceInfo.audience_list.length > 0) {
  //     props.conferenceInfo.audience_list.forEach(element => {
  //       if (element.id == id) {
  //         found = true;
  //       }
  //     });
  //     return found;
  //   }
  // };
  const getUserSchedule = () => {
    var data = {
      user_id: props.userId,
    };
    commonApi
      .get_user_schedule(data)
      .then(res => {
        if (res.success === true) {
          setAverage(res.average);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const showSnapshotDetail = ssId => {
    var payLoad = {
      accomplishment_id: ssId,
    };
    commonApi
      .detail_accomplishment(payLoad)
      .then(response => {
        if (response.status === 200) {
          setSnapshotId(response.accomplishment_detail);
          setShowComponent({...showComponent, snapshot: true});
        }
      })
      .catch(error => {
        console.log('Err', error);
        return {type: 'error', message: error.message};
      });
  };

  const getOtherUserSetting = id => {
    let payLoad = {
      type: 'message',
      user_id: id,
    };
    commonApi
      .get_other_user_setting(payLoad)
      .then(response => {
        if (response.success) {
          checkChatRoom(id);
        }
      })
      .catch(error => {
        return {type: 'error', message: error.message};
      });
  };

  const checkChatRoom = id => {
    let payLoad = {
      user_id: id,
    };
    commonApi
      .check_chat_room(payLoad)
      .then(response => {
        if (response.status === 200) {
          if (response.exist) {
            const allChatUsers = [];
            allChatUsers.push(response.chat_detail.creator_detail);
            allChatUsers.push(response.chat_detail.user_detail);
            setChatType('personal-single');
            setChatDetails(response.chat_detail);
            setAllChatUsers(allChatUsers);
            setChatRoomName(response.chat_room_name);
            setChatRoomId(response.chat_room_id);
            setShowComponent({...showComponent, chat: true});
          } else {
            setChatType('new-personal-single');
            setChatDetails([]);
            setAllChatUsers([userInfo.data]);
            setChatRoomName('');
            setChatRoomId('');
            setShowComponent({...showComponent, chat: true});
          }
        }
      })
      .catch(error => {
        return {type: 'error', message: error.message};
      });
  };

  const handleSnapshotId = (id, type) => {
    // setSnapshotId(id);
    if (type === 'snapshot') {
      showSnapshotDetail(id);
    } else if (type === 'opportunity') {
      setOpportunityItemData(id);
      setShowComponent({...showComponent, opportunity: true});
    } else if (type === 'session') {
      setSessionData(id);
      setShowComponent({...showComponent, session: true});
    } else if (type === 'referOpportunity') {
      // setOpportunityItemData(id);

      setShowComponent({...showComponent, referOpportunity: true});
      //
    } else if (type === 'transformationDetail') {
      setDataofTransFormation(id);
      setShowComponent({...showComponent, transformation: true});
    } else if (type === 'snapshot_filter') {
      setShowComponent({...showComponent, snapshot_filter: true});
    } else if (type === 'stripeModal') {
      setSelectedPrice(id.price);
      setPaymentId(id.paymentId);
      setShowComponent({...showComponent, stripe_modal: true});
    } else if (type === 'appointmentDetail') {
      setAppointmentData(id);
      setShowComponent({...showComponent, appointment_detail: true});
    } else if (type === 'appointmentStatus') {
      statusOfUserAppointment(
        id,
        localStorage.getItem('id') === id.creator_details.id
          ? 'to_me'
          : 'from_me',
      );
    } else if (type === 'followUp') {
      setItemOfActionData(id);
      setShowComponent({...showComponent, follow_up_detail: true});
    } else if (type === 'create_followUp') {
      // setCustomFollowUp(id);
      setFollowUpType(id.followUpType);
      setFutureDate(id.date);
      setShowComponent({...showComponent, create_follow_up: true});
    } else if (type === 'create_task') {
      // setCustomFollowUp(id);

      setShowComponent({...showComponent, create_task: true});
    } else if (type === 'pipeline') {
      // setCustomFollowUp(id);

      setShowComponent({...showComponent, pipeline: true});
    } else if (type === 'createDeal') {
      // setCustomFollowUp(id);

      setShowComponent({...showComponent, create_deal: true});
    } else if (type === 'dealDeatil') {
      setItemOfActionData(id);

      setShowComponent({...showComponent, deal_detail: true});
    } else if (type === 'changePhase') {
      setItemOfActionData(id);

      setSelectedPhase(userInfo.deals_detail?.phase_status_id);
      setCategoriesArr(userInfo.deals_detail?.all_phases);

      setShowComponent({...showComponent, change_phase_status: true});
    } else if (type === 'payment_filter') {
      setShowComponent({...showComponent, payment_filter: true});
    } else if (type === 'create_invoice') {
      setShowComponent({...showComponent, create_invoice: true});
    } else if (type === 'task_filter') {
      setShowComponent({...showComponent, task_filter: true});
    } else if (type === 'appointment_filter') {
      setShowComponent({...showComponent, appointment_filter: true});
    } else if (type === 'create_session') {
      setShowComponent({...showComponent, create_session: true});
    } else if (type === 'session_filter') {
      setShowComponent({...showComponent, session_filter: true});
    } else if (type === 'create_recruitment') {
      setShowComponent({...showComponent, create_recruitment: true});
    } else if (type === 'recruitment_filter') {
      setShowComponent({...showComponent, recruitment_filter: true});
    }else if (type === 'create_tag') {
      setShowComponent({...showComponent, create_tag: true});
    }
  };

  const myProfile = userInfo?.data?.id === localStorage.getItem('id');
  const handleBlock = (id, type) => {
    setProfileId(id);

    // setProfileType(type);
    if (type === 'followers') {
      setShowComponent({...showComponent, followers: true});
    } else if (type === 'appointment') {
      if (parseInt(id.id) === parseInt(localStorage.getItem('id'))) {
        setShowComponent({...showComponent, mineAppointment: true});
      } else {
        setShowComponent({...showComponent, appointment: true});
      }
    } else if (type === 'praise') {
      // alert('"""""')

      setShowComponent({...showComponent, praise: true});
    } else if (type === 'circle') {
      setShowComponent({...showComponent, circle: true});
    } else if (type === 'share') {
      setShowComponent({...showComponent, share: true});
    } else if (type === 'chat') {
      // setShowComponent({ ...showComponent, ["chat"]: true });
      getOtherUserSetting(id.id);
    } else if (type === 'profile-notes') {
      setShowComponent({...showComponent, profilenotes: true});
    } else if (type === 'work-space') {
      setShowComponent({...showComponent, personal: true});
    } else if (type === 'Profile_view') {
      setShowComponent({...showComponent, Profile_view: true});
    } else if (type === 'editProfile') {
      setDataofTransFormation(id);
      setShowComponent({...showComponent, editProfile: true});
    } else if (type === 'follow-up') {
      setShowComponent({...showComponent, follow_up: true});
    }else if (type === 'view_tag') {
      setShowComponent({...showComponent, view_tag: true});
    }
  };

  const handleAppointmentFilter = alphabet => {
    setValue_filter(alphabet);
  };
  const handleSessionFilter = alphabet => {
    setSession_filter(alphabet);
  };

  const handleNotificationDirection = () => {
    const {state} = locationWeb;
    if (state && state.typeIs === 'scheduleAppointment') {
      setShowComponent({...showComponent, mineAppointment: true});
      setTimeout(() => {
        setShowComponent({...showComponent, mineAppointment: true});
      }, 1000);
      // history.replace({pathname: pathname, key: Date.now()});
    }
  };

  useEffect(() => {
    // getUserData();
    // getSnapshotData();
    // getUpcommingData();
    // setconferenceInfo(props.conferenceInfo);
    // getOpportunityData();
    // getUserSchedule();

    // var check = false;
    // check = CheckPersonIsModerator(props.userId);
    // if (check) {
    //     setUserisModerator(true)
    // }
    if (
      props.userData &&
      Object.entries(props.userData).length > 0 &&
      props.userData.audio
    ) {
      setaudio(true);
    } else {
      setaudio(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audio, UserisModerator]);

  useEffect(() => {
    props.jdSpecialM.includes(props.userId) && setUserisModerator(true);
    props.jdSpecialA.includes(props.userId) && setUserisModerator(false);

    handleNotificationDirection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    //
    setconferenceInfo(props.conferenceInfo);
    getUserData();
    // getUserSchedule();
    // getSnapshotData();
    // getOpportunityData();
    // getUpcommingData();
    // setTimeout(() => {
    //   getAppointmentType();
    // }, 1000);

    setShowComponent({
      followers: false,
      following: false,
      snapshot: false,
      opportunity: false,
      session: false,
      mineAppointment: false,
      appointment: false,
      praise: false,
      circle: false,
      share: false,
      referOpportunity: false,
      chat: false,
      profilenotes: false,
      personal: false,
      Profile_view: false,
      transformation: false,
      snapshot_filter: false,
      payment_filter: false,
    });

    if (props.userId === getItem('id')) {
      amplitudeEvent('VISIT_OWN_PROFILE');
    } else {
      amplitudeEvent('VISIT_OTHER_PROFILE');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.userId]);

  useEffect(() => {
    // getUserSchedule();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showComponent]);

  useEffect(() => {


   setTimeout(() => {
    if(props.type ==='snapshotRefer'&& props.snaphotId !==''){
      if((/^\d+$/.test(Number(props.snaphotId)))){
        showSnapshotDetail(props.snaphotId)
      }else{
        errorMessage(labels.Snapshot_Not_found[selected_lang])
      }
      
    }
   }, 1000);
  }, [])
  

  const handleFilterOption = alphabet => {
    setPayment_filter(alphabet);
  };

  const toggleDropdown = () => {
    setIsVipOpen(false);
    setIsOpen(!isOpen);
  };

  const send_invitation_in_company = phone => {
    const data = {contact: phone};
    commonApi
      .send_invitation_in_company(data)
      .then(res => {
        if (res) {
          successMessage(res.message);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const handleApStatus = alphabet => {
    // getMyAppointmentsByType(alphabet);
    // setSelectedFilterValue(alphabet);
    setUserApStatus(alphabet);
  };

  const changeUserMeetingStatus = status => {
    const data = {meeting_id: changedStatusAppointment.id, user_status: status};

    commonApi
      .change_button_status(data)
      .then(res => {
        refreshUserAppointmentList();

        successMessage(res.message);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const refreshUserAppointmentList = () => {
    var Payload = {
      user_id: props.userId,
    };

    dispatch(getUserProfileAppointments(Payload));
  };
  const statusOfUserAppointment = (item, type) => {
    if (type === 'to_me') {
      setUserApStatus(item.creator_status);
    } else if (type === 'from_me') {
      setUserApStatus(item.user_status);
    }

    setChangedStatusAppointment(item);
    setShowComponent({...showComponent, appointment_meeting_status: true});
  };

  const handleOverViewAppointment = (item, value) => {
    if (userInfo.appointment_detail.id === item.id) {
      if (localStorage.getItem('id') === item.creator_details.id) {
        userInfo.appointment_detail.creator_status = value;
      } else {
        userInfo.appointment_detail.user_status = value;
      }
    }
  };

  const handlePhaseStatus = alphabet => {
    setSelectedPhase(alphabet);
  };

  const changeDealPhase = status => {
    const payload = {
      deal_id: userInfo?.deals_detail ? userInfo?.deals_detail?.id : '',
      phase_status_id: selectedPhase,
    };

    console.log('payload', payload);
    commonApi
      .create_deal(payload)
      .then(res => {
        if (res.status === 200) {
          successMessage(res.message);
          setShowComponent({
            ...showComponent,
            change_phase_status: false,
          });
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const renderProfileButtons = profile => (
    <>
      
        <i
          className="icon-circles as-link"
          style={{
            color: ' white',
          }}
          onClick={() => {
            handleBlock(userInfo.data, 'circle');
          }}
        />

        <i
          className="fas fa-share  as-link"
          onClick={() => {
            handleBlock(userInfo.data, 'share');
          }}
        />
        {userInfo?.data?.id !== localStorage.getItem('id') &&  (
          <>
            <i
              className="icon-plane  as-link"
              onClick={() => {
                handleBlock(userInfo.data, 'chat');
              }}
            />
            <i
              className="fa-solid fa-bell as-link"
              onClick={() => {
                handleBlock(userInfo.data, 'follow-up');
              }}></i>
          </>
        )}
      
    </>
  );

  // console.log(props.type,'props.typeprops.typeprops.type')
  return (
    <>
      <div>
       

        {/* { props.noshow === true || props.hideCloseButton  ?<></>:
        <span className="close as-link " onClick={() => closeModal()}>
          <i className="fas fa-times " />
        </span>} */}
        {/* showSnapshots && showOpportunity && showUpcomming && */}
        {showUserInfo && (
          <div className="session-profile-wrapper otherProfileView profile-modal">
            <SlidingPanel
              hidePanel={() => props.onClose()}
              renderData={renderProfileButtons}
              lastDivStyle={{borderRadius: '35px',   background: '#f7f6f2',}}
              firstDivStyle={{
                padding: 0,
                background: 'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)',
              }}
              showBlackHeader={true}

              // handleBlock={handleBlock}
              // profile={userInfo}

              //  slideFrom={'right'}
            >
              

              
               {showLoader && <LoaderSpinner className={'curved-spinner-bg'} />}
              <Scrollbars>
                {props.moderator &&
                  props.conferenceInfo.creator_id !== props.userId && (
                    <div
                      className={`sidebar-heading-wrapper mt-0 mb-3 with-panel`}>
                      <div className="modal-header-icons">
                        {UserisModerator &&
                          (audio ? (
                            <span
                              className="action-icon audio-icon active"
                              onClick={() => {
                                mute();
                              }}>
                              <i className="fas fa-microphone" />
                            </span>
                          ) : (
                            // <span
                            //   className="action-icon audio-icon disableIcon"
                            //   onClick={() => unmute(false)}>
                            //   <i className="fas icon-mute-2" />
                            // </span>
                            <></>
                          ))}
                        {/* {props.conferenceInfo.creator_id ===
                          localStorage.getItem('user') && (
                          <span onClick={() => switchCreator()}>
                            <i className="far fa-star" />
                          </span>
                        )} */}
                        <span
                          className="sessionRolls-icon leftPosition"
                          onClick={() => {
                            setIsOpen(false);
                            setIsVipOpen(!isVipOpen);
                          }}>
                          <img
                            src={isVipOpen ? MakeVipYellow : MakeVipGrey}
                            alt=""
                          />
                          {isVipOpen && (
                            <div className="dropdown-content">
                              {/* {/ Dropdown content /} */}
                              <span className="listItems">
                                <img src={StarDisable} alt="" />
                                <p>VIP</p>
                              </span>
                              <span className="listItems">
                                <img src={RemoveBadgeActive} alt="" />
                                <p>Remove Badge</p>
                              </span>
                              <span className="listItems">
                                <img src={SheildDisable} alt="" />
                                <p>Acknowledge</p>
                              </span>
                            </div>
                          )}
                        </span>
                        {/* <span>
                          <img src={MakeVipYellow} alt="" />
                        </span> */}

                        <span className="sessionRolls-icon leftPosition changeRolls-sheet">
                          <img
                            src={isOpen ? InfoDotsActive : InfoDotsDisable}
                            alt=""
                            onClick={toggleDropdown}
                          />
                          {isOpen && (
                            <div className="dropdown-content">
                              {userTypeInSession !== 'moderator' &&
                                props.moderator && (
                                  <span
                                    className="listItems"
                                    onClick={() =>
                                      changeUserRights('moderator')
                                    }>
                                    <img src={MakeModeratorDisable} alt="" />
                                    <p>Make Moderator</p>
                                  </span>
                                )}
                              {userTypeInSession === 'audience' &&
                                props.moderator && (
                                  <span
                                    className="listItems"
                                    // onClick={() => unmute(true)}>
                                    onClick={() => changeUserRights('stage')}>
                                    <img src={StageMicDisable} alt="" />
                                    <p>Move to Stage</p>
                                  </span>
                                )}

                              {(userTypeInSession === 'stage' ||
                                userTypeInSession === 'moderator') &&
                                props.moderator && (
                                  <span
                                    className="listItems"
                                    onClick={() =>
                                      changeUserRights('audience')
                                    }>
                                    <img src={MakeModeratorDisable} alt="" />
                                    <p>Back To Audience</p>
                                  </span>
                                )}

                              {(userTypeInSession === 'stage' ||
                                userTypeInSession === 'moderator') &&
                                userTypeInSession !== 'audience' &&
                                props.moderator && (
                                  <span
                                    className="listItems"
                                    onClick={() => changeUserRights('creator')}>
                                    <img src={MakeCreatorDisable} alt="" />
                                    <p>Make Creator</p>
                                  </span>
                                )}

                              {
                                <span
                                  className="listItems"
                                  onClick={() => changeUserRights('ban')}>
                                  <img src={BanDisable} alt="" />
                                  <p>Ban User</p>
                                </span>
                              }
                            </div>
                          )}
                        </span>
                        {/* <span>
                          <img src={InfoDotsDisable} alt="" />
                        </span> */}
                      </div>

                      {/* <div className="moderator-btns btns-wrapper">
                        {props.moderator &&
                          props.conferenceInfo.creator_id !== props.userId &&
                          (UserisModerator ? (
                            <>
                              {props.jdSpecialS.includes(props.userId) && (
                                <span
                                  className="listItems"
                                  onClick={() => manageUser('moderator')}>
                                  <img src={MakeModeratorDisable} alt="" />
                                  <p>Make Moderator</p>
                                </span>
                              )}

                              <span
                                className="listItems"
                                onClick={() => manageUser('audience')}>
                                <img src={MakeModeratorDisable} alt="" />
                                <p>Back To Audience</p>
                              </span>
                              Send To Audience
                            </>
                          ) : (
                            <>
                              <span
                                className="listItems"
                                onClick={() => manageUser('moderator')}>
                                <img src={MakeModeratorDisable} alt="" />
                                <p>Make Moderator</p>
                              </span>
                              Make Moderator

                              <Button onClick={() => unmute(true)}>
                                <i className="icon icon-meetups" />
                                Send To Stage
                              </Button>
                            </>
                          ))}

                        {props.conferenceInfo.creator_id ===
                          localStorage.getItem('user') && (
                          <Button className="btn-dark" onClick={() => Ban()}>
                            <i className="fas fa-ban" />
                            Ban
                          </Button>
                        )}
                      </div> */}
                    </div>
                  )}

                <div className="fix-width-forms fixed-width-inputs  " style={{padding: '20px'}}>
                  <ProfileBlock
                    type={'otherUser'}
                    average={average}
                    profile={userInfo}
                    handleBlock={handleBlock}
                    checkAppoinmentData={checkAppoinmentData}
                    noshow={props.noshow}
                    setEditProfile={props.setEditProfile}
                    editProfile={props.editProfile}
                    submit={props.submit}
                    typeModAud={props.typeModAud}
                    showButton={props.showButton}
                    serverTime={props.serverTime}
                    sessionData={props.sessionData}
                    withHeadingSpace={props.withHeadingSpace}
                    tagsListing={userInfo}
                  />
                  {/* {userInfo.data.invited_by.first_name && (
                    <div className="invited-by-wrapper">
                      <div className="user-img">
                        <img
                          src={
                            userInfo.data.invited_by.profile_img
                              ? userInfo.data.invited_by.profile_img
                              : DEFAULT_IMAGE
                          }
                          alt=""
                        />
                      </div>
                      <div className="user-info">
                        <span className="info-name">
                          Invited by{' '}
                          <b>
                            {userInfo.data.invited_by.first_name +
                              ' ' +
                              userInfo.data.invited_by.last_name}
                          </b>
                        </span>
                        <span className="info-date">
                          {moment(
                            userInfo.data.invited_by.invitation_date,
                          ).format('MM/DD/YY')}
                        </span>
                      </div>
                    </div>
                  )} */}
                  {!props.noshow &&
                    userInfo &&
                    userInfo.data &&
                      (
                      <>
                        <div className="mt-4">
                          <ProfileGallery
                            userId={props.userId}
                            userData={userInfo}
                            gallery={userSnapshots}
                            isNewSession={false}
                            sesList={userUpcomming}
                            oppos={userOpportunity}
                            handleCommand={handleSnapshotId}
                            filterCat={filterCat}
                            hitApiRef={hitApiRef}
                            handleBlock={handleBlock}
                            selectedPaymentFilter={selectedPaymentFilter}
                            filterOptionValue={filterOptionValue}
                            selectedFilterValue={selectedFilterValue}
                            sessionFilterValue={sessionFilterValue}
                            setSessionFilterValue={setSessionFilterValue}
                            snapShotRefer={props.type && props.type ==='snapshotRefer' ? true : false}
                            setshowLoader={setshowLoader}
                          />
                        </div>
                      </>
                    )}
                  {/* {userInfo && userInfo.data.exist_in_build === 'no' && (
                    <div className="px-5  d-flex flex-column justify-content-center align-self-center align-items-center no-records personal_invite">
                      <p className="p-bold my-3">Awaiting profile setup</p>
                      <p className="mb-3 text-center">
                        {`  We will let you know when ${fixName(
                          userInfo.data.first_name,
                          userInfo.data.last_name,
                        )}  joins BUILD. Until than 
                  , you can still manage this contact like the others.`}
                      </p>
                      <Button
                        disabled
                        className="btn btn-dark"
                        onClick={() => {
                          send_invitation_in_company(userInfo.data.phone);
                        }}>
                        {'Send Personal Invite'}
                      </Button>
                    </div>
                  )} */}
                </div>
              </Scrollbars>
              
            </SlidingPanel>
          </div>
        )}
      </div>
      {showComponent.snapshot ? (
        <SnapshotDetail
          snapshotId={snapshotId}
          userData={userInfo.data}
          onClose={() => setShowComponent({...showComponent, snapshot: false})}
          showRefer={true}
          noshow={props.noshow}
        />
      ) : showComponent.opportunity ? (
        <>
          {opportunityData &&
          opportunityData.chat_detail &&
          opportunityData.chat_detail.hasOwnProperty('id') ? (
            <SmartChatPage
              allmsg={true}
              type={'other-opportunity'}
              item={[]}
              oppoDetails={opportunityData}
              chatDetails={opportunityData.chat_detail}
              chatroomInfo={chatroomInfoOppo(opportunityData, 'opportunity')}
              allChatUsers={
                opportunityData.hasOwnProperty('creator_details')
                  ? [opportunityData.creator_details]
                  : [opportunityData.creator_detail]
              }
              chatRoomName={
                opportunityData.chat_detail.hasOwnProperty('chat_room_name')
                  ? opportunityData.chat_detail.chat_room_name
                  : ''
              }
              chatRoomId={
                opportunityData.chat_detail.hasOwnProperty('id')
                  ? opportunityData.chat_detail.id
                  : ''
              }
              userData={
                opportunityData.hasOwnProperty('creator_details')
                  ? opportunityData.creator_details
                  : opportunityData.creator_detail
              }
              chatRoomStatus={
                opportunityData.chat_detail.hasOwnProperty('status')
                  ? opportunityData.chat_detail.status
                  : 'pending'
              }
              onClose={() => {
                setShowComponent({...showComponent, opportunity: false});
              }}
            />
          ) : (opportunityData &&
              opportunityData.team_member_details.length > 0 &&
              opportunityData.team_member_details.find(
                objs =>
                  parseInt(objs.id) === parseInt(localStorage.getItem('id')),
              )) ||
            parseInt(opportunityData.created_by) ===
              parseInt(localStorage.getItem('id')) ? (
            <OpportunityMessageList
              item={opportunityData}
              onClose={() => {
                // dispatch(getOpportunityListing(opportunityData.payload));
                // setShowButton(false);
                // setProperComponent('');
                setShowComponent({...showComponent, opportunity: false});
              }}
              onEditOppo={() => {
                // setProperComponent('fromOppoMsg'); // temparary majbori
                // // setInviteUser(true);
                // setCreateSessionEvent(false);
              }}
              onCreateSessionEvent={() => {
                // setCreateSessionEvent(true);
                // // setInviteUser(false);
                // setProperComponent('');
              }}
              onShowChat={() => {
                // setProperComponent('show-chats');
              }}
            />
          ) : (
            <OpportunityDetail
              item={opportunityData}
              userData={userInfo.data}
              onClose={() =>
                setShowComponent({...showComponent, opportunity: false})
              }
            />
          )}
        </>
      ) : showComponent.followers ? (
        <>
          {/* <span className="close as-link" onClick={() => setShowComponent({ ...showComponent, ['followers']: false })}>
                    Back
                </span> */}
          <FollowersList
            setShowComponent={setShowComponent}
            userId={profileId}
          />
        </>
      ) : showComponent.praise ? (
        <>
          {/* <span className="close as-link" onClick={() => setShowComponent({ ...showComponent, ['praise']: false })}>
                    Back
                </span> */}
          <PraiseCoution
            setShowComponent={setShowComponent}
            userData={profileId}
          />
        </>
      ) : showComponent.referOpportunity ? (
        // <div className="content-sidebar">
        <ReferOpportunity
          onClose={() =>
            setShowComponent({...showComponent, referOpportunity: false})
          }
          type={'opportunity'}
          userData={profileId}
        />
      ) : // </div>
      showComponent.session ? (
        <>
          {sessionData.creator_id === localStorage.getItem('id') ? (
            <CreateSession
              onClose={() =>
                setShowComponent({...showComponent, session: false})
              }
              editSession={sessionData}
              type={true}
              item={sessionData}
            />
          ) : (
            <SessionDetail
              item={sessionData}
              onClose={() =>
                setShowComponent({...showComponent, session: false})
              }
            />
          )}
        </>
      ) : showComponent.appointment ? (
        <div>
          {/* <span className="close as-link" onClick={() => setShowComponent({ ...showComponent, ['appointment']: false })}>
                    Back
                </span> */}
          <AppointmentList
            setShowComponent={setShowComponent}
            userData={profileId}
            withHeadingSpace={props.withHeadingSpace}
          />
        </div>
      ) : showComponent.mineAppointment ? (
        <div style={{height: '100%'}}>
          <UserAppointments
            onClose={() =>
              setShowComponent({...showComponent, mineAppointment: false})
            }
            userData={profileId}
          />
        </div>
      ) : showComponent.circle ? (
        <>
          {/* <div className="content-sidebar"> */}
          {/* <span className="close as-link" onClick={() => setShowComponent({ ...showComponent, ['circle']: false })}>
                    Back
                </span> */}
          <CircleListBuilders
            onClose={() => setShowComponent({...showComponent, circle: false})}
            // setInviteCircle={setInviteCircle}
            hide={false}
            // CircleUserListData={}
          />
          {/* <CircleList
        setShowComponent={setShowComponent}
        checkAppoinmentData={checkAppoinmentData}
        userData={profileId}
        onClose={() => setShowComponent({...showComponent, ['circle']: false})}
      /> */}
          {/* </div> */}
        </>
      ) : showComponent.share ? (
        <>
          {/* <span className="close as-link" onClick={() => setShowComponent({ ...showComponent, ['share']: false })}>
                    Back
                </span> */}
          <ReferProfile
            userData={profileId}
            onClose={() => setShowComponent({...showComponent, share: false})}
          />
        </>
      ) : showComponent.chat ? (
        <>
          <SmartChatPage
            allmsg={true}
            type={chatType}
            item={[]}
            chatroomInfo={
              chatDetails.length > 0
                ? chatroomInfo(chatDetails)
                : newSingleChatroom(userInfo.data)
            }
            oppoDetails={chatDetails}
            chatDetails={chatDetails}
            allChatUsers={allChatUsers}
            chatRoomName={chatRoomName}
            chatRoomId={chatRoomId}
            onClose={() => {
              setShowComponent({...showComponent, chat: false});
            }}
            userData={userInfo.data}
            // handleCommonChat={(data, type)=>{handleCommonChat(data, type)}}
          />
        </>
      ) : showComponent.profilenotes ? (
        <ProfileNotes
          onClose={() =>
            setShowComponent({...showComponent, profilenotes: false})
          }
          userData={profileId}
        />
      ) : showComponent.personal ? (
        <PersonalModal
          type={'other-Profile'}
          userData={profileId}
          list={list}
          onClose={() => setShowComponent({...showComponent, personal: false})}
        />
      ) : showComponent.Profile_view ? (
        <SlidingPanel
          hidePanel={() =>
            setShowComponent({...showComponent, Profile_view: false})
          }
          type={'remove_class'}>
          <ProfileComponent
            userId={profileId}
            show={false}
            closeModal={() => {
              setShowComponent({...showComponent, Profile_view: false});
            }}
            userData={[]}
            moderator={false}
            VoxeetSDK={[]}
            conferenceInfo={[]}
            jdSpecialM={[]}
            jdSpecialA={[]}
            onClose={() => {
              setShowComponent({...showComponent, Profile_view: false});
            }}
          />
        </SlidingPanel>
      ) : showComponent.transformation ? (
        <TransformDetail
          item={dataofTransFormation}
          onClose={() => {
            setShowComponent('');
          }}
        />
      ) : showComponent.editProfile ? (
        <EditUserProfile
          type="profileComponent"
          profile={userInfo}
          setUserInfo={setUserInfo}
          onClose={() => {
            setShowComponent({...showComponent, editProfile: false});
          }}
          getUserData={getUserData}
          setProfileImgScrollBar={props.setProfileImgScrollBar}
        />
      ) : showComponent.snapshot_filter ? (
        <CustomModal
          size="medium"
          className={'filter-modal from-otherProfile-Snapshot'}
          style={{height: '85%'}}
          onClose={() => {
            setShowComponent({...showComponent, snapshot_filter: false});
          }}>
          <div className="filter-wrapper  custom-modal-filter mb-0">
            <div className="filter-headings text-center">
              <h2>Filter By Category</h2>
            </div>
            <Scrollbars className="custom-scroll">
              <div className="filter-category-list">
                <ul>
                  <li
                    key={`sessionC-100`}
                    className={categoriesArr.length === 8 ? 'active' : ''}>
                    <Form.Label>
                      {'All'}
                      <input
                        type="checkbox"
                        value={'{"id":"All", "title":"All"}'}
                        onChange={handleSnapshotSelection}
                        checked={categoriesArr.length === 8}
                      />
                      <span />
                    </Form.Label>
                  </li>
                  {Object.entries(snapshotCategories).length > 0 &&
                    snapshotCategories.map((item, index) => (
                      <li
                        key={`sessionC-${index}`}
                        className={
                          categoriesArr.includes(item.id) ? 'active' : ''
                        }>
                        <Form.Label>
                          {item.category_name}
                          <input
                            type="checkbox"
                            value={
                              '{"id":"' +
                              item.id +
                              '", "title":"' +
                              item.category_name +
                              '"}'
                            }
                            onChange={handleSnapshotSelection}
                            checked={categoriesArr.includes(item.id)}
                          />
                          <span />
                        </Form.Label>
                      </li>
                    ))}
                </ul>
              </div>
            </Scrollbars>
            <div className="filter-category-list text-center">
              <Button
                className="btn-dark"
                onClick={() => {
                  setFilterCat(categoriesArr);
                  setShowComponent({
                    ...showComponent,
                    snapshot_filter: false,
                  });
                }}>
                Filter
              </Button>
            </div>
          </div>
        </CustomModal>
      ) : showComponent.stripe_modal ? (
        <CustomModal
          size="small"
          onClose={() => {
            setShowComponent({...showComponent, stripe_modal: false});
            setSelectedPrice('');
            setPaymentId('');
          }}
          className="availability-modal">
          <div
            className="new-session-modal-wrapper text-center  "
            style={{width: '-webkit-fill-available'}}>
            <div className="new-session-modal estimate_name_modal">
              <Elements
                stripe={stripePromise}
                options={{
                  mode: 'payment',
                  amount: 10 * 100,
                  currency: 'usd',
                  paymentMethodTypes: ['card'],
                  payment_method_types: ['card'],
                }}>
                <CheckoutForm
                  price={selectedPrice}
                  paymentId={paymentId}
                  transactionType={'invoice'}
                  onClose={() => {
                    setShowComponent({...showComponent, stripe_modal: false});
                    setSelectedPrice('');
                    setPaymentId('');
                    hitApiRef.current.getInvoice();
                  }}
                />
              </Elements>
            </div>
          </div>
        </CustomModal>
      ) : showComponent.follow_up ? (
        <ProfileNotes
          onClose={() => setShowComponent({...showComponent, follow_up: false})}
          type={'follow-up'}
          userData={profileId}
        />
      ) : showComponent.appointment_detail ? (
        <AppointmentCallDetail
          type={'userProfile'}
          appointmentData={appointmentData}
          subTabType={
            localStorage.getItem('id') === appointmentData.creator_details.id
              ? 'to_me'
              : 'from_me'
          }
          onClose={() => {
            setShowComponent({...showComponent, appointment_detail: false});
          }}
          setUserApStatus={setUserApStatus}
          refreshUserAppointmentList={refreshUserAppointmentList}
          changeMeetingStatusForOverView={(item, status) => {
            handleOverViewAppointment(item, status);
          }}
        />
      ) : showComponent.appointment_meeting_status ? (
        <CustomModal
          size="medium"
          onClose={() => {
            setShowComponent({
              ...showComponent,
              appointment_meeting_status: false,
            });
          }}>
          <div className="filter-wrapper">
            <div className="filter-category-list">
              <ul>
                {Object.entries(Appointment_Status_List).length > 0 &&
                  Appointment_Status_List.map((item, index) => (
                    <li key={`sessionC-${index}`}>
                      <Form.Label>
                        {item.title}
                        <input
                          type="checkbox"
                          id={item.title}
                          value={item.title}
                          checked={userApStatus === item.title}
                          onChange={() => handleApStatus(item.title)}
                        />
                        <span />
                      </Form.Label>
                    </li>
                  ))}
              </ul>
              <div className="filter-btn-wrapper text-center">
                <Button
                  type="submit"
                  className="btn-dark"
                  onClick={() => {
                    changeUserMeetingStatus(userApStatus);
                    handleOverViewAppointment(
                      changedStatusAppointment,
                      userApStatus,
                    );
                    setShowComponent({
                      ...showComponent,
                      appointment_meeting_status: false,
                    });
                  }}>
                  Select
                </Button>
              </div>
            </div>
          </div>
        </CustomModal>
      ) : showComponent.follow_up_detail ? (
        <ActionDetail
          dataOfAction={itemOfActionData}
          onClose={() => {
            setShowComponent({
              ...showComponent,
              follow_up_detail: false,
            });
            setItemOfActionData({});
          }}
        />
      ) : showComponent.create_follow_up ? (
        <CreateAction
          component={'userProfile'}
          userData={userInfo.data}
          onClose={() => {
            setShowComponent({
              ...showComponent,
              create_follow_up: false,
            });
          }}
          refreshUserDataList={() => {
            hitApiRef.current.getFollowUps();
          }}
          // setHitApi={setHitApi}
          followUpType={followUpType}
          futureDate={futureDate}
        />
      ) : showComponent.create_task ? (
        <CreateAction
          component={'userProfile'}
          userData={userInfo.data}
          onClose={() => {
            setShowComponent({
              ...showComponent,
              create_task: false,
            });
          }}
          refreshUserDataList={() => {
            hitApiRef.current.getTasks();
          }}
        />
      ) : showComponent.pipeline ? (
        <CreatePipeLine
          onClose={() => {
            setShowComponent({
              ...showComponent,
              pipeline: false,
            });
            // setItemOfActionData({});
          }}
        />
      ) : showComponent.create_deal ? (
        <CreateDeal
          userData={userInfo.data}
          onClose={() => {
            setShowComponent({
              ...showComponent,
              create_deal: false,
            });
          }}
          getUserData={getUserData}
        />
      ) : showComponent.deal_detail ? (
        <DealDetail
          dealItem={itemOfActionData}
          onClose={() => {
            setShowComponent({
              ...showComponent,
              deal_detail: false,
            });
            setItemOfActionData({});
          }}
          userData={userInfo}
          getUserData={getUserData}
        />
      ) : showComponent.change_phase_status ? (
        <CustomModal
          size="medium"
          onClose={() => {
            setShowComponent({
              ...showComponent,
              change_phase_status: false,
            });
          }}>
          <div className="filter-wrapper">
            <div className="filter-headings">
              <h2 className="text-center">Select Phase</h2>
            </div>
            <div className="filter-category-list">
              <ul>
                {Object.entries(categoriesArr).length > 0 &&
                  categoriesArr.map((item, index) => (
                    <li key={`sessionC-${index}`}>
                      <Form.Label>
                        {item.phase}
                        <input
                          type="checkbox"
                          id={item.id}
                          value={item.id}
                          checked={selectedPhase === item.id}
                          onChange={() => handlePhaseStatus(item.id)}
                        />
                        <span />
                      </Form.Label>
                    </li>
                  ))}
              </ul>
              <div className="filter-btn-wrapper text-center">
                <Button
                  type="submit"
                  className="btn-dark"
                  onClick={() => {
                    changeDealPhase(selectedPhase);
                  }}>
                  Select
                </Button>
              </div>
            </div>
          </div>
        </CustomModal>
      ) : showComponent.payment_filter ? (
        <CustomModal
          size="medium"
          onClose={() => {
            setShowComponent({
              ...showComponent,
              payment_filter: false,
            });
          }}>
          <div className="filter-wrapper">
            <div className="filter-category-list">
              <ul>
                {Object.entries(filterListArray).length > 0 &&
                  filterListArray.map((item, index) => (
                    <li key={`sessionC-${index}`}>
                      <Form.Label>
                        {item.title}
                        <input
                          type="checkbox"
                          id={item.id}
                          value={item.value}
                          checked={payment_filter === item.value}
                          onChange={() => handleFilterOption(item.value)}
                        />
                        <span />
                      </Form.Label>
                    </li>
                  ))}
              </ul>
              <div className="filter-btn-wrapper text-center">
                <Button
                  className="btn-dark"
                  onClick={() => {
                    setSelectedPaymentFilter(payment_filter);
                    setShowComponent({
                      ...showComponent,
                      payment_filter: false,
                    });
                  }}>
                  Select
                </Button>
              </div>
            </div>
          </div>
        </CustomModal>
      ) : showComponent.create_invoice ? (
        <CreateInvoice
          type={'profile'}
          userData={userInfo}
          onClose={() => {
            setShowComponent({
              ...showComponent,
              create_invoice: false,
            });
          }}
          hitApiRefresh={() => {
            hitApiRef.current.getInvoice();
          }}
        />
      ) : showComponent.task_filter ? (
        <CustomModal
          className="shareables-workspace-modal"
          size="small"
          onClose={() =>
            setShowComponent({
              ...showComponent,
              task_filter: false,
            })
          }>
          <div className="filter-wrapper">
            <div className="filter-category-list">
              <ul>
                {/* <li
                    className={
                      checkFilterOption.includes('all') ||
                      filterOptionValue.includes('all')
                        ? 'active'
                        : ''
                    }>
                    <Form.Label>
                      All
                      <input
                        type="checkbox"
                        id="All"
                        value="All"
                        onChange={() => {
                          handleSelectAll('all');
                        }}
                        checked={
                          checkFilterOption.includes('all') ||
                          filterOptionValue.includes('all')
                        }
                      />
                      <span />
                    </Form.Label>
                  </li> */}

                {ActionCategories.map((item, index) => (
                  <li
                    key={`sessionC-${index}`}
                    className={
                      checkFilterOption.includes(item.id) ? 'active' : ''
                    }>
                    <Form.Label>
                      {item.title}
                      <input
                        type="checkbox"
                        id={item.id}
                        value={item.title}
                        onChange={e => {
                          handleTaskFilter(item.id, e.target.value);
                        }}
                        checked={checkFilterOption.includes(item.id)}
                      />
                      <span />
                    </Form.Label>
                  </li>
                ))}
              </ul>

              <div className="text-center">
                <Button
                  type="submit"
                  className="btn-dark "
                  onClick={() => {
                    const item = ActionCategories.filter(
                      item => item.id === checkFilterOption[0],
                    );
                    console.log(item, 'itemfromfilter');
                    setFilterOptionValue([item[0].title]);
                    setShowComponent({
                      ...showComponent,
                      task_filter: false,
                    });
                  }}>
                  Filter
                </Button>
              </div>
            </div>
          </div>
        </CustomModal>
      ) : showComponent.appointment_filter ? (
        <CustomModal
          size="medium"
          onClose={() => {
            setShowComponent({
              ...showComponent,
              appointment_filter: false,
            });
          }}>
          <div className="filter-wrapper">
            <div className="filter-category-list">
              <ul>
                {Object.entries(filterListArray).length > 0 &&
                  filterListArray.map((item, index) => (
                    <li key={`sessionC-${index}`}>
                      <Form.Label>
                        {item.title}
                        <input
                          type="checkbox"
                          id={item.id}
                          value={item.value}
                          checked={value_filter === item.value}
                          onChange={() => handleAppointmentFilter(item.value)}
                        />
                        <span />
                      </Form.Label>
                    </li>
                  ))}
              </ul>
              <div className="filter-btn-wrapper text-center">
                <Button
                  type="submit"
                  className="btn-dark"
                  onClick={() => {
                    // onSubmitFilter(value_filter);
                    setSelectedFilterValue(value_filter);
                    setShowComponent({
                      ...showComponent,
                      appointment_filter: false,
                    });
                  }}>
                  Select
                </Button>
              </div>
            </div>
          </div>
        </CustomModal>
      ) : showComponent.create_session ? (
        <CreateSession
          onClose={() =>
            setShowComponent({...showComponent, create_session: false})
          }
          // editSession={sessionData}
          // type={true}
          // item={sessionData}
        />
      ) : showComponent.session_filter ? (
        <CustomModal
          size="medium"
          onClose={() => {
            setShowComponent({
              ...showComponent,
              session_filter: false,
            });
          }}>
          <div className="filter-wrapper">
            <div className="filter-category-list">
              <ul>
                {Object.entries(sessionFilterArray).length > 0 &&
                  sessionFilterArray.map((item, index) => (
                    <li key={`sessionC-${index}`}>
                      <Form.Label>
                        {item.title}
                        <input
                          type="checkbox"
                          id={item.id}
                          value={item.value}
                          checked={session_filter === item.value}
                          onChange={() => handleSessionFilter(item.value)}
                        />
                        <span />
                      </Form.Label>
                    </li>
                  ))}
              </ul>
              <div className="filter-btn-wrapper text-center">
                <Button
                  type="submit"
                  className="btn-dark"
                  onClick={() => {
                    // onSubmitFilter(value_filter);
                    setSessionFilterValue(session_filter);
                    setShowComponent({
                      ...showComponent,
                      session_filter: false,
                    });
                  }}>
                  Select
                </Button>
              </div>
            </div>
          </div>
        </CustomModal>
      ) : 
        showComponent.create_recruitment ? (
          <EditOpportunities
            onClose={() =>
              setShowComponent({...showComponent, create_recruitment: false})
            }
            item={[]}
            // editSession={sessionData}
            // type={true}
            // item={sessionData}
          />
        ) : showComponent.create_tag ? (
          <CreateTag
          onClose={() =>
            setShowComponent({...showComponent,create_tag : false})
          }
          />
        ):  showComponent.view_tag && (
          <ProfileTags
          handleCommand={handleSnapshotId}
          onClose={() =>
            setShowComponent({...showComponent,view_tag : false})
          }
          user_id={props.userId}
          userTagList={userInfo?.tags_details}
          userInfo={userInfo}
          permession={userInfo?.tag_permission?.create_tag} 
          />
        )
      }
    </>
  );
}

const mapStateProps = state => ({
  windowWidth: state.windowWidth,
});

export default connect(mapStateProps, {})(ProfileComponent);
