import React, { useEffect, useState, useRef } from 'react';
import { Button } from 'react-bootstrap';
import Slider from 'react-slick';
import { generateId } from '../Utils/Helpers';

const CustomSlidingButtons = ({
  items,
  onItemClick,
  activeButtons,
  showItems,
  label,
}) => {
  const [tabId, setTabId] = useState('');
  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    arrows: true,
    dots: false,
    infinite: false, // Disable infinite scrolling
    speed: 500,
    slidesToShow: showItems ? showItems : 3,
    slidesToScroll: 1,
    variableWidth: false,
    initialSlide: 0,
    afterChange: (current) => setCurrentSlide(current), // Track current slide index
  };

  useEffect(() => {
    const tabId = generateId();
    setTabId(tabId);
  }, []);

  const totalSlides = items.length; // Dynamically calculate total slides

  return (
    <div>
      <Slider {...settings}>
        {items &&
          items.map((item, index) => (
            <Button
              key={`custom-tabs-${tabId}-${index}`}
              id={item.id}
              value={item.title}
              className={activeButtons.includes(item.id) ? 'cate-btn active' : ''}
              onClick={onItemClick}
            >
              {label
                ? item[label]
                : item.title
                ? item.title
                : item.category_title
                ? item.category_title
                : item}
            </Button>
          ))}
      </Slider>
    </div>
  );
};

export default CustomSlidingButtons;
