import React, {useState, useEffect} from 'react';
import Sidebar from '../../Pages/Sidebar';
import {selected_lang} from '../../Utils/env';
import Pannels from '../../Components/Pannels';

import {arrow_up, rewardStar, taskCheckMark} from '../../Constants/Images';
import {labels} from '../../Constants/Translations';
import {Button, Form} from 'react-bootstrap';

import Slider from 'react-slick';
import CreateWorkSpace from '../../Components/WorkSpace/CreateWorkSpace';
import Scrollbars from 'react-custom-scrollbars';
import {CustomModal, CustomSlidingButtons} from '../../Components';

import {getAllActions} from '../../Store/Actions/SessionAction';

import {useDispatch, useSelector} from 'react-redux';
import {capitalizeFirst, fixName} from '../../Utils/commonUtils';
import CreateAction from '../../Components/Snapshot/CreateAction';
import ActionDetail from '../../Components/Actions/ActionDetail';

const Task = props => {
  const [isFloated, toggleFloatedPanel] = useState(false);
  const [showHiddenPanel, toggleHiddenPanel] = useState(false);
  const [showComponent, setShowComponent] = useState('');
  const [tabType, setTabType] = useState('list');
  const [threeDots, setThreeDots] = useState('');
  const [workspaceData, setWorkspaceData] = useState([]);

  const [analycticsSlider, setAnalycticsSlider] = useState(false);

  const dispatch = useDispatch();

  // const [actionType, setActionType] = useState('');
  const [searchQry, setSearchQry] = useState('');
  const [searchFilter, setSearchFilterModal] = useState(false);
  const [checkFilterOption, setCheckFilterOption] = useState([1]);
  const [filterOptionValue, setFilterOptionValue] = useState(['All']);
  const [actionType, setActionType] = useState([1]);
  const [actionTypeValue, setActionTypeValue] = useState(['All']);
  const [s3howModel, setshowModel] = useState('');
  const [dataOfAction, setDataOfAction] = useState('');

  const ActionCategories = [
    {id: 1, title: 'All'},
    {id: 2, title: 'Me'},
    {id: 3, title: 'Watching'},
  ];

  const filterOption = [
    {id: 1, title: 'All'},
    {id: 2, title: 'Pending'},
    {id: 3, title: 'Completed'},
    {id: 4, title: 'Expired'},
  ];

  const handleSearchQry = q => {
    getActions(q);
  };

  const getActions = qry => {
    const actions = actionTypeValue.map(element => {
      let lowerCaseElement = element.toLowerCase();
      if (lowerCaseElement === 'expired') {
        lowerCaseElement = lowerCaseElement.slice(0, -1); // Remove the last character 'd'
      }
      return lowerCaseElement;
    });

    const options = filterOptionValue.map(element => {
      return element.toLowerCase();
    });

    console.log(actionTypeValue, 'upppercase');

    var Payload = {
      user_id: localStorage.getItem('id'),
      type: props.tabType
        ? ['other']
        : options === 'watching'
        ? ['other']
        : options,
      search_query: searchQry,
      filter: actions === 'expired' ? ['expire'] : actions,
    };
    localStorage.setItem('action_paylaod', JSON.stringify(Payload));
    dispatch(getAllActions(Payload));
  };

  const handleClickList = (id, value) => {
    setCheckFilterOption([id]);
    setFilterOptionValue([value]);
  };

  useEffect(() => {
    getActions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.hitApi, actionTypeValue, props.tabType]);

  useEffect(() => {
    getActions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showComponent, searchQry]);

  const handleType = e => {
    const {id, value} = e.target;

    setActionType(id);
    setActionTypeValue([value]);
  };

  const assigneDetail = item => {
    const userId = localStorage.getItem('id');
    const check = item.watchers_detail.some(obj => obj.id === userId);

    return item.created_by === userId && item.assigne !== '0' ? (
      <p className="p-reg m-0 actionsCategories single-line-text">
        From you to{' '}
        {fixName(item.assigne_detail.first_name, item.assigne_detail.last_name)}
      </p>
    ) : item.assigne === userId || check ? (
      <p className="p-reg m-0 actionsCategories single-line-text">
        For you from{' '}
        {fixName(item.creator_detail.first_name, item.creator_detail.last_name)}
      </p>
    ) : (
      <></>
    );
  };

  const actions = useSelector(state => state.AllActionsList);

  const TaskList = [
    {
      id: '342',
      title: 'New Task',
      priority: 'medium',
      due_date: '2024-01-19 21:09:00',
      due_time: '21:09:00',
      description: 'Anything',
      association_id: '2515',
      association_type: 'profile',
      assigne: '1737',
      watchers: '',
      workspace_ids: '172',
      personal: 'yes',
      status: 'pending',
      images: '',
      created_by: '2515',
      created_at: '2024-01-01 05:10:12',
      updated_by: '0',
      updated_at: '2024-01-01 05:10:12',
      creator_detail: {
        id: '2515',
        first_name: 'Hello',
        last_name: 'World',
      },
      assigne_detail: {
        id: '1737',
        first_name: 'Free',
        last_name: 'Tickets',
      },
      watchers_detail: [],
      association_name: 'helloworld879321',
    },
  ];

  const taskSlider = {
    // className: "center",
    // centerMode: true,
    dots: false,
    arrows: false,
    infinite: false,
    // centerPadding : "60px",
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 768, // This is the breakpoint for mobile devices (e.g., tablets)
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480, // This is the breakpoint for smaller mobile devices
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    // adaptiveHeight: true,
  };

  const taskSliderData = [
    {
      taskStatus: 'Backlog',
    },

    {
      taskStatus: 'Pending',
    },
    {
      taskStatus: 'Awaiting QA',
    },
    {
      taskStatus: 'Complete',
    },
  ];
  useEffect(() => {
    setTimeout(() => {
      const {pathname} = window.location;
      const urlPart = pathname.split('/').filter(Boolean);
      
      if (
        Object.keys(workspaceData).length > 0 &&
        urlPart.length === 1 &&
        showComponent !== ''
      ) {
        setShowComponent('');
      }
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceData]);

  useEffect(() => {
    if (showComponent !== '') {
      toggleHiddenPanel(true);
    } else {
      toggleHiddenPanel(false);
    }
  }, [showComponent]);

  const rewardSlider = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };
  const rewardSliderData = [
    {
      name: 'Completion',
      money: '2.4%',
      time: 'versus last month',
      svgIcon: rewardStar,
    },

    {
      name: 'High Impact',
      money: '85%',
      time: 'versus last month',
      svgIcon: rewardStar,
    },

    {
      name: 'Customer',
      money: '45%',
      time: 'versus last month',
      svgIcon: rewardStar,
    },
  ];

  const dynamicStyle = {
    padding: 0,
  };

  return (
    <div className="page-wrapper">
      <Sidebar
        location={props.location}
        showComponent={showComponent}
        setShowComponent={setShowComponent}
        setWorkSpaceItem={setWorkspaceData}
        workspaceData={workspaceData}
      />
      <div className="page-content-wrapper">
        <div className="sessions-list-wrapper slider-screen-main">
          <div className="Reward_main analytics_slider_bg">
            <span
              className="analytics_slider_heading "
              style={{color: analycticsSlider ? '' : '#999999'}}
              onClick={() => {
                setAnalycticsSlider(!analycticsSlider);
              }}>
              Analytics Row
            </span>

            {analycticsSlider && (
              <div className="reward-top mt-3 mb-2">
                <Slider {...rewardSlider}>
                  {rewardSliderData.length > 0 &&
                    rewardSliderData.map((item, index) => (
                      <div className="reward-main">
                        <div className="reward-card">
                          <span style={{fontWeight: 700, fontSize: '13px'}}>
                            {item.name}
                          </span>
                          <span
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}>
                            <span style={{fontSize: '17px'}}>{item.money}</span>
                            {item.name === 'Ratings' && (
                              <img src={item.svgIcon} alt="" />
                            )}
                            {item.name === 'Conversion' ? (
                              <i
                                className="fa-solid fa-arrow-down"
                                style={{color: '#BD4141'}}></i>
                            ) : (
                              <i
                                class="fa-solid fa-arrow-up"
                                style={{color: '#52bd41'}}></i>
                            )}
                          </span>
                          <div class="message-date-info">
                            <span style={{color: '#333'}} class="message-date">
                              {item.time}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                </Slider>
              </div>
            )}
          </div>
          <div className="add_border"></div>

          {tabType === 'list' && (
            <Pannels
              isFloated={isFloated}
              showHiddenPanel={showHiddenPanel}
              updateHiddenPanelState={nState => toggleHiddenPanel(nState)}
              customCss={dynamicStyle}
              toggleFloatedPanel={toggleFloatedPanel}
              primary={
                <>
                  <div className="task-page primary_panel_wraper primary_panel_content">
                    <div className="messages-header">
                      <div className="event-tabs tabs-style-2 mb-0">
                        {' '}
                        {/* Merging */}
                        <ul className="LabelTabs">
                          <li className="ml-20 d-flex align-items-center">
                            <Button
                              className={tabType === 'list' ? 'active' : ''}
                              onClick={() => {
                                setTabType('list');
                              }}>
                              List
                            </Button>
                          </li>

                          <li className="ml-20 d-flex align-items-center">
                            <Button
                              className={tabType === 'board' ? 'active' : ''}
                              onClick={() => {
                                setTabType('board');
                              }}>
                              Board
                            </Button>
                          </li>

                          {/* <li className="ml-20 d-flex align-items-center">
                            <Button
                              className={
                                tabType === 'checklists' ? 'active' : ''
                              }
                              onClick={() => {
                                setTabType('list');
                              }}>
                              {labels.CHECKLISTS[selected_lang]}
                            </Button>
                          </li> */}
                        </ul>
                      </div>

                      <div className="search-tabs-icons">
                        <span
                          className={
                            showComponent === 'addAction'
                              ? 'icon active'
                              : 'icon'
                          }>
                          <i
                            className="icon-plus"
                            onClick={() => {
                              if (showComponent === 'addAction') {
                                setShowComponent('');
                              } else {
                                setShowComponent('addAction');
                              }
                            }}
                          />
                        </span>

                        <span
                          onClick={() => {
                            setThreeDots(!threeDots);
                          }}
                          className={threeDots ? 'icon active' : 'icon'}>
                          <i className="fas fa-ellipsis-vertical" />
                        </span>
                      </div>
                    </div>
                    {threeDots && (
                      <>
                        <div className="person-search-wrapper  mt-0 messages-users-wrapper pt-2">
                          <div className="search-form d-flex">
                            <Form.Group className="search-input">
                              <Form.Control
                                type="text"
                                placeholder="Search"
                                value={searchQry}
                                onChange={e => {
                                  setSearchQry(e.target.value);
                                }}
                              />
                            </Form.Group>
                            <Button
                              onClick={() => {
                                setSearchFilterModal('filter');
                              }}>
                              <i className="icon-filter-icon" />
                            </Button>
                          </div>
                        </div>
                        <div >
                      <div>
                        <div className="category-btns-wrapper category-btns-wrapper-slider mt-0 mb-4">
                          <CustomSlidingButtons
                            items={filterOption}
                            activeButtons={actionType}
                            onItemClick={e => {
                              handleType(e);
                            }}
                            showItems={3}
                            value={actionTypeValue}
                          />
                        </div>
                      </div>
                    </div>
                      </>
                    )}
                    
                    <div className={threeDots ? "refferal-list-wrapper" : 'refferal-list-wrapper mt-3'}>
                      <Scrollbars className="custom-refferal-vertical-scrollbar" autoHide>
                        <div className="task-list-waraper">
                          {/* <div className="task-list-title mb-4">Backlog</div> */}
                          {Object.entries(actions).length > 0 ? (
                            actions.map((item, index) => (
                              <div
                                className="refferals-list action-listing as-link"
                                onClick={() => {
                                  setDataOfAction(item);
                                  setShowComponent('actionDetails');
                                }}>
                                <div className="task-item">
                                  {/* <div className="red-bar"></div> */}
                                  <h2 className="truncate_2_lines actions-title mb-2">
                                    {/* {item.title} */}
                                    {item.title}
                                  </h2>
                                  <div className="d-flex align-items-center session-cam mb-2">
                                    {/* <img src={VideoGreyIcon} alt="video-cam" /> */}
                                    <p className="p-reg m-0 single-line-text">
                                      {/* {item.association_name} */}
                                      {capitalizeFirst(item.priority)}
                                    </p>
                                  </div>
                                  <p className="p-reg m-0 mb-2">
                                    {/* {item.due_date !== '0000-00-00 00:00:00' &&
                    'Due ' + getRelativeDate(item.due_date)}
                  
                  {item.priority !== '' && (
                    <span>({capitalizeFirst(item.priority)} Priority)</span>
                  )} */}
                                    {capitalizeFirst(item.status)}
                                  </p>
                                  <div
                                    className="d-flex align-items-center justify-content-between"
                                    style={{alignItems: 'center'}}>
                                    <div className="d-flex align-items-center">
                                      
                                      <p className="p-reg m-0 actionsCategories single-line-text">
                                        {/* {item.association_name} */}
                                        {assigneDetail(item)}
                                      </p>
                                    </div>

                                    <div className="arrow-up">
                                      {/* <img
                                        height={20}
                                        width={20}
                                        src={arrow_up}
                                        alt="checkmark"
                                      />
                                      <span>21</span> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : (
                            <div className="no-results-wrapper payment-no-results no-record-notification">
                              <div className="img-bg-wrapper">
                                <img
                                  src={require('../../Assets/images/norecord/no-actions.png')}
                                  alt=""
                                />
                              </div>

                              <div className="no-results-content">
                                <h2>{labels.Actions_Pending[selected_lang]}</h2>
                                <p>{labels.NoAction_Desc[selected_lang]}</p>
                                <div className="btn-wrapper mt-3">
                                  <Button
                                    className="btn btn-dark"
                                    onClick={() => {
                                      setShowComponent('addAction');
                                    }}>
                                    Create Task
                                  </Button>
                                </div>
                              </div>
                            </div>
                          )}

                          {/* <div className="task-list-title mb-4">Complete</div>

                          <div className="task-list-title mb-4">
                            Awaiting QA
                          </div>

                          <div className="task-list-title mb-4">Pending</div> */}
                        </div>
                      </Scrollbars>
                    </div>
                  </div>

                  {/* Filter Modal */}
                  {searchFilter && (
                    <CustomModal
                      className="shareables-workspace-modal"
                      size="small"
                      onClose={() => setSearchFilterModal(false)}>
                      <div className="filter-wrapper">
                        <div className="filter-category-list">
                          <ul>
                            {/* <li
                  className={
                    checkFilterOption.includes('all') ||
                    filterOptionValue.includes('all')
                      ? 'active'
                      : ''
                  }>
                  <Form.Label>
                    All
                    <input
                      type="checkbox"
                      id="All"
                      value="All"
                      onChange={() => {
                        handleSelectAll('all');
                      }}
                      checked={
                        checkFilterOption.includes('all') ||
                        filterOptionValue.includes('all')
                      }
                    />
                    <span />
                  </Form.Label>
                </li> */}

                            {ActionCategories.map((item, index) => (
                              <li
                                key={`sessionC-${index}`}
                                className={
                                  checkFilterOption.includes(item.id) ||
                                  filterOptionValue.includes(item.title)
                                    ? 'active'
                                    : ''
                                }>
                                <Form.Label>
                                  {item.title}
                                  <input
                                    type="checkbox"
                                    id={item.id}
                                    value={item.title}
                                    onChange={e => {
                                      handleClickList(item.id, e.target.value);
                                    }}
                                    checked={
                                      checkFilterOption.includes(item.id) ||
                                      filterOptionValue.includes(item.title)
                                    }
                                  />
                                  <span />
                                </Form.Label>
                              </li>
                            ))}
                          </ul>

                          {/* <Button
                // type="submit"
                className="btn-dark"
                onClick={() => {
                  getActions();
                  setSearchFilterModal(false);
                }}>
                Filter
              </Button> */}
                          <div className="text-center">
                            <Button
                              type="submit"
                              className="btn-dark "
                              onClick={() => {
                                getActions();
                                setSearchFilterModal(false);
                              }}>
                              Filter
                            </Button>
                          </div>
                        </div>
                      </div>
                    </CustomModal>
                  )}
                </>
              }
              secondary={
                <>
                  {showComponent === 'addAction' && (
                    <CreateAction
                      onClose={() => {
                        setShowComponent('');
                      }}
                    />
                  )}

                  {showComponent === 'actionDetails' && (
                    <ActionDetail
                      dataOfAction={dataOfAction}
                      setDataOfAction={setDataOfAction}
                      actions={actions}
                      onClose={() => {
                        setShowComponent('');
                      }}
                    />
                  )}
                </>
              }
            />
          )}

          {tabType === 'board' && (
            <div style={{display: 'flex', flex: 1}}>
              <div
                className="task-page primary_panel_content"
                style={{padding: '25px 20px 15px'}}>
                <div className="messages-header" style={{maxWidth: '379px'}}>
                  <div className="event-tabs tabs-style-2 mb-0">
                    {' '}
                    {/* Merging */}
                    <ul className="LabelTabs">
                      <li className="ml-20 d-flex align-items-center">
                        <Button
                          className={tabType === 'list' ? 'active' : ''}
                          onClick={() => {
                            setTabType('list');
                          }}>
                          {labels.LIST[selected_lang]}
                        </Button>
                      </li>

                      <li className="ml-20 d-flex align-items-center">
                        <Button
                          className={tabType === 'board' ? 'active' : ''}
                          onClick={() => {
                            setTabType('board');
                          }}>
                          {labels.BOARD[selected_lang]}
                        </Button>
                      </li>

                      {/* <li className="ml-20 d-flex align-items-center">
                        <Button
                          className={tabType === 'checklists' ? 'active' : ''}
                          onClick={() => {
                            setTabType('list');
                          }}>
                          {labels.CHECKLISTS[selected_lang]}
                        </Button>
                      </li> */}
                    </ul>
                  </div>

                  {/* <span className="add-icon">
                          <i
                            className="icon-plus"
                            onClick={() => setSecondComponent("group-component")}
                          />
                        </span>
                        <span className="add-icon">
                          <i
                            className="icon-filter-icon"
                            onClick={() => setSearchFilterModal(true)}
                          />
                        </span> */}

                  <div className="search-tabs-icons">
                    <span
                      className={
                        showComponent === 'addAction' ? 'icon active' : 'icon'
                      }>
                      <i
                        className="icon-plus"
                        onClick={() => {
                          if (showComponent === 'addAction') {
                            setShowComponent('');
                          } else {
                            setShowComponent('addAction');
                          }
                        }}
                      />
                    </span>

                    <span
                      onClick={() => {
                        setThreeDots(!threeDots);
                      }}
                      className={threeDots ? 'icon active' : 'icon'}>
                      <i className="fas fa-ellipsis-vertical" />
                    </span>
                  </div>
                </div>

                <div className="refferal-list-wrapper mt-4">
                  <Scrollbars autoHide>
                    <div className="board-list-wrapper mt-2">
                      <Slider {...taskSlider}>
                        {taskSliderData.length > 0 &&
                          taskSliderData.map((item, index) => (
                            <>
                              <div className="board-header">
                                <h1>{item.taskStatus}</h1>

                                <div className="add-task-btn as-link">
                                  Add Task
                                </div>
                              </div>
                              <div
                                className="refferals-list action-listing as-link"
                                onClick={() => {}}>
                                <div className="task-item">
                                  <div className="task-img"></div>
                                  <div className="red-bar"></div>

                                  <div className="task-board-list-card">
                                    <div className="task-profile-img">
                                      <img
                                        src="https://s3.amazonaws.com/build-dev/event/slider/1692340229593.jpg"
                                        alt=""
                                      />
                                    </div>

                                    <div className="task-content">
                                      <h2 className="truncate_2_lines actions-title mb-2 mt-3">
                                        {/* {item.title} */}
                                        Task Title
                                      </h2>
                                      <div className="d-flex align-items-center session-cam mb-2">
                                        {/* <img src={VideoGreyIcon} alt="video-cam" /> */}
                                        <p className="p-reg m-0 single-line-text">
                                          {/* {item.association_name} */}
                                          {'[Project Name]'}
                                        </p>
                                      </div>

                                      <div
                                        className="d-flex align-items-center justify-content-between"
                                        style={{alignItems: 'center'}}>
                                        <div className="d-flex align-items-center">
                                          {/* {assigneDetail(item)} */}
                                          <p className="p-reg m-0 actionsCategories single-line-text">
                                            Tomorrow
                                          </p>
                                        </div>

                                        <img
                                          height={20}
                                          width={20}
                                          src={taskCheckMark}
                                          alt="checkmark"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div
                                className="refferals-list action-listing as-link task-bottom-card"
                                onClick={() => {}}>
                                <div className="task-item">
                                  <div className="task-board-list-card">
                                    <div className="task-profile-img">
                                      <img
                                        src="https://s3.amazonaws.com/build-dev/event/slider/1692340229593.jpg"
                                        alt=""
                                      />
                                    </div>

                                    <div className="task-content">
                                      <h2 className="truncate_2_lines actions-title mb-2 mt-3">
                                        {/* {item.title} */}
                                        Task Title
                                      </h2>
                                      <div className="d-flex align-items-center session-cam mb-2">
                                        {/* <img src={VideoGreyIcon} alt="video-cam" /> */}
                                        <p className="p-reg m-0 single-line-text">
                                          {/* {item.association_name} */}
                                          {'[Project Name]'}
                                        </p>
                                      </div>

                                      <div
                                        className="d-flex align-items-center justify-content-between"
                                        style={{alignItems: 'center'}}>
                                        <div className="d-flex align-items-center">
                                          {/* {assigneDetail(item)} */}
                                          <p className="p-reg m-0 actionsCategories single-line-text">
                                            Tomorrow
                                          </p>
                                        </div>

                                        <img
                                          height={20}
                                          width={20}
                                          src={taskCheckMark}
                                          alt="checkmark"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ))}
                      </Slider>
                    </div>
                  </Scrollbars>
                </div>
              </div>
            </div>
          )}

          {showComponent === 'WorkSpace' && (
            <CreateWorkSpace
              onClose={() => {
                setShowComponent('');
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Task;
