import React, {useState, useEffect} from 'react';
import {Button, Form, Dropdown} from 'react-bootstrap';

import SlidingPanel from '../SlidingPanel';
import Scrollbars from 'react-custom-scrollbars';
import {
  calculateItemSubtotal,
  formatNumberWithCommas,
  formatSelectedDuration
} from '../../Utils/commonUtils';
import {FormattedText} from '../../Utils/Helpers';
import {labels} from '../../Constants/Translations';
import {selected_lang} from '../../Utils/env';
import {
  checkSubscriptionType,
  formatNumberWithTwoDecimals,
  formatMinutesTime,
  validateStandard,
  formatPaymentDescription,
  getLabelFromSubscriptionMonth,
} from '../../Utils/commonUtils';
import moment from 'moment';
import {errorMessage, successMessage} from '../../Utils/ToastMessages';
import commonApi from '../../Services/CommonService';
import { getItem } from '../../Utils/LocalStorage';
import { RoiLogo } from '../../Constants/Images';
const LeaderBoardDeatil = ({
  selectedEstimate,
  setSelectedEstimate,
  setSelectedEstimateItemCost,
  estimateItem,
  onClose,
  setEstimates,
  estimates,
  selectedFilterValue,
  getUserInvoices,
  setSearchQuery,
  setShowLoader,
}) => {
  const [showAll, setShowAll] = useState([]);

  const [readMore, setReadMore] = useState([]);
  const [selectedEstimates, setSelectedEstimates] = useState([]);
  const [selectedStandard, setSelectedStandard] = useState([]);

  const handleComparisonItem = item => {
    // const updatedGroup = estimates.map((MiniItem, index) => {
    //   if (MiniItem.invoices[0].comparison_match === item.comparison_match) {
    //     return {
    //       ...MiniItem,
    //       selected_id: item.id,
    //       comparison_match: item.comparison_match,
    //       cost: item.total,
    //     };
    //   }
    //   return MiniItem;
    // });

    // setEstimates(updatedGroup);

    setSelectedEstimates([item.id]);

    sendSelectedEstimates([item.id]);

    // onClose();
  };

  const handleStandardItem = item => {
    if (!selectedEstimates.includes(item.id)) {
      setSelectedEstimates(previous => [...previous, item.id]);
      setSelectedStandard(previous => [...previous, item]);
    } else {
      setSelectedEstimates(previous =>
        previous.filter(miniId => miniId !== item.id),
      );
      setSelectedStandard(previous =>
        previous.filter(MiniItem => MiniItem.id !== item.id),
      );
    }
  };

  const handleShowAll = id => {
    if (!showAll.includes(id)) {
      setShowAll(previous => [...previous, id]);
    } else {
      setShowAll(previous => previous.filter(item => item !== id));
    }
  };

  const handleReadMore = id => {
    if (!readMore.includes(id)) {
      setReadMore(previous => [...previous, id]);
    } else {
      setReadMore(previous => previous.filter(item => item !== id));
    }
  };

  const sendSelectedEstimates = comparison => {
    var payload = {
      comp_id:
        comparison && comparison.length > 0 ? comparison : selectedEstimates,
      comparison_match: estimateItem.invoices[0].comparison_match,
      sub_type: estimateItem.invoices[0].sub_type,
    };

    commonApi
      .estimate_to_invoice(payload)
      .then(res => {
        if (res.success === true) {
          successMessage(res.message);
          setSearchQuery('');
          setShowLoader(true);
          getUserInvoices('estimate');
          onClose();
        } else {
          errorMessage(res.message);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const ItemDetail = ({item, index}) => {
    const [moreOptions, setMoreOptions] = useState(false);

    const [levels, setLevels] = useState([]);

    const [durations, setDuartions] = useState([]);
    const [levelType, setLevelType] = useState('');
    const [durationType, setDurationType] = useState('');
    const [durationItem, setDurationItem] = useState({});
    const [levelItem, setLevelItem] = useState({});

    const handleAmount = item => {
      setDurationItem(item ? item : {});
    };

    useEffect(() => {
      if (item.catalog_type === 'membership') {
        if (item.level) {
          setLevelItem(item.level);
          setLevels([item.level]);
          setLevelType(item.level.id);
        }
      }
    }, []);

    useEffect(() => {
      if (item.catalog_type === 'membership' && levelType !== '') {
        setDuartions([item.level.frequency]);

        setDurationType(item.level.frequency.id);
        handleAmount(item.level.frequency);
      }
    }, [levelType]);

    let dropdownWidth = {
      width: '181.55px',
    };

    return (
      <div key={`catalog_details${index}`}>
        <h1 className="mb-2 text-break">{item.title}</h1>
        <div className="template-flex ">
          <span className="template-text f_700">
            {item.duration ? formatMinutesTime(item.duration) : ''}
          </span>

          {item.catalog_type === 'membership' && (
            <div
              className={`d-flex gap-2 ${
                item.catalog_type === 'membership' ? 'flex-1' : ''
              }`}>
              {item.catalog_type === 'membership' && levels.length > 0 && (
                <div className="d-flex gap-2">
                  <div className="f_700">Level:</div>
                  <div>
                    {levelType
                      ? levels.find(item => item.id === levelType).level_name
                      : ''}{' '}
                    (
                    {formatSelectedDuration(
                      durationType
                        ? durations.find(item => item.id === durationType)
                        : '',
                    )}
                    )
                  </div>
                </div>
              )}
              {/* {item.catalog_type === 'membership' && levels.length > 0 && (
                <div className="mb-3" style={dropdownWidth}>
                  <Form.Label controlid="validationCustom01">
                    Level{' '}
                  </Form.Label>
                  <Dropdown
                    className="select-wrapper"
                    onSelect={(eventKey, event) => {
                      var item = JSON.parse(eventKey);
                      setLevelItem(item);
                      setLevelType(item.id);
                    }}
                    drop="down">
                    <Dropdown.Toggle variant="primary" disabled={true}>
                      {levelType
                        ? levels.find(item => item.id === levelType).level_name
                        : 'Select Role'}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {levels.map((item, index) => (
                        <Dropdown.Item
                          key={`st-${index}`}
                          eventKey={JSON.stringify(item)}
                          active={item.id === levelType}>
                          {item.level_name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )} */}

              {/* {item.catalog_type === 'membership' &&
                levels.length > 0 &&
                durations.length > 0 && (
                  <div className="mb-3" style={dropdownWidth}>
                    <Form.Label controlid="validationCustom01">
                      Duration{' '}
                    </Form.Label>
                    <Dropdown
                      className="select-wrapper "
                      onSelect={(eventKey, event) => {
                        var item = JSON.parse(eventKey);
                        handleAmount(item);
                        setDurationType(item.id);
                      }}
                      drop="down">
                      <Dropdown.Toggle variant="primary" disabled={true}>
                        {formatPaymentDescription(
                          durationType
                            ? durations.find(item => item.id === durationType)
                            : 'Select Role',
                        )}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {durations.map((item, index) => (
                          <Dropdown.Item
                            key={`st-${index}`}
                            eventKey={JSON.stringify(item)}
                            // eventKey={item.id.toString()}
                            active={item.id === durationType}>
                            {formatPaymentDescription(item)}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                )} */}
            </div>
          )}
          <span>${formatNumberWithTwoDecimals(item.cost)}</span>
        </div>

        {!moreOptions && (
          <div
            className="more-option detail_btn as-link fit_content mb-3"
            onClick={() => {
              setMoreOptions(!moreOptions);
            }}>
            View More
          </div>
        )}
        {moreOptions && (
          <>
            {/* <div className="template-flex">
                          <span className="f_400">Subtotal</span>

                          <span className="f_600">
                            $
                            {calculateSubtotal(invoiceItem.sub_total)}
                          </span>
                        </div> */}

{item.created_by === getItem('id') &&
              item.expense_ids &&
              item.expense_ids.length > 0 && (
                <div className="payment_milestone_flex">
                  <div className="f_700 mb-2">Expenses</div>

                  {item.expense_deatils.map((expense, index) => (
                    <div key={`estimateInvoices-${index}`} className='mb-2'>
                      <div className="d-flex gap-2">
                        <span className=" f_700">Title:</span>
                        <span className="text-break"> {expense.title}</span>
                      </div>

                      {expense.description ? (
                        <div className="d-flex gap-2">
                          <span className="text-break f_700">Description:</span>
                          <span className="text-break">{expense.description}</span>
                        </div>
                      ) : null}

                      <div className="d-flex gap-2">
                        <span className="f_700">Cost :</span>
                        <span className="text-break"> ${expense.cost}</span>
                      </div>
                    </div>
                  ))}
                </div>
              )}

            {item.selected_payment_type === 'subscription' &&
              item.subscription_month &&
              item.subscription_month !== '' && (
                <div className="template-flex">
                  <span className="f_400">Subscription</span>

                  <span className="template-text f_600">
                    {getLabelFromSubscriptionMonth(item.subscription_month)}
                  </span>
                </div>
              )}

            {(item.selected_payment_type ==='milestone' && item.payment_milestone &&
              item.payment_milestone.length > 0 ) && (
                <div className="payment_milestone_flex">
                  <div className="f_400 mb-2">Payment Milestone</div>
                  <div className="payment_milestone_list">
                    <ul>
                      {item.payment_milestone.length > 0 &&
                        item.payment_milestone.map(
                          (milestone, index) => (
                            <li key={`estimateInvoices-${index}`}>
                              <span className="text-break">
                                {moment(milestone.date).format('YYYY-MM-DD')} -
                                ($
                                {milestone.amount})
                              </span>
                            </li>
                          ),
                        )}
                    </ul>
                  </div>
                </div>
              )}

            <div className="template-flex">
              <span className="f_400">Taxes and Fees</span>

              <span className="template-text f_600">
                ${formatNumberWithTwoDecimals(item.taxes_fee)}
              </span>
            </div>

            <div className="template-flex">
              <span className="f_400">Discount</span>

              <span className="template-text f_600">
                ${formatNumberWithTwoDecimals(item.discount)}
              </span>
            </div>

            {item.catalog_type === 'product' && (
              <div className="template-flex">
                <span className="f_400">Shipping & handling</span>

                <span className="template-text f_600">
                  ${formatNumberWithTwoDecimals(item.shipping_handling)}
                </span>
              </div>
            )}

            {(item.catalog_type === 'service' ||
              item.catalog_type === 'membership') && (
              <div className="template-flex">
                <span className="f_400">Late Fee</span>

                <span className="template-text f_600">
                  ${formatNumberWithTwoDecimals(item.late_fee)}
                </span>
              </div>
            )}

            <div className="template-flex">
              <span className="f_400">Subtotal</span>

              <span className="f_600">${calculateItemSubtotal(item)}</span>
            </div>
          </>
        )}

        {moreOptions && (
          <div
            className="more-option detail_btn as-link fit_content mb-3"
            onClick={() => {
              setMoreOptions(!moreOptions);
            }}>
            View Less
          </div>
        )}
      </div>
    );
  };

  const renderHeader = () => {
  
    return (
      <>
        
          <img src={RoiLogo} alt="" />
        
      </>
    );
  };
  console.log(showAll, 'showAll');

  return (
    <>
      <SlidingPanel
        hidePanel={() => {
          onClose();
        }}
        renderData={renderHeader}
    showBlackHeader={true}
    headerPadding={'24px 20px'}
    lastDivStyle={{borderRadius: '35px', background: '#f7f6f2'}}
    firstDivStyle={{
      padding: 0,
      background:
        'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)',
    }}
        >
        <div className="serach-filter-wrapper create-sessionEvents pt-3">
          <Scrollbars>
            <div className="create-ticket-form fixed-width-inputs black-head-form-pad-left fixed-width-inputs fix-width-forms">
              <div className="leaderboard_detail_screen">
                {Object.entries(estimateItem.invoices).length > 0 &&
                  estimateItem.invoices.map((item, index) => {
                    const showMoreClass = readMore.includes(item.id)
                      ? 'show-all'
                      : '';
                    const customerNote = item.customer_note
                      ? item.customer_note.split('\n')
                      : '';

                    return (
                      <div
                        className="leaderboard_card invoice_detail_page create-template mb-4"
                        key={`estimateInvoices-${index}`}>
                        <div className="leaderboard_card_top">
                          <span>{item.group_name}</span>

                          <span>
                            ${formatNumberWithCommas(item.total)}
                            {item.sub_type === 'standard' && (
                              <span>
                                {'  '} ({item.add_on.toUpperCase()})
                              </span>
                            )}
                          </span>
                        </div>

                        <div className="leaderboard_inner_card">
                          {/* <h1>House Refresh Basic</h1> */}
                          <h1>{item.invoice_title}</h1>

                          {/* <div className="ld_rate_flex">
                        <div>
                          {' '}
                          <b>Was</b> <span> $6,200</span>
                        </div>

                        <div>
                          {' '}
                          <b>Now</b> <span>$4,500 </span>
                        </div>
                      </div> */}

                          <span>Included</span>

                          <div className="ld_list_card">
                            <ul>
                              {item.catalog_details.length > 0 &&
                                item.catalog_details.map((Item, index) => {
                                  let limit = showAll.includes(item.id)
                                    ? item.catalog_details.length
                                    : 2;

                                  if (index < limit) {
                                    return (
                                      <li key={`estimateInvoices-${index}`}>
                                        <span className="text-break">
                                          {Item.title}
                                        </span>
                                      </li>
                                    );
                                  }
                                })}
                            </ul>

                            {!showAll.includes(item.id) && (
                              <span
                                onClick={() => {
                                  handleShowAll(item.id);
                                }}
                                className="as-link">
                                View More
                              </span>
                            )}

                            {showAll.includes(item.id) && (
                              <span
                                onClick={() => {
                                  handleShowAll(item.id);
                                }}
                                className="as-link">
                                View Less
                              </span>
                            )}
                          </div>
                        </div>

                        {showAll.includes(item.id) && (
                          <div
                            className="create-template-content "
                            style={{padding: '16px'}}>
                            <div className="invoice ">
                              <div className="template-flex-total invoice_due px-2">
                                <span>
                                  Due{' '}
                                  {moment(item?.due_date)
                                    .format('MM/DD/YY')
                                    .toString()}
                                </span>
                                <span className="f_600">
                                  ${formatNumberWithTwoDecimals(item.total)}
                                </span>
                              </div>

                              {item.catalog_details.length > 0 &&
                                item.catalog_details.map((MiniItem, index) => (
                                  // <div
                                  //   className="px-2"
                                  //   key={`catalog_details${index}`}>
                                  //   <h1 className="mb-2">{MiniItem.title}</h1>
                                  //   <div className="template-flex ">
                                  //     <span className=" f_700">
                                  //       {MiniItem.duration
                                  //         ? formatMinutesTime(MiniItem.duration)
                                  //         : ''}
                                  //     </span>

                                  //     <span>
                                  //       $
                                  //       {formatNumberWithTwoDecimals(
                                  //         MiniItem.cost,
                                  //       )}
                                  //     </span>
                                  //   </div>
                                  // </div>

                                  <ItemDetail item={MiniItem} index={index} />
                                ))}

                              {/* <div className="px-2">
                                <div className="template-flex">
                                  <span className="f_400">Subtotal</span>

                                  <span className="f_600">
                                    $
                                    {formatNumberWithTwoDecimals(
                                      item.sub_total,
                                    )}
                                  </span>
                                </div>

                                <div className="template-flex">
                                  <span className="f_400">Taxes and Fees</span>

                                  <span className=" f_600">
                                    $
                                    {formatNumberWithTwoDecimals(
                                      item.taxes_fee,
                                    )}
                                  </span>
                                </div>

                                <div className="template-flex">
                                  <span className="f_400">Discount</span>

                                  <span className=" f_600">
                                    $
                                    {formatNumberWithTwoDecimals(item.discount)}
                                  </span>
                                </div>

                                <div className="template-flex">
                                  <span className="f_400">
                                    Shipping & handling
                                  </span>

                                  <span className=" f_600">
                                    $
                                    {formatNumberWithTwoDecimals(
                                      item.shipping_handling,
                                    )}
                                  </span>
                                </div>
                              </div> */}

                              {item.customer_note !== '' && (
                                <div className="leaderboard_inner_card mt-2 px-2 mb-2">
                                  <h1>Note :</h1>
                                  <div className="message-other-users">
                                    <div className="watchers-imgs">
                                      <div className="flex align-items-center notes-view mt-2">
                                        <div>
                                          <FormattedText
                                            className={`profile-description ${showMoreClass}`}
                                            content={item.customer_note}
                                          />

                                          {(item.customer_note.length > 250 ||
                                            customerNote.length > 3) && (
                                            <>
                                              {' '}
                                              <span
                                                className="show-more-link"
                                                onClick={() =>
                                                  handleReadMore(item.id)
                                                }>
                                                {!readMore.includes(item.id)
                                                  ? labels.read_more[
                                                      selected_lang
                                                    ]
                                                  : labels.read_less[
                                                      selected_lang
                                                    ]}
                                              </span>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}

                              <div className="template-flex-total px-2">
                                <span className="template-text f_700">
                                  Total
                                </span>

                                <span className="f_700">
                                  ${formatNumberWithTwoDecimals(item.total)}
                                </span>
                              </div>

                              <div className="template-flex payment-schedule px-2">
                                <span>Payment Schedule</span>

                                <span className="f_600">
                                  {item.catalog_details.length > 0 &&
                                  checkSubscriptionType(
                                    item.catalog_details,
                                  ) === 'monthly'
                                    ? 'Recurring Monthly'
                                    : checkSubscriptionType(
                                        item.catalog_details,
                                      )}
                                </span>
                              </div>
                            </div>
                          </div>
                        )}

                        {/* {item.customer_note !== '' && (
                        <div className="leaderboard_inner_card mt-2">
                            <h1>Note :</h1>
                            <div className="message-other-users">
                              <div className="watchers-imgs">
                                <div className="flex align-items-center notes-view mt-2">
                                  <div>
                                    <FormattedText
                                      className={`profile-description ${showMoreClass}`}
                                      content={item.customer_note}
                                    />

                                    {(item.customer_note.length > 250 ||
                                      customerNote.length > 3) && (
                                      <>
                                        {' '}
                                        <span
                                          className="show-more-link"
                                          onClick={() =>
                                            handleReadMore(item.id)
                                          }>
                                          {!readMore.includes(item.id)
                                            ? labels.read_more[selected_lang]
                                            : labels.read_less[selected_lang]}
                                          
                                        </span>
                                      </>
                                    )}
                                  </div>

                                  
                                </div>
                              </div>
                            </div>
                            </div>
                        )} */}

                        <div className="leaderboard_card_bottom">
                          <span>Financing Available</span>

                          {!estimateItem.pay_able && (
                            <>
                              {selectedFilterValue !== 'from_me' &&
                                item.sub_type === 'comparison' && (
                                  <Button
                                    className="new-btn btn-green  "
                                    // disabled={estimateItem.pay_able ? true : false}
                                    onClick={() => {
                                      handleComparisonItem(item);
                                    }}>
                                    Select
                                  </Button>
                                )}

                              {selectedFilterValue !== 'from_me' &&
                                item.sub_type === 'standard' && (
                                  <Button
                                    className="new-btn btn-green  "
                                    // disabled={estimateItem.pay_able ? true : false}
                                    onClick={() => {
                                      handleStandardItem(item);
                                    }}>
                                    {selectedEstimates.includes(item.id)
                                      ? 'Unselect'
                                      : 'Select'}
                                  </Button>
                                )}
                            </>
                          )}

                          {estimateItem.pay_able &&
                            selectedFilterValue !== 'from_me' && (
                              <Button
                                className="new-btn btn-green  "
                                disabled={estimateItem.pay_able ? true : false}>
                                {item.action_status === 'select'
                                  ? 'Selected'
                                  : 'Unselected'}
                              </Button>
                            )}
                        </div>
                      </div>
                    );
                  })}

                {/* <div className="leaderboard_card leaderboard_greeen">
                  <div className="leaderboard_card_top">
                    <span>Better</span>
                    <div>
                      <i
                        className="fa-solid fa-star"
                        style={{color: '#FFEC42'}}
                      />{' '}
                      <span>$4,500</span>
                    </div>
                  </div>

                  <div className="leaderboard_inner_card">
                    <h1>House Refresh Basic</h1>

                    <div className="ld_rate_flex">
                      <div>
                        {' '}
                        <b>Was</b> <span> $6,200</span>
                      </div>

                      <div>
                        {' '}
                        <b>Now</b> <span>$4,500 </span>
                      </div>
                    </div>

                    <span>Included</span>

                    <div className="ld_list_card">
                      <ul>
                        <li>
                          <span>Ozone cleaning</span>
                        </li>
                        <li>
                          <span>Carpet shampooing</span>
                        </li>
                      </ul>

                      <span>View More</span>
                    </div>
                  </div>

                  <div className="leaderboard_card_bottom">
                    <span>Financing Available</span>

                    <Button className="new-btn btn-green  " onClick={() => {}}>
                      Select
                    </Button>
                  </div>
                </div> */}
              </div>
            </div>
          </Scrollbars>

          {selectedFilterValue !== 'from_me' &&
            estimateItem.invoices[0].sub_type === 'standard' &&
            selectedEstimates.length > 0 && (
              <div className="filter-btn-wrapper fix-width-forms pt-2">
                <div className="invite-btn text-center">
                  <Button
                    className="btn-dark m-0"
                    type="button"
                    onClick={() => {
                      if (validateStandard(selectedStandard)) {
                        sendSelectedEstimates([]);
                      } else {
                        errorMessage('Please Select Atleast One OR Group ');
                      }
                    }}>
                    Proceed
                  </Button>
                </div>
              </div>
            )}
        </div>
      </SlidingPanel>
    </>
  );
};

export default LeaderBoardDeatil;
